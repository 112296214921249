/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { IPropsFormDialog } from '../generics/formInterfaces';
import { FormDialog } from './FormDialog';
import { editFormData } from './functions';

export function GenericFormDialog<T extends FormikValues>(props: IPropsFormDialog<T>) {
    const [objectData] = useState(props.objectData);
    const formData = useFormik<T>({
        initialValues: props.emptyFormData, validate: props.validate,
        onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => {
            props.setLoading(true);
            const data = Object.assign<T, any>(values, props.submitForceData);
            if (props.onSubmit) props.onSubmit(props, data, formData);
        }
    });
    useEffect(editFormData<T, any>(props, formData, props.editForceData, props.editFunctions), [props.editFormData]);
    // console.log('formData: ', props.editFormData);
    return (
        <FormDialog<T>
            title={props.title}
            icon={props.icon}
            objectData={objectData}
            showDialog={props.showDialog}
            formData={formData}
            setDialogData={props.setDialogData}
            fields={props.retrieveFields(formData, props.formLists)}
            saveButtonDisabled={props.saveButtonDisabled || false}
            cancelButtonDisabled={props.cancelButtonDisabled || false} showStepDialog={false} />
    );
}

