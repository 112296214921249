import moment from 'moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { useEffect, useState } from 'react';
import { padRight } from '../../../utils/functions/string';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import './MessageTrigger.css';


const contactsArray1 = [
    { _id: 1, dddPhone: '21', mobilePhone: '987654321', nameContact: 'Pedro Silva', status: 'pendente', processDateTime: undefined },
    { _id: 2, dddPhone: '11', mobilePhone: '988765432', nameContact: 'Mariana Oliveira', status: 'pendente', processDateTime: undefined },
    { _id: 3, dddPhone: '31', mobilePhone: '989876543', nameContact: 'Lucas Souza', status: 'pendente', processDateTime: undefined },
    { _id: 4, dddPhone: '41', mobilePhone: '990987654', nameContact: 'Gabriela Andrade', status: 'pendente', processDateTime: undefined },
    { _id: 5, dddPhone: '51', mobilePhone: '991098765', nameContact: 'Rafael Costa', status: 'pendente', processDateTime: undefined },
    { _id: 6, dddPhone: '21', mobilePhone: '992109876', nameContact: 'Fernanda Lima', status: 'pendente', processDateTime: undefined },
    { _id: 7, dddPhone: '11', mobilePhone: '993210987', nameContact: 'Bruno Rocha', status: 'pendente', processDateTime: undefined },
    { _id: 8, dddPhone: '31', mobilePhone: '994321098', nameContact: 'Juliana Alves', status: 'pendente', processDateTime: undefined },
    { _id: 9, dddPhone: '41', mobilePhone: '995432109', nameContact: 'Renato Borges', status: 'pendente', processDateTime: undefined },
    { _id: 10, dddPhone: '51', mobilePhone: '996543210', nameContact: 'Tatiana Mendes', status: 'pendente', processDateTime: undefined },
];

const contactsArray2 = [
    { _id: 1, dddPhone: '71', mobilePhone: '980123456', nameContact: 'Sérgio Cardoso', status: 'pendente', processDateTime: undefined },
    { _id: 2, dddPhone: '11', mobilePhone: '981234567', nameContact: 'Aline Ramos', status: 'pendente', processDateTime: undefined },
    { _id: 3, dddPhone: '61', mobilePhone: '982345678', nameContact: 'Carlos Nogueira', status: 'pendente', processDateTime: undefined },
    { _id: 4, dddPhone: '81', mobilePhone: '983456789', nameContact: 'Daniela Barbosa', status: 'pendente', processDateTime: undefined },
    { _id: 5, dddPhone: '91', mobilePhone: '984567890', nameContact: 'Felipe Martins', status: 'pendente', processDateTime: undefined },
    { _id: 6, dddPhone: '71', mobilePhone: '985678901', nameContact: 'Patrícia Silva', status: 'pendente', processDateTime: undefined },
    { _id: 7, dddPhone: '61', mobilePhone: '986789012', nameContact: 'Thiago Freitas', status: 'pendente', processDateTime: undefined },
    { _id: 8, dddPhone: '81', mobilePhone: '987890123', nameContact: 'Letícia Melo', status: 'pendente', processDateTime: undefined },
    { _id: 9, dddPhone: '91', mobilePhone: '988901234', nameContact: 'Marcelo Cunha', status: 'pendente', processDateTime: undefined },
    { _id: 10, dddPhone: '71', mobilePhone: '989012345', nameContact: 'Carla Dias', status: 'pendente', processDateTime: undefined },
    { _id: 11, dddPhone: '11', mobilePhone: '990123456', nameContact: 'Paula Monteiro', status: 'pendente', processDateTime: undefined },
];

const contactsArray3 = [
    { _id: 1, dddPhone: '19', mobilePhone: '981234567', nameContact: 'Rodrigo Santos', status: 'pendente', processDateTime: undefined },
    { _id: 2, dddPhone: '12', mobilePhone: '982345678', nameContact: 'Clarice Ferreira', status: 'pendente', processDateTime: undefined },
    { _id: 3, dddPhone: '13', mobilePhone: '983456789', nameContact: 'Eduardo Araújo', status: 'pendente', processDateTime: undefined },
    { _id: 4, dddPhone: '14', mobilePhone: '984567890', nameContact: 'Luana Braga', status: 'pendente', processDateTime: undefined },
    { _id: 5, dddPhone: '15', mobilePhone: '985678901', nameContact: 'Ricardo Xavier', status: 'pendente', processDateTime: undefined },
    { _id: 6, dddPhone: '19', mobilePhone: '986789012', nameContact: 'Tatiane Gomes', status: 'pendente', processDateTime: undefined },
    { _id: 7, dddPhone: '12', mobilePhone: '987890123', nameContact: 'Carlos Mendes', status: 'pendente', processDateTime: undefined },
    { _id: 8, dddPhone: '13', mobilePhone: '988901234', nameContact: 'Sofia Almeida', status: 'pendente', processDateTime: undefined },
    { _id: 9, dddPhone: '14', mobilePhone: '989012345', nameContact: 'Diego Lima', status: 'pendente', processDateTime: undefined },
    { _id: 10, dddPhone: '15', mobilePhone: '990123456', nameContact: 'Adriana Neves', status: 'pendente', processDateTime: undefined },
    { _id: 11, dddPhone: '19', mobilePhone: '991234567', nameContact: 'Bruno Pires', status: 'pendente', processDateTime: undefined },
    { _id: 12, dddPhone: '12', mobilePhone: '992345678', nameContact: 'Fernanda Cruz', status: 'pendente', processDateTime: undefined },
    { _id: 13, dddPhone: '13', mobilePhone: '993456789', nameContact: 'Mateus Moreira', status: 'pendente', processDateTime: undefined },
    { _id: 14, dddPhone: '14', mobilePhone: '994567890', nameContact: 'Beatriz Farias', status: 'pendente', processDateTime: undefined },
    { _id: 15, dddPhone: '15', mobilePhone: '995678901', nameContact: 'Henrique Matos', status: 'pendente', processDateTime: undefined },
    { _id: 16, dddPhone: '19', mobilePhone: '996789012', nameContact: 'Larissa Nunes', status: 'pendente', processDateTime: undefined },
    { _id: 17, dddPhone: '12', mobilePhone: '997890123', nameContact: 'André Lopes', status: 'pendente', processDateTime: undefined },
    { _id: 18, dddPhone: '13', mobilePhone: '998901234', nameContact: 'Priscila Carvalho', status: 'pendente', processDateTime: undefined },
    { _id: 19, dddPhone: '14', mobilePhone: '999012345', nameContact: 'Caio Ribeiro', status: 'pendente', processDateTime: undefined },
    { _id: 20, dddPhone: '15', mobilePhone: '990987654', nameContact: 'Elaine Silveira', status: 'pendente', processDateTime: undefined },
];

// Quarto Array
const contactsArray4 = [
    { _id: 1, dddPhone: '61', mobilePhone: '981111111', nameContact: 'Felipe Mendes', status: 'pendente', processDateTime: undefined },
    { _id: 2, dddPhone: '62', mobilePhone: '982222222', nameContact: 'Giovana Sousa', status: 'pendente', processDateTime: undefined },
    { _id: 3, dddPhone: '63', mobilePhone: '983333333', nameContact: 'Maurício Alves', status: 'pendente', processDateTime: undefined },
    { _id: 4, dddPhone: '64', mobilePhone: '984444444', nameContact: 'Simone Paiva', status: 'pendente', processDateTime: undefined },
    { _id: 5, dddPhone: '65', mobilePhone: '985555555', nameContact: 'Victor Barros', status: 'pendente', processDateTime: undefined },
    { _id: 6, dddPhone: '61', mobilePhone: '986666666', nameContact: 'Tatiana Carvalho', status: 'pendente', processDateTime: undefined },
    { _id: 7, dddPhone: '62', mobilePhone: '987777777', nameContact: 'Cláudio Batista', status: 'pendente', processDateTime: undefined },
    { _id: 8, dddPhone: '63', mobilePhone: '988888888', nameContact: 'Gabriel Viana', status: 'pendente', processDateTime: undefined },
    { _id: 9, dddPhone: '64', mobilePhone: '989999999', nameContact: 'Isabel Fernandes', status: 'pendente', processDateTime: undefined },
    { _id: 10, dddPhone: '65', mobilePhone: '990000000', nameContact: 'Renata Costa', status: 'pendente', processDateTime: undefined },
    { _id: 11, dddPhone: '61', mobilePhone: '991111222', nameContact: 'Márcio Pereira', status: 'pendente', processDateTime: undefined },
    { _id: 12, dddPhone: '62', mobilePhone: '992222333', nameContact: 'Ana Beatriz', status: 'pendente', processDateTime: undefined },
    { _id: 13, dddPhone: '63', mobilePhone: '993333444', nameContact: 'Roberta Nascimento', status: 'pendente', processDateTime: undefined },
    { _id: 14, dddPhone: '64', mobilePhone: '994444555', nameContact: 'João Lopes', status: 'pendente', processDateTime: undefined },
    { _id: 15, dddPhone: '65', mobilePhone: '995555666', nameContact: 'Regina Martins', status: 'pendente', processDateTime: undefined },
    { _id: 16, dddPhone: '61', mobilePhone: '996666777', nameContact: 'Patrícia Dias', status: 'pendente', processDateTime: undefined },
    { _id: 17, dddPhone: '62', mobilePhone: '997777888', nameContact: 'Flávio Azevedo', status: 'pendente', processDateTime: undefined },
    { _id: 18, dddPhone: '63', mobilePhone: '998888999', nameContact: 'Mônica Lima', status: 'pendente', processDateTime: undefined },
];




const lMessageTriggers = [
    {
        _id: 1,
        description: 'Mensagem de Feliz Aniversário',
        message: 'Desejamos um maravilhoso dia para você e esperamos que esteja conosco em breve para festejarmos juntos e um dia de brindes.',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Leste',
        companyNumber: '27.351.241/0001-31',
        status: 'pendente',
        requestDateTime: moment().set('hours', 10).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Francisco',
        processingStartDateTime: undefined,
        processingEndDateTime: undefined,
        windowStartDateTime: '9:00',
        windowEndDateTime: '18:00',
        processInWeekend: true,
        cancellationDateTime: '',
        cancellationUser: '',
        intervalMessage: '300',
        contacts: contactsArray1
    },
    {
        _id: 2,
        description: 'Mensagem promoção em aula de Dança',
        message: 'Temos uma nova promoção para você, entre em contato conosco para aprovietar as novas aulas.',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Shopping Central',
        companyNumber: '27.351.241/0001-31',
        status: 'pendente',
        requestDateTime: moment().set('hours', 9).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Francisco',
        processingStartDateTime: undefined,
        processingEndDateTime: undefined,
        windowStartDateTime: '8:00',
        windowEndDateTime: '21:00',
        processInWeekend: false,
        cancellationDateTime: '',
        cancellationUser: '',
        intervalMessage: '120',
        contacts: contactsArray1
    },
    {
        _id: 3,
        description: 'Mensagem de Boas-vindas',
        message: 'Bem-vindo(a) à nossa academia! Estamos muito felizes em ter você com a gente. Conte conosco para atingir seus objetivos!',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Central',
        companyNumber: '27.351.241/0001-31',
        status: 'pendente',
        requestDateTime: moment().set('hours', 8).set('minutes', 30).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Mariana',
        processingStartDateTime: undefined,
        processingEndDateTime: undefined,
        windowStartDateTime: '7:00',
        windowEndDateTime: '20:00',
        processInWeekend: false,
        cancellationDateTime: '',
        cancellationUser: '',
        intervalMessage: '180',
        contacts: contactsArray2
    },
    {
        _id: 4,
        description: 'Lembrete de Renovação de Plano',
        message: 'Seu plano está próximo de expirar! Venha renovar e garanta os mesmos benefícios por mais um período.',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Norte',
        companyNumber: '27.351.241/0001-31',
        status: 'em andamento',
        requestDateTime: moment().set('hours', 14).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Rafael',
        processingStartDateTime: moment().subtract(-4, 'hours').set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        processingEndDateTime: undefined,
        windowStartDateTime: '10:00',
        windowEndDateTime: '18:00',
        processInWeekend: false,
        cancellationDateTime: '',
        cancellationUser: '',
        intervalMessage: '240',
        contacts: contactsArray3.map(c => { return { _id: c._id, dddPhone: c.dddPhone, mobilePhone: c.mobilePhone, nameContact: c.nameContact, status: c._id < 10 ? 'processado' : 'pendente', processDateTime: c._id < 10 ? moment().subtract(c._id, 'minutes').set('seconds', 0).set('milliseconds', 0) : undefined } })
    },
    {
        _id: 5,
        description: 'Mensagem de Parabéns por Conquistas',
        message: 'Parabéns por completar mais um mês de treinos! Continue assim e estaremos sempre aqui para apoiar.',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Sul',
        companyNumber: '27.351.241/0001-31',
        status: 'cancelado',
        requestDateTime: moment().set('hours', 12).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Francisco',
        processingStartDateTime: undefined,
        processingEndDateTime: undefined,
        windowStartDateTime: '9:00',
        windowEndDateTime: '20:00',
        processInWeekend: true,
        cancellationDateTime: moment().set('hours', 12).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        cancellationUser: 'Francisco',
        intervalMessage: '360',
        contacts: contactsArray4.map(c => { return { _id: c._id, dddPhone: c.dddPhone, mobilePhone: c.mobilePhone, nameContact: c.nameContact, status: 'cancelado', processDateTime: undefined } })
    },
    {
        _id: 6,
        description: 'Convite para Evento de Nutrição',
        message: 'Vamos ter um evento especial sobre nutrição saudável na próxima semana. Inscreva-se e venha aprender com a gente!',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Leste',
        companyNumber: '27.351.241/0001-31',
        status: 'finalizado',
        requestDateTime: moment().subtract(-3, 'days').set('hours', 15).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Francisco',
        processingStartDateTime: moment().subtract(-1, 'days').set('hours', 8).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        processingEndDateTime: moment().subtract(-1, 'days').set('hours', 18).set('minutes', 30).set('seconds', 0).set('milliseconds', 0),
        windowStartDateTime: '10:00',
        windowEndDateTime: '19:00',
        processInWeekend: false,
        cancellationDateTime: '',
        cancellationUser: '',
        intervalMessage: '300',
        contacts: contactsArray2.map(c => { return { _id: c._id, dddPhone: c.dddPhone, mobilePhone: c.mobilePhone, nameContact: c.nameContact, status: 'processado', processDateTime: moment().subtract(-1, 'days').set('hours', 12).set('minutes', 0).set('seconds', 0).set('milliseconds', 0) } })
    },
    {
        _id: 7,
        description: 'Lembrete de Avaliação Física',
        message: 'Não esqueça sua avaliação física agendada para amanhã! Vamos acompanhar sua evolução.',
        networkGym: 'Rede Chat4Fit',
        branchGym: 'Unidade Oeste',
        companyNumber: '27.351.241/0001-31',
        status: 'finalizado',
        requestDateTime: moment().subtract(-4, 'days').set('hours', 16).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        ERPRequestUser: 'Mariana',
        processingStartDateTime: moment().subtract(-2, 'days').set('hours', 8).set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
        processingEndDateTime: moment().subtract(-2, 'days').set('hours', 18).set('minutes', 30).set('seconds', 0).set('milliseconds', 0),
        windowStartDateTime: '8:00',
        windowEndDateTime: '20:00',
        processInWeekend: false,
        cancellationDateTime: '',
        cancellationUser: '',
        intervalMessage: '120',
        contacts: contactsArray1.map(c => { return { _id: c._id, dddPhone: c.dddPhone, mobilePhone: c.mobilePhone, nameContact: c.nameContact, status: 'processado', processDateTime: moment().subtract(-2, 'days').set('hours', 12).set('minutes', 0).set('seconds', 0).set('milliseconds', 0) } })
    }
];

const MessageTrigger = (props: IPropsArgsComponents) => {
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [visible, setVisible] = useState(false);
    const [listContacts, setListContacts] = useState<any[]>();
    const [messageTriggers, setMessageTriggers] = useState<any[]>(lMessageTriggers);
    const [messageTriggerRendered, setMessageTriggerRendered] = useState<any[]>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
    ]), []);

    useEffect(() => renderMessageTriggers(), [messageTriggers]);

    const renderMessageTriggers = () => {
        const findOne = messageTriggers.find(m => m.status === 'em andamento');
        setMessageTriggerRendered(messageTriggers.map((trigger, index) => {
            const backgroundColor = trigger.status === 'finalizado' ? '#555' : trigger.status === 'cancelado' ? '#8115' : trigger.status === 'em andamento' ? '#43ac90' : '#fff';
            return <Card className='card-message-trigger' style={{ backgroundColor }}>
                <h5 className="card-title">Disparo: <strong>{trigger.description}</strong></h5>
                <Divider style={{ margin: '5px', borderTop: '2px rgba(0, 0, 0, 0.12)' }} />
                <div className='grid'>
                    <div className='col-10'>

                        <div className='grid'>
                            <div className="card-text col-8" style={{ fontSize: '12px' }}><strong>Mensagem para envio:</strong> {padRight(trigger.message, 70, '')}...</div>
                            <div className="card-text col-4" style={{ fontSize: '15px' }}><strong>Situação:</strong> {trigger.status}</div>
                        </div>
                        <div className='grid'>
                            <div className="card-text col-4"><strong>Solicitado:</strong> {trigger.ERPRequestUser} - ({trigger.requestDateTime.format('DD/MM/YYYY')})</div>
                            <div className="card-text col-3"><strong>Período:</strong> {trigger.windowStartDateTime} / {trigger.windowEndDateTime}</div>
                            <div className="card-text col-3"><strong>Intervalo envio (segundos):</strong> {trigger.intervalMessage}</div>
                            <div className="card-text col-2"><strong>Envia fds?</strong> {trigger.processInWeekend ? 'Sim' : 'Não'}</div>
                            {/* <div className="card-text col-4"><strong>Data/Hora Cancelamento:</strong> {trigger.cancellationDateTime}</div>
                    <div className="card-text col-4"><strong>Usuário que solicitou Cancelamento:</strong> {trigger.cancellationUser}</div> */}
                        </div>
                        {trigger.processingStartDateTime ?
                            <div className='grid'>
                                <div className="card-text col-4"><strong>Inicio:</strong> {trigger.processingStartDateTime?.format('DD/MM/YYYY HH:mm')}</div>
                                {
                                    trigger.status === 'finalizado' ?
                                        <div className="card-text col-4"><strong>Fim:</strong> {trigger.processingEndDateTime?.format('DD/MM/YYYY HH:mm')}</div>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className='flex justify-content-between flex-wrap'>
                        <div style={{ padding: '5px' }}>
                            <Button icon="pi pi-list" rounded style={{ border: '1px solid #555', backgroundColor: '#fff', color: '#000' }} aria-label="Mostrar Contatos" tooltip='Mostrar Contatos' onClick={() => showContacts(trigger)} />
                            {trigger.status !== 'finalizado' && trigger.status !== 'cancelado' ?
                                <Button icon="pi pi-stop" rounded style={{ border: '1px solid #555' }} severity="danger" aria-label="Cancelar" tooltip='Cancelar' onClick={() => cancelTrigger(trigger)} />
                                :
                                null
                            }
                            {trigger.status === 'em andamento' ?
                                <Button icon="pi pi-pause" rounded style={{ border: '1px solid #555' }} severity="warning" aria-label="Pausar" tooltip='Pausar' onClick={() => pauseTrigger(trigger)} />
                                :
                                null
                            }
                            {(trigger.status === 'pendente' || trigger.status === 'pausado') && !findOne ?
                                <Button icon="pi pi-play" rounded style={{ border: '1px solid #555' }} severity="success" aria-label="Continuar" tooltip='Continuar' onClick={() => continueTrigger(trigger)} />
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </Card>
        }));
    }

    const showContacts = (trigger: any) => {
        setListContacts(trigger.contacts);
        setVisible(true);
    }

    const cancelTrigger = (trigger: any) => {
        messageTriggers.forEach((m: any) => {
            if (m._id === trigger._id) {
                m.status = 'cancelado';
                m.cancellationDateTime = new Date().toLocaleString();
            }
        });
        let running = messageTriggers.find((m: any, index: number) => m.status === 'em andamento');

        if (!running) {
            continueTrigger(messageTriggers.find((m: any, index: number) => m.status === 'pendente'))
        }
        setMessageTriggers([...messageTriggers]);
    }

    const pauseTrigger = (trigger: any) => {
        // Código para pausar o disparo, mudar status para "pausado"
        // if (messageTriggers[index].status === 'em processamento') {
        //     messageTriggers[index].status = 'pausado';
        //     renderMessageTriggers();
        // } else {
        //     alert('Somente disparos em processamento podem ser pausados.');
        // }
        messageTriggers.forEach((m: any) => {
            if (m._id === trigger._id) {
                m.status = 'pausado';
                m.cancellationDateTime = new Date().toLocaleString();
            }
        });
        let running = messageTriggers.find((m: any, index: number) => m.status === 'em andamento');

        if (!running) {
            continueTrigger(messageTriggers.find((m: any, index: number) => m.status === 'pendente'))
        }
        setMessageTriggers([...messageTriggers]);
    }

    const continueTrigger = (trigger: any) => {
        // Código para continuar o disparo pausado, mudar status para "pendente"
        // if (messageTriggers[index].status === 'pausado') {
        //     messageTriggers[index].status = 'pendente';
        //     renderMessageTriggers();
        // } else {
        //     alert('Somente disparos pausados podem ser continuados.');
        // }
        if (trigger) {
            messageTriggers.forEach((m: any) => {
                if (m._id === trigger._id) {
                    m.status = 'em andamento';
                }
            });
            setMessageTriggers([...messageTriggers]);
        }
    }


    if (!networkGymId)
        return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
            <h3>Você precisa selecionar uma Rede.</h3>
        </div>);
    else
        return (<>
            <Card title="Lista de Disparos">
                {messageTriggerRendered}
            </Card>
            <Dialog header="Lista de Contatos" visible={visible} style={{ width: '80vw', padding: '20px', backgroundColor: '#fff' }} onHide={() => { if (!visible) return; setVisible(false); }}>

                <div className='grid'>
                    <div className='col-4'><strong>Nome:</strong></div>
                    <div className='col-4'><strong>Número:</strong></div>
                    <div className='col-2'><strong>Status:</strong></div>
                    <div className='col-2'><strong>Data/Hora do Envio:</strong></div>
                </div>
                {listContacts?.map((contact: any) => {

                    return (<div className='grid'>
                        <div className='col-4'> {contact.nameContact}</div>
                        <div className='col-4'> {contact.dddPhone} {contact.mobilePhone}</div>
                        <div className='col-2'> {contact.status}</div>
                        <div className='col-2'>{contact.processDateTime?.subtract(contact._id * -1, 'minutes').format('DD/MM/YYYY HH:mm') || 'Não enviado'}</div>
                    </div>);
                })}
            </Dialog>
        </>
        );


};

export default MessageTrigger;