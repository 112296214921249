/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import BranchGymPermissionService from '../../service/admin/BranchGymPermissionService';
import BranchGymService from '../../service/admin/BranchGymService';
import GroupPermissionService from '../../service/admin/GroupPermissionService';
import HierarchyRoleService from '../../service/admin/HierarchyRoleService';
import PermissionsFunctionalitiesService from '../../service/admin/PermissionsFunctionalitiesService';
import { IFormListDataBranchGymPermission, IFormListDataGroupPermission, IFormListDataHierarchyRole, IFormListDataPermissionsFunctionality } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import { createView } from '../generics/view';
import './BranchGymPermission.css';


const groupPermissionService = new GroupPermissionService();
const hierarchyRoleService = new HierarchyRoleService();
const permissionsFunctionalitiesService = new PermissionsFunctionalitiesService();
const branchGymService = new BranchGymService();
const branchGymPermissionService = new BranchGymPermissionService();


export const BranchGymPermission = (props: IPropsArgsComponents) => {
    const [title, setTitle] = useState('Unidade < Permissões');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dataGroupPermissions, setDataGroupPermissions] = useState<IFormListDataGroupPermission[]>([]);
    const [dataHierarchyRole, setDataHierarchyRole] = useState<IFormListDataHierarchyRole[]>([]);
    const [dataPermissionsFunctionalities, setDataPermissionsFunctionalities] = useState<IFormListDataPermissionsFunctionality[]>([]);
    const [branchGymData, setBranchGymData] = useState<any[]>();

    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormListDataBranchGymPermission[]>([]);
    const [dataBranchGymPermission, setDataBranchGymPermission] = useState<any[]>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: true },
    ]), []);

    useEffect(() => { refreshListGroupPermissions(); refreshListHierarchyRole(); refreshListPermissionsFunctionalities(); }, []);


    useEffect(() => { setTitle(branchGymId ? 'Unidade < Permissões' : 'Permissões') }, [branchGymId]);
    useEffect(() => { refreshListBranchGymPermissions(); }, [networkGymId, branchGymId, branchGymData, dataPermissionsFunctionalities]);

    useEffect(() => { refreshListBranchGyms(); }, []);
    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    const refreshListBranchGymPermissions = () => {
        let isMounted = true;
        props.args.setLoading(true);

        if (networkGymId && branchGymData && branchGymData?.length > 0 && permissionsFunctionalitiesService && dataPermissionsFunctionalities?.length > 0) {
            reloadDataBranchGymPermission(networkGymId, dataPermissionsFunctionalities, setDataBranchGymPermission, isMounted, props.args.setLoading, props.args.setMessage);
        } else {
            props.args.setLoading(false);
            setDataBranchGymPermission([]);
        }
        return () => { isMounted = false };
    };

    const refreshListHierarchyRole = () => {
        let isMounted = true;
        hierarchyRoleService.allHierarchyRoles().then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setDataHierarchyRole([]);
                } else if (isSuccess(returned.status)) {
                    let returnedData = returned.data;
                    setDataHierarchyRole(returnedData);
                } else {
                    setDataHierarchyRole([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setDataHierarchyRole([]);
            }
        });
    }

    const refreshListPermissionsFunctionalities = () => {
        let isMounted = true;
        permissionsFunctionalitiesService.allPermissionsFunctionalities().then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setDataPermissionsFunctionalities([]);
                } else if (isSuccess(returned.status)) {
                    let returnedData = returned.data;
                    setDataPermissionsFunctionalities(returnedData);
                } else {
                    setDataPermissionsFunctionalities([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setDataPermissionsFunctionalities([]);
            }
        });
    }

    const refreshListGroupPermissions = () => {
        let isMounted = true;
        groupPermissionService.allGroupPermissions().then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setDataGroupPermissions([]);
                } else if (isSuccess(returned.status)) {
                    let returnedData = returned.data;
                    setDataGroupPermissions(returnedData);
                } else {
                    setDataGroupPermissions([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setDataGroupPermissions([]);
            }
        });
    }
    const refreshListBranchGyms = async () => {
        let isMounted = true;
        setLoadingData(true);
        const branchGyms: any[] = []

        const branchGymsByStorageToken = await ls.branchGym.getBranchGymsByStorageToken();

        if (branchGymsByStorageToken && branchGymsByStorageToken.length > 0) {
            for (let i = 0; i < branchGymsByStorageToken.length; i++) {
                const branchGym = branchGymsByStorageToken[i];
                const branchGymReturned: any = await branchGymService.findBranchGymById(branchGym._id);
                if (branchGymReturned.data && branchGymReturned.data && !branchGymReturned.data.nonpayer && !branchGymReturned.data.unsubscribe) {
                    branchGyms.push(branchGymReturned.data)
                }
            }
            setBranchGymData(branchGyms);
        } else {
            setBranchGymData([]);
        }

        setLoadingData(false);
        return () => { isMounted = false };
    };

    const reloadDataBranchGymPermission = async (networkGymId: string | null, permissionsFunctionalities: IFormListDataPermissionsFunctionality[], setDataBranchGymPermission: React.Dispatch<any>, isMounted: boolean, setLoading?: any, setMessage?: any) => {
        if (!networkGymId) return setDataBranchGymPermission([]);
        return new Promise((resolve, _reject) => {
            branchGymPermissionService.allNetworkGymPermissions(networkGymId).then(async (returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        if (setMessage) setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { if (setLoading) setLoading(false); window.location.href = '/'; }, 5000);
                        setDataBranchGymPermission([]);
                        resolve(true);
                    } else if (isSuccess(returned.status)) {
                        setDataBranchGymPermission(returned.data);
                        await processListPermissionsFunctionality(permissionsFunctionalities, returned.data);
                        resolve(true);
                    } else {
                        setDataBranchGymPermission([]);
                        await processListPermissionsFunctionality(permissionsFunctionalities, []);
                        resolve(true);
                    }
                    if (setLoading) setLoading(false);
                }
            }).catch(async error => {
                console.error('error', error);
                if (isMounted) {
                    if (setLoading) setLoading(false);
                    setDataBranchGymPermission([]);
                    await processListPermissionsFunctionality(permissionsFunctionalities, []);
                }
                resolve(true);
            });

        })
    }

    const processListPermissionsFunctionality = async (permissionsFunctionalities: IFormListDataPermissionsFunctionality[], branchGymPermissions: IFormListDataBranchGymPermission[]) => {
        if (!branchGymData) return;
        let updateDataBase = false;
        for (let i = 0; i < permissionsFunctionalities.length; i++) {
            const permissionsFunctionality = permissionsFunctionalities[i];
            for (let j = 0; j < branchGymData.length; j++) {
                const branchGym = branchGymData[j];
                if (branchGym?.networkGym?._id === networkGymId) {
                    let branchGymPermissionLocated = branchGymPermissions ? branchGymPermissions.find((branchGymPermission: IFormListDataBranchGymPermission) => {
                        return branchGymPermission.networkGym?._id === networkGymId && branchGymPermission.branchGym?._id === branchGym._id &&
                            branchGymPermission.permissionsFunctionality?._id === permissionsFunctionality._id;
                    }) : undefined;
                    if (!branchGymPermissionLocated) {
                        updateDataBase = true;
                        await branchGymPermissionService.saveBranchGymPermission({
                            networkGym: networkGymId,
                            branchGym: branchGym._id,
                            permissionsFunctionality: permissionsFunctionality._id,
                            role: permissionsFunctionality.defaultRole._id
                        });
                    }
                }
            }
        }
        if (updateDataBase) {
            refreshListBranchGymPermissions();
        }
    }

    const renderListData = () => {
        let dataList: any[] = branchGymData ? branchGymData.filter(branchGym => branchGym.networkGym?._id === networkGymId && (!branchGymId || branchGym?._id === branchGymId)) : [];
        if (!networkGymId || !dataList || !dataGroupPermissions || !dataPermissionsFunctionalities || !dataBranchGymPermission)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;

        return (
            dataList.map(item => {
                return (
                    <div key={item._id} className='grid grid-nogutter m-2'>
                        {
                            !branchGymId ?
                                <div className='flex flex-nowrap' style={{ width: '100%' }}>
                                    <div className='col-12'>
                                        <div className='text-left shadow-2 p-3 border-2 border-white bg-primary font-bold'><i className='pi pi-fw pi-th-large mr-2' />{item.name}</div>
                                    </div>
                                </div>
                                : null
                        }
                        {renderListGroupPermissions(item)}
                        &nbsp;
                    </div>
                )
            })
        )
    }

    const renderListGroupPermissions = (branchGym: any) => {
        let listDataGroupPermission = dataGroupPermissions ? dataGroupPermissions : [];
        return (
            listDataGroupPermission.filter((permissionGroup:any)=> permissionGroup.groupKey !== 'register_tags').map(permissionGroup => {
                return (
                    <div key={permissionGroup._id} className='grid grid-nogutter' style={{ width: '100%' }}>
                        <div className='flex flex-nowrap' style={{ width: '100%' }}>
                            {!branchGymId ? <div className='col-1'>
                                <div className=''>&nbsp;</div>
                            </div> : null}
                            <div className={branchGymId ? 'col-12' : 'col-11'}>
                                <div className='text-left p-3 border-2 border-primary bg-primary-reverse font-bold'><i className='pi pi-unlock mr-2' />{permissionGroup.groupDescription}</div>
                            </div>
                        </div>
                        {permissionGroup._id ? renderListPermissionsFunctionalities(branchGym, permissionGroup._id) : null}
                    </div>
                )
            })
        );
    }


    const renderListPermissionsFunctionalities = (branchGym: any, permissionGroupId: string) => {
        if (!networkGymId) return;
        let listDataPermissionsFunctionalities = dataPermissionsFunctionalities ? dataPermissionsFunctionalities : [];

        return (
            <>
                {
                    listDataPermissionsFunctionalities.map(permissionsFunctionality => {
                        let branchGymPermissionLocated = dataBranchGymPermission ? dataBranchGymPermission.find((branchGymPermission: IFormListDataBranchGymPermission) => {
                            return branchGymPermission.networkGym?._id === networkGymId && branchGymPermission.branchGym?._id === branchGym._id &&
                                branchGymPermission.permissionsFunctionality?._id === permissionsFunctionality._id && permissionsFunctionality.groupPermission._id === permissionGroupId
                                && permissionsFunctionality.groupPermission.groupKey !== 'register_tags';
                        }) : undefined;
                        if (!branchGymPermissionLocated) return null;
                        return (
                            <div key={permissionsFunctionality._id} className='flex flex-nowrap' style={{ width: '99.5%' }}>
                                <div className={branchGymId ? 'col-1' : 'col-2'}>
                                    <div className=''>&nbsp;</div>
                                </div>
                                <div className={`${branchGymId ? 'col-7' : 'col-6'} border-2 border-primary bg-primary-reverse`}>
                                    <div className='text-left p-3'><i className='pi pi-unlock mr-2' />{permissionsFunctionality.functionalityDescription}</div>
                                </div>
                                <div className='col-4 align-items-baseline border-2 border-primary'>
                                    <Dropdown className='flex align-self-auto justify-content-center font-bold'
                                        value={branchGymPermissionLocated?.role?._id || permissionsFunctionality.defaultRole?._id}
                                        options={dataHierarchyRole} optionValue='_id' optionLabel='hierarchyRoleDescription'
                                        placeholder="Selecione perfil"
                                        onChange={(e) => permissionsFunctionality && permissionsFunctionality?._id ?
                                            setSelectedPermission(branchGymPermissionLocated._id, e.value) : null
                                        } />
                                </div>
                            </div>
                        )
                    })
                }
            </>
        );
    }

    const setSelectedPermission = async (branchGymPermissionId: string, roleId: string) => {
        await branchGymPermissionService.saveBranchGymPermission({
            _id: branchGymPermissionId,
            role: roleId
        });
        refreshListBranchGymPermissions();

    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: !!networkGymId && branchGymData && checkRoleHierarchyByToken(RoleRules.manager),
                hasSearchTerm: false, searchTerm, setSearchTerm, setDialogData,
                listData: () => renderListData()
            })}
        </>
    )
}


