import { IPerformRequestResult } from "../../../utils/interfaces";
import { IFormDataNetworkGymExternalUser } from "../../components/generics/entities";
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class NetworkGymExternalUserService extends GenericService {
    allNetworkGymExternalUsers = async (networkGym: string, search?: string | undefined) => await super.findByReferenceIdWithProjection(Entities.NETWORK_GYM_EXTERNAL_USER, false, EntitiesService(Entities.NETWORK_GYM).singular, networkGym, undefined, search);
    findNetworkGymExternalUserById = async (_id: string | null) => await super.findById(Entities.NETWORK_GYM_EXTERNAL_USER, false, _id);
    allNetworkGymExternalUsersByNetworkGym = async (networkGymId: string | null, search?: string | undefined): Promise<IPerformRequestResult<IFormDataNetworkGymExternalUser[]>> =>
        await super.findByReferenceId(Entities.NETWORK_GYM_EXTERNAL_USER, false, EntitiesService(Entities.NETWORK_GYM).singular, networkGymId, search);
    saveNetworkGymExternalUser = async (data: any) => await super.save(Entities.NETWORK_GYM_EXTERNAL_USER, true, data);
}
