/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { convertRoleRules, RoleRules } from '../../../projects/checkRoleHierarchy';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import ExternalUserService from '../../service/admin/ExternalUserService';
import NetworkGymExternalUserService from '../../service/admin/NetworkGymExternalUserService';
import { addNewRegister } from '../commons/formDataFunctions';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { SelecDialog } from '../commons/SelectDialog';
import { IFormDataExternalUser, IFormDataNetworkGymExternalUser } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import './NetworkGymExternalUser.css';

const networkGymExternalUserService = new NetworkGymExternalUserService();
const externalUserService = new ExternalUserService();

export const NetworkGymExternalUser = (props: IPropsArgsComponents) => {
    const [title] = useState('Proprietários multirede');
    const [objectData] = useState('Proprietários');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataNetworkGymExternalUser[]>([]);
    const [dataSelect, setDataSelect] = useState<IFormDataExternalUser[]>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);

    const [typeData] = useState<{ value: string, tag: string }>({ value: 'string', tag: 'string' });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [editFormData, setEditFormData] = useState<any | null>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
    ]), []);

    useEffect(() => {
        // console.log('data: ', data);
        refreshListSelect();
    }, [data]);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => refreshList(), [networkGymId]);

    const refreshListSelect = () => {
        let isMounted = true;
        setLoadingData(true);
        externalUserService.allExternalUsers().then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setDataSelect([]);
                } else if (isSuccess(returned.status)) {
                    let returnedDatas = returned.data as Array<IFormDataExternalUser>;
                    returnedDatas = returnedDatas.filter(g => !data.find((d: any) => d.customer._id === g._id));
                    // console.log('returnedDatas: ', returnedDatas);
                    setDataSelect(returnedDatas);
                } else {
                    setDataSelect([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setDataSelect([]);
            }
        });
        return () => { isMounted = false };
    };

    const refreshList = () => {
        let isMounted = true;
        if (networkGymId) {
            setLoadingData(true);
            networkGymExternalUserService.allNetworkGymExternalUsersByNetworkGym(networkGymId, searchTerm).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setData([]);
                    } else if (isSuccess(returned.status)) {
                        setData(returned.data);
                    } else {
                        setData([]);
                    }
                    setLoadingData(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingData(false);
                    setData([]);
                }
            });
        }
        return () => { isMounted = false };
    };

    const listData = () => {
        const listData = data ? data : [];

        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;
        else if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map((d: any) => {
                    const selectedNetworkGymExternalUserId = localStorage.getItem('networkGymExternalUser');
                    const config: IConfig = {
                        title: { icon: 'pi-user', value: d.customer.nickname },
                        lines: [
                            { description: 'E-mail', value: d.customer.user.username },
                            { description: 'Permissão', value: convertRoleRules(d.role)?.description },
                            // { description: 'Celular', value: d.customer.user.mobilePhone },
                        ],
                        data: d,
                        buttons: d._id ? [
                            // { key: 'editar', icon: 'pi-pencil', onClick: () => edit('networkGymExternalUser', d._id), tooltip: 'Editar' },
                            // { key: `clone`, icon: 'pi-copy', onClick: () => clone('networkGymExternalUser', d._id), tooltip: 'Copiar' },
                            // selectedNetworkGymExternalUserId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('networkGymExternalUser', d._id), tooltip: 'Selecionar' } : null,
                            // selectedNetworkGymExternalUserId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('networkGymExternalUser', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedNetworkGymExternalUserId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    const saveSuccessed = (returned: IPerformRequestResult<any>) => {
        if (isSuccess(returned.status)) {
            props.args.setLoading(false);
            // setDialogData(false);
            props.args.setMessage({ severity: 'success', summary: 'Registro atualizado com sucesso!', detail: '', life: 5000 });
            refreshList();
        } else if (returned.status === HttpStatus.UNAUTHORIZED) {
            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
            // setDialogData(false);
            setTimeout(() => { props.args.setLoading(false); window.location.href = '/'; }, 5000);
        } else {
            props.args.setLoading(false);
            props.args.setMessage({ severity: 'warn', summary: 'Dados incorretos!', detail: '', life: 5000 });
        }
    }

    const saveFailed = (error: any) => {
        console.error('error: ', error);
        props.args.setMessage({ severity: 'error', summary: 'Ocorreu um erro desconhecido!', detail: '', life: 5000 });
        // setDialogData(false);
        setTimeout(() => { props.args.setLoading(false); window.location.href = '/'; }, 5000);
    }

    const select = (type: string, _id?: string | null, ...args: string[]) => {
        const networkGymExternalUserData = {
            networkGym: networkGymId,
            customer: _id,
            role: RoleRules.owner,
            active: true
        };
        networkGymExternalUserService.saveNetworkGymExternalUser(networkGymExternalUserData)
            .then((returned: IPerformRequestResult<any>) => saveSuccessed(returned))
            .catch((error: any) => saveFailed(error));

    };

    const unselect = (type: string, _id?: string | null, ...args: string[]) => {

    }

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setDialogData(true);
    // }

    const closeDialogData = () => {
        refreshList();
        setDialogData(false);
    }

    // console.log('dataSelect: ', dataSelect);

    return (
        <>
            {createView(title, {
                editData: !!networkGymId,
                addNew: true, addNewRegister: () => addNewRegister('networkGymExternalUser', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token && dataSelect ?
                <SelecDialog
                    key={`network-gym-external-user-dialog`}
                    keyDialog={`network-gym-external-user-dialog`}
                    typeData={typeData}
                    icon={'pi-plus'}
                    dialogIcon={'pi-table'}
                    titleIcon={'pi-user'}
                    evalTitleValue={`data.name+' (email: '+data.email+')'`}
                    data={dataSelect}
                    objectData={objectData}
                    select={select}
                    unselect={unselect}
                    setDialogData={closeDialogData}
                    showDialog={dialogData}
                    loadingData={loadingData}
                />
                :
                null
            }
        </>
    )
}

