import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class TagService extends GenericService {
    allNetworkGymTags = async (networkGymId: string) => {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId }
            ], 
            projection: [
                { field: 'networkGym' },
                { field: 'tag' },
                { field: 'description' },
                { field: 'blocked' }
            ],
            ignoreSummaryData: true
        };
        return await super.findByQuery(Entities.TAG, false, search, undefined, { limit: 0 })
    };
    allNetworkGymAndTagTags = async (networkGymId: string, tag: string) => {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId },
                { field: 'tag', value: tag }
            ]
        };
        return await super.findByQuery(Entities.TAG, false, search, undefined, { limit: 0 })
    };

    allTags = async (branchGym: string, search?: string | undefined) => await super.findByReferenceIdWithProjection(Entities.TAG, false, EntitiesService(Entities.NETWORK_GYM).singular, branchGym, undefined, search);
    // allTags = async (search?: string | undefined) => await super.findAll(Entities.TAG, false, search);
    findTagById = async (_id: string | null) => await super.findById(Entities.TAG, false, _id);
    saveTag = async (data: any) => await super.save(Entities.TAG, false, data);
}
