
export enum Entities {
    ATTENDANCE,
    BRANCH_GYM,
    BRANCH_GYM_GUIDE,
    BRANCH_GYM_EXTERNAL_USER,
    BRANCH_GYM_PERMISSION,
    BRANCH_GYM_SPORT,
    CHAT4FITBOT,
    CONTACT,
    CONTACT_LIST,
    CUSTOMER,
    DASHBOARD,
    DASHBOARD_REPORT,
    EXTERNAL_USER,
    GUIDE,
    GUIDE_SCRIPT,
    GROUP_PERMISSION,
    HIERARCHY_ROLE,
    NETWORK_GYM,
    NETWORK_GYM_GUIDE,
    NETWORK_GYM_EXTERNAL_USER,
    PERMISSIONS_FUNCTIONALITY,
    PLAN_BRANCH_GYM,
    QUICK_MESSAGE,
    QUICK_MESSAGE_BRANCH_GYM,
    SCALE_BRANCH_GYM,
    SCALE_BRANCH_GYM_SPORT,
    SCHEDULE,
    SCRIPT,
    SPORT,
    SYSTEM_MESSAGE,
    SYSTEM_MESSAGE_DEFAULT,
    TAG,
    TAG_BRANCH_GYM,
    TRACKER_CONTACT_MESSAGE,
    TRACKER_MESSAGE,
    TRACKER_MOBILE,
    WHATSAPP_CHAT,
    WHATSAPP_CONTACT,
    WHATSAPP_MESSAGE,
};

const EntitiesService = (entity: Entities) => {
    switch (entity) {
        case Entities.ATTENDANCE: return { singular: 'attendance', plural: 'attendances' };
        case Entities.BRANCH_GYM: return { singular: 'branchGym', plural: 'branchGyms' };
        case Entities.BRANCH_GYM_GUIDE: return { singular: 'branchGymGuide', plural: 'branchGymGuides' };
        case Entities.BRANCH_GYM_EXTERNAL_USER: return { singular: 'branchGymExternalUser', plural: 'branchGymExternalUsers' };
        case Entities.BRANCH_GYM_SPORT: return { singular: 'branchGymSport', plural: 'branchGymSports' };
        case Entities.BRANCH_GYM_PERMISSION: return { singular: 'branchGymPermission', plural: 'branchGymPermissions' };
        case Entities.CHAT4FITBOT: return { singular: 'chat4FitBot', plural: 'chat4FitBots' };
        case Entities.CONTACT: return { singular: 'contact', plural: 'contacts' };
        case Entities.CONTACT_LIST: return { singular: 'contactList', plural: 'contactLists' };
        case Entities.CUSTOMER: return { singular: 'customer', plural: 'customers' };
        case Entities.DASHBOARD: return { singular: 'dashboard', plural: 'dashboards' };
        case Entities.DASHBOARD_REPORT: return { singular: 'dashboardReport', plural: 'dashboardReports' };
        case Entities.EXTERNAL_USER: return { singular: 'externalUser', plural: 'externalUsers' };
        case Entities.GUIDE: return { singular: 'guide', plural: 'guides' };
        case Entities.GUIDE_SCRIPT: return { singular: 'guideScript', plural: 'guideScripts' };
        case Entities.GROUP_PERMISSION: return { singular: 'groupPermission', plural: 'groupPermissions' };
        case Entities.HIERARCHY_ROLE: return { singular: 'hierarchyRole', plural: 'hierarchyRoles' };
        case Entities.NETWORK_GYM: return { singular: 'networkGym', plural: 'networkGyms' };
        case Entities.NETWORK_GYM_GUIDE: return { singular: 'networkGymGuide', plural: 'networkGymGuides' };
        case Entities.NETWORK_GYM_EXTERNAL_USER: return { singular: 'networkGymExternalUser', plural: 'networkGymExternalUsers' };
        case Entities.PERMISSIONS_FUNCTIONALITY: return { singular: 'permissionFunctionality', plural: 'permissionsFunctionalities' };
        case Entities.PLAN_BRANCH_GYM: return { singular: 'planBranchGym', plural: 'planBranchGyms' };
        case Entities.QUICK_MESSAGE: return { singular: 'quickMessage', plural: 'quickMessages' };
        case Entities.QUICK_MESSAGE_BRANCH_GYM: return { singular: 'quickMessageBranchGym', plural: 'quickMessageBranchGyms' };
        case Entities.SCALE_BRANCH_GYM: return { singular: 'scaleBranchGym', plural: 'scaleBranchGyms' };
        case Entities.SCALE_BRANCH_GYM_SPORT: return { singular: 'scaleBranchGymSport', plural: 'scaleBranchGymSports' };
        case Entities.SCHEDULE: return { singular: 'schedule', plural: 'schedules' };
        case Entities.SCRIPT: return { singular: 'script', plural: 'scripts' };
        case Entities.SPORT: return { singular: 'sport', plural: 'sports' };
        case Entities.SYSTEM_MESSAGE: return { singular: 'systemMessage', plural: 'systemMessages' };
        case Entities.SYSTEM_MESSAGE_DEFAULT: return { singular: 'systemMessageDefault', plural: 'systemMessageDefaults' };
        case Entities.TAG: return { singular: 'tag', plural: 'tags' };
        case Entities.TAG_BRANCH_GYM: return { singular: 'tagBranchGym', plural: 'tagBranchGyms' };
        case Entities.TRACKER_CONTACT_MESSAGE: return { singular: 'trackerContactMessage', plural: 'trackerContactMessages' };
        case Entities.TRACKER_MESSAGE: return { singular: 'trackerMessage', plural: 'trackerMessages' };
        case Entities.TRACKER_MOBILE: return { singular: 'trackerMobile', plural: 'trackerMobiles' };
        case Entities.WHATSAPP_CHAT: return { singular: 'whatsAppChat', plural: 'whatsAppChats' };
        case Entities.WHATSAPP_CONTACT: return { singular: 'whatsAppContact', plural: 'whatsAppContacts' };
        case Entities.WHATSAPP_MESSAGE: return { singular: 'whatsAppMessage', plural: 'whatsAppMessages' };
        default: throw new Error('Invalid Entity!');
    }
}

export default EntitiesService;
