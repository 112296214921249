import { ProjectInterface } from '..';

const Chat4Fit: ProjectInterface = {
    id: 'chat4fit',
    name: 'Chat4Fit',
    template: 'serenity-react-6.0.1',
    description: 'ChatBot to Gym',
    themeColor: '#FFFFFF',
    themeCssWrapper: { id: 'theme-css-wrapper', href: '/assets/theme/##_config.id##-theme/theme.css' },
    layoutCssWrapper: { id: 'layout-css-wrapper', href: '/assets/layout/css/##_config.id##-layout/layout.css' },
    // layoutCssWrapper: '/assets/theme/chat4fit-theme/theme.css',
    // themeCssWrapper: '/assets/layout/css/chat4fit-layout/layout.css',

};

export default Chat4Fit;