import { IPerformRequestResult } from "../../../utils/interfaces";
import { IFormDataBranchGymSport } from "../../components/generics/entities";
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class BranchGymSportService extends GenericService {
    allBranchGymSports = async (branchGym: string, search?: string | undefined): Promise<IPerformRequestResult<IFormDataBranchGymSport[]>> => await super.findAll(Entities.BRANCH_GYM_SPORT, true, search);
    findBranchGymSportById = async (_id: string | null): Promise<IPerformRequestResult<IFormDataBranchGymSport>> => await super.findById(Entities.BRANCH_GYM_SPORT, false, _id);
    findAllBranchGymSportByBranchGymId = async (branchGymId: string | null, search?: string | undefined): Promise<IPerformRequestResult<IFormDataBranchGymSport[]>> => await super.findByReferenceId(Entities.BRANCH_GYM_SPORT, false,
        EntitiesService(Entities.BRANCH_GYM).singular, branchGymId, search);
    saveBranchGymSport = async (data: any): Promise<IPerformRequestResult<IFormDataBranchGymSport>> => await super.save(Entities.BRANCH_GYM_SPORT, true, data);
}