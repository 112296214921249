import axios from 'axios';
import jwt from 'jsonwebtoken';
import projectEnviroment from '../project.enviroments';
// import log4js from "log4js";
import { decrypt } from './crypto';
import { isArray } from './functions';
import { tryParse } from './functions/object';
import HttpStatus, { httpStatusByStatusCode } from './httpStatus';
import { IParamPerformRequest, IPerformRequestResult } from './interfaces';

// log4js.configure("/log4j.json");

class PerformRequest<T> {
  // logger: log4js.Logger;
  type!: T;

  protected async call<T>(args: IParamPerformRequest): Promise<IPerformRequestResult<T>> {
    // console.log('call: Argumments perform request: ', args);
    return new Promise<IPerformRequestResult<T>>((resolve, reject) => {
      // console.log('START - async call : ', new Date().getTime());
      axios({
        url: args.url,
        method: args.method || 'get',
        baseURL: args.baseURL,
        transformRequest: args.transformRequest,
        transformResponse: args.transformResponse,
        headers: args.headers,
        params: args.params,
        paramsSerializer: args.paramsSerializer,
        data: args.data,
        timeout: args.timeout,
        timeoutErrorMessage: args.timeoutErrorMessage,
        withCredentials: args.withCredentials,
        adapter: args.adapter,
        auth: args.auth,
        xsrfCookieName: args.xsrfCookieName || 'XSRF-TOKEN',
        xsrfHeaderName: args.xsrfHeaderName || 'X-XSRF-TOKEN',
        onUploadProgress: args.onUploadProgress,
        onDownloadProgress: args.onDownloadProgress,
        maxContentLength: args.maxContentLength,
        maxBodyLength: args.maxBodyLength,
        validateStatus: args.validateStatus,
        maxRedirects: args.maxRedirects || 5,
        socketPath: args.socketPath || null,
        httpAgent: args.httpAgent,
        httpsAgent: args.httpsAgent,
        proxy: args.proxy,
        cancelToken: args.cancelToken,
        decompress: true
      })
        .then((responseReturn) => {
          // console.log(' STOP - async call : ', new Date().getTime());
          const { data: returnObject, status, headers } = responseReturn;
          // console.log('returnObject.encryptData: ', !!returnObject.encryptData);
          const config = projectEnviroment.configServerApplication.find(c => c.type === args.env || c.type === projectEnviroment.default);

          // console.log('config?.encryptServiceSecret: ', !!config?.encryptServiceSecret);
          // console.log('call.then: Returned Object perform request.');
          // if (args.url && args.url.indexOf('60543dbbe8240de3eb397b9d_60579f743d884ee0beb437f1_6063ef2fcba7145195626dc0_BTC') > 0) {
          // console.log('PerformRequest.call.then.args.method: ', args.method);
          // console.log('PerformRequest.call.then.args.url: ', args.url);
          // console.log('PerformRequest.call.then.args.params: ', args.params ? args.params : undefined);
          // console.log('PerformRequest.call.then.returnObject: ', returnObject && returnObject.message ? returnObject.message : undefined);
          // }
          if (returnObject && returnObject.data) {
            returnObject.status = httpStatusByStatusCode(status);
            returnObject.headers = headers;
            if (returnObject.status.code < 200 || returnObject.status.code > 299)
              // this.logger.error(`PerformRequest.call.url: ${args.url} return ${returnObject.status.code} - ${returnObject.status.description}`);
              return resolve(returnObject);
          }
          if (returnObject && returnObject.encryptData) {
            const decodeObject = tryParse(decrypt(decodeURI(returnObject.encryptData), config?.encryptServiceSecret));
            if (decodeObject && decodeObject.data) {
              decodeObject.status = httpStatusByStatusCode(status);
              return resolve(decodeObject);
            }
            // this.logger.trace('call.then: Returned Decode Object perform request: ', decodeObject);
            return resolve({ status: httpStatusByStatusCode(status), data: decodeObject, headers });
          }
          // this.logger.trace('call.then: Returned Object perform request: ', returnObject);
          return resolve({ status: httpStatusByStatusCode(status), data: returnObject, headers });
        })
        .catch((error) => {
          // console.log(' STOP - async call : ', new Date().getTime());
          // this.logger.error('call.catch: Returned Error perform request: ', error.message);
          // if (args.url) && args.url.indexOf('60543dbbe8240de3eb397b9d_60579f743d884ee0beb437f1_6063ef2fcba7145195626dc0_BTC') > 0) {
          //   this.logger.fatal('PerformRequest.call.catch.args.method: ', args.method);
          //   this.logger.fatal('PerformRequest.call.catch.args.url: ', args.url);
          //   this.logger.fatal('PerformRequest.call.catch.args.params: ', args.params) ? args.params : undefined);
          // }
          if (error && error.response && error.response.status) return resolve({ status: error.response.status, data: error.response.data, headers: error.headers });
          return reject(error.message);
        });
    });
  }


  protected prepareBearerHeaderAdmin = (bearerToken: string): any => {
    return this.prepareBearerHeader(bearerToken, true);
  }

  protected prepareBearerHeader = (bearerToken: string, admin: boolean = false): any => {
    const bearerHeader = {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET, PATCH, POST, PUT, OPTIONS',
      // 'Access-Control-Allow-Headers': 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified'
    };
    if (admin)
      return Object.assign(bearerHeader, {
        'Authorization': `Bearer ${bearerToken}`,
        limit: 0,
        admin
      });
    else
      return Object.assign(bearerHeader, {
        'Authorization': `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
        limit: 0,
      });
  }
};

export const retrieveExpiredMillis = (token: string) => {
  // const logger = log4js.getLogger('performRequest');
  // logger.trace('retrieveExpiredMillis.');
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const decode = <any>jwt.decode(token);
  if (decode && decode.exp) {
    const millis = ((decode.exp * 1000) - new Date().getTime()) * 0.9;
    return Math.round(millis);
  }
  return 1000;
}

export const successRequest = (status: HttpStatus): boolean => {
  // const logger = log4js.getLogger('performRequest');
  // logger.trace('successRequest.');
  return status.code < 300;
}

export const returnSuccessData = <T>(response: IPerformRequestResult<T> | undefined): Array<T> | undefined => {
  // const logger = log4js.getLogger('performRequest');
  // logger.trace('returnSuccessData.');
  if (response && response.status && successRequest(response.status)) {
    if (response.data) return response.data;
  }
  return undefined;
}

export const returnFirstSuccessData = <T>(response: IPerformRequestResult<T>): T | undefined => {
  // const logger = log4js.getLogger('performRequest');
  // logger.trace('returnFirstSuccessData.');
  if (response.status && successRequest(response.status)) {
    if (response.data && isArray(response.data) && response.data.length > 0) return response.data[0];
    if (response.data && !isArray(response.data)) return response.data;
  }
  return undefined;
}

export default PerformRequest;
