/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import ls from '../../../projects/localStorage';
import { waitSleep } from '../../../utils/functions';
import { isCNPJ, isMail, isMobilePhone } from '../../../utils/functions/data';
import { makeHash, makeKeyByString } from '../../../utils/functions/hash';
import { onlyNumbers } from '../../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import StateCityService from '../../service/StateCityService';
import GuideScriptService from '../../service/admin/GuideScriptService';
import ScriptService from '../../service/admin/ScriptService';
import { IMessage } from '../Control';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { GenericFields } from '../commons/GenericFields';
import { uploadCloudRegister } from '../commons/formDataFunctions';
import { executeMethodFromFromList, retrieveListFromFromList, saveFailed, saveSuccessed } from '../commons/functions';
import { retrieveListData, retrieveListDataWithParentId } from '../commons/retrieveDataFunctions';
import { IFormDataGuideScript } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { createGenericListData } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './GuideScript.css';

const scriptService = new ScriptService();
const guideScriptService = new GuideScriptService();
const stateCityService = new StateCityService();

export const GuideScript = (props: IPropsArgsComponents) => {
    const [title] = useState('Script');
    const [token] = useState(props.args.token);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataGuideScript[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [guideId, setGuideId] = useState(props.args.guideId);

    const [editFormData, setEditFormData] = useState<any | null>();

    const [states, setStates] = useState<any>([]);
    const [stateSelected, setStateSelected] = useState<string>();
    const [cities, setCities] = useState<any>([]);

    useEffect(() => setGuideId(props.args.guideId), [props.args.guideId]);
    useEffect(retrieveListData(() => stateCityService.allStates(), props, (data: any[]) => setStates(data)), []);
    useEffect(retrieveListDataWithParentId(stateSelected, (stateSelectedId: string) => stateCityService.cityByState(stateSelectedId), props, (data: any[]) => setCities(data)), [stateSelected]);

    // const selectStateHandle = (event: DropdownChangeEvent) => event && event.value && event.value._id ? setStateSelected(event.value._id) : setStateSelected(undefined);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'guide', show: true, considered: true },
    ]), []);


    useEffect(() => {
        if (!token) window.location.href = '/';
    }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const refreshList = () => {
        if (guideId) {
            let isMounted = true;
            setLoadingData(true);
            guideScriptService.findAllGuideScriptByGuideId(guideId, searchTerm).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setData([]);
                    } else if (isSuccess(returned.status)) {
                        setData(returned.data);
                    } else {
                        setData([]);
                    }
                    setLoadingData(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingData(false);
                    setData([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const listData = (data: any[]) => {
        if (!guideId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar um Roteiro.</h3>
            </div>;
        return createGenericListData('guideScript', data,
            { icon: 'pi-th-large', evalValue: '\'v.\'+data.version' },
            [{ description: '\'Roteiro\'', value: 'data.guide.description' },
            { description: '\'Script\'', value: 'data.script.key' },
                // { condition: 'data.charge === true', description: '\'CNPJ\'', value: 'data.companyNumber' },
                // { condition: 'data.charge === true', value: '`${data.district} / ${data.city.name} - ${data.state.key}`' }
            ],
            localStorage.getItem('guideScript'),
            {
                isEdited: true, isCloned: true, isSelected: !ls.checkUniqueLocalStorageToken('guideScript'),
                loadingData,
                editForceData: { networkGym: guideId },
                removeForceData: ['_id', 'key'],
                select: props.args.select,
                unselect: props.args.unselect,
                setEditFormData,
                setDialogData,
            });
    }

    const proccessGuide = async (guideId: string | null) => {
        if (guideId) {
            props.args.setLoading(true);

            const guideScripts = await guideScriptService.findAllGuideScriptByGuideId(guideId);
            if (guideScripts && guideScripts.data && guideScripts.data.length >= 0) {
                const returns = guideScripts.data;
                let lastVersion = '1.0.0';
                for (let i = 0; i < returns.length; i++) {
                    const returned = returns[i];
                    if (returned.active === true) {
                        lastVersion = returned.version;
                        await guideScriptService.saveGuideScript({ _id: returned._id, active: false });
                    }
                }
                const versionParts = lastVersion.split('.');
                lastVersion = `${parseInt(versionParts[0])}.${parseInt(versionParts[1])}.${parseInt(versionParts[2]) + 1}`;

                const savedScript = await scriptService.saveScript({
                    key: makeKeyByString(makeHash(10), 10),
                    description: makeHash(50)
                });

                const savedGuideScript = await guideScriptService.saveGuideScript({
                    guide: guideId,
                    script: savedScript.data._id,
                    version: lastVersion
                });

                console.log('savedGuideScript: ', savedGuideScript && savedGuideScript.data ? savedGuideScript.data._id : '');
            }

            uploadCloudRegister('guide', { guideId })
            await waitSleep(1000 * 1);
            props.args.setLoading(false);
        }
    };

    return (
        <>
            {createView(title, {
                editData: !!guideId,
                uploadCloud: true, uploadCloudRegister: () => guideId ? proccessGuide(guideId) : null,
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData(data)
            })}
            {guideId && token ?
                <GenericFormDialog<IFormDataGuideScript> key='GuideScriptDialog'
                    token={token}
                    objectData='unidade'
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataGuideScript>[]) => [
                        GenericFields.indentificationAdminField(formData, { colSize: 6 }),
                        GenericFields.chargeField(formData, { colSize: 6 }),
                        GenericFields.nameField(formData),
                        GenericFields.genericGroupField('Dados do Responsável'),
                        {
                            colSize: 'col-12', fieldName: 'responsibleName', fieldType: 'InputTextField', description: '* Responsável', placeholder: 'nome do responsável', value: formData.values.responsibleName,
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        {
                            colSize: 'col-6 sm:col-12', fieldName: 'responsibleMobilePhone', fieldType: 'InputMaskField', description: '* Celular do Responsável', placeholder: '+55 (99) 99999-9999', value: formData.values.responsibleMobilePhone,
                            mask: '+55 (99) 99999-9999',
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        {
                            colSize: 'col-6 sm:col-12', fieldName: 'responsibleEmail', fieldType: 'InputTextField', description: '* Email do Responsável', placeholder: 'email do responsável', value: formData.values.responsibleEmail,
                            autoComplete: 'off',
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        formData.values.charge ? GenericFields.genericGroupField('Dados de Cobrança') : null,
                        formData.values.charge ?
                            {
                                colSize: 'col-12', fieldName: 'companyName', fieldType: 'InputTextField', description: '* Razão Social:', placeholder: 'razão social', value: formData.values.companyName, autoFocus: false,
                                onChange: formData.handleChange
                            } : null,

                        formData.values.charge ?
                            {
                                colSize: 'col-12', fieldName: 'companyNumber', fieldType: 'InputMaskField', description: '* CNPJ:', placeholder: 'CNPJ', value: formData.values.companyNumber,
                                mask: '99.999.999/9999-99',
                                onChange: formData.handleChange, autoFocus: false
                            } : null,
                        formData.values.charge ? GenericFields.genericGroupField('Dados de Endereço') : null,
                        {
                            colSize: 'col-8', fieldName: 'address', fieldType: 'InputTextField', description: '* Logradouro:', placeholder: 'Logradouro', value: formData.values.address, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-4', fieldName: 'number', fieldType: 'InputTextField', description: '* Número:', placeholder: 'Número', value: formData.values.number, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-12', fieldName: 'complement', fieldType: 'InputTextField', description: '* Complemento:', placeholder: 'Complemento', value: formData.values.complement, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-6', fieldName: 'district', fieldType: 'InputTextField', description: '* Bairro:', placeholder: 'Bairro', value: formData.values.district, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-6', fieldName: 'zipCode', fieldType: 'InputMaskField', description: '* CEP:', placeholder: 'CEP', value: formData.values.zipCode, autoFocus: false,
                            mask: '99999-999',
                            onChange: formData.handleChange
                        },
                        //TODO: verificar o fieldName: 'state'
                        {
                            colSize: 'col-4', fieldName: 'state', fieldType: 'DropdownField', description: '* Estado:', placeholder: 'Selecione um estado', value: formData.values.state, autoFocus: false,
                            onChange: (event: DropdownChangeEvent) => {
                                if (formLists) executeMethodFromFromList('state', formLists, event);
                                formData.handleChange(event);
                            },
                            options: retrieveListFromFromList('state', formLists),
                            optionLabel: 'name', filter: true, showClear: true, filterBy: 'name',
                            disabled: !states || states.length === 0,
                        },
                        //TODO: verificar o fieldName: 'city'
                        {
                            colSize: 'col-8', fieldName: 'city', fieldType: 'DropdownField', description: '* Cidade:', placeholder: 'Selecione uma cidade', value: formData.values.city, autoFocus: false,
                            onChange: formData.handleChange,
                            options: retrieveListFromFromList('city', formLists),
                            optionLabel: 'name', filter: true, showClear: true, filterBy: 'name',
                            disabled: !formData.values.state || !cities || cities.length === 0,
                        },
                    ])}
                    emptyFormData={{
                        guide: guideId ? guideId : '',
                        script: '',
                        version: 0,
                        active: true,
                        deleted: false
                    }}
                    editFormData={editFormData}
                    editForceData={{ networkGym: guideId }}
                    editFunctions={[(formData: FormikValues) => {
                        setStateSelected(formData.state?._id);
                    }]}
                    // editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};

                        if (!data.key) {
                            errors.key = 'Identificação é obrigatório.';
                        } else if (data.key.length < 5) {
                            errors.key = 'Identificação precisa ter mais que 4 caracteres.';
                        }

                        if (!data.name) {
                            errors.name = 'Nome é obrigatório.';
                        } else if (data.name.length < 3) {
                            errors.name = 'Nome precisa ter mais que 2 caracteres.';
                        }

                        if (!data.networkGym) {
                            errors.networkGym = 'Rede é obrigatório.';
                        }

                        if (!data.responsibleName) {
                            errors.responsibleName = 'Nome do Responsável é obrigatório.';
                        } else if (data.responsibleName.length < 3) {
                            errors.responsibleName = 'Nome do Responsável precisa ter mais que 2 caracteres.';
                        }

                        if (!data.responsibleMobilePhone) {
                            errors.responsibleMobilePhone = 'Celular do Responsável é obrigatório.';
                        } else if (!isMobilePhone(onlyNumbers(data.responsibleMobilePhone))) {
                            errors.responsibleMobilePhone = 'Número de celular do responsável inválido.';
                        }

                        if (!data.responsibleEmail) {
                            errors.responsibleEmail = 'Email do Responsável é obrigatório.';
                        } else if (!isMail(data.responsibleEmail)) {
                            errors.responsibleEmail = 'Email do Responsável inválido.';
                        }

                        if (data.charge) {
                            if (!data.companyNumber) {
                                errors.companyNumber = 'CNPJ é obrigatório.';
                            } else if (!isCNPJ(data.companyNumber)) {
                                errors.companyNumber = 'CNPJ é inválido. Favor verificar';
                            }

                            if (!data.companyName) {
                                errors.companyName = 'Razão Social é obrigatório.';
                            }
                        }

                        if (!data.address) {
                            errors.address = 'Endereço é obrigatório.';
                        }

                        if (!data.number) {
                            errors.number = 'Número é obrigatório.';
                        }

                        if (!data.district) {
                            errors.district = 'Bairro é obrigatório.';
                        }

                        if (!data.zipCode) {
                            errors.zipCode = 'CEP é obrigatório.';
                        }

                        if (!data.city) {
                            errors.city = 'Cidade é obrigatório.';
                        }

                        if (!data.state) {
                            errors.state = 'Estado é obrigatório.';
                        }

                        return errors;
                    }}
                    submitForceData={{ networkGym: guideId }}
                    onSubmit={(props: IPropsFormDialog<IFormDataGuideScript>, data: FormikValues, formData: FormikInterface) => {
                        new GuideScriptService().saveGuideScript(data)
                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }}
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                />
                :
                null
            }
        </>
    )
};

