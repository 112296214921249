/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import SportService from '../../service/admin/SportService';
import { addNewRegister } from '../commons/formDataFunctions';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { IMessage } from '../Control';
import { IFormDataSport } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './Sport.css';

const sportService = new SportService();


export const Sport = (props: IPropsArgsComponents) => {
    const [title] = useState('Modalidades');
    // const [objectData] = useState('modalidade');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataSport[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [editFormData, setEditFormData] = useState<any | null>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'sport', show: true, considered: false },
    ]), []);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        sportService.allSports(searchTerm).then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    setData(returned.data);
                } else {
                    setData([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setData([]);
            }
        });
        return () => { isMounted = false };
    };

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setEditFormData(null);
    //     setDialogData(true);
    // }

    const edit = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;

        if (type === 'sport') {
            setEditFormData({
                _id: item._id,
                key: item.key,
                description: item.description,
                active: item.active
            });
            setDialogData(true);
        }
    }

    const clone = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;

        if (type === 'sport') {
            setEditFormData({
                key: item.key,
                description: item.description,
                active: item.active
            });
            setDialogData(true);
        }
    }

    const listData = () => {
        const listData = data ? data : [];
        if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map(d => {
                    const selectedSportId = localStorage.getItem('sport');
                    const config: IConfig = {
                        title: { icon: 'pi-bolt', value: d.key },
                        lines: [
                            { description: 'Descrição', value: d.description, rows: 3 }
                        ],
                        data: d,
                        buttons: d._id ? [
                            { key: 'editar', icon: 'pi-pencil', onClick: () => edit('sport', d._id), tooltip: 'Editar' },
                            { key: `clone`, icon: 'pi-copy', onClick: () => clone('sport', d._id), tooltip: 'Copiar' },
                            selectedSportId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('sport', d._id), tooltip: 'Selecionar' } : null,
                            selectedSportId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('sport', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedSportId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: true, addNewRegister: () => addNewRegister('sport', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token ?
                <GenericFormDialog<IFormDataSport> key='SportDialog'
                    token={token}
                    objectData='roteiro'
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataSport>[]) => [
                        {
                            colSize: 'col-4', fieldName: 'key', fieldType: 'InputTextField', description: '* Identificação', placeholder: 'identificação', value: formData.values.key,
                            onChange: formData.handleChange, autoFocus: true,
                        },
                        {
                            colSize: 'col-8', fieldName: 'description', fieldType: 'InputTextField', description: '* Descrição', placeholder: 'descrição', value: formData.values.description,
                            onChange: formData.handleChange, autoFocus: true,
                        }
                    ])}
                    emptyFormData={{
                        key: '',
                        description: '',
                        active: true,
                        deleted: false
                    }}
                    editFormData={editFormData}
                    editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};


                        if (!data.key) {
                            errors.key = 'Identificação é obrigatório.';
                        } else if (data.key.length < 4) {
                            errors.key = 'Identificação precisa ter mais que 3 caracteres.';
                        }

                        if (!data.description) {
                            errors.description = 'Descrição é obrigatório.';
                        } else if (data.description.length < 3) {
                            errors.description = 'Nome precisa ter mais que 2 caracteres.';
                        }

                        return errors;
                    }}
                    onSubmit={(props: IPropsFormDialog<IFormDataSport>, data: FormikValues, formData: FormikInterface) => {
                        new SportService().saveSport(data)
                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }}
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                />
                :
                null
            }
        </>
    )
}
