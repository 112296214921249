import enviroment from "../../.env";
import projectEnviroment from "../../project.enviroments";
import ls from "../../projects/localStorage";
import { Method } from "../../utils/method";
import PerformRequest from "../../utils/performRequest";
import AuthenticateService from "./AuthenticateService";

export default class StateCityService extends PerformRequest<any> {
    async stateById(_id: string, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`states/${_id}`, enviroment()),
            headers: this.prepareBearerHeader(userToken)
        });
    }

    async stateByKey(key: string, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`states/byField/key/${key}`, enviroment()),
            headers: this.prepareBearerHeader(userToken)
        });
    }

    async cityById(_id: string, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`cities/${_id}`, enviroment()),
            headers: this.prepareBearerHeader(userToken)
        });
    }

    async cityByKey(key: string, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`cities/byField/key/${key}`, enviroment()),
            headers: this.prepareBearerHeader(userToken)
        });
    }

    async cityByState(stateId: string, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`cities/byField/state/${stateId}`, enviroment()),
            headers: { ...this.prepareBearerHeader(userToken), limit: 0 }
        });
    }

    async allStates(token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`states`, enviroment()),
            headers: { ...this.prepareBearerHeader(userToken), limit: 0 }
        });
    }

    async allCities(token: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath(`cities`, enviroment()),
            headers: this.prepareBearerHeader(userToken)
        });
    }

    async saveState(data: any, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return new AuthenticateService().adminToken(userToken).then(async (returned) => {
            const adminToken = returned;
            if (!adminToken) throw new Error('Você não tem permissão para este acesso!');
            return await this.call<any>({
                method: Method.POST,
                url: projectEnviroment.buildPath(`admin/states`, enviroment()),
                headers: this.prepareBearerHeaderAdmin(adminToken),
                data: JSON.stringify({ state: data })
            });
        });
    }


    async saveCity(data: any, token?: string | null) {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return new AuthenticateService().adminToken(userToken).then(async (returned) => {
            const adminToken = returned;
            if (!adminToken) throw new Error('Você não tem permissão para este acesso!');
            return await this.call<any>({
                method: Method.POST,
                url: projectEnviroment.buildPath(`admin/cities`, enviroment()),
                headers: this.prepareBearerHeaderAdmin(adminToken),
                data: JSON.stringify({ city: data })
            });
        });
    }
}
