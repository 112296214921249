import { cpf, cnpj } from 'cpf-cnpj-validator';
import { Moment } from 'moment';

export const isMail = (mail: string): boolean => /^(?:[\w-]+\.?)*[\w-]+@(?:[\w-]+\.)+[\w]{2,3}$/.test(mail);
export const isPhone = (mobilePhone: string): boolean => /^55[1-9]{2}9[0-9]{8}$/im.test(mobilePhone) || /^55[1-9]{2}[0-9]{8}$/im.test(mobilePhone);
export const isMobilePhone = (mobilePhone: string): boolean => /^55[1-9]{2}9[0-9]{8}$/im.test(mobilePhone);
export const isCPF = (value: string): boolean => cpf.isValid(value);
export const isCNPJ = (value: string): boolean => cnpj.isValid(value);

export const momentRoundMinutes = (m: Moment, offset: number = 1) => {
    let value = m.get('minutes');
    value = Math.ceil(value / offset);
    m = m.set('minutes', value * offset);
    m.seconds(0);
    return m;
}

