/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import GuideService from '../../service/admin/GuideService';
import NetworkGymGuideService from '../../service/admin/NetworkGymGuideService';
import { addNewRegister } from '../commons/formDataFunctions';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { SelecDialog } from '../commons/SelectDialog';
import { IFormDataGuide, IFormDataNetworkGymGuide } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import './NetworkGymGuide.css';

const networkGymGuideService = new NetworkGymGuideService();
const guideService = new GuideService();

export const NetworkGymGuide = (props: IPropsArgsComponents) => {
    const [title] = useState('Rede < Roteiros');
    const [objectData] = useState('roteiros');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataNetworkGymGuide[]>([]);
    const [dataSelect, setDataSelect] = useState<IFormDataGuide[]>();
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [typeData] = useState<{ value: string, tag: string }>({ value: 'string', tag: 'string' });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [editFormData, setEditFormData] = useState<any | null>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
    ]), []);

    useEffect(() => { refreshListSelect(); }, [data]);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const refreshListSelect = () => {
        let isMounted = true;
        setLoadingData(true);
        guideService.allGuides().then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setDataSelect([]);
                } else if (isSuccess(returned.status)) {
                    let returnedDatas = returned.data as Array<IFormDataGuide>;
                    returnedDatas = returnedDatas.filter(g => !data.find((d: any) => d.guide._id === g._id));
                    // console.log('returnedDatas: ', returnedDatas);
                    setDataSelect(returnedDatas);
                } else {
                    setDataSelect([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setDataSelect([]);
            }
        });
        return () => { isMounted = false };
    };

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        networkGymGuideService.allNetworkGymGuides(searchTerm).then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    setData(returned.data);
                } else {
                    setData([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setData([]);
            }
        });
        return () => { isMounted = false };
    };

    const listData = () => {
        const listData = data ? data : [];

        if (!props.args.networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;
        else if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map((d: any) => {
                    const selectedNetworkGymGuideId = localStorage.getItem('networkGymGuide');
                    const config: IConfig = {
                        title: { icon: 'pi-list', value: d.guide.key },
                        lines: [
                            { description: '', value: d.guide.description, rows: 5 },
                        ],
                        data: d,
                        buttons: d._id ? [
                            // { key: 'editar', icon: 'pi-pencil', onClick: () => edit('networkGymGuide', d._id), tooltip: 'Editar' },
                            // { key: `clone`, icon: 'pi-copy', onClick: () => clone('networkGymGuide', d._id), tooltip: 'Copiar' },
                            selectedNetworkGymGuideId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('networkGymGuide', d._id), tooltip: 'Selecionar' } : null,
                            selectedNetworkGymGuideId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('networkGymGuide', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedNetworkGymGuideId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    const saveSuccessed = (returned: IPerformRequestResult<any>) => {
        if (isSuccess(returned.status)) {
            props.args.setLoading(false);
            // setDialogData(false);
            props.args.setMessage({ severity: 'success', summary: 'Registro atualizado com sucesso!', detail: '', life: 5000 });
            refreshList();
        } else if (returned.status === HttpStatus.UNAUTHORIZED) {
            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
            // setDialogData(false);
            setTimeout(() => { props.args.setLoading(false); window.location.href = '/'; }, 5000);
        } else {
            props.args.setLoading(false);
            props.args.setMessage({ severity: 'warn', summary: 'Dados incorretos!', detail: '', life: 5000 });
        }
    }

    const saveFailed = (error: any) => {
        console.error('error: ', error);
        props.args.setMessage({ severity: 'error', summary: 'Ocorreu um erro desconhecido!', detail: '', life: 5000 });
        // setDialogData(false);
        setTimeout(() => { props.args.setLoading(false); window.location.href = '/'; }, 5000);
    }

    const select = (type: string, _id?: string | null, ...args: string[]) => {
        const networkGymGuideData = {
            networkGym: props.args.networkGymId,
            guide: _id,
            active: true
        };
        networkGymGuideService.saveNetworkGymGuide(networkGymGuideData)
            .then((returned: IPerformRequestResult<any>) => saveSuccessed(returned))
            .catch((error: any) => saveFailed(error));

    };

    const unselect = (type: string, _id?: string | null, ...args: string[]) => {

    }

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setDialogData(true);
    // }


    const closeDialogData = () => {
        refreshList();
        setDialogData(false);
    }



    return (
        <>
            {createView(title, {
                editData: !!props.args.networkGymId,
                addNew: true, addNewRegister: () => addNewRegister('networkGymGuide', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token && dataSelect ?
                <SelecDialog key={`network-gym-dialog`}
                    keyDialog={`network-gym-dialog`}
                    typeData={typeData}
                    icon={'pi-plus'}
                    dialogIcon={'pi-table'}
                    titleIcon={'pi-list'}
                    evalTitleValue={'data.key'}
                    data={dataSelect}
                    objectData={objectData}
                    select={select}
                    unselect={unselect}
                    setDialogData={closeDialogData}
                    showDialog={dialogData}
                    loadingData={loadingData}
                />
                :
                null
            }
        </>
    )
}

