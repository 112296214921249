
import { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";
import ls from '../../../projects/localStorage';

export default class SystemMessageDefaultService extends GenericService {
    allSystemMessageDefaults = async (search?: string | undefined, headers?: any) => await super.findAll(Entities.SYSTEM_MESSAGE_DEFAULT, false, search, undefined, headers);
    allSystemMessageDefaultNoBlockeds = async (search?: any | undefined) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        const networkGyms = await ls.networkGym.getNetworkGymsByStorageToken();
        search.queries.push({ field: '_id', value: { $in: networkGyms?.map((b: any) => b._id) } });
        return await super.findByQuery(Entities.SYSTEM_MESSAGE_DEFAULT, false, search);
    }
    findSystemMessageDefaultById = async (_id: string | null) => await super.findById(Entities.SYSTEM_MESSAGE_DEFAULT, false, _id);
    findSystemMessageDefaultByKey = async (systemMessageKey: string, search?: string | undefined, headers?: any) => await super.findByReferenceId(Entities.SYSTEM_MESSAGE_DEFAULT, false, 'systemMessageKey', systemMessageKey, search, undefined);
    saveSystemMessageDefault = async (data: any) => await super.save(Entities.SYSTEM_MESSAGE_DEFAULT, false, data);
}
