export const asc = (value: string) => {
    return value.charCodeAt(0);
};

export const chr = (asciiNum: number) => {
    return String.fromCharCode(asciiNum);
};


export const onlyNumbers = (value: string) => {
    return value.replace(/[^0-9]/gmi, '');
};

export const onlyAlphaNumeric = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/gmi, '');
};


export const isString = (stringToCheck: any) => typeof stringToCheck === 'string';

const pad = (value: string | number, size: number, str: string, padLeft: boolean): string => {
    if (typeof str === 'undefined') return value.toString();
    if (value.toString().length === size) return value.toString();
    if (padLeft) return (str.repeat(size) + value).slice(-size);
    return (value + str.repeat(size)).substring(0, size);
};
export const padLeft = (value: string | number = '', size: number, str: string): string => pad(value, size, str, true);

export const padRight = (value: string | number = '', size: number, str: string): string => pad(value, size, str, false);

export const replaceCommaToDot = (value: string) => {
    const valueWithDot = value.replace(',', '.');
    const valueWithDotFloat = parseFloat(valueWithDot);
    return valueWithDotFloat;
};

export const space = (size: number): string => {
    return padRight('', size, ' ');
};

export const stringBreak = (value: string, str: string): string => {
    if (value && str && value.indexOf(str) >= 0) {
        return value.substring(0, value.indexOf(str));
    }
    return value;
};

export const limitCharacters = (value: string, limit: number) => {
    return value && value?.length > limit ? `${value.substring(0, limit - 3)}...` : value;
};

export const replaceValue = (value: string, that: string, by?: string) => {
    return value && value?.length > 0 ? `${value.replace(that, by ? by : '')}` : value;
};
