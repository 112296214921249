import { IPerformRequestResult } from "../../../utils/interfaces";
import { IFormDataGuide } from "../../components/generics/entities";
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class GuideService extends GenericService {
    allGuides = async (search?: string | undefined): Promise<IPerformRequestResult<IFormDataGuide[]>> => await super.findAll(Entities.GUIDE, true, search);
    findGuideById = async (_id: string | null): Promise<IPerformRequestResult<IFormDataGuide>> => await super.findById(Entities.GUIDE, false, _id);
    findAllGuideByNetworkGymId = async (networkGymId: string | null, search?: string | undefined): Promise<IPerformRequestResult<IFormDataGuide[]>> => await super.findByReferenceId(Entities.NETWORK_GYM_GUIDE, false,
        EntitiesService(Entities.NETWORK_GYM).singular, networkGymId, search);
    saveGuide = async (data: any): Promise<IPerformRequestResult<IFormDataGuide>> => await super.save(Entities.GUIDE, true, data);
}
