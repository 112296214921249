import ls from '../../../projects/localStorage';
import { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class SystemMessageService extends GenericService {
    allSystemMessages = async (networkGym: string, search?: string | undefined, headers?: any) => await super.findByReferenceId(Entities.SYSTEM_MESSAGE, false, 'networkGym', networkGym, search, undefined);
    allSystemMessageNoBlockeds = async (search?: any | undefined) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        const networkGyms = await ls.networkGym.getNetworkGymsByStorageToken();
        search.queries.push({ field: '_id', value: { $in: networkGyms?.map((b: any) => b._id) } });
        return await super.findByQuery(Entities.SYSTEM_MESSAGE, false, search);
    }
    findSystemMessageByKey = async (systemMessageKey: string, networkGym: string, search?: any | undefined, headers?: any) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        
        search.queries.push({ field: 'systemMessageKey', value: systemMessageKey })
        search.queries.push({ field: 'networkGym', value: networkGym })

        return await super.findByQuery(Entities.SYSTEM_MESSAGE, false, search);
    };
    findSystemMessageById = async (_id: string | null) => await super.findById(Entities.SYSTEM_MESSAGE, false, _id);
    saveSystemMessage = async (data: any) => await super.save(Entities.SYSTEM_MESSAGE, false, data);
}
