import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class TrackerContactMessageService extends GenericService {
    findTrackerContactMessageById = async (_id: string | null) => await super.findById(Entities.TRACKER_CONTACT_MESSAGE, false, _id);
    saveTrackerContactMessage = async (data: any) => await super.save(Entities.TRACKER_CONTACT_MESSAGE, false, data);
    updateTrackerContactMessageByFiltered = async (id: string, data: { filter: any, object: any }) => await super.updateFiltered(Entities.TRACKER_CONTACT_MESSAGE, false, id, data);
    findTrackerContactMessageByNetworkGymId = async (networkGymId: string | null, branchGymId?: string | null, search?: any, limit:number = 100) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        search.queries.push({
            field: EntitiesService(Entities.NETWORK_GYM).singular,
            value: networkGymId
        });

        if (branchGymId) {
            // search.queries.push({
            //     field: EntitiesService(Entities.BRANCH_GYM).singular,
            //     value: branchGymId
            // });
            search.queries.push({
                field: '$or',
                value: [
                    { branchGym: branchGymId },
                    { branchGym: { $exists: false } },
                ]
            });
        };

        search.sorts = [{
            field: 'lastChange', value: -1
        }];

        // console.log('search: ', search);
        // return await super.findByQuery(Entities.TRACKER_CONTACT_MESSAGE, false, search, undefined, { auto_populate: 'false', summaries: 'true' })
        return await super.findByQuery(Entities.TRACKER_CONTACT_MESSAGE, false, search, undefined, { summaries: 'true', limit })
    };

    findBranchGymByTrackerContactMessageId = async (trackerContactMessageId: string | null, search?: any) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        search.queries.push({
            field: '_id',
            value: trackerContactMessageId
        });

        search.projections = [
            { field: 'key' },
            { field: 'branchGym' },
        ];


        // console.log('search: ', search);
        return await super.findByQuery(Entities.TRACKER_CONTACT_MESSAGE, false, search)
    };
    findTrackerContactMessageByBranchGymId = async (branchGymId: string | null, search?: string) => await super.findByReferenceId(Entities.TRACKER_CONTACT_MESSAGE, false,
        EntitiesService(Entities.BRANCH_GYM).singular, branchGymId, search);
    findTrackerContactMessageByTrackerMobileId = async (trackerMobileId: string | null, search?: string) => await super.findByReferenceId(Entities.TRACKER_CONTACT_MESSAGE, false,
        EntitiesService(Entities.TRACKER_MOBILE).singular, trackerMobileId, search);
}
