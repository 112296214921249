import ls from '../../../projects/localStorage';
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class BranchGymService extends GenericService {
    findBranchGymById = async (_id: string | null,headers?:any) => await super.findById(Entities.BRANCH_GYM, false, _id, undefined,headers);
    findAllBranchGymByNetworkGymIds = async (networkGymIds: string[], search?: any) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        search.queries.push({ field: EntitiesService(Entities.NETWORK_GYM).singular, value: {$in: networkGymIds} });
        return await super.findByQuery(Entities.BRANCH_GYM, false, search);
    };
    findBranchGymByNetworkGymId = async (networkGymId: string | null, search?: string) => await super.findByReferenceId(Entities.BRANCH_GYM, false,
        EntitiesService(Entities.NETWORK_GYM).singular, networkGymId, search);
    findBranchGymNoBlockedByNetworkGymId = async (networkGymId: string | null, search?: any) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        search.queries.push({ field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId });
        const branchGyms = await ls.branchGym.getBranchGymsByStorageToken();
        search.queries.push({ field: '_id', value: { $in: branchGyms?.map((b: any) => b._id) } });
        search.queries.push({ field: 'nonpayer', value: false });
        search.queries.push({ field: 'unsubscribe', value: false });
        return await super.findByQuery(Entities.BRANCH_GYM, false, search);
    };
    findAllBranchGymNoBlockedByNetworkGymId = async (networkGymId: string | null, search?: any) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        search.queries.push({ field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId });
        search.queries.push({ field: 'nonpayer', value: false });
        search.queries.push({ field: 'unsubscribe', value: false });
        return await super.findByQuery(Entities.BRANCH_GYM, false, search);
    };
    saveBranchGym = async (data: any) => await super.save(Entities.BRANCH_GYM, false, data);
}
