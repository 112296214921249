import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import ProjectDefault from './projects';

let projectStorageId: string | null = localStorage.getItem('project.id');
// console.log('projectStorageId: ', projectStorageId);
if (!projectStorageId) { localStorage.setItem('project.id', 'chat4fit'); projectStorageId = 'chat4fit'; };
const selectProjects = [];
for (const project of ProjectDefault.projects) selectProjects.push(<Card><Button name={project.id} onClick={() => {
  localStorage.setItem('project.id', project.id);
  document.location.reload();
}}>{project.id.toUpperCase()}</Button></Card>);


// eslint-disable-next-line no-lone-blocks
{/* 
<React.StrictMode>
  <HashRouter>
    {!projectStorageId ? <>{selectProjects}</> : <AppWrapper project={projectStorageId} />}
  </HashRouter>
</React.StrictMode>, 
*/}
ReactDOM.render(
  <HashRouter>
    {!projectStorageId ? <>{selectProjects}</> : <AppWrapper project={projectStorageId} />}
  </HashRouter>,
  document.getElementById('root'),
  () => {
    if (!projectStorageId) return;
    ProjectDefault.hideSplashScreen();
    ProjectDefault.defineProject(projectStorageId);
  }
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
