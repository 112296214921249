/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useEffect, useState } from 'react';
import { aroundDate, StatusSchedule } from '../data/Schedule';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import { Schedule } from '../Schedule/Schedule';
import './ScheduleListStyle.css';

export const ScheduleList = (props: IPropsArgsComponents) => {
    const [actualDate] = useState(aroundDate(moment()));
    const [firstDate] = useState(moment(actualDate));
    const [pendentScheduleList, setPendentScheduleList] = useState(new Array<any>());
    const [finishedScheduleList, setFinishedScheduleList] = useState(new Array<any>());
    const [dataSchedules, setDataSchedules] = useState(new Array<any>());

    const [currentDate] = useState(aroundDate(moment()));

    useEffect(() => {
        if (props.data) setDataSchedules(props.data);
    }, [props.data]);

    useEffect(() => {
        let newFirstDate = firstDate;
        if (dataSchedules && dataSchedules.length === 1) {
            while (moment(newFirstDate).weekday() !== 0) newFirstDate = newFirstDate.subtract(1, 'day');
            setPendentScheduleList(dataSchedules[0].pendentList?.filter((d: any) => moment(d.date).diff(currentDate) < 0 && d.statusSchedule === 'opened'
                && (d.statusSchedule !== StatusSchedule.HIRED && d.statusSchedule !== StatusSchedule.VISITED && d.statusSchedule !== StatusSchedule.RESCHEDULED)));
            setFinishedScheduleList(dataSchedules[0].finishedList?.filter((d: any) => d.statusSchedule === 'closed'
                && (d.statusSchedule !== StatusSchedule.HIRED && d.statusSchedule !== StatusSchedule.VISITED && d.statusSchedule !== StatusSchedule.RESCHEDULED)));
        }
    }, [dataSchedules]);

    return (
        <Accordion key={`Accordion-${Math.random() * 99999}`} className='widget-schedule-list-box' multiple activeIndex={[1]}>
            <AccordionTab headerClassName='header-finished' header={`ENCERRADOS 7 DIAS ${finishedScheduleList.length > 0 ? `(${finishedScheduleList.length})` : ''}`}>
                <div className='grid'>
                    {/* style={{ height: '50rem', overflowX: 'auto' }} */}
                    {finishedScheduleList.map((s: any) => <div key={`AccordionTabFinished-${Math.random() * 99999}`} className='sm:col-4 md:col-4 lg:col-4 xl:col-12'>
                        <Schedule width={'100%'} info={s} onClick={() => props.params.eventScheduleClick(s)} />
                    </div>)}
                </div>
            </AccordionTab>
            <AccordionTab headerClassName='header-pendent' header={`PENDENTES ${pendentScheduleList.length > 0 ? `(${pendentScheduleList.length})` : ''}`}>
                <div className='grid'>
                    {/* style={{ height: '50rem', overflowX: 'auto' }} */}
                    {pendentScheduleList.map((s: any) => <div key={`AccordionTabPendent-${Math.random() * 99999}`} className='sm:col-4 md:col-4 lg:col-4 xl:col-12'>
                        <Schedule width={'100%'} info={s} onClick={() => props.params.eventScheduleClick(s)} />
                    </div>)}
                </div>
            </AccordionTab>
        </Accordion>
    )
}