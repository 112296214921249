/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { RoleRules, checVigilanceByToken, checkRoleHierarchyByToken, convertRoleRules } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import { isCPF, isMail, isMobilePhone } from '../../../utils/functions/data';
import { onlyNumbers } from '../../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import CustomerService from '../../service/CustomerService';
import BranchGymExternalUserService from '../../service/admin/BranchGymExternalUserService';
import ExternalUserService from '../../service/admin/ExternalUserService';
import { IMessage } from '../Control';
import { createSkeletons } from '../commons/GenericCard';
import { GenericFields } from '../commons/GenericFields';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { addNewRegister } from '../commons/formDataFunctions';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormListDataBranchGymExternalUser, IFormListDataEditBranchGymExternalUser } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { createGenericListData } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './BranchGymExternalUser.css';

const branchGymExternalUserService = new BranchGymExternalUserService();
const customerService = new CustomerService();


export const BranchGymExternalUser = (props: IPropsArgsComponents) => {
    const [title] = useState('Unidade < Usuários');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [editDialogData, setEditDialogData] = useState(false);
    const [data, setData] = useState<IFormListDataBranchGymExternalUser[] | null>(null);
    const [branchGymData, setBranchGymData] = useState<any[] | null>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [editFormData, setEditFormData] = useState<any | null>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: true },
    ]), []);


    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    useEffect(() => { refreshListBranchGyms(); }, [networkGymId]);
    // useEffect(() => { refreshListBranchGyms(); refreshListBranchGymExternalUsers(); }, []);
    useEffect(() => refreshList(), [networkGymId, branchGymId]);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, branchGymData]);

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        if (networkGymId && branchGymData) {
            branchGymExternalUserService.allBranchGymExternalUsersByNetworkGymId(networkGymId, searchTerm).then(async (returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setData(null);
                    } else if (isSuccess(returned.status)) {
                        let returnedBranchGymExternalUsers = returned.data.filter((d: any) => {
                            return (checkRoleHierarchyByToken(RoleRules.manager) ? true : d.role === 'attendant') && d.active === true;
                        });
                        returnedBranchGymExternalUsers = returnedBranchGymExternalUsers.filter((r: any) => r.customer._id !== ls.customer.getCustomerIdStorageToken());
                        returnedBranchGymExternalUsers = returnedBranchGymExternalUsers.filter((r: any) => branchGymData.map(b => b._id)?.includes(r.branchGym._id));
                        // returnedBranchGymExternalUsers = returnedBranchGymExternalUsers.filter((r: any) => RoleRules.manager === getRoleValueByToken() ? r.role === RoleRules.attendant : true);
                        if (branchGymId) returnedBranchGymExternalUsers = returnedBranchGymExternalUsers.filter((r: any) => returnedBranchGymExternalUsers.filter((r2: any) => r2.branchGym._id === branchGymId).map((r3: any) => r3.customer._id).includes(r.customer._id));

                        let branchGymExternalUsers: any[] = [];
                        for (let i = 0; i < returnedBranchGymExternalUsers.length; i++) {
                            const u = returnedBranchGymExternalUsers[i];
                            let founded = branchGymExternalUsers.find((u2: any) => u2.customer._id === u.customer._id);
                            if (!founded) {
                                branchGymExternalUsers.push(Object.assign({
                                    branchGymExternalUser: branchGymData?.map((b: any) => {
                                        return {
                                            _id: b._id,
                                            name: b.name,
                                            selected: b._id === u.branchGym._id
                                        }
                                    })
                                }, u));
                            } else {
                                let branch = founded.branchGymExternalUser.find((b: any) => b._id === u.branchGym._id);
                                if (branch) branch.selected = true;
                            }
                        }
                        if (branchGymExternalUsers && branchGymExternalUsers.length > 0) setData(branchGymExternalUsers);
                        else setData(null);
                    } else {
                        setData(null);
                    }
                    setLoadingData(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingData(false);
                    setData(null);
                }
            });
        } else {
            setLoadingData(false);
            setData(null);
        }
        return () => { isMounted = false };
    };


    const refreshListBranchGyms = async () => {
        let isMounted = true;
        setLoadingData(true);
        const branchGymsByStorageToken = await ls.branchGym.getBranchGymsByStorageToken();

        if (isMounted) {
            setBranchGymData(ls.branchGym.getAllBranchGyms({
                networkGymId,
                branchGymIds: branchGymsByStorageToken?.map(b => b._id),
                nonpayer: false,
                unsubscribe: false
            }));
        }
        setLoadingData(false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
    };

    const listData = () => {
        let listData = data;

        if (listData) {
            listData = listData.sort((l1: any, l2: any) => {
                return l1.customer?.nickname < l2.customer?.nickname ? -1 : l1.customer?.nickname > l2.customer?.nickname ? 1 : 0;
            });

            listData.forEach((d: any) => {
                // d.branchGymExternalUser = loadBranchGyms(d.customer._id, branchGymData!, branchGymExternalUserData);
                d.branchGymExternalUsers = d.branchGymExternalUser.filter((f: any) => f.selected).map((b: any) => b.name).join(', ');
            });
        }

        // console.log('listData: ', listData);
        // branchGymExternalUsers

        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;
        // if (!branchGymId && !checkRoleHierarchyByToken(RoleRules.owner))
        //     return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
        //         <h3>Você precisa selecionar uma Unidade.</h3>
        //     </div>;
        if (loadingData) return createSkeletons(8);


        const buttons = [];

        buttons.push({
            evalCondition: '!data.customer.blocked || data.customer.blocked === false', key: `bloquear`, icon: 'pi-lock',
            method: (type: string, _id?: string | null | undefined, ...args: string[]) => blockedCustomer(type, _id, ...args), tooltip: 'Bloquear'
        });
        buttons.push({
            evalCondition: 'data.customer.blocked && data.customer.blocked === true', key: `desbloquear`, icon: 'pi-lock-open',
            method: (type: string, _id?: string | null | undefined, ...args: string[]) => unblockedCustomer(type, _id, ...args), tooltip: 'Desbloquear'
        });

         // 

        return createGenericListData('branchGymExternalUser', data,
            { icon: 'pi-user', evalValue: 'data.customer.nickname', evalInactive: 'data.customer.blocked' },
            [{ description: '\'E-mail\'', value: 'data.customer.email' },
            { description: '\'Perfil\'', value: 'data.role === \'attendant\' ? \'Atendente\' : data.role === \'manager\' ? \'Supervisor\' : data.role === \'owner\' ? \'Proprietário\' : \'-\'' },
            { description: '\'Unidades\'', value: 'data.branchGymExternalUsers', fontSize: '\'0.75rem\'', overflow: '\'visible\'' },
            { description: '', value: '' }],
            localStorage.getItem('branchGymExternalUser'),
            {
                isEdited: !!checkRoleHierarchyByToken(RoleRules.manager),
                isCloned: false, isSelected: false,
                loadingData,
                editForceData: { networkGym: networkGymId },
                // removeForceData: ['_id', 'key'],
                select: props.args.select,
                unselect: props.args.unselect,
                setEditFormData: setEditCustomerData,
                setDialogData: setEditDialogData,
                buttons: [
                    // {
                    //     evalCondition: '!data.customer.blocked || data.customer.blocked === false', key: `editar`, icon: 'pi-pencil',
                    //     method: (type: string, _id?: string | null | undefined, ...args: string[]) => setEditCustomerData(type, _id, ...args), tooltip: 'Editar'
                    // },
                    // {
                    //     evalCondition: '!data.customer.blocked || data.customer.blocked === false', key: `alterar_senha`, icon: 'pi-hashtag',
                    //     method: (type: string, _id?: string | null | undefined, ...args: string[]) => setChangePassword(type, _id, ...args), tooltip: 'Alterar Senha'
                    // },
                    ...buttons
                ]
            });
    }

    // const saveSuccessed = (returned: IPerformRequestResult<any>) => {
    //     if (isSuccess(returned.status)) {
    //         props.args.setLoading(false);
    //         setDialogData(false);
    //         props.args.setMessage({ severity: 'success', summary: 'Registro atualizado com sucesso!', detail: '', life: 5000 });
    //         refreshList();
    //     } else if (returned.status === HttpStatus.UNAUTHORIZED) {
    //         props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
    //         // setDialogData(false);
    //         setTimeout(() => { props.args.setLoading(false); window.location.href = '/'; }, 5000);
    //     } else {
    //         props.args.setLoading(false);
    //         props.args.setMessage({ severity: 'warn', summary: 'Dados incorretos!', detail: '', life: 5000 });
    //     }
    // }

    // const saveFailed = (error: any) => {
    //     console.error('error: ', error);
    //     props.args.setMessage({ severity: 'error', summary: 'Ocorreu um erro desconhecido!', detail: '', life: 5000 });
    //     // setDialogData(false);
    //     setTimeout(() => { props.args.setLoading(false); window.location.href = '/'; }, 5000);
    // }

    const blockOrUnblockCustomer = async (branchGymExternalUserById: string, block: boolean) => {
        props.args.setLoading(true);
        const branchGymExternalUser: any = await branchGymExternalUserService.findBranchGymExternalUserById(branchGymExternalUserById);
        let customerId = branchGymExternalUser?.data?.customer?._id || branchGymExternalUserById;

        if (networkGymId && customerId) {
            const branchGymExternalUsers: any = await branchGymExternalUserService.allNetworkGymExternalUsers(networkGymId);
            // console.log('branchGymExternalUsers: ', branchGymExternalUsers, customerId);
            if (branchGymExternalUsers && branchGymExternalUsers.data) {
                let blockOrUnblockBranchGymExternalUsers = branchGymExternalUsers.data.filter((b: any) => b.customer._id === customerId);

                for (let i = 0; i < blockOrUnblockBranchGymExternalUsers.length; i++) {
                    const blockOrUnblockBranchGymExternalUser = blockOrUnblockBranchGymExternalUsers[i];
                    await customerService.changeBlockedCustomer(blockOrUnblockBranchGymExternalUser.customer._id, block);
                }
                refreshList();
            }
        }
        props.args.setLoading(false);
    }
    const blockedCustomer = async (type: string, _id?: string | null | undefined, ...args: string[]) => {
        if (_id) await blockOrUnblockCustomer(_id, true);
    }

    const unblockedCustomer = async (type: string, _id?: string | null | undefined, ...args: string[]) => {
        if (_id) await blockOrUnblockCustomer(_id, false);
    }

    const setEditCustomerData = async (editCustomerData: any) => {
        if (editCustomerData?.customer?._id) {
            const customerFounded = await customerService.findCustomerById(editCustomerData.customer._id);
            if (customerFounded && customerFounded.data) {
                setEditFormData({
                    _id: customerFounded.data._id,
                    branchGymExternalUserId: editCustomerData._id,
                    name: customerFounded.data.name,
                    nickname: customerFounded.data.nickname,
                    cpf: customerFounded.data.cpf,
                    mobilePhone: customerFounded.data.mobilePhone,
                    email: customerFounded.data.email,
                    role: editCustomerData.role && editCustomerData.role === 'owner' ?
                        { id: RoleRules.owner, name: RoleRules.owner, description: convertRoleRules(RoleRules.owner)?.description }
                        : editCustomerData.role && editCustomerData.role === 'manager' ?
                            { id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description }
                            : { id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description },
                    blocked: customerFounded.data.blocked,
                    // branchGymExternalUser: loadBranchGyms(customerFounded.data._id, branchGymData!, branchGymExternalUserData),
                    branchGymExternalUser: editCustomerData.branchGymExternalUser
                });
            }
        }
    }

    const setEditNewCustomerData = () => {
        setEditFormData({
            role: checkRoleHierarchyByToken(RoleRules.owner) ?
                { id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description } :
                { id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description },
            name: '',
            nickname: '',
            cpf: '',
            mobilePhone: '',
            email: '',
            password: '',
            networkGym: networkGymId,
            branchGym: branchGymId,
            customer: '',
            blocked: false,
            branchGymExternalUser: branchGymData?.map((b: any) => {
                return {
                    _id: b._id,
                    name: b.name,
                    selected: false
                }
            }),
            active: true,
            deleted: false
        });
    }

    const updateBranchGymExternalUserCustomer = async (externalUser: FormikValues, customer: any) => {
        for (let i = 0; i < externalUser.branchGymExternalUser.length; i++) {
            const b = externalUser.branchGymExternalUser[i];

            if (customer || customer._id) {
                externalUser.branchGym = b._id;
                await (new BranchGymExternalUserService()).allNetworkGymExternalUsers(externalUser.networkGym, b._id, customer || customer._id)
                    .then(async (returnedExternalUser: any) => {
                        if (isSuccess(returnedExternalUser.status)) {
                            const dataExternalUser = returnedExternalUser.data[0];
                            const saveBranchGymExternalUser = {
                                _id: dataExternalUser._id,
                                role: externalUser.role,
                                deleted: !b.selected,
                                active: b.selected
                            };
                            // console.log('saveBranchGymExternalUser: ', saveBranchGymExternalUser);
                            await (new BranchGymExternalUserService()).saveBranchGymExternalUser(saveBranchGymExternalUser);
                        } else {
                            if (b.selected) {
                                const saveBranchGymExternalUser = {
                                    role: externalUser.role,
                                    networkGym: externalUser.networkGym,
                                    branchGym: externalUser.branchGym,
                                    customer: customer || customer._id,
                                    deleted: !b.selected,
                                    active: b.selected
                                };
                                // console.log('saveBranchGymExternalUser: ', saveBranchGymExternalUser);
                                await (new BranchGymExternalUserService()).saveBranchGymExternalUser(saveBranchGymExternalUser);
                            }
                        }
                    }).catch(async (error: any) => {
                        if (b.selected) {
                            const saveBranchGymExternalUser = {
                                role: externalUser.role,
                                networkGym: externalUser.networkGym,
                                branchGym: externalUser.branchGym,
                                customer: customer || customer._id,
                                deleted: !b.selected,
                                active: b.selected
                            };
                            // console.log('saveBranchGymExternalUser: ', saveBranchGymExternalUser);
                            await (new BranchGymExternalUserService()).saveBranchGymExternalUser(saveBranchGymExternalUser);
                        }
                    });

            }
        }
        // await reloadBranchGymExternalUserData(networkGymId, setBranchGymExternalUserData, true);
        return true;
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: !!networkGymId && branchGymData! && (checkRoleHierarchyByToken(RoleRules.owner) || (checkRoleHierarchyByToken(RoleRules.manager) && checVigilanceByToken())),
                addNewRegister: () => addNewRegister('branchGymExternalUser', undefined, { setEditFormData: setEditNewCustomerData, setDialogData }),
                hasSearchTerm: false, searchTerm, setSearchTerm, setEditFormData: setEditNewCustomerData, setDialogData,
                listData: () => listData()
            })}
            {token && networkGymId && branchGymData ?
                <>
                    <GenericFormDialog<IFormListDataBranchGymExternalUser> key='ExternalUserDialog'
                        token={token}
                        objectData='usuário'
                        refreshList={() => refreshList()}
                        setLoading={(value) => props.args.setLoading(value)}
                        showDialog={dialogData}
                        setDialogData={(value) => setDialogData(value)}
                        setMessage={(message: IMessage) => props.args.setMessage(message)}
                        editFormData={editFormData}
                        emptyFormData={{
                            role: checkRoleHierarchyByToken(RoleRules.owner) ?
                                { id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description } :
                                { id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description },
                            name: '',
                            nickname: '',
                            cpf: '',
                            mobilePhone: '',
                            email: '',
                            password: '',
                            networkGym: networkGymId,
                            branchGym: branchGymId,
                            customer: '',
                            blocked: false,
                            branchGymExternalUser: [],
                            active: true,
                            deleted: false
                        }}
                        retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormListDataBranchGymExternalUser>[]) => [
                            {
                                colSize: 'col-8', fieldName: 'name', fieldType: 'InputTextField', description: '* Nome', placeholder: 'nome do usuário', value: formData.values.name,
                                onChange: formData.handleChange, autoFocus: true,
                            },
                            {
                                colSize: 'col-4', fieldName: 'nickname', fieldType: 'InputTextField', description: '* Apelido', placeholder: 'apelido do usuário', value: formData.values.nickname,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-3', fieldName: 'cpf', fieldType: 'InputMaskField', description: '* CPF', placeholder: '999.999.999-99', value: formData.values.cpf,
                                mask: '999.999.999-99',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-3', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
                                mask: '+55 (99) 99999-9999',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-6', fieldName: 'role', fieldType: 'DropdownField', description: '* Perfil:', placeholder: 'Selecione um perfil', value: formData.values.role, autoFocus: false,
                                onChange: (event: any) => formData.handleChange(event),
                                options: checkRoleHierarchyByToken(RoleRules.owner) ?
                                    checVigilanceByToken() ?
                                        [{ id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description },
                                        { id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description }]
                                        :
                                        [{ id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description }]
                                    : [{ id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description }],
                                optionLabel: 'description', filter: true, showClear: true, filterBy: 'description',
                            },
                            {
                                colSize: 'col-12', fieldName: 'email', fieldType: 'InputTextField', description: '* Email', placeholder: 'email do usuário', value: formData.values.email,
                                autoComplete: 'off',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            !formData.values._id ?
                                {
                                    colSize: 'col-6', fieldName: 'password', fieldType: 'PasswordField', description: '* Senha', placeholder: 'senha do usuário', value: formData.values.password,
                                    autoComplete: 'new-password', toggleMask: true,
                                    onChange: formData.handleChange, autoFocus: false,
                                } : null,
                            !formData.values._id ?
                                {
                                    colSize: 'col-6', fieldName: 'password_check', fieldType: 'PasswordField', description: '* Confirmação Senha', placeholder: 'repita a senha do usuário', value: formData.values.password_check,
                                    autoComplete: 'new-password', toggleMask: true,
                                    onChange: formData.handleChange, autoFocus: false,
                                } : null,
                            // !formData.values._id ?
                            //     checkRoleHierarchyByToken(RoleRules.manager) ?
                            //         GenericFields.blockedField(formData, { colSize: 6 })
                            //         : null
                            //     : null,
                            {
                                colSize: 'col-12', fieldName: 'branchGymExternalUser', fieldType: 'SelectAreaList', description: '* Unidades', placeholder: 'unidades do usuário', value: formData.values.branchGymExternalUser,
                                objectDataList: formData.values.branchGymExternalUser,
                                colspanItem: 'col-4', idField: '_id',
                                nameField: 'name',
                                selectField: 'selected',
                                onChangeListOption: (branchGymId: string, select: boolean) => {
                                    const list = formData.values.branchGymExternalUser;
                                    list.forEach((l: any) => {
                                        if (l._id === branchGymId) l.selected = select;
                                    });
                                    formData.setFieldValue('branchGymExternalUser', list);
                                }, autoFocus: false,
                            },
                        ])}
                        editForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        // editFunctions={[]}
                        formLists={[]}
                        validate={(data: any) => {
                            let errors: any = {};

                            if (!data.nickname) {
                                errors.nickname = 'Apelido é obrigatório.';
                            } else if (data.nickname.length < 3) {
                                errors.nickname = 'Apelido precisa ter mais que 2 caracteres.';
                            }

                            if (!data.name) {
                                errors.name = 'Nome é obrigatório.';
                            } else if (data.name.length < 5) {
                                errors.name = 'Nome precisa ter mais que 4 caracteres.';
                            }

                            if (data.cpf && !isCPF(data.cpf)) {
                                errors.cpf = 'CPF inválido.';
                            }

                            if (!data.mobilePhone) {
                                errors.mobilePhone = 'Número de celular é obrigatório.';
                            } else if (!isMobilePhone(onlyNumbers(data.mobilePhone))) {
                                errors.mobilePhone = 'Número de celular inválido.';
                            }

                            if (!data.email) {
                                errors.email = 'E-mail é obrigatório.';
                            } else if (!isMail(data.email)) {
                                errors.email = 'Email inválido.';
                            }

                            if (!data.password) {
                                errors.password = 'Senha é obrigatório.';
                            } else if (data.password.length < 6) {
                                errors.password = 'Senha precisa ter mais que 6 caracteres.';
                            } else if (data.password !== data.password_check) {
                                errors.password = 'Senha e confirmação de senha precisam ser exatamente iguais!';
                            }

                            if (!data.password_check) {
                                errors.password_check = 'Confirmação de senha é obrigatório.';
                            } else if (data.password_check.length < 6) {
                                errors.password_check = 'Confirmação de senha precisa ter mais que 6 caracteres.';
                            } else if (data.password !== data.password_check) {
                                errors.password_check = 'Senha e confirmação de senha precisam ser exatamente iguais!';
                            }

                            if (!data.role) {
                                errors.role = 'Perfil é obrigatório.';
                            }

                            if (!data.branchGymExternalUser || !data.branchGymExternalUser.find((b: any) => b.selected)) {
                                errors.branchGymExternalUser = 'É obrigatória a seleção de uma unidade para incluir o usuário.';
                            }

                            return errors;
                        }}
                        submitForceData={{
                            networkGym: networkGymId,
                            branchGym: branchGymId
                        }}
                        onSubmit={(props: IPropsFormDialog<IFormListDataBranchGymExternalUser>, data: FormikValues, formData: FormikInterface) => {
                            let customer: any;
                            const externalUser = Object.assign({}, data);
                            customerService.findCustomerByEmail(encodeURI(externalUser.email)).then((resultCustomer: any) => {
                                // console.log('resultCustomer[0]: ', resultCustomer.data[0]);
                                if (isSuccess(resultCustomer.status) && resultCustomer.data
                                    && resultCustomer.data.length > 0 && resultCustomer.data[0]._id) customer = resultCustomer.data[0]._id;
                            }).finally(() => {
                                externalUser.role = externalUser.role.id;
                                if (!customer) {
                                    const branchGymExternalUserSelected = externalUser.branchGymExternalUser.find((b: any) => b.selected);
                                    if (branchGymExternalUserSelected) {
                                        if (branchGymExternalUserSelected.selected) {
                                            externalUser.branchGym = branchGymExternalUserSelected._id;
                                            new ExternalUserService().saveExternalUser(externalUser)
                                                .then((returned: any) => {
                                                    if (isSuccess(returned.status)) {
                                                        customerService.findCustomerByEmail(encodeURI(externalUser.email)).then((resultCustomer: any) => {
                                                            if (isSuccess(returned.status)) {
                                                                customer = resultCustomer.data[0]._id;
                                                                // console.log('customer = resultCustomer.data[0]._id: ', customer, resultCustomer.data[0], resultCustomer.data[0]._id)
                                                                updateBranchGymExternalUserCustomer(externalUser, customer).finally(() => {
                                                                    saveSuccessed(props, formData)(returned);
                                                                });
                                                            } else {
                                                                saveFailed(props, formData)(new Error('Ocorreu um erro desconhecido!'));
                                                            }
                                                        })
                                                            .catch((error: any) => saveFailed(props, formData)(error));;
                                                    } else {
                                                        if (returned.status.code === 409) saveSuccessed(props, formData, 'Email informado já cadastrado no sistema Chat4Fit, verifique o e-mail informado ou entre em contato com o suporte!')(returned);
                                                        saveSuccessed(props, formData)(returned);
                                                    }
                                                })
                                                .catch((error: any) => saveFailed(props, formData)(error));
                                        }
                                    } else {
                                        saveFailed(props, formData)(new Error('Ocorreu um erro desconhecido!'));
                                    };
                                } else {
                                    // updateBranchGymExternalUserCustomer(externalUser, customer);
                                    saveSuccessed(props, formData, 'Email informado já cadastrado no sistema Chat4Fit, verifique o e-mail informado ou entre em contato com o suporte!')();
                                }
                            });
                        }} />

                    <GenericFormDialog<IFormListDataEditBranchGymExternalUser> key='ExternalUserEditDialog'
                        token={token}
                        objectData='usuário'
                        refreshList={() => refreshList()}
                        setLoading={(value) => props.args.setLoading(value)}
                        showDialog={editDialogData}
                        setDialogData={(value) => setEditDialogData(value)}
                        setMessage={(message: IMessage) => props.args.setMessage(message)}
                        editFormData={editFormData}
                        emptyFormData={{
                            role: checkRoleHierarchyByToken(RoleRules.owner) ?
                                { id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description } :
                                { id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description },
                            name: '',
                            nickname: '',
                            cpf: '',
                            mobilePhone: '',
                            email: '',
                            password: '',
                            networkGym: networkGymId,
                            branchGym: branchGymId,
                            customer: '',
                            branchGymExternalUser: [],
                            active: true,
                            deleted: false
                        }}
                        retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormListDataEditBranchGymExternalUser>[]) => [
                            {
                                colSize: 'col-8', fieldName: 'name', fieldType: 'InputTextField', description: '* Nome', placeholder: 'nome do usuário', value: formData.values.name,
                                onChange: formData.handleChange, autoFocus: true,
                            },
                            {
                                colSize: 'col-4', fieldName: 'nickname', fieldType: 'InputTextField', description: '* Apelido', placeholder: 'apelido do usuário', value: formData.values.nickname,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-3', fieldName: 'cpf', fieldType: 'InputMaskField', description: '* CPF', placeholder: '999.999.999-99', value: formData.values.cpf,
                                mask: '999.999.999-99',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-3', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
                                mask: '+55 (99) 99999-9999',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-6', fieldName: 'role', fieldType: 'DropdownField', description: '* Perfil:', placeholder: 'Selecione um perfil', value: formData.values.role, autoFocus: false,
                                onChange: (event: any) => formData.handleChange(event),
                                options: checkRoleHierarchyByToken(RoleRules.owner) ?
                                    [{ id: RoleRules.manager, name: RoleRules.manager, description: convertRoleRules(RoleRules.manager)?.description },
                                    { id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description }]
                                    : [{ id: RoleRules.attendant, name: RoleRules.attendant, description: convertRoleRules(RoleRules.attendant)?.description }],
                                optionLabel: 'description', filter: true, showClear: true, filterBy: 'description',
                            },
                            {
                                colSize: 'col-12', fieldName: 'email', fieldType: 'InputTextField', description: '* Email', placeholder: 'email do usuário', value: formData.values.email,
                                autoComplete: 'off',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            GenericFields.genericGroupField('Redefinição de senha de acesso do usuário'),
                            {
                                colSize: 'col-6', fieldName: 'password', fieldType: 'PasswordField', description: 'Senha', placeholder: 'senha do usuário', value: formData.values.password,
                                autoComplete: 'new-password', toggleMask: true,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-6', fieldName: 'password_check', fieldType: 'PasswordField', description: 'Confirmação Senha', placeholder: 'repita a senha do usuário', value: formData.values.password_check,
                                autoComplete: 'new-password', toggleMask: true,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-12', fieldName: 'branchGymExternalUser', fieldType: 'SelectAreaList', description: '* Unidades', placeholder: 'unidades do usuário', value: formData.values.branchGymExternalUser,
                                objectDataList: formData.values.branchGymExternalUser,
                                colspanItem: 'col-4', idField: '_id',
                                nameField: 'name',
                                selectField: 'selected',
                                onChangeListOption: (branchGymId: string, select: boolean) => {
                                    const list = formData.values.branchGymExternalUser;
                                    list.forEach((l: any) => {
                                        if (l._id === branchGymId) l.selected = select;
                                    });
                                    formData.setFieldValue('branchGymExternalUser', list);
                                }, autoFocus: false,
                            },
                        ])}
                        editForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        // editFunctions={[]}
                        formLists={[]}
                        validate={(data: any) => {
                            let errors: any = {};

                            if (!data.nickname) {
                                errors.nickname = 'Apelido é obrigatório.';
                            } else if (data.nickname.length < 3) {
                                errors.nickname = 'Apelido precisa ter mais que 2 caracteres.';
                            }

                            if (!data.name) {
                                errors.name = 'Nome é obrigatório.';
                            } else if (data.name.length < 5) {
                                errors.name = 'Nome precisa ter mais que 4 caracteres.';
                            }

                            if (!data.role) {
                                errors.role = 'Perfil é obrigatório.';
                            }

                            if (data.cpf && !isCPF(data.cpf)) {
                                errors.cpf = 'CPF inválido.';
                            }
                            if (!data.mobilePhone) {
                                errors.mobilePhone = 'Número de celular é obrigatório.';
                            } else if (!isMobilePhone(onlyNumbers(data.mobilePhone))) {
                                errors.mobilePhone = 'Número de celular inválido.';
                            }

                            if (!data.email) {
                                errors.email = 'E-mail é obrigatório.';
                            } else if (!isMail(data.email)) {
                                errors.email = 'Email inválido.';
                            }

                            if (data.password || data.password_check) {
                                if (!data.password) {
                                    errors.password = 'Senha é obrigatório.';
                                } else if (data.password.length < 6) {
                                    errors.password = 'Senha precisa ter mais que 6 caracteres.';
                                } else if (data.password !== data.password_check) {
                                    errors.password = 'Senha e confirmação de senha precisam ser exatamente iguais!';
                                }

                                if (!data.password_check) {
                                    errors.password_check = 'Confirmação de senha é obrigatório.';
                                } else if (data.password_check.length < 6) {
                                    errors.password_check = 'Confirmação de senha precisa ter mais que 6 caracteres.';
                                } else if (data.password !== data.password_check) {
                                    errors.password_check = 'Senha e confirmação de senha precisam ser exatamente iguais!';
                                }
                            }

                            if (!data.branchGymExternalUser || !data.branchGymExternalUser.find((b: any) => b.selected)) {
                                errors.branchGymExternalUser = 'É obrigatória a seleção de uma unidade para incluir o usuário.';
                            }

                            return errors;
                        }}
                        submitForceData={{
                            networkGym: networkGymId,
                            branchGym: branchGymId
                        }}
                        onSubmit={(props: IPropsFormDialog<IFormListDataEditBranchGymExternalUser>, data: FormikValues, formData: FormikInterface) => {
                            const externalUser = Object.assign({}, data);
                            externalUser.role = externalUser.role.id;

                            updateBranchGymExternalUserCustomer(externalUser, externalUser._id).finally(() => {
                                // console.log('updateBranchGymExternalUserCustomer', 'finally');
                                ; new CustomerService().saveCustomer(externalUser)
                                    .then((returned: IPerformRequestResult<any>) => {
                                        // console.log('saveCustomer', 'then');
                                        if (returned.status === HttpStatus.NOT_FOUND) {
                                            saveSuccessed(props, formData, 'Email informado já cadastrado no sistema Chat4Fit, verifique o e-mail informado ou entre em contato com o suporte!')(returned);
                                        } else {
                                            saveSuccessed(props, formData)(returned);
                                        }
                                    })
                                    .catch((error: any) => {
                                        // console.log('saveCustomer', 'catch');
                                        saveFailed(props, formData)(error);
                                    });
                            });
                        }} />
                </>
                :
                null
            }
        </>
    )
}


