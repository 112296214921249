import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class QuickMessageService extends GenericService {
    allNetworkGymQuickMessages = async (networkGymId: string) => {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId }
            ], 
            projections: [
                { field: 'networkGym' },
                { field: 'holder' },
                { field: 'title' },
                { field: 'isObject' },
                // { field: 'text' },
                // { field: 'mediaBase64' },
                // { field: 'mimetype' },
                { field: 'tag' },
                { field: 'blocked' }
            ],
            ignoreSummaryData: true
        };
        return await super.findByQuery(Entities.QUICK_MESSAGE, false, search, undefined, { limit: 0 })
    };
    allNetworkGymAndTagQuickMessages = async (networkGymId: string, tag: string) => {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId },
                { field: 'tag', value: tag }
            ]
        };
        return await super.findByQuery(Entities.QUICK_MESSAGE, false, search, undefined, { limit: 0 })
    };

    allQuickMessages = async (branchGym: string, search?: string | undefined) => await super.findByReferenceIdWithProjection(Entities.QUICK_MESSAGE, false, EntitiesService(Entities.BRANCH_GYM).singular, branchGym, undefined, search);
    // allQuickMessages = async (search?: string | undefined) => await super.findAll(Entities.QUICK_MESSAGE, false, search);
    findQuickMessageById = async (_id: string | null) => await super.findById(Entities.QUICK_MESSAGE, false, _id);
    saveQuickMessage = async (data: any) => await super.save(Entities.QUICK_MESSAGE, false, data);
}
