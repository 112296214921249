import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import { IPropsArgsComponents } from '../generics/formInterfaces';

export function retrieveListData(
    method: (param?: string) => Promise<IPerformRequestResult<any>>,
    props: IPropsArgsComponents, setList: React.Dispatch<any>): React.EffectCallback {
    return () => {
        let isMounted = true;
        method().then((returned: any) => {

            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                    setList([]);
                } else if (isSuccess(returned.status)) {
                    setList(returned.data);
                } else {
                    setList([]);
                }
            }
        }).catch(error => {
            if (isMounted) {
                console.error('error', error);
                setList([]);
            }
        });
        return () => { isMounted = false };
    };
}

export function retrieveListDataWithParentId(parentId: string | undefined,
    method: (parentId: string) => Promise<IPerformRequestResult<any>>,
    props: IPropsArgsComponents, setList: React.Dispatch<any>): React.EffectCallback {
        return () => {
            if (parentId) {
                method(parentId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                    setList([]);
                } else if (isSuccess(returned.status)) {
                    setList(returned.data);
                } else {
                    setList([]);
                }
            }).catch(error => {
                console.error('error', error);
                setList([]);
            });
        } else {
            setList([]);
        }
    };
}