/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { isDate } from "./date";
import { checkPropertyKey } from "./object";

export const configEnvironment = () => require('dotenv').config({ path: process.env['NODE_ENV'] === 'test' ? '.env.test' : '.env' });

export const defined = <T>(value: T | undefined | null): value is T => {
  let result = true;
  if (value) {
    if (isArray(value)) {
      if (value.length <= 0) result = false;
    } else if (typeof value === 'object') {
      if (isDate(value)) return true;
      let foundProperty = false;
      foundProperty = checkPropertyKey(value);
      result = foundProperty;
    }
  } else if (typeof value === 'undefined' || ((<boolean><any>value) !== false && (<number><any>value) !== 0)) {
    result = false;
  }
  return result;
};

export const freezeProcess = (timer: number) => new Promise((resolve) => setTimeout(() => resolve('🤡'), timer));

export const isArray = (arrayToCheck: Array<any> | any): arrayToCheck is Array<any> => (arrayToCheck instanceof Array) || (Array.isArray(arrayToCheck)) || (arrayToCheck && arrayToCheck !== Object.prototype && isArray(Object.getPrototypeOf(arrayToCheck)));

export const isBuffer = (bufferToCheck: any) => Buffer.isBuffer(bufferToCheck);

export const isFunction = (functionToCheck: any) => functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

export const waitSleep  = async(milles: number) => new Promise((resolve,  _reject) => setTimeout(() => resolve(true), milles));