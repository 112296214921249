/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import { EnumTypeShowMessage } from '../../../utils/functions/message';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import TrackerMessageService from '../../service/admin/TrackerMessageService';
import { IFormDataAttendance } from '../generics/entities';
import { IPropsArgsComponents } from '../generics/formInterfaces';

import ls from '../../../projects/localStorage';
import './MonitoringAttendance.css';

const trackerMessageService = new TrackerMessageService();

const timeRefreshMessages = 2000;
const limitMessages = 2000;


export const MonitoringAttendanceMessage = (props: IPropsArgsComponents) => {
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [contactMessageList, setContactMessageList] = useState(props.contactMessageList);
    const [data, setData] = useState<IFormDataAttendance[]>([]);
    const [dataLength, setDataLength] = useState<number>(0);
    const [refreshKey, setRefreshKey] = useState(0);
    const [contactMessageSelected, setContactMessageSelected] = useState<any>();
    const [lastContactMessageSelected, setLastContactMessageSelected] = useState<any>();
    const [listMessagesOption, setListMessagesOption] = useState<string>('todas');
    const [filterTextMessage, setFilterTextMessage] = useState<string>('');
    const [startDateMessages, setStartDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
    const [endDateMessages, setEndDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().toDate());


    useEffect(() => {
        if (!props?.args?.networkGymId) props.args.setLoading(false);
        setNetworkGymId(props.args.networkGymId);
    }, [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);
    useEffect(() => {
        if (props.contactSelected?._id !== contactMessageSelected?._id) {
            setContactMessageSelected(!props.contactSelected ? null : props.contactSelected);
        }
    }, [props.contactSelected]);
    useEffect(() => { setListMessagesOption(!props.listMessagesOption ? 'todas' : props.listMessagesOption); }, [props.listMessagesOption]);
    useEffect(() => { setStartDateMessages(!props.startDateMessages ? ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate() : props.startDateMessages);; }, [props.startDateMessages]);
    useEffect(() => { setEndDateMessages(!props.endDateMessages ? ls.socket.getServerSocketDateTime().toDate() : props.endDateMessages);; }, [props.endDateMessages]);
    useEffect(() => { setFilterTextMessage(!props.filterTextMessage ? '' : props.filterTextMessage);; }, [props.filterTextMessage]);
    useEffect(() => {
        if (!(props.contactMessageList && contactMessageList
            && contactMessageList?.length === props.contactMessageList?.length
            && contactMessageList?.every(function (u, i) { return props.contactMessageList && u === props.contactMessageList[i]; }))) {
            // setData([]);
            setContactMessageList(props.contactMessageList);
        }
    }, [props.contactMessageList]);

    useEffect(() => { refreshList(false, true); }, [refreshKey]);
    useEffect(() => { setData([]); refreshList(true, false); }, [networkGymId, branchGymId]);
    useEffect(() => { setData([]); refreshList(true, false); }, [contactMessageSelected]);
    useEffect(() => { refreshList(false, false); }, [startDateMessages, endDateMessages, contactMessageList]);
    // useEffect(() => { console.log('networkGymId, branchGymId: ', networkGymId, branchGymId); }, [networkGymId, branchGymId]);
    // useEffect(() => { console.log('contactMessageSelected: ', contactMessageSelected); }, [contactMessageSelected]);
    // useEffect(() => { console.log('startDateMessages, endDateMessages, contactMessageList: ', startDateMessages, endDateMessages, contactMessageList); }, [startDateMessages, endDateMessages, contactMessageList]);


    useEffect(() => { if (dataLength !== data.length) { setDataLength(data.length); } }, [data]);

    const refreshList = async (unloadingScreen?: boolean, refreshTimeout: boolean = true) => {
        let isMounted = true;

        if (unloadingScreen) props.args.setLoading(true);
        let first = lastContactMessageSelected != contactMessageSelected;
        if (first) setLastContactMessageSelected(contactMessageSelected);

        if (networkGymId) {
            const messageMonitorings = await trackerMessageService.findTrackerMessageMonitoring({
                jid: contactMessageSelected?.jid,
                networkGymId: networkGymId,
                branchGymId: branchGymId,
                // trackerMobileId: trackerMobileId,
                dateFilterParam: { start: startDateMessages, end: endDateMessages },
                contactMessageList: contactMessageList,
                // search?: string,
                limit: limitMessages
            });

            // console.log('networkGymId: ', networkGymId);

            if (messageMonitorings) {
                if (messageMonitorings.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                    setData([]);
                    setContactMessageSelected(undefined);
                    if (unloadingScreen) props.args.setLoading(false);
                    if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                } else if (isSuccess(messageMonitorings.status)) {
                    if (isMounted) {
                        setData(messageMonitorings.data.filter((d: any) => !d.deleted));
                    }
                    if (unloadingScreen) props.args.setLoading(false);
                    if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                } else {
                    setData([]);
                    if (unloadingScreen) props.args.setLoading(false);
                    if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
                }
            } else {
                setData([]);
                if (unloadingScreen) props.args.setLoading(false);
                if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
            }
        } else {
            setData([]);
            if (unloadingScreen) props.args.setLoading(false);
            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshMessages);
        }
        return () => { isMounted = false };
    };

    const prettyMessage = (message: string) => {
        return message.replace(/```([a-z][\S\s]+?)```/gmi, '<span style="font-size: 0.7rem; font-style: italic;">$1</span>')
            .replace(/\*([a-z][\S\s]+?)\*/gmi, '<span style="font-weight: bold;">$1</span>')
            .replace(/_([a-z][\S\s]+?)_/gmi, '<span style="font-style: italic;">$1</span>')
            .replace(/\n/g, "<br />");
    }

    const renderMessageInteraction = (contactMessageSelected: any, messageAttendance: IFormDataAttendance,
        content: JSX.Element,
        setMessagesEnd?: (el: HTMLDivElement | null) => void): JSX.Element => {
        return <div key={`${messageAttendance._id}_${messageAttendance.dateText}`}
            className={classNames('msg', { 'msg_geral': !contactMessageSelected?.jid || messageAttendance.group },
                { 'msg-object': messageAttendance.isObject && !messageAttendance?.mimetype?.includes('image') && !messageAttendance?.mimetype?.includes('video') && !messageAttendance?.mimetype?.includes('audio') && !messageAttendance?.mimetype?.includes('application') },
                { 'msg-me': messageAttendance.fromMe },
                { 'msg-frnd': !messageAttendance.fromMe },
                { 'msg-hidden': messageAttendance.typeShowMessage === EnumTypeShowMessage.hidden },
                { 'msg-system-message': messageAttendance.typeShowMessage === EnumTypeShowMessage.systemMessage },
                { 'msg-bot-message': messageAttendance.typeShowMessage === EnumTypeShowMessage.botMessage },
                { 'msg-bot-message-hidden': messageAttendance.typeShowMessage === EnumTypeShowMessage.botMessageHidden },
                { 'msg-interaction-message': messageAttendance.typeShowMessage === EnumTypeShowMessage.interactionMessage },
                { 'msg-nps-question-hidden': messageAttendance.typeShowMessage === EnumTypeShowMessage.npsQuestionHidden },
                { 'msg-not-read-receipt': !messageAttendance.readReceipt })}
            ref={setMessagesEnd ? (el) => setMessagesEnd(el) : null}>
            {content}
        </div>;
    }

    const renderMessages = () => {
        let messageList = data.sort((t1: any, t2: any) => moment(t2.dateText).diff(moment(t1.dateText)));

        // console.log('messageList-1: ', messageList?.length);

        if (listMessagesOption !== 'todas')
            messageList = messageList.filter(m => m.fromMe === (listMessagesOption === 'enviadas'));

        // console.log('messageList-2: ', messageList?.length);

        if (contactMessageSelected)
            messageList = messageList.filter(m => m.jid === contactMessageSelected.jid);

        // console.log('messageList-3: ', messageList?.length);

        messageList = messageList.filter(m => !m.isObject || (m.isObject === true && m.mimetype !== ''));

        // console.log('messageList-4: ', messageList?.length);

        messageList = filterTextMessage && filterTextMessage.length > 0 ? messageList.filter(m =>
            (m.pushName && m.pushName.toLowerCase().indexOf(filterTextMessage.toLowerCase()) >= 0) ||
            (m.text && m.text.toLowerCase().indexOf(filterTextMessage.toLowerCase()) >= 0)) : messageList;

        // console.log('messageList-5: ', messageList?.length);

        return messageList.map((m, i) => {
            // let contact = trackerContactMessages.find(c => c.jid === m.jid);
            // contact = contact ? contact.pushName : 'desconhecido';
            let contact = m.pushName; // contact ? contact.pushName : 'desconhecido';
            const content = <div className='msg_context'>
                {!contactMessageSelected?.jid || m.group ? <span className='msg_contact'>{contact}</span> : null}
                {!m.isObject ?
                    m.typeShowMessage === EnumTypeShowMessage.npsQuestionHidden.toString() && !checkRoleHierarchyByToken(RoleRules.admin) ?
                        <span dangerouslySetInnerHTML={{ __html: '<< sigilo de resposta da avaliação >>' }}></span>
                        :
                        m.text ? <span dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span> : null
                    :
                    m && m.mimetype && m.mimetype.includes('image') ?
                        <>
                            <div className='flex-initial flex align-items-center justify-content-center'>
                                <Image className='flex-initial flex flex-wrap' downloadable={true} src={`${m.pathCDN}`} alt="Imagem" height="100" preview />
                                {/* <Image className='flex-initial flex flex-wrap' downloadable={true} src={`${m.pathCDN}`} alt="Imagem" height="100" preview
                                    onContextMenu={(e) => e.preventDefault()}></Image> */}
                            </div>
                            {m.text ?
                                <div className='flex-initial flex align-items-center justify-content-center'>
                                    <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                                </div>
                                : null
                            }
                        </>
                        :
                        m && m.mimetype && m.mimetype.includes('video') ?
                            <>
                                <div className='flex-initial flex align-items-center justify-content-center'>
                                    <video className='flex-initial flex flex-wrap' width="400" height="200" controls>
                                        <source src={`${m.pathCDN}`} type={m.mimetype} />
                                    </video>
                                </div>
                                {m.text ?
                                    <div className='flex-initial flex align-items-center justify-content-center'>
                                        <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                                    </div>
                                    : null
                                }
                            </>
                            :
                            m && m.mimetype && m.mimetype.includes('audio') ?
                                <>
                                    <div className='flex-initial flex align-items-center justify-content-center'>
                                        <audio className='flex-initial flex flex-wrap' controls>
                                            <source src={`${m.pathCDN}`} type={m.mimetype} />
                                        </audio>
                                    </div>
                                    {m.text ?
                                        <div className='flex-initial flex align-items-center justify-content-center'>
                                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                                        </div>
                                        : null
                                    }
                                </>
                                :
                                m && m.mimetype && m.mimetype.includes('pdf') ?
                                    <>
                                        {m.jpegThumbnail ?
                                            <div className='flex-initial flex align-items-center justify-content-center'>
                                                {/* <Image className='flex-initial flex flex-wrap' src={`${m.jpegThumbnail ? `data:image/jpg;base64,${m.jpegThumbnail}` : ''}`} alt="Arquivo" height="80"
                                                    onContextMenu={(e) => e.preventDefault()}></Image> */}
                                                <Image className='flex-initial flex flex-wrap' src={`${m.jpegThumbnail ? `data:image/jpg;base64,${m.jpegThumbnail}` : ''}`} alt="Arquivo" height="80" />
                                            </div>
                                            :
                                            m.mimetype.includes('ms-excel') ?
                                                <div className='flex-initial flex align-items-center justify-content-center'>
                                                    <i className="pi pi-file-excel" style={{ fontSize: '4rem' }}></i>
                                                </div>
                                                :

                                                m.mimetype.includes('ms-word') ?
                                                    <div className='flex-initial flex align-items-center justify-content-center'>
                                                        <i className="pi pi-file-word" style={{ fontSize: '4rem' }}></i>
                                                    </div>
                                                    :
                                                    m.mimetype.includes('pdf') ?
                                                        <div className='flex-initial flex align-items-center justify-content-center'>
                                                            <i className="pi pi-file-pdf" style={{ fontSize: '4rem' }}></i>
                                                        </div>
                                                        : null
                                        }
                                        {/* <div className='flex-initial flex align-items-center justify-content-center' style={{ marginTop: 10 }}>
                                            <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: m.mimetype }}></span>
                                        </div> */}
                                        {m.pathCDN ?
                                            <div className='flex-initial flex align-items-center justify-content-center' style={{ padding: 0, paddingLeft: 10 }}>
                                                <a href={`${m.pathCDN}`} download target='_blank' rel="noreferrer">
                                                    <Button label="Clique para realizar o download." icon="pi pi-download" />
                                                </a>
                                            </div>
                                            : null}
                                        {m.text ?
                                            <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0, paddingLeft: 10 }}>
                                                <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                                            </div>
                                            : null
                                        }
                                    </>
                                    :
                                    <>
                                        <div className='flex-initial flex align-items-left justify-content-left' style={{ marginTop: 0 }}>
                                            <span className='flex-initial flex flex-wrap'>Envio de arquivo recusado pelo sistema!</span>
                                        </div>
                                        {m.mimetype ?
                                            <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0, paddingLeft: 10 }}>
                                                <span className='flex-initial flex flex-wrap'>Tipo: {m.mimetype}</span>
                                            </div>
                                            : null}
                                        {m.text ?
                                            <div className='flex-initial flex align-items-left justify-content-left' style={{ padding: 0 }}>
                                                <span className='flex-initial flex flex-wrap' dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                                            </div>
                                            : null}
                                    </>
                }
                <span className={classNames('msg_time', `tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`)}>{moment(m.dateText).format('DD-MM-yyyy HH:mm')}
                    <i className={classNames('pi', { 'pi-circle': !m.readReceipt }, { 'pi-check-circle': m.readReceipt })} style={{ fontSize: 10, marginTop: 3, marginLeft: 10 }}></i>
                </span>
                <Tooltip target={`.tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`} content={`${moment(m.dateText).format('DD-MM-yyyy HH:mm')}`} />
            </div>;

            return m.typeShowMessage === EnumTypeShowMessage.npsQuestionHidden.toString() && !checkRoleHierarchyByToken(RoleRules.admin) ?
                null :
                renderMessageInteraction(contactMessageSelected, m, content, i === messageList.length - 1 ? undefined : undefined);

        });
    }


    if (!data || data.length === 0)
        return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
            <h3>Nenhuma mensagem localizada.!</h3>
        </div>);
    else
        return (
            <>
                {renderMessages()}
                <div style={{ minHeight: 50 }}>
                    &nbsp;
                </div>
            </>
        );
};

