/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import { isCNPJ, isMail } from '../../../utils/functions/data';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import StateCityService from '../../service/StateCityService';
import BranchGymService from '../../service/admin/BranchGymService';
import { IMessage } from '../Control';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { GenericFields } from '../commons/GenericFields';
import { addNewRegister } from '../commons/formDataFunctions';
import { executeMethodFromFromList, retrieveListFromFromList, saveFailed, saveSuccessed } from '../commons/functions';
import { retrieveListData, retrieveListDataWithParentId } from '../commons/retrieveDataFunctions';
import { IFormDataBranchGym } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { createGenericListData } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './BranchGym.css';

const branchGymService = new BranchGymService();
const stateCityService = new StateCityService();

export const BranchGym = (props: IPropsArgsComponents) => {
    const [title] = useState('Unidades');
    const [token] = useState(props.args.token);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataBranchGym[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);

    const [editFormData, setEditFormData] = useState<any | null>();

    const [states, setStates] = useState<any>([]);
    const [stateSelected, setStateSelected] = useState<string>();
    const [cities, setCities] = useState<any>([]);
    useEffect(retrieveListData(() => stateCityService.allStates(), props, (data: any[]) => setStates(data)), []);
    useEffect(retrieveListDataWithParentId(stateSelected, (stateSelectedId: string) => stateCityService.cityByState(stateSelectedId), props, (data: any[]) => setCities(data)), [stateSelected]);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: false },
    ]), []);

    useEffect(() => {
        if (!token) window.location.href = '/';
    }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => refreshList(), [networkGymId]);

    const selectStateHandle = (event: any) => event && event.value && event.value._id ? setStateSelected(event.value._id) : setStateSelected(undefined);

    const refreshList = () => {
        if (networkGymId) {
            let isMounted = true;
            setLoadingData(true);
            // console.log('branchGymService.findBranchGymByNetworkGymId: ', networkGymId);
            branchGymService.findBranchGymByNetworkGymId(networkGymId, searchTerm).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setData([]);
                    } else if (isSuccess(returned.status)) {
                        setData(returned.data);
                    } else {
                        setData([]);
                    }
                    setLoadingData(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingData(false);
                    setData([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const listData = (data: any[]) => {
        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;

        // console.log('data.nonpayer && data.unsubscribe: ', data[1].nonpayer, data[1].unsubscribe);
        return createGenericListData('branchGym', data,
            { icon: 'pi-th-large', evalValue: 'data.name', evalInactive: 'data.nonpayer || data.unsubscribe' },
            [{ description: '\'Nome Fantasia\'', value: 'data.name' },
            { condition: 'data.charge === true', description: '\'CNPJ\'', value: 'data.companyNumber' },
            { condition: 'data.charge === true', value: '`${data.district} / ${data.city.name} - ${data.state.key}`' }],
            localStorage.getItem('branchGym'),
            {
                isEdited: !!checkRoleHierarchyByToken(RoleRules.manager),
                isCloned: !!checkRoleHierarchyByToken(RoleRules.admin), isSelected: !ls.checkUniqueLocalStorageToken('branchGym'),
                loadingData,
                editForceData: { networkGym: networkGymId },
                removeForceData: ['_id', 'key'],
                select: props.args.select,
                unselect: props.args.unselect,
                setEditFormData,
                setDialogData,
            });
    }

    return (
        <>
            {createView(title, {
                editData: !!networkGymId,
                addNew: checkRoleHierarchyByToken(RoleRules.admin), addNewRegister: () => addNewRegister('branchGym', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData(data)
            })}
            {networkGymId && token ?
                <GenericFormDialog<IFormDataBranchGym> key='BranchGymDialog'
                    token={token}
                    objectData='unidade'
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                    editFormData={editFormData}
                    emptyFormData={{
                        key: '',
                        name: '',
                        networkGym: networkGymId ? networkGymId : '',
                        responsibleName: '',
                        responsibleMobilePhone: '',
                        responsibleEmail: '',
                        charge: true,
                        companyName: '',
                        companyNumber: '',
                        address: '',
                        number: '',
                        complement: '',
                        district: '',
                        zipCode: '',
                        city: '',
                        state: '',
                        active: true,
                        deleted: false,
                        nonpayer: false,
                        unsubscribe: false,
                        showButtons: false,
                        minutesTimeoutAttendant: 15
                    }}
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataBranchGym>[]) => [
                        // GenericFields.indentificationAdminField(formData, { colSize: 6 }),
                        checkRoleHierarchyByToken(RoleRules.admin) ? GenericFields.chargeField(formData, { colSize: 6 }) : null,
                        checkRoleHierarchyByToken(RoleRules.admin) && formData.values.charge ?
                            {
                                colSize: 'col-12', fieldName: 'companyNumber', fieldType: 'InputMaskField', description: '* CNPJ:', placeholder: 'CNPJ', value: formData.values.companyNumber,
                                mask: '99.999.999/9999-99',
                                onChange: formData.handleChange, autoFocus: false
                            } : null,
                        GenericFields.nameField(formData),
                        checkRoleHierarchyByToken(RoleRules.admin) ? GenericFields.genericGroupField('Dados do Responsável') : null,
                        checkRoleHierarchyByToken(RoleRules.admin) ? {
                            colSize: 'col-6 sm:col-12-12', fieldName: 'responsibleName', fieldType: 'InputTextField', description: '* Nome do Responsável:', placeholder: 'nome', value: formData.values.responsibleName, autoFocus: false,
                            onChange: formData.handleChange
                        } : null,
                        checkRoleHierarchyByToken(RoleRules.admin) ? {
                            colSize: 'col-6 sm:col-12', fieldName: 'responsibleMobilePhone', fieldType: 'InputMaskField', description: 'Celular do Responsável', placeholder: '+55 (99) 99999-9999', value: formData.values.responsibleMobilePhone,
                            mask: '+55 (99) 99999-9999',
                            onChange: formData.handleChange, autoFocus: false,
                        } : null,
                        checkRoleHierarchyByToken(RoleRules.admin) ? {
                            colSize: 'col-6 sm:col-12', fieldName: 'responsibleEmail', fieldType: 'InputTextField', description: '* Email do Responsável', placeholder: 'email do responsável', value: formData.values.responsibleEmail,
                            autoComplete: 'off',
                            onChange: formData.handleChange, autoFocus: false,
                        } : null,
                        checkRoleHierarchyByToken(RoleRules.admin) && formData.values.charge ? GenericFields.genericGroupField('Dados de Cobrança') : null,
                        checkRoleHierarchyByToken(RoleRules.admin) && formData.values.charge ?
                            {
                                colSize: 'col-12', fieldName: 'companyName', fieldType: 'InputTextField', description: '* Razão Social:', placeholder: 'razão social', value: formData.values.companyName, autoFocus: false,
                                onChange: formData.handleChange
                            } : null,
                        GenericFields.genericGroupField('Dados de Endereço'),
                        {
                            colSize: 'col-8', fieldName: 'address', fieldType: 'InputTextField', description: '* Logradouro:', placeholder: 'Logradouro', value: formData.values.address, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-4', fieldName: 'number', fieldType: 'InputTextField', description: '* Número:', placeholder: 'Número', value: formData.values.number, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-12', fieldName: 'complement', fieldType: 'InputTextField', description: '* Complemento:', placeholder: 'Complemento', value: formData.values.complement, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-6', fieldName: 'district', fieldType: 'InputTextField', description: '* Bairro:', placeholder: 'Bairro', value: formData.values.district, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-6', fieldName: 'zipCode', fieldType: 'InputMaskField', description: '* CEP:', placeholder: 'CEP', value: formData.values.zipCode, autoFocus: false,
                            mask: '99999-999',
                            onChange: formData.handleChange
                        },
                        //TODO: verificar o fieldName: 'state'
                        {
                            colSize: 'col-4', fieldName: 'state', fieldType: 'DropdownField', description: '* Estado:', placeholder: 'Selecione um estado', value: formData.values.state, autoFocus: false,
                            onChange: (event: any) => {
                                if (formLists) executeMethodFromFromList('state', formLists, event);
                                if (event.value?._id !== formData.values.state?._id) formData.values.city = '';
                                formData.handleChange(event);
                            },
                            options: retrieveListFromFromList('state', formLists),
                            optionLabel: 'name', filter: true, showClear: true, filterBy: 'name',
                            disabled: !states || states.length === 0,
                        },
                        //TODO: verificar o fieldName: 'city'
                        {
                            colSize: 'col-8', fieldName: 'city', fieldType: 'DropdownField', description: '* Cidade:', placeholder: 'Selecione uma cidade', value: formData.values.city, autoFocus: false,
                            onChange: formData.handleChange,
                            options: retrieveListFromFromList('city', formLists),
                            optionLabel: 'name', filter: true, showClear: true, filterBy: 'name',
                            disabled: !formData.values.state || !cities || cities.length === 0,
                        },
                        checkRoleHierarchyByToken(RoleRules.admin) ? GenericFields.nonpayerField(formData, { colSize: 6 }) : null,
                        checkRoleHierarchyByToken(RoleRules.admin) ? GenericFields.unsubscribeField(formData, { colSize: 6 }) : null,
                        {
                            // 'col-6 col-offset-6'
                            colSize: 'col-12', fieldName: 'minutesTimeoutAttendant', fieldType: 'InputNumberField', description: '* Tempo Limite (minutos) de inatividade do Atendente em conversas. As conversas ficarão disponíveis para outros Atendentes:', value: formData.values.minutesTimeoutAttendant,
                            onValueChange: formData.handleChange,
                            mode: "decimal", min: 0, max: 2880, showButtons: true,
                            onChange: formData.handleChange, autoFocus: false
                        }
                    ])}
                    editForceData={{ networkGym: networkGymId }}
                    editFunctions={[(formData: FormikValues) => {
                        setStateSelected(formData.state?._id);
                    }]}
                    // editFunctions={[]}
                    formLists={[{
                        key: 'state',
                        data: states,
                        changeMethod: {
                            field: 'state',
                            method: (value: any) => selectStateHandle(value),
                        }
                    }, {
                        key: 'city',
                        data: cities,
                    }]}
                    validate={(data: any) => {
                        let errors: any = {};

                        // if (!data.key) {
                        //     errors.key = 'Identificação é obrigatório.';
                        // } else if (data.key.length < 5) {
                        //     errors.key = 'Identificação precisa ter mais que 4 caracteres.';
                        // }

                        if (!data.name) {
                            errors.name = 'Nome fantasia é obrigatório.';
                        } else if (data.name.length < 3) {
                            errors.name = 'Nome fantasia precisa ter mais que 2 caracteres.';
                        } else if (data.name.length > 20) {
                            errors.name = 'Nome fantasia precisa ter menos que 21 caracteres.';
                        }

                        if (!data.networkGym) {
                            errors.networkGym = 'Rede é obrigatório.';
                        }


                        data.key = data.networkGym + '.' + data.name;

                        if (!data.responsibleName) {
                            errors.responsibleName = 'Nome do Responsável é obrigatório.';
                        } else if (data.responsibleName.length < 3) {
                            errors.responsibleName = 'Nome do Responsável precisa ter mais que 2 caracteres.';
                        }

                        // if (!data.responsibleMobilePhone) {
                        //     errors.responsibleMobilePhone = 'Celular do Responsável é obrigatório.';
                        // } else if (!isMobilePhone(onlyNumbers(data.responsibleMobilePhone))) {
                        //     errors.responsibleMobilePhone = 'Número de celular do responsável inválido.';
                        // }

                        if (!data.responsibleEmail) {
                            errors.responsibleEmail = 'Email do Responsável é obrigatório.';
                        } else if (!isMail(data.responsibleEmail)) {
                            errors.responsibleEmail = 'Email do Responsável inválido.';
                        }

                        if (data.charge && data.charge === true) {
                            if (!data.companyNumber) {
                                errors.companyNumber = 'CNPJ é obrigatório.';
                            } else if (!isCNPJ(data.companyNumber)) {
                                errors.companyNumber = 'CNPJ é inválido. Favor verificar';
                            }

                            if (!data.companyName) {
                                errors.companyName = 'Razão Social é obrigatório.';
                            } else if (data.name.length > 150) {
                                errors.name = 'Razão Social precisa ter menos que 150 caracteres.';
                            }
                        }

                        if (!data.address) {
                            errors.address = 'Endereço é obrigatório.';
                        }

                        if (!data.number) {
                            errors.number = 'Número é obrigatório.';
                        }

                        if (!data.district) {
                            errors.district = 'Bairro é obrigatório.';
                        }

                        if (!data.zipCode) {
                            errors.zipCode = 'CEP é obrigatório.';
                        }

                        if (!data.city) {
                            errors.city = 'Cidade é obrigatório.';
                        }

                        if (!data.state) {
                            errors.state = 'Estado é obrigatório.';
                        }

                        if (!data.minutesTimeoutAttendant) {
                            errors.minutesTimeoutAttendant = 'Tempo Limite é obrigatório.';
                        }

                        if (!data._id || !data.unsubscribe) {
                            data.unsubscribe = false;
                        }
                        if (!data._id || !data.nonpayer) {
                            data.nonpayer = false;
                        }

                        return errors;
                    }}
                    submitForceData={{ networkGym: networkGymId }}
                    onSubmit={(props: IPropsFormDialog<IFormDataBranchGym>, data: FormikValues, formData: FormikInterface) => {
                        new BranchGymService().saveBranchGym(data)
                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }}
                />
                :
                null
            }
        </>
    )
};

// function genericHeaderPagelist(type: string, title: string, editData: boolean, args: {
//     searchTerm: string, setSearchTerm: React.Dispatch<React.SetStateAction<string>>,
//     setEditFormData: React.Dispatch<any>, setDialogData: React.Dispatch<React.SetStateAction<boolean>>
// }) {
//     return <div className='col-12'>
//         <div className='flex justify-content-start flex-wrap card-container blue-container' style={{ height: 40 }}>
//             <div className='flex col-6'>
//                 <h3>{title}</h3>
//                 {!!editData ?
//                     <Button icon='pi pi-plus' className='p-button-rounded shadow-3'
//                         style={{ marginLeft: 20, height: 24, width: 24 }}
//                         onClick={() => addNewRegister(type, undefined, { setEditFormData: args.setEditFormData, setDialogData: args.setDialogData })} tooltip='Adicionar' />
//                     : null}
//             </div>
//             {!!editData ?
//                 <div className='col-6 flex justify-content-end'>
//                     <b>Procurar: </b>
//                     <InputText id='searchTerm' name='searchTerm' style={{ fontSize: '0.9rem', height: 20, width: '100%', marginTop: -10 }} value={args.searchTerm}
//                         onChange={(e) => args.setSearchTerm(e.currentTarget.value)}
//                         className='p-inputtext-lg' placeholder='Busque por qualquer informação...' />
//                 </div>
//                 : null}
//         </div>
//     </div>;
// }

