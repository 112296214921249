import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class ContactService extends GenericService {
    findContactById = async (_id: string | null) => await super.findById(Entities.CONTACT, false, _id);
    saveContact = async (data: any) => await super.save(Entities.CONTACT, false, data);
    findContactsByIds = async (networkGymId: string, branchGymId: string, guideId: string, search?: string) => await super.findByQuery(Entities.CONTACT, false, {
        references: [
            { field: Entities.NETWORK_GYM, value: networkGymId },
            { field: Entities.BRANCH_GYM, value: branchGymId },
            { field: Entities.GUIDE, value: guideId },
        ]
    });
    findContactByGuideId = async (guideId: string | null, search?: string) =>
        await super.findByReferenceId(Entities.CONTACT, false,
            EntitiesService(Entities.GUIDE).singular, guideId, search);
}
