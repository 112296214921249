import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class BranchGymExternalUserService extends GenericService {
    allNetworkGymExternalUsers = async (networkGymId: string, branchGymId?: string | null, customerId?: string | null) => {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId }
            ]
        };
        if (branchGymId) search.queries.push({ field: EntitiesService(Entities.BRANCH_GYM).singular, value: branchGymId });
        if (customerId) search.queries.push({ field: EntitiesService(Entities.CUSTOMER).singular, value: customerId });
        return await super.findByQuery(Entities.BRANCH_GYM_EXTERNAL_USER, false, search, undefined, { limit: 0 })
    };
    allBranchGymExternalUsers = async (branchGym: string, search?: string | undefined) => await super.findByReferenceIdWithProjection(Entities.BRANCH_GYM_EXTERNAL_USER, false, EntitiesService(Entities.BRANCH_GYM).singular, branchGym, undefined, search);
    allBranchGymExternalUsersByNetworkGymId = async (networkGymId: string, search?: string | undefined) => {

        // return await super.findByReferenceIdWithProjection(Entities.BRANCH_GYM_EXTERNAL_USER, false, EntitiesService(Entities.NETWORK_GYM).singular, networkGymId, undefined, search);
        return await super.findByQuery(Entities.BRANCH_GYM_EXTERNAL_USER, false,
            {
                queries: [{ field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId }],
                projections: [
                    { field: '_id' },
                    { field: 'networkGym', detail: [{ field: '_id' }] },
                    { field: 'branchGym', detail: [{ field: '_id' }, { field: 'name' }] },
                    { field: 'customer', detail: [{ field: '_id' }, { field: 'email' }, { field: 'nickname' }, { field: 'blocked' }] },
                    { field: 'role' },
                    { field: 'active' },
                ]
            });
    };
    // allBranchGymExternalUsers = async (search?: string | undefined) => await super.findAll(Entities.BRANCH_GYM_EXTERNAL_USER, false, search);
    findBranchGymExternalUserById = async (_id: string | null) => await super.findById(Entities.BRANCH_GYM_EXTERNAL_USER, false, _id);
    saveBranchGymExternalUser = async (data: any) => await super.save(Entities.BRANCH_GYM_EXTERNAL_USER, false, data);
}
