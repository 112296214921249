import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer clearfix">  
        {/* style={{ opacity: 0.5 }} */}
            <a href="/">
                <img alt="logo-colored" src="chat4fit/assets/layout/images/logo-horizontal-black.png" />
            </a>
            <span className="footer-text-right">
                <span>All Rights Reserved</span>
            </span>
        </div>
    );

}

export default AppFooter;