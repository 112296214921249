import { Route } from 'react-router-dom';
import { checkRoleHierarchyByToken, checVigilanceByToken, RoleRules } from '../projects/checkRoleHierarchy';
import { MenuInterface, RouteInterface } from '../projects/interfaces';
import { Attendance } from './components/Attendance/Attendance';
import { BranchGym } from './components/BranchGym/BranchGym';
import { BranchGymExternalUser } from './components/BranchGymExternalUser/BranchGymExternalUser';
import { BranchGymPermission } from './components/BranchGymPermission/BranchGymPermission';
import { ChannelNetworkGym } from './components/ChannelNetworkGym/ChannelNetworkGym';
import { Chat4FitBot } from './components/Chat4FitBot/Chat4FitBot';
import { Contact } from './components/Contact/Contact';
import { Dashboard } from './components/Dashboard/Dashboard';
import { ExternalUser } from './components/ExternalUser/ExternalUser';
import { IArgsComponents } from './components/generics/formInterfaces';
import { Guide } from './components/Guide/Guide';
import { GuideScript } from './components/GuideScript/GuideScript';
import { MonitoringAttendance } from './components/MonitoringAttendance/MonitoringAttendance';
import { NetworkGym } from './components/NetworkGym/NetworkGym';
import { NetworkGymExternalUser } from './components/NetworkGymExternalUser/NetworkGymExternalUser';
import { NetworkGymGuide } from './components/NetworkGymGuide/NetworkGymGuide';
import { QuickMessage } from './components/QuickMessage/QuickMessage';
import { Sport } from './components/Sport/Sport';
import { SystemMessage } from './components/SystemMessage/SystemMessage';
import { SystemMessageDefault } from './components/SystemMessageDefault/SystemMessageDefault';
import { Tag } from './components/Tag/Tag';
import { TrackerMobile } from './components/TrackerMobile/TrackerMobile';
import { Schedule } from './pages/Schedule';
import MessageTrigger from './components/MessageTrigger/MessageTrigger';
// import { SystemMessageDefault } from './components/SystemMessageDefault/SystemMessageDefault';
// import { StatesCities } from './components/StatesCities';

const AppRoutes: RouteInterface[] = [
    // {
    //     id: 'login', key: 'login', label: 'Login', icon: 'pi pi-fw pi-chart-bar', to: '/login'
    // },
    {
        id: 'dashboard', key: 'dashboard', label: 'dashboard', icon: 'pi pi-fw pi-chart-bar', to: '/dashboard', component: Dashboard, order: 100, rolePermited: RoleRules.manager, show: true, hideVigilance: true
    },
    {
        id: 'schedule', key: 'schedule', label: 'Agenda', icon: 'pi pi-fw pi-calendar', to: '/schedule', component: Schedule, order: 200, rolePermited: RoleRules.attendant, show: true, hideVigilance: true
    },
    {
        id: 'attendance', key: 'attendance', label: 'Atendimento', icon: 'pi pi-fw pi-megaphone', to: '/attendance', component: Attendance, order: 300, rolePermited: RoleRules.attendant, show: true, hideVigilance: true,
        items: [
            { id: 'quickMessages', key: 'quickMessages', label: 'Mensagens Rápidas', icon: 'pi pi-fw pi-align-left', to: '/quickMessages', component: QuickMessage, order: 600, rolePermited: RoleRules.attendant, show: true, hideVigilance: true },
            { id: 'tags', key: 'tags', label: 'Etiquetas', icon: 'pi pi-fw pi-bookmark', to: '/tags', component: Tag, order: 700, rolePermited: RoleRules.attendant, show: true, hideVigilance: true },
        ]
    },
    // {
    //     id: 'trackerMessage', key: 'trackerMessage', label: 'Vigilância2', icon: 'pi pi-fw pi-comments', to: '/trackerMessage', component: TrackerMessage, order: 500, rolePermited: RoleRules.manager, show: true
    // },
    {
        id: 'monitoringAttendance', key: 'monitoringAttendance', label: 'Vigilância', icon: 'pi pi-fw pi-comments', to: '/monitoringAttendance', component: MonitoringAttendance, order: 550, rolePermited: RoleRules.manager, show: true
    },
    {
        id: 'messageTrigger', key: 'messageTrigger', label: 'Disparos', icon: 'pi pi-fw pi-send', to: '/messageTrigger', component: MessageTrigger, order: 560, rolePermited: RoleRules.manager, show: true
    },
    // { id: 'networkGymMonitoringAttendance', key: 'networkGymMonitoringAttendance', label: 'Vigilância', icon: 'pi pi-fw pi-eye', to: '/admin/networkGym/MonitoringAttendance', component: MonitoringAttendance, order: 400, rolePermited: RoleRules.admin, show: true },
    {
        id: 'manager', key: 'manager', label: 'Gerenciamento', order: 7000, rolePermited: checVigilanceByToken() ? RoleRules.manager : RoleRules.owner, show: true,
        items: [
            { id: 'managerRegistersNetworkGyms', key: 'managerRegistersNetworkGyms', label: 'Rede', icon: 'pi pi-fw pi-table', to: '/manager/networkGyms', component: NetworkGym, order: 600, rolePermited: RoleRules.manager, show: true },
            { id: 'managerChannelNetworkGyms', key: 'managerChannelNetworkGyms', label: 'Canais', icon: 'pi pi-fw pi-megaphone', to: '/manager/channels', component: ChannelNetworkGym, order: 700, rolePermited: RoleRules.manager, show: true },
            { id: 'managerBranchGyms', key: 'managerBranchGyms', label: 'Unidades', icon: 'pi pi-fw pi-th-large', to: '/manager/branchGyms', component: BranchGym, order: 800, rolePermited: RoleRules.manager, show: true, hideVigilance: true },
            { id: 'managerBranchGymExternalUsers', key: 'managerBranchGymExternalUsers', label: 'Usuários', icon: 'pi pi-fw pi-users', to: '/manager/branchGym/externalUsers', component: BranchGymExternalUser, order: 900, rolePermited: RoleRules.manager, show: true },
            { id: 'managerBranchGympermissions', key: 'managerBranchGympermissions', label: 'Permissões', icon: 'pi pi-fw pi-unlock', to: '/manager/branchGym/permissions', component: BranchGymPermission, order: 1000, rolePermited: RoleRules.owner, show: true, hideVigilance: true },
            { id: 'managerRegistersSystemMessages', key: 'managerRegistersSystemMessages', label: 'Mensagens Sistêmicas', icon: 'pi pi-fw pi-wrench', to: '/manager/SystemMessages', component: SystemMessage, order: 1100, rolePermited: RoleRules.manager, show: false },
        ]
    },
    {
        id: 'manager', key: 'manager', label: 'Gerenciamento', order: 8000, rolePermited: RoleRules.manager, show: false,
        items: [
            {
                id: 'managerNetworkGym', key: 'managerNetworkGym', label: 'Rede', order: 100, rolePermited: RoleRules.manager, show: true,
                items: [
                    // { id: 'managerRegistersNetworkGyms', key: 'managerRegistersNetworkGyms', label: 'Cadastro', icon: 'pi pi-fw pi-table', to: '/manager/networkGyms', component: NetworkGym, order: 100, rolePermited: RoleRules.manager, show: (await ls.networkGym.getCountNetworkGymsByStorageToken()) > 1 },
                    { id: 'managerRegistersNetworkGyms', key: 'managerRegistersNetworkGyms', label: 'Cadastro', icon: 'pi pi-fw pi-table', to: '/manager/networkGyms', component: NetworkGym, order: 100, rolePermited: RoleRules.manager, show: true },
                    { id: 'managerChannelNetworkGyms', key: 'managerChannelNetworkGyms', label: 'Canais', icon: 'pi pi-fw pi-megaphone', to: '/manager/channels', component: ChannelNetworkGym, order: 300, rolePermited: RoleRules.owner, show: true }
                ]
            },
            {
                id: 'managerBranchGym', key: 'managerBranchGym', label: 'Unidade', order: 200, rolePermited: RoleRules.manager, show: true,
                items: [
                    { id: 'managerBranchGyms', key: 'managerBranchGyms', label: 'Cadastro', icon: 'pi pi-fw pi-th-large', to: '/manager/branchGyms', component: BranchGym, order: 100, rolePermited: RoleRules.manager, show: true },
                    // { id: 'managerBranchGymSports', key: 'managerBranchGymSports', label: 'Modalidades', icon: 'pi pi-fw pi-bolt', to: '/manager/branchGym/sports', component: BranchGymSport, order: 200, rolePermited: RoleRules.admin, show: false },
                    // { id: 'managerBranchGymGuides', key: 'managerBranchGymGuides', label: 'Roteiros', icon: 'pi pi-fw pi-list', to: '/manager/branchGym/guides', component: BranchGymGuide, order: 300, rolePermited: RoleRules.admin, show: false },
                    { id: 'managerBranchGymExternalUsers', key: 'managerBranchGymExternalUsers', label: 'Usuários', icon: 'pi pi-fw pi-users', to: '/manager/branchGym/externalUsers', component: BranchGymExternalUser, order: 400, rolePermited: RoleRules.manager, show: true },
                    // { id: 'managerBranchGymPlans', key: 'managerBranchGymPlans', label: 'Planos', icon: 'pi pi-fw pi-tags', to: '/manager/branchGym/plans', component: PlanBranchGym, order: 500, rolePermited: RoleRules.admin, show: false },
                    // { id: 'managerBranchGymScale', key: 'managerBranchGymScale', label: 'Escala', icon: 'pi pi-fw pi-clock', to: '/manager/branchGym/scales', component: ScaleBranchGym, order: 600, rolePermited: RoleRules.admin, show: false },
                    // { id: 'managerBranchGymSpecialDates', key: 'managerBranchGymSpecialDates', label: 'Datas Especiais', icon: 'pi pi-fw pi-calendar-times', to: '/manager/branchGym/specialDates', order: 700, rolePermited: RoleRules.admin, show: false },
                    // { id: 'managerChannelNetworkGyms', key: 'managerChannelNetworkGyms', label: 'Canais', icon: 'pi pi-fw pi-megaphone', to: '/manager/channels', order: 800, rolePermited: RoleRules.manager, show: false },
                ]
            },
            { id: 'managerContacts', key: 'managerContacts', label: 'Leads', icon: 'pi pi-fw pi-users', to: '/manager/leads', component: Contact, order: 300, rolePermited: RoleRules.admin, show: false },
            // { id: 'managerTrackerMobile', key: 'managerTrackerMobile', label: 'Vigilância', icon: 'pi pi-fw pi-eye', to: '/manager/trackerMobile', component: TrackerMobile, order: 400, rolePermited: RoleRules.manager, show: false },
        ]
    },
    {
        id: 'administrator', key: 'administrator', label: 'Administrativo', order: 9000, rolePermited: RoleRules.admin, show: true,
        items: [
            {
                id: 'registers', key: 'registers', label: 'Cadastros', order: 100, rolePermited: RoleRules.admin, show: true,
                items: [
                    { id: 'adminRegistersNetworkGyms', key: 'adminRegistersNetworkGyms', label: 'Redes', icon: 'pi pi-fw pi-table', to: '/admin/networkGyms', component: NetworkGym, order: 100, rolePermited: RoleRules.admin, show: true },
                    { id: 'adminRegistersBranchGyms', key: 'adminRegistersBranchGyms', label: 'Unidades', icon: 'pi pi-fw pi-th-large', to: '/admin/branchGyms', component: BranchGym, order: 200, rolePermited: RoleRules.admin, show: true },
                    { id: 'adminRegistersSports', key: 'adminRegistersSports', label: 'Modalidades', icon: 'pi pi-fw pi-bolt', to: '/admin/sports', component: Sport, order: 300, rolePermited: RoleRules.admin, show: false },
                    { id: 'adminRegistersGuides', key: 'adminRegistersGuides', label: 'Roteiros', icon: 'pi pi-fw pi-list', to: '/admin/guides', component: Guide, order: 400, rolePermited: RoleRules.admin, show: false },
                    { id: 'adminRegistersUsers', key: 'adminRegistersUsers', label: 'Proprietários', icon: 'pi pi-fw pi-users', to: '/admin/users', component: ExternalUser, order: 500, rolePermited: RoleRules.admin, show: true },
                    { id: 'networkGymExternalUsers', key: 'networkGymExternalUsers', label: 'Multirrede', icon: 'pi pi-fw pi-users', to: '/admin/networkGym/externalUsers', component: NetworkGymExternalUser, order: 200, rolePermited: RoleRules.admin, show: false },
                    { id: 'adminRegistersGuideScripts', key: 'adminRegistersGuideScripts', label: 'Scripts', icon: 'pi pi-fw pi-sitemap', to: '/admin/guide/scripts', component: GuideScript, order: 600, rolePermited: RoleRules.admin, show: false },
                ]
            },
            {
                id: 'configs', key: 'configs', label: 'Configurações', order: 200, rolePermited: RoleRules.admin, show: true,
                items: [
                    { id: 'adminRegistersSystemMessageDefaults', key: 'adminRegistersSystemMessageDefaults', label: 'Mensagem Sistema Padrão', icon: 'pi pi-fw pi-wrench', to: '/admin/SystemMessageDefaults', component: SystemMessageDefault, order: 250, rolePermited: RoleRules.admin, show: true },
                    { id: 'adminRegistersSystemMessages', key: 'adminRegistersSystemMessages', label: 'Mensagem Sistema Rede', icon: 'pi pi-fw pi-wrench', to: '/admin/SystemMessages', component: SystemMessage, order: 260, rolePermited: RoleRules.admin, show: true },
                ]
            }, {
                id: 'links', key: 'links', label: 'Vinculos', order: 300, rolePermited: RoleRules.admin, show: false,
                items: [
                    { id: 'networkGymGuides', key: 'networkGymGuides', label: 'Roteiros', icon: 'pi pi-fw pi-list', to: '/admin/networkGym/guides', component: NetworkGymGuide, order: 100, rolePermited: RoleRules.admin, show: false },
                    { id: 'networkGymTrackerMobile', key: 'networkGymTrackerMobile', label: 'Vigilância', icon: 'pi pi-fw pi-eye', to: '/admin/networkGym/trackerMobile', component: TrackerMobile, order: 300, rolePermited: RoleRules.admin, show: true },
                ]
            },

            { id: 'adminChat4FitBot', key: 'adminChat4FitBot', label: 'Bot-Chat4Fit', icon: 'pi pi-fw pi-whatsapp', to: '/admin/botchat4fit', component: Chat4FitBot, order: 300, rolePermited: RoleRules.admin, show: false },
        ]

    },
];

export const retrieveMenus = (): MenuInterface[] => {
    const menus: MenuInterface[] = [];
    AppRoutes.sort((m1, m2) => m1.order - m2.order).forEach(menu => menu.show && (!menu.hideVigilance || checVigilanceByToken() || checkRoleHierarchyByToken(RoleRules.admin, false)) &&
        checkRoleHierarchyByToken(menu.rolePermited) ?
        menus.push(...prepareMenus(menu)) : null);
    return menus;
}

const prepareMenus = (menu: MenuInterface): MenuInterface[] => {
    const menus: MenuInterface[] = [];
    const newMenu: MenuInterface = {
        label: menu.label,
        icon: menu.icon,
        badge: menu.badge,
        badgeStyleClass: menu.badgeStyleClass,
        className: menu.className,
        to: menu.to,
        url: menu.url,
        target: menu.target,
        items: menu.items ? [] : undefined,
        order: menu.order,
        rolePermited: menu.rolePermited,
        show: menu.show,
        hideVigilance: menu.hideVigilance,
    }

    // (!route.hideVigilance || checkRoleHierarchyByToken(RoleRules.admin, false)) &&

    if (menu.items && newMenu.items) menu.items.forEach(menuItem => menuItem.show && (!menuItem.hideVigilance || checVigilanceByToken() || checkRoleHierarchyByToken(RoleRules.admin, false)) &&
        checkRoleHierarchyByToken(menuItem.rolePermited) ?
        newMenu.items?.push(...prepareMenus(menuItem)) : null);

    menus.push(newMenu);

    return menus;
}

export const retrieveRoutes = (args: IArgsComponents): any[] => {
    const routes: any[] = [];

    AppRoutes.forEach(route => route.show &&
        checkRoleHierarchyByToken(route.rolePermited) && (!route.hideVigilance || checVigilanceByToken() || checkRoleHierarchyByToken(RoleRules.admin, false)) ?
        routes.push(...prepareRoutes(route, args)) : null);
    return routes;
}

const prepareRoutes = (route: RouteInterface, args: any): any[] => {
    const routes: any[] = [];

    // console.log(`Route-${route.id} path=${route.to} hideVigilance=${route.hideVigilance}`);

    if (route.to && route.component) {
        routes.push(<Route key={`Route-${route.id}`} path={route.to}
            render={() => <route.component args={args} />} />);
    }
    if (route.items) {
        route.items.forEach(routeItem => routeItem.show &&
            checkRoleHierarchyByToken(routeItem.rolePermited) && (!route.hideVigilance || checVigilanceByToken() || checkRoleHierarchyByToken(RoleRules.admin, false)) ?
            routes.push(...prepareRoutes(routeItem, args)) : null);
    }
    return routes;
}

export default AppRoutes;



