/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import SystemMessageDefaultService from '../../service/admin/SystemMessageDefaultService';
import SystemMessageService from '../../service/admin/SystemMessageService';
import { IMessage } from '../Control';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { GenericFields } from '../commons/GenericFields';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataSystemMessage } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './SystemMessage.css';

const systemMessageDefaultService = new SystemMessageDefaultService();
const systemMessageService = new SystemMessageService();

export const SystemMessage = (props: IPropsArgsComponents) => {
    const [title] = useState('Mensagem Sistema Rede');
    const [objectData] = useState('mensagem');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataSystemMessage[]>([]);

    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    useEffect(() => {
        if (!props?.args?.networkGymId) props.args.setLoading(false);
        setNetworkGymId(props.args.networkGymId);
    }, [props.args.networkGymId]);

    const [editFormData, setEditFormData] = useState<IFormDataSystemMessage | undefined>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: false },
    ]), []);

    // useEffect(() => { refreshList(); }, [networkGymId]);
    useEffect(() => { refreshListDefault(); }, [networkGymId]);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);


    const updateSystemMessageByNetworkGyms = (systemMessageDefaultDatas: any[], systemMessageDatas: any) => {
        let needUpdateList: boolean = false;
        for (let i = 0; i < systemMessageDefaultDatas.length; i++) {
            const systemMessageDefault = systemMessageDefaultDatas[i];
            const systemMessage = systemMessageDatas.find((s: any) => s.systemMessageKey === systemMessageDefault.systemMessageKey);
            if (!systemMessage) {
                needUpdateList = true;
                systemMessageService.saveSystemMessage({
                    networkGym: networkGymId,
                    systemMessageKey: systemMessageDefault.systemMessageKey,
                    title: systemMessageDefault.title,
                    origin: systemMessageDefault.origin,
                    dependencySystemMessageKeys: systemMessageDefault.dependencySystemMessageKeys,
                    text: systemMessageDefault.text,
                    description: systemMessageDefault.description,
                    blocked: systemMessageDefault.blockedDefault,
                    deleted: false,
                    active: true,
                });
            }
        }
        return needUpdateList;
    }

    const updateListSystemMessage = (systemMessageDefaultDatas: IFormDataSystemMessage[]) => {
        let isMounted = true;
        props.args.setLoading(true);
        setLoadingData(true);

        if (networkGymId) {
            systemMessageService.allSystemMessages(networkGymId).then((returned: any) => {
                if (isMounted) {
                    let needUpdateList = false;
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { props.args.setLoading(false); setLoadingData(false); window.location.href = '/'; }, 5000);
                        needUpdateList = updateSystemMessageByNetworkGyms(systemMessageDefaultDatas, []);
                        setData([]);
                    } else if (isSuccess(returned.status)) {
                        let systemMessageDatas = returned.data;
                        // console.log('systemMessageDatas: ', systemMessageDatas);
                        needUpdateList = updateSystemMessageByNetworkGyms(systemMessageDefaultDatas, systemMessageDatas);
                        if (needUpdateList) { setData([]); } else { setData(systemMessageDatas.sort((s1: any, s2: any) => s1.title < s2.title ? -1 : s1.title > s2.title ? 1 : 0)); }
                    } else {
                        needUpdateList = updateSystemMessageByNetworkGyms(systemMessageDefaultDatas, []);
                        setData([]);
                    }
                    setLoadingData(false); props.args.setLoading(false);
                    if (needUpdateList) refreshListDefault();
                }
            }).catch(error => {
                console.log('error', error);
                if (isMounted) {
                    let needUpdateList = updateSystemMessageByNetworkGyms(systemMessageDefaultDatas, []);
                    setLoadingData(false); props.args.setLoading(false);
                    setData([]);
                    if (needUpdateList) refreshListDefault();
                }
            });
        } else {
            setLoadingData(false); props.args.setLoading(false);
            setData([]);
        }

        return () => { isMounted = false };
    }

    // const refreshList = () => {
    //     let isMounted = true;
    //     setLoadingData(true);
    //     if (networkGymId) {
    //         systemMessageService.allSystemMessages(networkGymId).then((returned: any) => {
    //             if (isMounted) {
    //                 if (returned.status === HttpStatus.UNAUTHORIZED) {
    //                     props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
    //                     setTimeout(() => { props.args.setLoading(false); setLoadingData(false); window.location.href = '/'; }, 5000);
    //                     setData([]);
    //                 } else if (isSuccess(returned.status)) {
    //                     setData(returned.data);
    //                 } else {
    //                     setData([]);
    //                 }
    //                 setLoadingData(false); props.args.setLoading(false);
    //             }
    //         }).catch(error => {
    //             console.log('error', error);
    //             if (isMounted) {
    //                 setLoadingData(false); props.args.setLoading(false);
    //                 setData([]);
    //             }
    //         });
    //     } else {
    //         setLoadingData(false); props.args.setLoading(false);
    //         setData([]);
    //     }
    //     return () => { isMounted = false };
    // };

    const refreshListDefault = () => {
        let isMounted = true;
        setLoadingData(true);
        if (networkGymId) {
            systemMessageDefaultService.allSystemMessageDefaults('').then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { props.args.setLoading(false); setLoadingData(false); window.location.href = '/'; }, 5000);
                        updateListSystemMessage([]);
                    } else if (isSuccess(returned.status)) {
                        let listSystemMessage = returned.data.filter((sm: any) => sm.systemMessageKey !== 'OUTRA_UNIDADE');
                        updateListSystemMessage(listSystemMessage);
                    } else {
                        updateListSystemMessage([]);
                    }
                    setLoadingData(false); props.args.setLoading(false);
                }
            }).catch(error => {
                console.log('error', error);
                if (isMounted) {
                    setLoadingData(false); props.args.setLoading(false);
                    updateListSystemMessage([]);
                }
            });
        } else {
            setLoadingData(false); props.args.setLoading(false);
            setData([]);
        }
        return () => { isMounted = false };
    };

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setEditFormData(undefined);
    //     setDialogData(true);
    // }

    const edit = (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;
        if (type === 'systemMessage') {
            // console.log('item: ', item);
            setEditFormData({
                _id: item._id,
                key: item.key,
                networkGym: item.networkGym,
                systemMessageKey: item.systemMessageKey,
                title: item.title,
                origin: item.origin,
                text: item.text,
                description: item.description,
                blocked: item.blocked,
                deleted: item.deleted,
                active: item.active
            });
            setDialogData(true);
        }
    }

    // const clone = async (type: string, _id?: string, ...args: string[]) => {
    //     const item = data.find(d => d._id === _id);
    //     if (!item) return;
    //     if (type === 'systemMessage') {
    //         setEditFormData({
    //             key: item.key,
    //             systemMessageKey: item.systemMessageKey,
    //             title: item.title,
    //             origin: item.origin,
    //             text: item.text,
    //             description: item.description,
    //             blocked: item.blocked,
    //             deleted: item.deleted,
    //             active: item.active
    //         });
    //         setDialogData(true);
    //     }
    // }

    const listData = () => {
        const listData = data ? data : [];
        if (loadingData) return createSkeletons(8);
        else if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map(d => {
                    const selectedSystemMessageId = localStorage.getItem('systemMessage');
                    const config: IConfig = {
                        title: { icon: 'pi-wrench', value: d.title, inactive: d.blocked },
                        lines: [
                            // { description: 'Fantasia', value: d.fantasyName },
                            { description: 'Tipo', value: d.origin },
                            { description: 'Bloqueado', value: d.blocked ? 'Sim' : 'Não' },
                        ],
                        data: d,
                        buttons: d._id ? [
                            checkRoleHierarchyByToken(RoleRules.owner) ? { key: 'editar', icon: 'pi-pencil', onClick: () => edit('systemMessage', d._id), tooltip: 'Editar' } : null,
                            // checkRoleHierarchyByToken(RoleRules.admin) ? { key: `clone`, icon: 'pi-copy', onClick: () => clone('systemMessage', d._id), tooltip: 'Copiar' } : null,
                            // selectedSystemMessageId === d._id ? { key: 'unidades', icon: 'pi-th-large', onClick: () => { props.args.select('systemMessage', d._id); window.location.href = '/#/branchGyms'; }, tooltip: 'Unidades' } : null,
                            // !ls.checkUniqueLocalStorageToken('systemMessage') && selectedSystemMessageId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('systemMessage', d._id), tooltip: 'Selecionar' } : null,
                            // !ls.checkUniqueLocalStorageToken('systemMessage') && selectedSystemMessageId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('systemMessage', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedSystemMessageId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: false, //checkRoleHierarchyByToken(RoleRules.admin), addNewRegister: () => addNewRegister('systemMessage', undefined, { setEditFormData, setDialogData }),
                hasSearchTerm: false,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token && networkGymId ?
                <GenericFormDialog<IFormDataSystemMessage> key='SystemMessageDialog'
                    token={token}
                    objectData={objectData}
                    refreshList={() => refreshListDefault()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                    editFormData={editFormData}
                    emptyFormData={{
                        key: '',
                        networkGym: networkGymId,
                        systemMessageKey: '',
                        title: '',
                        origin: '',
                        text: '',
                        description: '',
                        blocked: false,
                        deleted: false,
                        active: true
                    }}
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataSystemMessage>[]) => [
                        // GenericFields.indentificationAdminField(formData, { colSize: 4 }),
                        {
                            colSize: 'col-8', fieldName: 'systemMessageKey', fieldType: 'InputTextField', description: 'Chave Mensagem:', placeholder: 'Chave da mensagem', value: formData.values.systemMessageKey,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        {
                            colSize: 'col-4', fieldName: 'origin', fieldType: 'InputTextField', description: 'Tipo:', placeholder: 'Tipo', value: formData.values.origin,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        {
                            colSize: 'col-8', fieldName: 'title', fieldType: 'InputTextField', description: 'Título:', placeholder: 'Título', value: formData.values.title,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        GenericFields.isisBlockedField(formData, { colSize: 4 }),
                        {
                            colSize: 'col-12', fieldType: 'IInstructionField', description: 'Instruções:', value: formData.values.description,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        {
                            colSize: 'col-12', fieldName: 'text', fieldType: 'InputTextAreaField', description: '* Descrição:', placeholder: 'Descrição', value: formData.values.text, autoFocus: false,
                            rows: 5, cols: 5,
                            onChange: formData.handleChange
                        },
                    ])}
                    editForceData={{}}
                    editFunctions={[]}
                    // editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};


                        if (!data.text) {
                            errors.text = 'Texto da mensagem é obrigatório.';
                        } else if (data.text.length < 5) {
                            errors.text = 'Texto da mensagem precisa ter mais que 2 caracteres.';
                        }

                        return errors;
                    }}
                    submitForceData={{}}
                    onSubmit={(props: IPropsFormDialog<IFormDataSystemMessage>, data: FormikValues, formData: FormikInterface) => {
                        // console.log('data: ', data);
                        let dataSystemMessage = {
                            _id: data._id,
                            text: data.text,
                            blocked: data.blocked
                        }
                        new SystemMessageService().saveSystemMessage(dataSystemMessage)
                            .then(async (returned: IPerformRequestResult<any>) => {
                                let dependencys: any = returned.data.dependencySystemMessageKeys;
                                dependencys = dependencys.split(';');
                                if (dependencys.length > 0) {
                                    for (let i = 0; i < dependencys.length; i++) {
                                        const dependency = dependencys[i];
                                        let systemMessageByKey: any = await (new SystemMessageService()).findSystemMessageByKey(dependency, networkGymId);
                                        if (systemMessageByKey && systemMessageByKey.data && systemMessageByKey.data.length > 0 && systemMessageByKey.data[0]) {
                                            let systemMessage = systemMessageByKey.data[0];
                                            await (new SystemMessageService()).saveSystemMessage({
                                                _id: systemMessage._id,
                                                blocked: data.blocked
                                            });
                                        }
                                    }
                                }
                                saveSuccessed(props, formData)(returned);
                            })
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }} />
                :
                null
            }
        </>
    )
}
