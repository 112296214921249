/* eslint-disable eqeqeq */
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RoleRules, checkRoleHierarchyByToken } from "../projects/checkRoleHierarchy";
import ls from "../projects/localStorage";
import { IMessage } from "./components/Control";
import { SelecDialog } from "./components/commons/SelectDialog";
import { IConsidered } from "./components/generics/considereds";
import BranchGymSportService from "./service/admin/BranchGymSportService";
import GuideService from "./service/admin/GuideService";
import NetworkGymService from "./service/admin/NetworkGymService";
import TrackerMobileService from "./service/admin/TrackerMobileService";


const networkGymService = new NetworkGymService();
const guideService = new GuideService();
const trackerMobileService = new TrackerMobileService();
const branchGymSportService = new BranchGymSportService();

export const AppTagSelecteds = (props: {
    token: string | null,
    networkGymId: string | null,
    networkGymSelected: any,
    networkGymShowButtons?: boolean,
    branchGymId: string | null,
    branchGymSelected: any,
    branchGymShowButtons?: boolean,
    guideId: string | null,
    guideSelected: any,
    guideShowButtons?: boolean,
    trackerMobileId: string | null,
    trackerMobileSelected: any,
    trackerMobileShowButtons?: boolean,
    branchGymSportId: string | null,
    branchGymSportSelected: any,
    branchGymSportShowButtons?: boolean,
    loadingData: boolean,
    considered: IConsidered[],
    topStyle: number,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setLoadingData: React.Dispatch<React.SetStateAction<boolean>>,
    setMessage: (value: IMessage) => void,
    select: (type: string, _id?: string | null, ...args: string[]) => void,
    unselect: (type: string, _id?: string | null, ...args: string[]) => void,
    selectShowButtons: (type: string, _id?: string | null, ...args: string[]) => void
}) => {
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [objectData, setObjectData] = useState<string>('');
    const [typeData, setTypeData] = useState<{ value: string, tag: string }>();
    const [dialogIcon, setDialogIcon] = useState('pi-table');
    const [titleIcon, setTitleIcon] = useState('pi-table');
    const [evalTitleValue, setEvalTitleValue] = useState('data.key');
    const [networkGymShowButtons, setNetworkGymShowButtons] = useState(props.networkGymShowButtons);
    const [branchGymShowButtons, setBranchGymShowButtons] = useState(props.branchGymShowButtons);
    const [guideShowButtons, setGuideShowButtons] = useState(props.guideShowButtons);
    const [trackerMobileShowButtons, setTrackerMobileShowButtons] = useState(props.trackerMobileShowButtons);
    const [branchGymSportShowButtons, setBranchGymSportShowButtons] = useState(props.branchGymSportShowButtons);
    const [countNetworkGymsByStorageToken, setCountNetworkGymsByStorageToken] = useState<number>(0);
    const [branchGymData, setBranchGymData] = useState<any[] | null>();
    const [branchGymsByStorageToken, setBranchGymsByStorageToken] = useState<any[] | null>();

    const history = useHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { listBranchGyms(); }, [branchGymsByStorageToken, props.networkGymSelected, props.branchGymSelected]);
    useEffect(() => { listBranchGymsByStorageToken(); }, []);

    const listBranchGyms = async () => {
        let listBranchGymsLocalStorage = ls.branchGym.getAllBranchGyms({
            networkGymId: props.networkGymSelected?._id,
            branchGymIds: branchGymsByStorageToken?.map(b => b._id),
            nonpayer: false,
            unsubscribe: false
        });
        if (listBranchGymsLocalStorage) {
            setBranchGymData(listBranchGymsLocalStorage);
        } else {
            ls.branchGym.setAllBranchGyms();
            setTimeout(() => listBranchGyms(), 500);
        }
    }

    const listBranchGymsByStorageToken = async () => {
        setBranchGymsByStorageToken(await ls.branchGym.getBranchGymsByStorageToken() || []);
    }

    const openSelect = async (type: any) => {
        props.setLoading(true);
        props.setLoadingData(true);
        let listData: any[];
        switch (type.value) {
            case 'networkGym':
                const resultNetworkGym = await networkGymService.allNetworkGymNoBlockeds();
                listData = resultNetworkGym.data;
                setTypeData(type);
                setData(listData);
                setObjectData('uma Rede');
                setDialogData(true);
                setDialogIcon('pi-table');
                setTitleIcon('pi-table');
                setEvalTitleValue('data.key + \'-\' + data.fantasyName');
                props.setLoadingData(false);
                props.setLoading(false);
                break;
            case 'branchGym':
                const resultBranchGym = branchGymData;
                // const resultBranchGym = await branchGymService.findBranchGymNoBlockedByNetworkGymId(props.networkGymSelected?._id);
                // listData = resultBranchGym.data;
                listData = resultBranchGym || [];
                setTypeData(type);
                setData(listData);
                setObjectData('uma Unidade');
                setDialogData(true);
                setDialogIcon('pi-th-large');
                setTitleIcon('pi-th-large');
                setEvalTitleValue('data.name');
                props.setLoadingData(false);
                props.setLoading(false);
                break;
            case 'guide':
                const resultGuide = await guideService.allGuides();
                listData = resultGuide.data;
                setTypeData(type);
                setData(listData);
                setObjectData('um Roteiro');
                setDialogData(true);
                setDialogIcon('pi-sitemap');
                setTitleIcon('pi-sitemap');
                setEvalTitleValue('data.key');
                props.setLoadingData(false);
                props.setLoading(false);
                break;
            case 'trackerMobile':
                const resultTrackerMobile = await trackerMobileService.findTrackerMobileByBranchGymId(props.branchGymSelected?._id)
                listData = resultTrackerMobile.data;
                setTypeData(type);
                setData(listData);
                setObjectData('um núm. monitorado');
                setDialogData(true);
                setDialogIcon('pi-eye');
                setTitleIcon('pi-eye');
                setEvalTitleValue('data.mobilePhone');
                props.setLoadingData(false);
                props.setLoading(false);
                break;
            case 'branchGymSport':
                const resultBranchGymSport = await branchGymSportService.allBranchGymSports(props.branchGymSelected?._id);
                listData = resultBranchGymSport.data;
                setTypeData(type);
                setData(listData);
                setObjectData('uma modalidade');
                setDialogData(true);
                setDialogIcon('pi-bolt');
                setTitleIcon('pi-bolt');
                setEvalTitleValue('data.key');
                props.setLoadingData(false);
                props.setLoading(false);
                break;
            default:
                setTypeData(type);
                setData([]);
                setObjectData('');
                setDialogData(false);
                props.setLoading(false);
                break;
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { defineUniqueDataList(); validCountNetworkGymsByStorageToken(); }, [branchGymData]);
    useEffect(() => setNetworkGymShowButtons(props.networkGymShowButtons), [props.networkGymShowButtons]);
    useEffect(() => setBranchGymShowButtons(props.branchGymShowButtons), [props.branchGymShowButtons]);
    useEffect(() => setGuideShowButtons(props.guideShowButtons), [props.guideShowButtons]);
    useEffect(() => setTrackerMobileShowButtons(props.trackerMobileShowButtons), [props.trackerMobileShowButtons]);
    useEffect(() => setBranchGymSportShowButtons(props.branchGymSportShowButtons), [props.branchGymSportShowButtons]);


    const validCountNetworkGymsByStorageToken = () => {
        // console.log('validCountNetworkGymsByStorageToken');
        ls.networkGym.getCountNetworkGymsByStorageToken().then((result: any) => {
            // console.log('validCountNetworkGymsByStorageToken.result: ', result);
            setCountNetworkGymsByStorageToken(result);
        }).catch((error => console.log('Error: ', error)));
    }

    const defineUniqueDataList = async () => {
        ls.networkGym.defineUniqueNetworkGym((type: string, _id?: string | null, ...args: string[]) => props.select(type, _id, ...args));
        ls.branchGym.defineUniqueBranchGym((type: string, _id?: string | null, ...args: string[]) => props.select(type, _id, ...args), branchGymData || []);
    }

    // const [countNetworkGymsByStorageToken, setCountNetworkGymsByStorageToken] = useState<number>(0);
    // const countNetworkGymsByStorageToken = await ls.networkGym.getCountNetworkGymsByStorageToken();

    const showStyle = props.considered.filter(c => c.show === true &&
        (c.key !== 'networkGym' || countNetworkGymsByStorageToken > 1 || !ls.networkGym.hasNetworkGymSelected())).length === 0 ? { display: 'none', top: props.topStyle } : { top: props.topStyle };

    // console.log('data.length: ', data.length);

    return (<>
        <div style={showStyle} className='layout-tag-selected'>
            <div className='grid'>
                <div className='justify-content-start sm:col-12 md:col-12 lg:col-12 xl:col-12 grid'>
                    {props.considered.find(c => c.key === 'networkGym')?.show &&
                        countNetworkGymsByStorageToken > 1 ? createSelected({
                            _id: props.networkGymSelected?._id,
                            key: props.networkGymSelected?.key,
                            name: props.networkGymSelected?.fantasyName,
                            // icon: 'pi-table',
                            style: {
                                top: '11px',
                                fontSize: '0.5rem'
                            },
                            type: { value: 'networkGym', tag: 'REDE' },
                            placehold: 'Filtro de redes.',
                            showButtons: networkGymShowButtons,
                            buttons: props.networkGymSelected?._id ?
                                [
                                    { key: `unidade`, icon: 'pi-th-large', onClick: () => { props.select('networkGym', props.networkGymSelected?._id); history.push('/manager/branchGyms'); }, tooltip: 'Unidades' }
                                ]// window.location.href = '/#'
                                : [],
                            isConsidered: props.considered.find(c => c.key === 'networkGym')?.considered || false,
                            openSelect,
                        }, props.unselect, (type: string, _id?: string | null | undefined, ...args: string[]) =>
                            props.selectShowButtons(type, _id, ...args)) : null}
                    {props.networkGymSelected && props.considered.find(c => c.key === 'branchGym')?.show && branchGymData && branchGymData?.length > 0 ? createSelected({
                        _id: props.branchGymSelected?._id,
                        key: props.branchGymSelected?.name,
                        name: props.branchGymSelected?.name,  // ignorado por solicitação // props.branchGymSelected?.name,
                        // icon: 'pi-th-large',
                        style: {
                            top: '6px',
                            fontSize: '0.5rem'
                        },
                        type: { value: 'branchGym', tag: 'UNIDADE' },
                        placehold: 'Filtro de unidades.',
                        showButtons: branchGymShowButtons,
                        buttons: props.branchGymSelected?._id && checkRoleHierarchyByToken(RoleRules.admin) ?
                            [
                                // { key: `plano`, icon: 'pi-tags', onClick: () => { props.select('branchGym', props.branchGymSelected?._id); history.push('/manager/branchGym/plans'); }, tooltip: 'Plano' },
                                // { key: `escala`, icon: 'pi-clock', onClick: () => { props.select('branchGym', props.branchGymSelected?._id); history.push('/manager/branchGym/scales'); }, tooltip: 'Escala' }
                            ]
                            : [],
                        isConsidered: props.considered.find(c => c.key === 'branchGym')?.considered || false,
                        openSelect,
                    }, props.unselect, (type: string, _id?: string | null | undefined, ...args: string[]) =>
                        props.selectShowButtons(type, _id, ...args)) : null}
                    {props.considered.find(c => c.key === 'guide')?.show ? createSelected({
                        _id: props.guideSelected?._id,
                        key: props.guideSelected?.key,
                        name: '',
                        // icon: 'pi-table',
                        style: {
                            top: '11px',
                            fontSize: '0.4rem'
                        },
                        type: { value: 'guide', tag: 'ROTEIRO' },
                        placehold: 'Filtro de Roteiros.',
                        showButtons: guideShowButtons,
                        buttons: props.guideSelected?._id && checkRoleHierarchyByToken(RoleRules.admin) ?
                            [{ key: `leads`, icon: 'pi-sitemap', onClick: () => { props.select('guide', props.guideSelected?._id); history.push('/manager/branchGym/leads'); }, tooltip: 'Contatos' }]// window.location.href = '/#'
                            : [],
                        isConsidered: props.considered.find(c => c.key === 'guide')?.considered || false,
                        openSelect,
                    }, props.unselect, (type: string, _id?: string | null | undefined, ...args: string[]) =>
                        props.selectShowButtons(type, _id, ...args)) : null}
                    {props.networkGymSelected && props.branchGymSelected && props.considered.find(c => c.key === 'trackerMobile')?.show ? createSelected({
                        _id: props.trackerMobileSelected?._id,
                        key: props.trackerMobileSelected?.key,
                        name: props.trackerMobileSelected?.mobilePhone,
                        // icon: 'pi-table',
                        style: {
                            top: '6px',
                            fontSize: '0.4rem',
                            display: 'block',
                            lineHeight: 1.2
                        },
                        type: { value: 'trackerMobile', tag: 'NÚMERO\nMONITORADO' },
                        placehold: 'Filtro de núm. monitorados.',
                        showButtons: trackerMobileShowButtons,
                        buttons: props.trackerMobileSelected?._id ?
                            [{ key: `trackerMobiles`, icon: 'pi-eye', onClick: () => { props.select('trackerMobile', props.trackerMobileSelected?._id); history.push('/manager/trackerMobile'); }, tooltip: 'Vigilância' },
                            { key: `trackerMessages`, icon: 'pi-comments', onClick: () => { props.select('trackerMobile', props.trackerMobileSelected?._id); history.push('/manager/trackerMessage'); }, tooltip: 'Conversas' }]// window.location.href = '/#'
                            : [],
                        isConsidered: props.considered.find(c => c.key === 'trackerMobile')?.considered || false,
                        openSelect,
                    }, props.unselect, (type: string, _id?: string | null | undefined, ...args: string[]) =>
                        props.selectShowButtons(type, _id, ...args)) : null}
                    {props.networkGymSelected && props.branchGymSelected && props.considered.find(c => c.key === 'branchGymSport')?.show ? createSelected({
                        _id: props.branchGymSportSelected?._id,
                        key: props.branchGymSportSelected?.key,
                        name: `Modalidade: ${props.branchGymSportSelected?.sport?.key}`,
                        // icon: 'pi-table',
                        style: {
                            top: '10px',
                            fontSize: '0.4rem',
                            fontWeight: 'bold',
                            color: '#FFF'
                        },
                        styleParagh: {
                            transform: 'scaleY(2.5)',
                            WebkitTransform: 'scaleY(2.5)',
                            MozTransform: 'scaleY(2.5)',
                            msTransform: 'scaleY(2.5)',
                            OTransform: 'scaleY(2.5)',
                        },
                        type: { value: 'branchGymSport', tag: 'Modalidade' },
                        placehold: 'Filtro de Modalidades.',
                        showButtons: branchGymSportShowButtons,
                        buttons: props.branchGymSportSelected?._id && checkRoleHierarchyByToken(RoleRules.admin) ? [] : [],
                        isConsidered: props.considered.find(c => c.key === 'branchGymSport')?.considered || false,
                        openSelect,
                    }, props.unselect, (type: string, _id?: string | null | undefined, ...args: string[]) =>
                        props.selectShowButtons(type, _id, ...args)) : null}
                </div>
            </div>
        </div>
        <div>
            {typeData ?
                <SelecDialog key={`tag-selected-dialog`}
                    dialogIcon={dialogIcon}
                    titleIcon={titleIcon}
                    evalTitleValue={evalTitleValue}
                    keyDialog={`tag-selected-dialog`}
                    typeData={typeData} data={data} objectData={objectData} select={props.select} unselect={props.unselect}
                    setDialogData={setDialogData} showDialog={dialogData} loadingData={props.loadingData} />
                :
                null}
        </div>
    </>);
}

export const createSelected = (selected: {
    _id?: string, key?: string, name?: string, styleParagh?: any, style?: any, type?: any, placehold?: string, showButtons?: boolean,
    buttons: any[], isConsidered: boolean, openSelect: (type: string) => void
}, unselect: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
    selectShowButtons: (type: string, _id?: string | null | undefined, ...args: string[]) => void): React.ReactNode => {
    let sizeTop = 30;
    switch (selected.type.tag.length) {
        case 4: sizeTop = 30; break;
        case 5: sizeTop = 27; break;
        case 6: sizeTop = 30; break;
        case 7: sizeTop = 20; break;
        default: sizeTop = 30; break;
    }

    return <div className='col-4 xl:col-2 lg:col-2 md:col-3 sm:col-3'>
        <div style={{ margin: '0 5px' }}
            onMouseOver={() => !selected.showButtons ? selectShowButtons(selected.type.value, selected._id) : null}
            onMouseLeave={() => selected.showButtons ? selectShowButtons(selected.type.value, selected._id) : null}>
            {selected._id ?
                <div className={classNames('tag-selected-item', 'shadow-6', { 'considered': selected.isConsidered }, 'grid')} >
                    <div className='col-2' style={{ padding: 0 }}>
                        <div className='tag-selected-item-type flex'>
                            <div className='-rotate-90'>
                                <span style={Object.assign({ top: sizeTop }, selected.style)}>
                                    <p style={Object.assign({}, selected.styleParagh)}>{selected.type.tag}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='col-10' style={{ padding: 0 }}>
                        <div className='col-12' style={{ padding: 0 }}>
                            <div className='grid'>
                                <div className='col-12 tag-selected-item-name' style={{ padding: 0, width: '90%' }}>
                                    <span>{selected.name}</span>
                                </div>

                                <div className='col-12 tag-selected-item-key' style={{ padding: 0, height: '90%' }}>
                                    <span>{selected.name?.toLocaleLowerCase() != selected.key?.toLocaleLowerCase() ?
                                        selected.key
                                        : '.'
                                    }
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            selected.showButtons ?
                                <div className='col-12' style={{ padding: 0 }}>
                                    <div className='tag-selected-item-buttons'>
                                        {selected.buttons.map(b => <Button key={b.key} icon={`pi ${b.icon}`} className='p-button-rounded shadow-3' onClick={b.onClick} tooltip={b.tooltip} />)}
                                        {!ls.checkUniqueLocalStorageToken(selected.type.value) ?
                                            <Button icon='pi pi-times' className='p-button-rounded shadow-3' onClick={() => unselect(selected.type.value, selected._id)} tooltip='Desprezar' />
                                            : null}
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
                :
                <div className={classNames('tag-unselected-item', 'shadow-6', { 'considered': selected.isConsidered }, 'grid')} onClick={() => selected.openSelect(selected.type)}>
                    <div className='col-2' style={{ padding: 0 }}>
                        <div className='tag-selected-item-type flex'>
                            <div className='-rotate-90'>
                                <span style={Object.assign({ top: sizeTop }, selected.style)}>
                                    <p style={Object.assign({}, selected.styleParagh)}>{selected.type.tag}</p>
                                </span>
                                {/* <span style={Object.assign({ top: sizeTop }, selected.style)}>{selected.type.tag}</span> */}
                            </div>
                        </div>
                    </div>
                    <div className='col-10' style={{ padding: 0 }}>
                        <div className='tag-selected-item-placehold' style={{ width: '90%' }}>
                            <span>{selected.placehold}</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
}