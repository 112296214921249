import { randomInt } from "./number";
import { padLeft } from "./string";

export const makeHash = (len: number) => {
    const buf = [];
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charlen = chars.length;
  
    for (let i = 0; i < len; ++i) {
      buf.push(chars[randomInt(0, charlen - 1)]);
    }
    return buf.join('');
  };
  export const makeHashByDate = (dateTime: Date) => {
    return `${padLeft(dateTime.getUTCFullYear(), 4, '0')}${padLeft(dateTime.getUTCMonth() + 1, 2, '0')}${padLeft(dateTime.getUTCDate(), 2, '0')}.${padLeft(dateTime.getUTCHours(), 2, '0')}${padLeft(dateTime.getUTCMinutes(), 2, '0')}${padLeft(dateTime.getUTCSeconds(), 2, '0')}.${padLeft(dateTime.getUTCMilliseconds(), 3, '0')}`;
};

export const makeIntByDate = (dateTime: Date) => {
    return parseInt(`${padLeft(dateTime.getUTCFullYear(), 4, '0')}${padLeft(dateTime.getUTCMonth() + 1, 2, '0')}${padLeft(dateTime.getUTCDate(), 2, '0')}${padLeft(dateTime.getUTCHours(), 2, '0')}${padLeft(dateTime.getUTCMinutes(), 2, '0')}${padLeft(dateTime.getUTCSeconds(), 2, '0')}`);
};

export const makeHashByNumber = (len: number) => {
    const buf = [];
    const chars = '0123456789';
    const charlen = chars.length;

    for (let i = 0; i < len; ++i) {
        buf.push(chars[randomInt(0, charlen - 1)]);
    }
    const value = buf.join('');
    return padLeft(value, len, '0');
};

export const makeKeyByString = (key: string, lengthSize: number = 0) => `${key.replace(/(\(.*\))/g, '').replace(/\s/g, '_').normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^\w\s]/gi, '').toLowerCase()}`.split('_').filter(s => !lengthSize || lengthSize <= 0 ? true : s.length >= lengthSize).join('_');
