import { defined } from ".";

export const checkPropertyKey = (values: any) => {
    let foundProperty = false, item = 0;
    while (foundProperty === false && item < Object.keys(values).length) {
        const key = Object.keys(values)[item] || '';
        foundProperty = values.hasOwnProperty(key); item += 1;
    }
    return foundProperty;
}
export const clone = <T>(object: T): T =>{
    return JSON.parse(JSON.stringify(object));
}

export const compareObjects = (obj1: any, obj2: any) => {
    // Check exists both objects
    if (defined(obj1) !== defined(obj2)) return false;
    // Loop through properties in object 1
    for (const p in obj1) {
        // Check property exists on both objects
        if (defined(obj1.hasOwnProperty) !== defined(obj2.hasOwnProperty)) return false;
        // eslint-disable-next-line no-prototype-builtins
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
        switch (typeof (obj1[p])) {
            // Deep compare objects
            case 'object':
                if (!compareObjects(obj1[p], obj2[p])) return false;
                break;
            // Compare function code
            case 'function':
                if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
                break;
            // Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }
    // Check object 2 for any extra properties
    for (const p in obj2) {
        if (typeof (obj1[p]) === 'undefined' && typeof (obj2[p]) !== 'undefined') return false;
    }
    return true;
};

export const deletePropertyUndefined = (object: any, checkProperties: Array<String>) => {
    for (const key in object) if (!defined(object[key]) && (!defined(checkProperties) || defined(checkProperties.find(c => c === key)))) delete object[key];
}

export const isObject = (objectToCheck: any) => objectToCheck !== null && typeof objectToCheck === 'object';

export const tryParse = (valueToParse: any, throwError: boolean = false) => {
    let valueParsed = valueToParse;
    try {
        if (isObject(valueParsed)) valueParsed = JSON.stringify(valueParsed);
        valueParsed = JSON.parse(valueParsed);
    } catch (error: any) {
        if (throwError === true) throw error;
    }
    return valueParsed;
};