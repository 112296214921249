import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const Access = () => {

	const history = useHistory();

	const goDashboard = () => {
		history.push('/')
	}

	return (
		<div className="exception-body accessdenied">
			<div className="exception-panel">
				<div className="exception-code">
					<img src="chat4fit/assets/layout/images/exception/401.svg" alt="serenity-react" />
				</div>

				<div className="exception-detail">

					<div className="exception-icon">
						<i className="pi pi-lock"></i>
					</div>
					<h1>ACCESS DENIED</h1>
					<p>Please contact the system administrator</p>
					<Button label="GO TO DASHBOARD" onClick={goDashboard} />
				</div>
			</div>
		</div>
	)

}