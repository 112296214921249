import axios from 'axios';
import enviroment from "../../.env";
import projectEnviroment from '../../project.enviroments';
import ls from '../../projects/localStorage';
import { isSuccess } from '../../utils/httpStatus';
import { Method } from '../../utils/method';
import { Entities } from './generics/EntitiesService';
import GenericService from './generics/GenericService';

export default class CustomerService  extends GenericService {
    findCustomerById = async (_id: string | null,headers?:any) => await super.findByIdNoAnon(Entities.CUSTOMER, false, _id, undefined, headers);
    findCustomerByEmail = async (email: string | null) => await super.findByEmailNoAnon(Entities.CUSTOMER, false, email);
    saveCustomer = async (data: any) => await super.save(Entities.CUSTOMER, false, data);

    async getInformation(token?: string) {
        let userToken = token ? token : ls.getLocalStorageToken();
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('customers', enviroment()),
            headers: { 'Authorization': `Bearer ${userToken}` }
        });
        if (isSuccess(returned.status)) return returned.data;
        throw new Error('Falha ao recuperar os dados!');
    }

    async changeBlockedCustomer(_id: string, block: boolean, token?: string) {
        let userToken = token ? token : ls.getLocalStorageToken();
        const returned = await this.call<any>({
            method: Method.PUT,
            url: projectEnviroment.buildPath(`customers/id/${_id}`, enviroment()),
            headers: { 'Authorization': `Bearer ${userToken}` },
            data: { customer: { _id, blocked: block } }
        });
        if (isSuccess(returned.status)) return returned.data;
        throw new Error('Falha ao recuperar os dados!');
    }

    getCustomersSmall() {
        return axios.get('chat4fit/assets/demo/data/customers-small.json').then(res => res.data.data);
    }

    getCustomersMedium() {
        return axios.get('chat4fit/assets/demo/data/customers-medium.json').then(res => res.data.data);
    }

    getCustomersLarge() {
        return axios.get('chat4fit/assets/demo/data/customers-large.json').then(res => res.data.data);
    }

    getCustomersXLarge() {
        return axios.get('chat4fit/assets/demo/data/customers-xlarge.json').then(res => res.data.data);
    }

}