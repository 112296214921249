import ls from '../../../projects/localStorage';
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class QuickMessageBranchGymService extends GenericService {
    allQuickMessageBranchGyms = async (quickMessageList: (string|undefined)[]) => {
        const search = {
            queries: [
                { field: 'branchGym', value: { $in: ((await ls.branchGym.getBranchGymsByStorageToken()))?.map(b => b._id) } },
                { field: 'quickMessage', value: { $in: quickMessageList } }
            ],
            ignoreSummaryData: true
        };
        return await super.findByQuery(Entities.QUICK_MESSAGE_BRANCH_GYM, false, search, undefined, { autoPopulate: 'false', limit: 0 })
    };
    // allQuickMessageBranchGyms = async (search?: string | undefined) => await super.findAll(Entities.QUICK_MESSAGE_BRANCH_GYM, false, search);
    findQuickMessageBranchGymById = async (_id: string | null) => await super.findById(Entities.QUICK_MESSAGE_BRANCH_GYM, false, _id);
    findQuickMessageBranchGymByQuickMessageId = async (quickMessageId: string | null) => await super.findByReferenceId(Entities.QUICK_MESSAGE_BRANCH_GYM, false, EntitiesService(Entities.QUICK_MESSAGE).singular, quickMessageId);
    saveQuickMessageBranchGym = async (data: any) => await super.save(Entities.QUICK_MESSAGE_BRANCH_GYM, false, data);
    deleteByQuickMessageBranchGymId = async (_id: string | null) => await super.deleteById(Entities.QUICK_MESSAGE_BRANCH_GYM, false, _id);
}
