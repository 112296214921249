import jwt from 'jsonwebtoken';


export const getCustomerIdStorageToken = (): string | null => {
    const checkToken = localStorage.getItem('project.chat4fit.token');
    if (!checkToken) return null;
    const decode: any = jwt.decode(checkToken);
    if (decode && decode.account && decode.account.customerId)
        return decode.account.customerId;
    return null;
};

export const getCustomerNicknameStorage = (): string | null => {
    let customerInformation: any = localStorage.getItem('customer.information');
    if (!customerInformation) return 'atendente';
    customerInformation = JSON.parse(customerInformation);
    return customerInformation?.nickname || 'atendente';
};

export const getCustomerUsernameStorage = (): string | null => {
    let customerInformation: any = localStorage.getItem('customer.information');
    if (!customerInformation) return 'não informado';
    customerInformation = JSON.parse(customerInformation);
    return customerInformation?.user?.username || 'não informado';
};
