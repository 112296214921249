import App from '../../chat4fit/App';
import { RouteInterface } from '../routes';
import { Access, Error, Login, NotFound } from '../../chat4fit/pages';

export const routes: RouteInterface[] = [
    { type: 'route', pathname: '/login', route: Login },
    { type: 'route', pathname: '/error', route: Error },
    { type: 'route', pathname: '/notfound', route: NotFound },
    { type: 'route', pathname: '/access', route: Access },
    { type: 'class', pathname: '/', route: App },
    { type: 'class', pathname: 'default', route: App },
];

