import enviroment from "../../.env";
import projectEnviroment from "../../project.enviroments";
import HttpStatus from "../../utils/httpStatus";
import { Method } from "../../utils/method";
import PerformRequest from "../../utils/performRequest";

const bearerHeader = {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, PATCH, POST, PUT, OPTIONS',
    // 'Access-Control-Allow-Headers': 'DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified'
};

export default class AuthenticateService extends PerformRequest<any> {
    async login(username: string, password: string) {
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('login', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}` })
        });

        if (returned.status === HttpStatus.OK && returned.data && returned.data.token) {
            localStorage.setItem('project.chat4fit.token', returned.data.token);
            return returned.data.token;
        } else {
            localStorage.removeItem('project.chat4fit.token');
            throw new Error('Login é inválido. Favor verificar!');
        }
    }
    async retrieveToken(username: string, password: string) {
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('login', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Basic ${Buffer.from(`${username}:${password}`).toString('base64')}` })
        });

        if (returned.status === HttpStatus.OK && returned.data && returned.data.token) {
            return returned.data.token;
        } else {
            throw new Error('Login é inválido. Favor verificar!');
        }
    }
    async refreshToken(token: string) {
        // console.log('refreshToken: ', token);
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('refreshToken', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Bearer ${token}` })
        });
        // console.log('returned.data.refreshedToken: ', returned.data.refreshedToken);
        if (returned.status === HttpStatus.OK && returned.data && returned.data.refreshedToken) {
            localStorage.setItem('project.chat4fit.token', returned.data.refreshedToken);
            return returned.data.refreshedToken;
        } else {
            localStorage.removeItem('project.chat4fit.token');
            throw new Error('Login é inválido. Favor verificar!');
        }
    }
    async dataList(token?: string) {
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('dataList', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Bearer ${token}` })
        });
        if (returned.status === HttpStatus.OK && returned.data) {
            // console.log('dataList: ', returned.data);
            return returned.data;
        } else {
            localStorage.removeItem('project.chat4fit.token');
            throw new Error('Login é inválido. Favor verificar!');
        }
    }
    async retrievePasswordChangeToken(token: string) {
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('refreshToken', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Bearer ${token}` })
        });
        if (returned.status === HttpStatus.OK && returned.data && returned.data.passwordOptions) {
            return returned.data.passwordOptions.passwordChangeToken;
        } else {
            throw new Error('Login é inválido. Favor verificar!');
        }
    }
    async changePassword(changePasswordToken: string, password: string) {
        const returned = await this.call<any>({
            method: Method.PUT,
            url: projectEnviroment.buildPath('user/password', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Bearer ${changePasswordToken}` }),
            data: {
                newPassword: password
            }
        });
        if (returned.status === HttpStatus.OK && returned.data) {
            return returned;
        } else {
            throw new Error('Login é inválido. Favor verificar!');
        }
    }
    async adminToken(token: string) {
        const returned = await this.call<any>({
            method: Method.GET,
            url: projectEnviroment.buildPath('adminToken', enviroment()),
            headers: Object.assign(bearerHeader, { 'Authorization': `Bearer ${token}`, admin: true })
        });
        if (returned.status === HttpStatus.OK && returned.data && returned.data.adminToken) {
            return returned.data.adminToken;
        } else {
            throw new Error('Login de administrador é inválido. Favor verificar!');
        }
    }

}