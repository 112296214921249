import AuthenticateService from '../../chat4fit/service/AuthenticateService';

const authenticateService = new AuthenticateService();

export const getNetworkGymsByStorageToken = async (): Promise<any[]> => {
    const token = localStorage.getItem('project.chat4fit.token');
    if (token) {
        if (localStorage.getItem('networkGymDataList')) {
            let networkGymDataList: any = localStorage.getItem('networkGymDataList');
            if (networkGymDataList) {
                networkGymDataList = JSON.parse(networkGymDataList);
                return networkGymDataList;
            }
        }
        return await authenticateService.dataList(token).then((returned: any) => {
            if (returned && returned.networkGyms) {
                if (returned.networkGyms?.length === 1 && !localStorage.getItem('networkGym')) localStorage.setItem('networkGym', returned.networkGyms[0]._id);
                localStorage.setItem('networkGymDataList', JSON.stringify(returned.networkGyms));
                return returned.networkGyms;
            } else {
                return [];
            }
        }).catch(() => []);
    } else {
        return [];
    }
};

export const hasNetworkGymSelected = (): boolean => {
    const networkGym = localStorage.getItem('networkGym');
    return !!networkGym;
};

export const getCountNetworkGymsByStorageToken = async (): Promise<number> => {
    return ((await getNetworkGymsByStorageToken())?.length || 0);
};

export const defineUniqueNetworkGym = async (select: (type: string, _id?: string | null, ...args: string[]) => void) => {
    const networkGyms = await getNetworkGymsByStorageToken();
    if (!hasNetworkGymSelected()) {
        if (networkGyms && networkGyms.length === 1) {
            if (networkGyms?.length === 1 && !localStorage.getItem('networkGym')) {
                localStorage.setItem('networkGym', networkGyms[0]._id);
                select('networkGym', networkGyms[0]._id);
            };
        }
    } else {
        if (networkGyms.find((n:any) => n._id === localStorage.getItem('networkGym'))) {
            select('networkGym', localStorage.getItem('networkGym'));
        } else {
            localStorage.removeItem('networkGym');
        }
    }
};
