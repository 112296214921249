/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import GuideService from '../../service/admin/GuideService';
import { addNewRegister } from '../commons/formDataFunctions';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { IMessage } from '../Control';
import { IFormDataGuide } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './Guide.css';

const guideService = new GuideService();


export const Guide = (props: IPropsArgsComponents) => {
    const [title] = useState('Roteiros');
    // const [objectData] = useState('roteiro');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataGuide[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [editFormData, setEditFormData] = useState<any | null>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'guide', show: true, considered: false },
    ]), []);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        guideService.allGuides(searchTerm).then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    setData(returned.data);
                } else {
                    setData([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setData([]);
            }
        });
        return () => { isMounted = false };
    };

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setEditFormData(null);
    //     setDialogData(true);
    // }

    const edit = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;

        if (type === 'guide') {
            setEditFormData({
                _id: item._id,
                key: item.key,
                description: item.description,
                recurrent: item.recurrent,
                validityInitial: item.validityInitial,
                validityFinal: item.validityFinal,
                fixedDate: item.fixedDate,
                attempts: item.attempts,
                hasGenericDate: item.hasGenericDate,
                descriptionGenericDate: item.descriptionGenericDate,
                active: item.active
            });
            setDialogData(true);
        }
    }

    const clone = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;

        if (type === 'guide') {
            setEditFormData({
                key: item.key,
                description: item.description,
                recurrent: item.recurrent,
                validityInitial: moment(item.validityInitial).toDate(),
                validityFinal: moment(item.validityFinal).toDate(),
                fixedDate: item.fixedDate,
                attempts: item.attempts,
                hasGenericDate: item.hasGenericDate,
                descriptionGenericDate: item.descriptionGenericDate,
                active: item.active
            });
            setDialogData(true);
        }
    }

    const listData = () => {
        const listData = data ? data : [];
        if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map(d => {
                    const selectedGuideId = localStorage.getItem('guide');
                    const config: IConfig = {
                        title: { icon: 'pi-list', value: d.key },
                        lines: [
                            { description: 'Descrição', value: d.description, rows: 5 },
                            { description: 'Validade', value: `${moment(d.validityInitial).format('DD/MM/yyyy')} - ${moment(d.validityFinal).format('DD/MM/yyyy')}`, rows: 2 },
                            d.fixedDate ? { description: 'Data fixa', value: `${moment(d.fixedDate).format('DD/MM/yyyy')}` } : null,
                        ],
                        data: d,
                        buttons: d._id ? [
                            { key: 'editar', icon: 'pi-pencil', onClick: () => edit('guide', d._id), tooltip: 'Editar' },
                            { key: `clone`, icon: 'pi-copy', onClick: () => clone('guide', d._id), tooltip: 'Copiar' },
                            selectedGuideId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('guide', d._id), tooltip: 'Selecionar' } : null,
                            selectedGuideId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('guide', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedGuideId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: true, addNewRegister: () => addNewRegister('guide', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token ?
                <GenericFormDialog<IFormDataGuide> key='GuideDialog'
                    token={token}
                    objectData='roteiro'
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataGuide>[]) => [
                        {
                            colSize: 'col-4', fieldName: 'key', fieldType: 'InputTextField', description: '* Identificação', placeholder: 'identificação', value: formData.values.key,
                            onChange: formData.handleChange, autoFocus: true,
                        },
                        {
                            colSize: 'col-8', fieldName: 'description', fieldType: 'InputTextField', description: '* Descrição', placeholder: 'descrição', value: formData.values.description,
                            onChange: formData.handleChange, autoFocus: true,
                        },
                        {
                            colSize: 'col-8', fieldType: 'GroupField', description: 'Vigência:',
                        },
                        {
                            colSize: 'col-4', fieldType: 'GroupField', description: 'Fixo:',
                        },
                        {
                            colSize: 'col-4', fieldName: 'validityInitial', fieldType: 'CalendarField', description: '* Inicial:', value: formData.values.validityInitial,
                            onChange: formData.handleChange, autoFocus: false,
                            dateFormat: 'dd/mm/yy', locale: 'pt-BR', showIcon: true
                        },
                        {
                            colSize: 'col-4', fieldName: 'validityFinal', fieldType: 'CalendarField', description: '* Final:', value: formData.values.validityFinal,
                            onChange: formData.handleChange, autoFocus: false,
                            dateFormat: 'dd/mm/yy', locale: 'pt-BR', showIcon: true
                        },
                        {
                            colSize: 'col-4', fieldName: 'fixedDate', fieldType: 'CalendarField', description: 'Data', value: formData.values.fixedDate,
                            onChange: formData.handleChange, autoFocus: false,
                            dateFormat: 'dd/mm/yy', locale: 'pt-BR', showIcon: true
                        },
                        {
                            colSize: 'col-4 col-offset-1', fieldName: 'recurrent', fieldType: 'SelectButtonField', description: '* Recorrente?:', value: formData.values.recurrent,
                            onChange: formData.handleChange, autoFocus: false,
                            options: [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
                            optionLabel: 'name'
                        },
                        {
                            colSize: 'col-4 col-offset-1', fieldName: 'attempts', fieldType: 'InputNumberField', description: '* Tentativas:', value: formData.values.attempts,
                            onValueChange: formData.handleChange,
                            mode: "decimal", min: 0, max: 10, showButtons: true,
                            onChange: formData.handleChange, autoFocus: false
                        },
                        {
                            colSize: 'col-6', fieldName: 'hasGenericDate', fieldType: 'SelectButtonField', description: '* Tem Data Genérica?:', value: formData.values.hasGenericDate,
                            onChange: formData.handleChange, autoFocus: false,
                            options: [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
                            optionLabel: 'name'
                        },
                        formData.values.hasGenericDate === true ?
                            {
                                colSize: 'col-6', fieldName: 'descriptionGenericDate', fieldType: 'InputTextField', description: '* Descrição da Data Genérica', placeholder: 'descrição data genérica', value: formData.values.descriptionGenericDate,
                                onChange: formData.handleChange, autoFocus: true,
                            } : null,
                    ])}
                    emptyFormData={{
                        key: '',
                        description: '',
                        recurrent: true,
                        validityInitial: '',
                        validityFinal: '',
                        fixedDate: '',
                        attempts: 5,
                        hasGenericDate: false,
                        descriptionGenericDate: '',
                        active: true,
                        deleted: false
                    }}
                    editFormData={editFormData}
                    editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};


                        if (!data.key) {
                            errors.key = 'Identificação é obrigatório.';
                        } else if (data.key.length < 5) {
                            errors.key = 'Identificação precisa ter mais que 4 caracteres.';
                        }

                        if (!data.description) {
                            errors.description = 'Descrição é obrigatório.';
                        } else if (data.description.length < 3) {
                            errors.description = 'Nome precisa ter mais que 2 caracteres.';
                        }

                        if (!data.validityInitial && !data.validityFinal && !data.fixedDate) {
                            errors.validityInitial = 'Data de validade é obrigatório.';
                        }

                        if (!data.validityInitial && data.validityFinal && !data.fixedDate) {
                            errors.validityInitial = 'Data de validade inicial é obrigatório.';
                        }

                        if (data.validityInitial && !data.validityFinal && !data.fixedDate) {
                            errors.validityInitial = 'Data de validade final é obrigatório.';
                        }

                        if (moment(data.validityInitial).isAfter(moment(data.validityFinal))) {
                            errors.validityFinal = 'Data de validade inicial dever ser anterior a data de validade final.';
                        }

                        if (moment(data.validityFinal).isBefore(moment())) {
                            errors.validityFinal = 'Data de validade final dever ser superior a data atual.';
                        }

                        if (data.validityInitial && !data.validityFinal) {
                            errors.validityFinal = 'Data de validade final é obrigatório.';
                        }

                        if (!data.attempts) {
                            errors.attempts = 'Número de tetativas é obrigatório.';
                        }

                        if (data.hasGenericDate && !data.descriptionGenericDate) {
                            errors.descriptionGenericDate = 'Descrição data genérica é obrigatório.';
                        }

                        return errors;
                    }}
                    onSubmit={(props: IPropsFormDialog<IFormDataGuide>, data: FormikValues, formData: FormikInterface) => {
                        new GuideService().saveGuide(data)
                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }}
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                />
                :
                null
            }
        </>
    )
}
