/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import { isCPF, isMail, isMobilePhone } from '../../../utils/functions/data';
import { onlyNumbers, padRight } from '../../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import CustomerService from '../../service/CustomerService';
import ExternalUserservice from '../../service/admin/ExternalUserService';
import NetworkGymExternalUserService from '../../service/admin/NetworkGymExternalUserService';
import { IMessage } from '../Control';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { addNewRegister } from '../commons/formDataFunctions';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataExternalUser } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './ExternalUser.css';

const externalUserService = new ExternalUserservice();


export const ExternalUser = (props: IPropsArgsComponents) => {
    const [title] = useState('Proprietários');
    // const [objectData] = useState('Proprietário');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataExternalUser[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);

    const [editFormData, setEditFormData] = useState<any | null>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true }
    ]), []);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);

    useEffect(() => refreshList(), [networkGymId]);

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        externalUserService.allExternalUsers(searchTerm).then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    // console.log('returned.data: ', returned.data);
                    if (networkGymId) setData(returned.data.filter((d: any) => d.role === RoleRules.owner && d.networkGymIds.indexOf(networkGymId) > -1));
                    else setData(returned.data);
                } else {
                    setData([]);
                }
                setLoadingData(false);
            }
        }).catch(error => {
            console.error('error', error);
            if (isMounted) {
                setLoadingData(false);
                setData([]);
            }
        });
        return () => { isMounted = false };
    };

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setEditFormData(null);
    //     setDialogData(true);
    // }

    const edit = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;

        if (type === 'externalUser') {
            setEditFormData({
                _id: item._id,
                name: item.name,
                nickname: item.nickname,
                cpf: item.cpf,
                mobilePhone: item.mobilePhone,
                email: item.email,
                password: item.password,
                active: item.active
            });
            setDialogData(true);
        }
    }

    const clone = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;
        if (type === 'externalUser') {
            setEditFormData({
                name: item.name,
                nickname: item.nickname,
                cpf: item.cpf,
                mobilePhone: item.mobilePhone,
                email: item.email,
                password: item.password,
                active: item.active
            });
            setDialogData(true);
        }
    }
    const listData = () => {
        const listData = data ? data : [];
        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;

        if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map(d => {
                    const selectedExternalId = localStorage.getItem('externalUser');
                    const config: IConfig = {
                        title: { icon: 'pi-user', value: d.nickname },
                        lines: [
                            { description: 'Nome', value: padRight(d.name, 26, '') },
                            { description: 'E-mail', value: padRight(d.email, 26, '') },
                            { description: 'Celular', value: d.mobilePhone },
                            { description: 'Redes', value: d.networkGymFantasyNames || '|não vinculado|' },
                        ],
                        data: d,
                        buttons: d._id ? [
                            { key: 'editar', icon: 'pi-pencil', onClick: () => edit('externalUser', d._id), tooltip: 'Editar' },
                            { key: `clone`, icon: 'pi-copy', onClick: () => clone('externalUser', d._id), tooltip: 'Copiar' },
                            selectedExternalId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('externalUser', d._id), tooltip: 'Selecionar' } : null,
                            selectedExternalId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('externalUser', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedExternalId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    return (
        <>
            {createView(title, {
                editData: !!networkGymId,
                addNew: checkRoleHierarchyByToken(RoleRules.admin), addNewRegister: () => addNewRegister('contact', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {networkGymId && token ?
                <GenericFormDialog<IFormDataExternalUser> key='ExternalUserDialog'
                    token={token}
                    objectData='proprietário'
                    refreshList={() => refreshList()}
                    setLoading={(value) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                    editFormData={editFormData}
                    emptyFormData={{
                        name: '',
                        nickname: '',
                        cpf: '',
                        mobilePhone: '',
                        email: '',
                        password: '',
                        active: true,
                        deleted: false
                    }}
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataExternalUser>[]) => [
                        {
                            colSize: 'col-12', fieldName: 'name', fieldType: 'InputTextField', description: '* Nome', placeholder: 'nome do Proprietário', value: formData.values.name,
                            onChange: formData.handleChange, autoFocus: true,
                        },
                        {
                            colSize: 'col-12', fieldName: 'nickname', fieldType: 'InputTextField', description: '* Apelido', placeholder: 'apelido do Proprietário', value: formData.values.nickname,
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        !formData.values._id ?
                            {
                                colSize: 'col-12', fieldName: 'cpf', fieldType: 'InputMaskField', description: '* CPF', placeholder: '999.999.999-99', value: formData.values.cpf,
                                mask: '999.999.999-99',
                                onChange: formData.handleChange, autoFocus: false,
                            } : null,
                        !formData.values._id ?
                            {
                                colSize: 'col-12', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
                                mask: '+55 (99) 99999-9999',
                                onChange: formData.handleChange, autoFocus: false,
                            } : null,
                        !formData.values._id ?
                            {
                                colSize: 'col-12', fieldName: 'email', fieldType: 'InputTextField', description: '* Email', placeholder: 'email do Proprietário', value: formData.values.email,
                                autoComplete: 'off',
                                onChange: formData.handleChange, autoFocus: false,
                            } : null,
                        !formData.values._id ?
                            {
                                colSize: 'col-12', fieldName: 'password', fieldType: 'PasswordField', description: '* Senha', placeholder: 'senha do Proprietário', value: formData.values.password,
                                autoComplete: 'new-password', toggleMask: true,
                                onChange: formData.handleChange, autoFocus: false,
                            } : null,
                    ])}
                    editForceData={{}}
                    // editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};

                        if (!data.nickname) {
                            errors.nickname = 'Apelido é obrigatório.';
                        } else if (data.nickname.length < 3) {
                            errors.nickname = 'Apelido precisa ter mais que 2 caracteres.';
                        }

                        if (!data.name) {
                            errors.name = 'Nome é obrigatório.';
                        } else if (data.name.length < 5) {
                            errors.name = 'Nome precisa ter mais que 4 caracteres.';
                        }

                        if (!data._id) {
                            if (data.cpf && !isCPF(data.cpf)) {
                                errors.cpf = 'CPF inválido.';
                            }

                            if (!data.mobilePhone) {
                                errors.mobilePhone = 'Número de celular é obrigatório.';
                            } else if (!isMobilePhone(onlyNumbers(data.mobilePhone))) {
                                errors.mobilePhone = 'Número de celular inválido.';
                            }

                            if (!data.email) {
                                errors.email = 'E-mail é obrigatório.';
                            } else if (!isMail(data.email)) {
                                errors.email = 'Email inválido.';
                            }
                        }

                        if (!data.password) {
                            errors.password = 'Senha é obrigatório.';
                        } else if (data.password.length < 6) {
                            errors.password = 'Senha precisa ter mais que 6 caracteres.';
                        }

                        return errors;
                    }}
                    submitForceData={{}}
                    onSubmit={(props: IPropsFormDialog<IFormDataExternalUser>, data: FormikValues, formData: FormikInterface) => {
                        new ExternalUserservice().saveExternalUser(data)
                            .then((returned: IPerformRequestResult<any>) => {
                                if (returned.status.code === 409) saveSuccessed(props, formData, 'Proprietário já existente no sistema com este cpf, e-mail e/ou núm. celular')(returned);
                                else {
                                    new CustomerService().findCustomerByEmail(data.email).then((returned: IPerformRequestResult<any>) => {
                                        const networkGymExternalUserData = {
                                            networkGym: networkGymId,
                                            customer: returned.data[0]._id,
                                            role: RoleRules.owner,
                                            active: true
                                        };
                                        new NetworkGymExternalUserService().saveNetworkGymExternalUser(networkGymExternalUserData)
                                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                                            .catch((error: any) => saveFailed(props, formData)(error));
                                    }).catch((error: any) => saveFailed(props, formData)(error));
                                };
                            })
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }} />
                :
                null
            }
        </>
    )
}

