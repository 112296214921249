import moment from 'moment';
import { makeKeyByString } from '../../../utils/functions/hash';
import EntitiesService, { Entities } from '../generics/EntitiesService';
import GenericService from '../generics/GenericService';

export default class DashboardReportService extends GenericService {
    findAllDashboardReport = async (networkGymId: string, branchGymId?: string | null, customerId?: string | null,
        startDate?: Date, endDate?: Date) => {
        const search: any = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId },
                { field: '$and', value: [{ date: { $gte: startDate } }, { date: { $lte: endDate } }] },
                { field: 'report', value: { '$in': ['conversation', 'schedule'] } },
            ],
            // projections: [
            //     { field: '_id' },
            //     { field: 'date' },
            //     { field: 'report' },
            //     { field: 'referenceReport' },
            //     { field: 'valueReport' },
            //     { field: 'branchGym' },
            //     { field: 'userReference' },
            // ]
        };
        if (branchGymId) search.queries.push({ field: EntitiesService(Entities.BRANCH_GYM).singular, value: branchGymId })
        if (customerId) search.queries.push({ field: 'userReference', value: customerId })
        // console.log('search: ', search);
        const dashboardReports = await super.findByQuery(Entities.DASHBOARD_REPORT, false, search);
        // console.log('dashboardReports: ', dashboardReports);
        return dashboardReports;
    };

    findDashboardReports = async (_id: string | null) => await super.findById(Entities.DASHBOARD_REPORT, false, _id);

    findDashboardReportsBySearch = async (search: any) => await super.findByQuery(Entities.DASHBOARD_REPORT, false, search);

    saveConversationDashboardReport = async (originReport: string, networkGymId: string | null | undefined, branchGymId: string | null | undefined,
        userReferenceId: string | undefined, conversation?: {
            contactMessage: any,
            contactMessageKey?: string,
            contactMessageEnumerator: number,
            flags?: {
                contactMessageRouted?: boolean,
                contactMessageAvailabled?: boolean,
                contactMessageFinished?: boolean,
                contactMessageEvaluated?: boolean,
                contactMessageScheduled?: boolean
                contactMessageRedirectBranchGym?: boolean,
                contactMessageReopened?: boolean,
                contactMessageProactive?: boolean,
            }
        }) => {
        try {
            let dashboardReportToSave: any = { originReport };
            let dashboardReportKey: string = conversation?.contactMessageKey || `conversation_register_${networkGymId}_${conversation?.contactMessage._id}_${conversation?.contactMessage.enumerator}`;

            // console.log('dashboardReportKey: ', dashboardReportKey);

            const dashboardReportLocated = await super.findByReferenceId(Entities.DASHBOARD_REPORT, false, 'key', dashboardReportKey);

            dashboardReportToSave.key = dashboardReportKey;

            dashboardReportToSave.contactMessage = conversation?.contactMessage?._id;
            dashboardReportToSave.report = 'conversation';
            dashboardReportToSave.referenceReport = 'register';
            dashboardReportToSave.networkGym = networkGymId;
            dashboardReportToSave.branchGym = branchGymId;

            if (dashboardReportLocated && dashboardReportLocated.data && dashboardReportLocated.data[0] && dashboardReportLocated.data[0]._id) {
                dashboardReportToSave._id = dashboardReportLocated.data[0]._id;
            } else {
                dashboardReportToSave.date = moment().toDate();
            }

            dashboardReportToSave.lastChangeDate = moment().toDate();
            dashboardReportToSave.userReference = userReferenceId;

            if (conversation?.contactMessageEnumerator) dashboardReportToSave.contactMessageEnumerator = conversation.contactMessageEnumerator;
            if (conversation?.flags?.contactMessageRouted) dashboardReportToSave.contactMessageRouted = conversation.flags.contactMessageRouted;
            if (conversation?.flags?.contactMessageAvailabled) dashboardReportToSave.contactMessageAvailabled = conversation.flags.contactMessageAvailabled;
            if (conversation?.flags?.contactMessageFinished) dashboardReportToSave.contactMessageFinished = conversation.flags.contactMessageFinished;
            if (conversation?.flags?.contactMessageEvaluated) dashboardReportToSave.contactMessageEvaluated = conversation.flags.contactMessageEvaluated;
            if (conversation?.flags?.contactMessageScheduled) dashboardReportToSave.contactMessageScheduled = conversation.flags.contactMessageScheduled;
            if (conversation?.flags?.contactMessageRedirectBranchGym) dashboardReportToSave.contactMessageRedirectBranchGym = conversation.flags.contactMessageRedirectBranchGym;
            if (conversation?.flags?.contactMessageReopened) dashboardReportToSave.contactMessageReopened = conversation.flags.contactMessageReopened;
            if (conversation?.flags?.contactMessageProactive) dashboardReportToSave.contactMessageProactive = conversation.flags.contactMessageProactive;

            dashboardReportToSave.processed = false;

            await super.save(Entities.DASHBOARD_REPORT, false, dashboardReportToSave);
        } catch (error) {
            console.error('DashboardReportService.saveConversationDashboardReport.error: ', error)
        }
    };

    saveScheduleDashboardReport = async (originReport: string, networkGymId: string | null | undefined, branchGymId: string | null | undefined,
        userReferenceId: string | undefined, schedule?: {
            schedule: any,
            scheduleKey?: string, flags?: {
                scheduleFinished?: boolean,
                scheduleByChat?: boolean,
                scheduleReason?: boolean
            }
        }) => {
        try {
            let dashboardReportToSave: any = { originReport };
            let dashboardReportKey: string = schedule?.scheduleKey || `schedule_register_${networkGymId}_${schedule?.schedule._id}`;

            const dashboardReportLocated = await super.findByReferenceId(Entities.DASHBOARD_REPORT, false, 'key', dashboardReportKey);

            dashboardReportToSave.key = dashboardReportKey;
            dashboardReportToSave.schedule = schedule?.schedule?._id;

            dashboardReportToSave.report = 'schedule';
            dashboardReportToSave.referenceReport = 'register';
            dashboardReportToSave.networkGym = networkGymId;
            dashboardReportToSave.branchGym = branchGymId;

            if (dashboardReportLocated && dashboardReportLocated.data && dashboardReportLocated.data[0] && dashboardReportLocated.data[0]._id) {
                dashboardReportToSave._id = dashboardReportLocated.data[0]._id;
                dashboardReportToSave.date = schedule?.schedule?.date || dashboardReportLocated.data[0].date;
            } else {
                dashboardReportToSave.date = schedule?.schedule?.date || moment().toDate();
            }

            dashboardReportToSave.lastChangeDate = schedule?.schedule?.date || moment().toDate();
            dashboardReportToSave.userReference = userReferenceId;

            if (schedule?.flags?.scheduleFinished) dashboardReportToSave.scheduleFinished = schedule.flags.scheduleFinished;
            if (schedule?.flags?.scheduleByChat) dashboardReportToSave.scheduleByChat = schedule.flags.scheduleByChat;
            if (schedule?.flags?.scheduleReason) dashboardReportToSave.scheduleReason = schedule.flags.scheduleReason;

            dashboardReportToSave.processed = false;

            await super.save(Entities.DASHBOARD_REPORT, false, dashboardReportToSave);
        } catch (error) {
            console.error('DashboardReportService.saveScheduleDashboardReport.error: ', error)
        }
    };

    saveGenericConversationDashboardReport = async (originReport: string, report: string, referenceReport: string,
        networkGymId: string, branchGymId: string, userReferenceId: string, contactMessageId: string,
        contactMessageEnumerator: string, auto?: boolean) => {
        try {
            const saveDashboardReport: any = {
                originReport,
                key: makeKeyByString(`${report}_${referenceReport}_${networkGymId}_${branchGymId}_${userReferenceId}_${contactMessageId}_${contactMessageEnumerator}}`),
                networkGym: networkGymId,
                branchGym: branchGymId,
                userReference: userReferenceId,
                date: moment().toDate(),
                lastChangeDate: moment().toDate(),
                report: report,
                referenceReport: referenceReport,
                contactMessage: contactMessageId,
                contactMessageEnumerator,
                processed: false,
                deleted: false,
                active: true
            };
            if (auto) saveDashboardReport.auto = auto;
            await super.save(Entities.DASHBOARD_REPORT, false, saveDashboardReport);
        } catch (error) {
            console.error('DashboardReportService.saveGenericConversationDashboardReport.error: ', error)
        }
    };

    saveGenericScheduleDashboardReport = async (originReport: string, report: string, referenceReport: string,
        networkGymId: string, branchGymId: string, userReferenceId: string, scheduleId: string) => {
        try {
            const saveDashboardReport: any = {
                originReport,
                key: makeKeyByString(`${report}_${referenceReport}_${networkGymId}_${branchGymId}_${userReferenceId}_${scheduleId}}`),
                networkGym: networkGymId,
                branchGym: branchGymId,
                userReference: userReferenceId,
                date: moment().toDate(),
                lastChangeDate: moment().toDate(),
                report: report,
                referenceReport: referenceReport,
                schedule: scheduleId,
                processed: false,
                deleted: false,
                active: true
            };
            await super.save(Entities.DASHBOARD_REPORT, false, saveDashboardReport);
        } catch (error) {
            console.error('DashboardReportService.saveGenericScheduleDashboardReport.error: ', error)
        }
    };

    saveGenericDashboardReport = async (originReport: string, report: 'logon', referenceReport: string,
        networkGymId: string, branchGymId: string, userReferenceId: string) => {
        try {
            const saveDashboardReport: any = {
                originReport,
                key: makeKeyByString(`${report}_${referenceReport}_${networkGymId}_${branchGymId}_${userReferenceId}}`),
                networkGym: networkGymId,
                branchGym: branchGymId,
                userReference: userReferenceId,
                date: moment().toDate(),
                lastChangeDate: moment().toDate(),
                report: report,
                referenceReport: referenceReport,
                processed: false,
                deleted: false,
                active: true
            };
            await super.save(Entities.DASHBOARD_REPORT, false, saveDashboardReport);
        } catch (error) {
            console.error('DashboardReportService.saveGenericDashboardReport.error: ', error)
        }
    };

    updateLogonDashboardReport = async (report: string, referenceReport: string, customerId: string) => {
        // console.log('report, referenceReport, customerId: ', report, referenceReport, customerId);
        try {
            const dashboardReportReturneds = await super.findByQuery(Entities.DASHBOARD_REPORT, false, {
                queries: [
                    { field: 'report', value: report },
                    { field: 'referenceReport', value: referenceReport },
                    { field: 'userReference', value: customerId }
                ]
            });
            // console.log('dashboardReportReturneds: ', dashboardReportReturneds);
            if (dashboardReportReturneds && dashboardReportReturneds.data) {
                const dashboardReports = dashboardReportReturneds.data;
                for (let i = 0; i < dashboardReports.length; i++) {
                    const dashboardReport = dashboardReports[i];
                    // const dashboardReportSaved = 
                    await super.save(Entities.DASHBOARD_REPORT, false, {
                        _id: dashboardReport._id,
                        date: moment().subtract(20, 'minutes').toDate()
                    });
                    // console.log('dashboardReportSaved: ', dashboardReportSaved);
                }
            }


        } catch (error) {
            console.error('DashboardReportService.updateLogonDashboardReport.error: ', error)
        }
    };

    updateDashboardReport = async (dashboardReport: any) => {
        // console.log('report, referenceReport, customerId: ', report, referenceReport, customerId);
        try {
            await super.save(Entities.DASHBOARD_REPORT, false, dashboardReport);
        } catch (error) {
            console.error('DashboardReportService.updateDashboardReport.error: ', error)
        }
    };
}
