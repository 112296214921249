import EntitiesService, { Entities } from '../generics/EntitiesService';
import GenericService from '../generics/GenericService';

export default class ExternalUserService extends GenericService {
    allExternalUsers = async (search?: string | undefined) => await super.findAll(Entities.EXTERNAL_USER, false, search);
    findExternalUserById = async (_id: string | null) => await super.findById(Entities.EXTERNAL_USER, false, _id);
    findAllExternalUserByNetworkGymId = async (networkGymId: string | null, search?: string | undefined) => await super.findByReferenceId(Entities.NETWORK_GYM_EXTERNAL_USER, false,
        EntitiesService(Entities.NETWORK_GYM).singular, networkGymId, search);
    findAllExternalUserByBranchGymId = async (branchGymId: string | null, search?: string | undefined) => await super.findByReferenceId(Entities.BRANCH_GYM_EXTERNAL_USER, false,
        EntitiesService(Entities.BRANCH_GYM).singular, branchGymId, search);
    saveExternalUser = async (data: any) => await super.save(Entities.EXTERNAL_USER, false, data);
}
