import emojiPattern from "./emojiPattern";
import { defined } from "./functions";
import { asc, chr } from "./functions/string";

const encryptKeySecret: string = process.env['ENCRYPT_KEY_SECRET'] || 'NO-ENCRYPT-KEY-SECRET';

export const encrypt = (object: any, keySecret?: string) => {
  if (!defined(object)) return '';
  let value = JSON.stringify(object);
  value = value.replace(/ā/g, 'ã');
  value = value.replace(/ē/g, 'e');
  value = value.replace(/ī/g, 'i');
  value = value.replace(/ō/g, 'õ');
  value = value.replace(/ū/g, 'u');

  value = escape(value);

  value = encodeHex(value);

  const workedKey = keySecret || encryptKeySecret;

  // nāæëīœōū7lmñç
  let mensx = '', l, i, j = 0;

  for (i = 0; i < value.length; i++) {
    j++;
    l = asc(value.substr(i, 1)) + asc(workedKey.substr(j, 1));
    if (j === workedKey.length - 1) {
      j = 1;
    }
    if (l > 255) {
      l -= 256;
    }
    mensx += (chr(l));
  }

  return mensx;
};

export const encryptSecure = (object: any, keySecret?: string) => {
  if (!defined(object)) return '';
  let value = JSON.stringify(object);
  value = value.replace(/ā/g, 'ã');
  value = value.replace(/ē/g, 'e');
  value = value.replace(/ī/g, 'i');
  value = value.replace(/ō/g, 'õ');
  value = value.replace(/ū/g, 'u');

  const workedKey = keySecret || encryptKeySecret;

  // nāæëīœōū7lmñç
  let mensx = '', l, i, j = 0, k = 0;
  const maxLength = Math.max(value.length, workedKey.length);
  for (i = 0; i < maxLength; i++) {
    l = asc(value.substr(k, 1)) + asc(workedKey.substr(j, 1));
    j++; k++;

    if (j === workedKey.length - 1) j = 0;
    if (k === value.length - 1) k = 0;

    if (l > 255) l -= 256;

    mensx += (chr(l));
  }

  mensx = escape(mensx);

  return mensx;
};

export const decrypt = (objectToken: any, keySecret?: string) => {
  const workedKey = keySecret || encryptKeySecret;

  objectToken = decodeHex(objectToken);

  let mensx = '';
  let l;
  let i;
  let j = 0;

  for (i = 0; i < objectToken.length; i++) {
    j++;
    l = asc(objectToken.substr(i, 1)) - asc(workedKey.substr(j, 1));
    if (j === workedKey.length - 1) {
      j = 1;
    }
    if (l < 0) {
      l += 256;
    }
    mensx += (chr(l));
  }
  
  mensx = unescape(mensx);

  return mensx;
};

export const encodeHex = (value: string): string => {
  // const rex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug;
  return value.replace(emojiPattern, (match) => `[e-${match.codePointAt(0)?.toString(16)}]`)
}

export const decodeHex = (value: string): string => {
  return value.replace(/\[e-([0-9a-fA-F]+)\]/g, (_match, hex) => String.fromCodePoint(Number.parseInt(hex, 16)));
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { encrypt, encryptSecure, decrypt };
