import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

export const createView = (title: string, args?: {
    lowTitle?: boolean,
    editData?: boolean,
    addNew?: boolean, addNewRegister?: () => void,
    uploadCloud?: boolean, uploadCloudRegister?: () => void,
    uploadPlan?: boolean, uploadPlanRegister?: () => void,
    downloadPlan?: boolean, downloadPlanRegister?: () => void,
    hasSearchTerm?: boolean, searchTerm?: string, setSearchTerm?: React.Dispatch<React.SetStateAction<string>>,
    setEditFormData?: React.Dispatch<any>, setEditStepData?: React.Dispatch<any>, setDialogData?: React.Dispatch<React.SetStateAction<boolean>>
    showBeforeList?: boolean,
    setShowBeforeList?: React.Dispatch<React.SetStateAction<boolean>>,
    hideBeforeList?: JSX.Element | JSX.Element[],
    beforeList?: JSX.Element | JSX.Element[],
    listData: () => JSX.Element | JSX.Element[],
    cover?: boolean,
}) => {
    return <div className='grid'>
        {createViewHeader(title, args)}
        {args?.showBeforeList ? args?.beforeList : args?.hideBeforeList ? args?.hideBeforeList : null}
        <div className={classNames('col-12', 'justify-content-center', { 'div-cover': args?.cover })}>
            <div className='grid' style={{ margin: 20 }}>
                {args?.listData()}
            </div>
        </div>
    </div>;
}


const createViewHeader = (title: string, args?: {
    lowTitle?: boolean,
    editData?: boolean, setEditFormData?: React.Dispatch<any>,
    addNew?: boolean, addNewRegister?: () => void,
    uploadCloud?: boolean, uploadCloudRegister?: () => void,
    uploadPlan?: boolean, uploadPlanRegister?: () => void,
    downloadPlan?: boolean, downloadPlanRegister?: () => void,
    hasSearchTerm?: boolean, searchTerm?: string, setSearchTerm?: React.Dispatch<React.SetStateAction<string>>,
    setDialogData?: React.Dispatch<React.SetStateAction<boolean>>,
    showBeforeList?: boolean,
    setShowBeforeList?: React.Dispatch<React.SetStateAction<boolean>>,
    hideBeforeList?: JSX.Element | JSX.Element[],
    beforeList?: JSX.Element | JSX.Element[]
}) => {
    return <div className='col-12'>
        <div className='flex justify-content-start flex-wrap card-container blue-container' style={{ height: 60, paddingTop: 10, borderBottom: '2px solid #aaa' }}>

            <div className='flex col-6'>
                {args?.lowTitle ? <h5 style={{ marginLeft: 20 }}>{title}</h5> : <h3 style={{ marginLeft: 20 }}>{title}</h3>}
                {!!args?.addNew && args?.addNewRegister ?
                    <Button icon='pi pi-plus' className='p-button-rounded shadow-3'
                        style={{ marginLeft: 20, height: 24, width: 24 }}
                        onClick={() => args?.addNewRegister ? args?.addNewRegister() : null} tooltip='Adicionar' />
                    : null}
                {!!args?.uploadCloud && args?.uploadCloudRegister ?
                    <Button icon='pi pi-cloud-upload' className='p-button-rounded shadow-3'
                        style={{ marginLeft: 20, height: 24, width: 24 }}
                        onClick={() => args?.uploadCloudRegister ? args?.uploadCloudRegister() : null} tooltip='Carregar' />
                    : null}
                {!!args?.downloadPlan && args?.downloadPlanRegister ?
                    <Button icon='pi pi-download' className='p-button-rounded shadow-3'
                        style={{ marginLeft: 20, height: 24, width: 24 }}
                        onClick={() => args?.downloadPlanRegister ? args?.downloadPlanRegister() : null} tooltip='Exemplo' />
                    : null}
                {!!args?.uploadPlan && args?.uploadPlanRegister ?
                    <Button icon='pi pi-upload' className='p-button-rounded shadow-3'
                        style={{ marginLeft: 20, height: 24, width: 24 }}
                        onClick={() => args?.uploadPlanRegister ? args?.uploadPlanRegister() : null} tooltip='Carregar' />
                    : null}
            </div>
            {args?.setShowBeforeList ?
                <div className='col-4 flex justify-content-end'>
                    <b>Visualizar filtros: </b>
                    <Button icon={`pi ${args?.showBeforeList ? 'pi-times-circle' : 'pi-cog'}`} className='p-button-rounded p-button-secondary shadow-3'
                        style={{ marginLeft: 20, height: 24, width: 24 }}
                        onClick={() => args?.setShowBeforeList ? args?.setShowBeforeList(!args?.showBeforeList) : null} tooltip='Visualizar filtros' />
                </div>
                :
                !!args?.editData && args?.hasSearchTerm !== false ?
                    <div className='col-6 flex justify-content-end'>
                        <b>Procurar: </b>
                        <InputText id='searchTerm' name='searchTerm' style={{ fontSize: '0.9rem', height: 35, width: '90%', marginTop: -7 }} value={args?.searchTerm}
                            onChange={(e) => args?.setSearchTerm ? args?.setSearchTerm(e.currentTarget.value) : null}
                            className='p-inputtext-lg' placeholder='Busque por qualquer informação...' />
                    </div>
                    : null}

        </div>
    </div>;
}