/* eslint-disable react-hooks/exhaustive-deps */
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';

export const BlockLoading = (props: { blocked: boolean, fullScreen?: boolean }) => {
	const hasWindow = typeof window !== 'undefined';
	const [blocked, setBlocked] = useState<boolean>(props.blocked);
	const [fullScreen] = useState<boolean>(props.fullScreen || true);
	const getWindowDimensions = () => { return { width: hasWindow ? window.innerWidth : null, height: hasWindow ? window.innerHeight : null }; }

	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


	const handleResize = () => setWindowDimensions(getWindowDimensions());

	useEffect(() => {
		if (hasWindow) {
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, [hasWindow]);

	useEffect(() => {
		setBlocked(props.blocked);
	}, [props.blocked]);

	return (
		<div className="loading-body p-fluid">
			{blocked ?
				<>
					<BlockUI blocked={blocked} fullScreen={fullScreen} />
					<ProgressSpinner style={{
						position: 'absolute', top: 0, minHeight: '100vh', display: 'flex', alignItems: 'center', margin: '0 auto', left: windowDimensions.width ? (windowDimensions.width - 200) / 2 : '50%',
						width: '80px', zIndex: 1200
					}} strokeWidth="4" animationDuration="1s" />
					{/* <ProgressSpinner style={{
						position: 'absolute', minHeight: '100vh', display: 'flex', alignItems: 'center', margin: '0 auto', left: windowDimensions.width ? (windowDimensions.width - 100) / 2 : '53%',
						width: '40px', zIndex: 1200
					}} strokeWidth="2" animationDuration="0.5s" /> */}
				</>
				:
				null
			}

		</div>
	)
}