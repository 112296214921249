import ProjectDefault, { ProjectInterface } from ".";
import methods from "../utils/js";

export const defineLink = (projectStorageId: string) => {
    if (!projectStorageId) return;
    const project = ProjectDefault.projects.find(p => p.id === projectStorageId);
    if (!project) return;
    methods.changeAttrib<HTMLLinkElement>('id', 'href', 'project-icon', `${ProjectDefault.publicUrl}/${project.config.id}/favicon.ico`);
    methods.changeAttrib<HTMLLinkElement>('id', 'href', project.config.themeCssWrapper.id, `${ProjectDefault.publicUrl}/${project.config.id}${replaceKeys(project.config, project.config.themeCssWrapper.href)}`);
    methods.changeAttrib<HTMLLinkElement>('id', 'href', project.config.layoutCssWrapper.id, `${ProjectDefault.publicUrl}/${project.config.id}${replaceKeys(project.config, project.config.layoutCssWrapper.href)}`);
}

export const defineMeta = (projectStorageId: string) => {
    if (!projectStorageId) return;
    const project = ProjectDefault.projects.find(p => p.id === projectStorageId);
    if (!project) return;
    methods.changeAttrib<HTMLMetaElement>('id', 'content', 'project-theme-color', `${project.config.themeColor}`);
    methods.changeAttrib<HTMLMetaElement>('id', 'content', 'project-description', `${replaceKeys(project.config, project.config.description)}`);
}

export const defineTitle = (projectStorageId: string) => {
    if (!projectStorageId) return;
    const project = ProjectDefault.projects.find(p => p.id === projectStorageId);
    // console.log('project: ', project);
    if (!project) return;
    methods.changeAttrib<HTMLTitleElement>('id', 'text', 'project-title', `${project.config.name || projectStorageId} - ${replaceKeys(project.config, project.config.description)}`);
}

export const defineProject = (projectStorageId: string) => {
    if (!projectStorageId) return;
    defineTitle(projectStorageId);
    defineLink(projectStorageId);
    defineMeta(projectStorageId);
}

export const replaceKeys = (_config: ProjectInterface, value: string): string => {
    try {
        const pos1 = value.indexOf('##');
        if (pos1 >= 0) {
            const pos2 = value.indexOf('##', pos1 + 2);
            if (pos2 >= 0) {
                // eslint-disable-next-line no-eval
                const newValueReplaced: string = eval(value.substring(pos1 + 2, pos2));
                const valueReplaced = value.replace(value.substring(pos1, pos2 + 2), newValueReplaced);
                if (valueReplaced.indexOf('##')) return replaceKeys(_config, valueReplaced);
                return valueReplaced;
            }
        }
    } catch (error) {
        console.error('methods.replaceKeys: ', error);
    }
    return value;
}
