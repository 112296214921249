import { FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent } from 'primereact/calendar';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Password } from 'primereact/password';
import { SelectButton } from 'primereact/selectbutton';
import { ToggleButton, ToggleButtonChangeEvent } from 'primereact/togglebutton';
import { classNames } from 'primereact/utils';
import { makeKeyByString } from '../../../utils/functions/hash';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import { IAction, ICalendarField, IColorField, IColorTagShowField, IDropdownField, IGroupField, IInputMaskField, IInputNumberField, IInputTextAreaField, IInputTextField, IInputTextHourField, IInstructionField, IPasswordField, IPropsFormDialog, IPropsFormListDialog, IPropsStepDialog, ISelectAreaList, ISelectButtonField, ITextField } from '../generics/formInterfaces';
import { FormikInterface } from '../interfaces/formikInterface';
import { ColorPicker } from 'primereact/colorpicker';


export function returnNoSuccessed<T>(props: IPropsFormDialog<T> | IPropsStepDialog<T>, formData: FormikInterface, message?: string, methodToRun?: string) {
    return () => {
        props.setMessage({ severity: 'warn', summary: `${message ? `${message}` : `Falha na alteração de senha!`}`, detail: '', life: 5000 });
        formData.resetForm();
        setTimeout(() => { props.setLoading(false); }, 5000);
    };
}

export function returnSuccessed<T>(props: IPropsFormDialog<T> | IPropsStepDialog<T>, formData: FormikInterface, message?: string, methodToRun?: any) {
    return () => {
        props.setMessage({ severity: 'success', summary: `${message ? `${message}` : `Senha alterada com sucesso!`}}`, detail: '', life: 3000 });
        formData.resetForm();
        setTimeout(() => { props.setLoading(false); if (methodToRun) methodToRun() }, 3000);
    };
}

export function saveSuccessed<T, R>(props: IPropsFormDialog<T> | IPropsStepDialog<T>, formData: FormikInterface, message?: string, methodToRun?: string) {
    return (returned?: IPerformRequestResult<R>) => {
        if (returned && isSuccess(returned.status)) {
            if (props.setStepDialogData) props.setStepDialogData(false);
            if (props.setDialogData) props.setDialogData(false);
            props.setMessage({ severity: 'success', summary: `${message ? `${message}` : 'Registro atualizado com sucesso!'}`, detail: '', life: 10000 });
            props.refreshList();
            props.setLoading(false);
            formData.resetForm();
        } else if (returned && returned.status === HttpStatus.UNAUTHORIZED) {
            if (props.setStepDialogData) props.setStepDialogData(false);
            if (props.setDialogData) props.setDialogData(false);
            props.setMessage({ severity: 'danger', summary: `${message ? `${message}` : 'Você não tem permissão para este acesso!'}`, detail: '', life: 10000 });
            formData.resetForm();
            setTimeout(() => { props.setLoading(false); window.location.href = '/'; }, 10000);
        } else {
            props.setMessage({ severity: 'warn', summary: `${message ? `${message}` : 'Dados incorretos!'}`, detail: '', life: 10000 });
            props.setLoading(false);
        }
    };
}

export function saveFailed<T, E>(props: IPropsFormDialog<T> | IPropsStepDialog<T>, formData: FormikInterface, message?: string) {
    return (error: E) => {
        console.error('error: ', error);
        if (props.setStepDialogData) props.setStepDialogData(false);
        if (props.setDialogData) props.setDialogData(false);
        props.setMessage({ severity: 'error', summary: `${message ? `${message}` : 'Ocorreu um erro desconhecido!'}`, detail: '', life: 5000 });
        formData.resetForm();
        setTimeout(() => { props.setLoading(false); window.location.href = '/'; }, 5000);
    };
}

export function editFormData<T extends {}, R>(props: IPropsFormDialog<T> | IPropsStepDialog<T>,
    formData: FormikInterface,
    replaces: R,
    functions?: Array<(formData: FormikValues) => void>): React.EffectCallback {
    return () => {
        if (props.editFormData) {
            const item = props.editFormData;
            if (functions) functions.forEach(f => f(item));
            let editObject: T = Object.assign<any, T>({}, item);
            editObject = Object.assign<T, R>(editObject, replaces);
            formData.setValues(editObject);
        } else {
            formData.resetForm();
        }
    };
}

export function editStepData<T extends {}, R>(props: IPropsStepDialog<T>,
    stepData: FormikInterface,
    replaces: R,
    functions?: Array<(stepData: FormikValues) => void>): React.EffectCallback {
    return () => {
        if (props.editStepData) {
            const item = props.editStepData;
            if (functions) functions.forEach(f => f(item));
            let editObject: T = Object.assign<any, T>({}, item);
            editObject = Object.assign<T, R>(editObject, replaces);
            stepData.setValues(editObject);
        } else {
            stepData.resetForm();
        }
    };
}

export function executeMethodFromFromList<T>(key: string, formLists: IPropsFormListDialog<T>[], event: DropdownChangeEvent) {
    const list = formLists.find(l => l.key === key);
    if (list?.changeMethod && list?.changeMethod.method)
        list.changeMethod.method(event);
}

export function retrieveListFromFromList<T>(key: string, formLists?: IPropsFormListDialog<T>[]) {
    if (!formLists) return [];
    const list = formLists.find(l => l.key === key);
    return list?.data ? list.data : [];
}

export function createObjectField<T>(field: any, isFormFieldValid: (name: keyof T) => boolean) {
    switch (field.fieldType) {
        case 'Action':
            const action = field as IAction<T>;
            return (typeof action.fieldName === 'string' ?
                <Button label={action.description} type='button' style={{ minWidth: 150 }}
                    onClick={action.onClick}
                    className='p-button-rounded p-button-success shadow-3' icon='pi pi-save' />
                :
                null);
        case 'TextField':
            const textField = field as ITextField<T>;
            return (typeof textField.fieldName === 'string' ?
                <span>
                    <label htmlFor={textField.fieldName} className={classNames({ 'p-error': isFormFieldValid(textField.fieldName) })}>{textField.description}</label>
                    <InputText id={textField.fieldName} name={textField.fieldName} value={textField.value}
                        className={classNames({ 'p-invalid': isFormFieldValid(textField.fieldName) })}
                        disabled={true} />
                </span>
                :
                null);
        case 'ColorField':
            const colorField = field as IColorField<T>;
            return (typeof colorField.fieldName === 'string' ?
                <span className='flex flex-column' style={{ padding: '1rem' }}>
                    <label htmlFor={colorField.fieldName} className={classNames({ 'p-error': isFormFieldValid(colorField.fieldName) })}>{colorField.description}</label>
                    <ColorPicker id={colorField.fieldName} format="hex" name={colorField.fieldName} value={colorField.value} onChange={colorField.onChange}
                        defaultColor={colorField.defaultColor}
                        disabled={colorField.disabled || false}
                    />
                </span>
                :
                null);
        case 'ColorTagShowField':
            const colorTagShowField = field as IColorTagShowField<T>;
            return (typeof colorTagShowField.fieldName === 'string' ?
                <div className='flex align-items-center justify-content-around' style={{ width: '100%', height: '100%', color: `#${colorTagShowField.value || colorTagShowField.defaultColor}`, backgroundColor: colorTagShowField.backgroundColor || '#FFF', border: '2px solid black' }}>
                    <div className='flex flex-column align-items-center justify-content-center'>
                        <span className="pi pi-bookmark" style={{ fontSize: '2rem', color: `${colorTagShowField.value}`, textShadow: `1px 0 ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, -1px 0 ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 0 1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 0 -1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 1px 1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, -1px -1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 1px -1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, -1px 1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}` }}></span>
                        <span style={{ marginTop: 10, fontSize: '1rem', color: `#000` }}>Etiqueta disponível</span>
                    </div>
                    <div className='flex flex-column align-items-center justify-content-center'>
                        <span className="pi pi-bookmark-fill" style={{ fontSize: '2rem', color: `${colorTagShowField.value}`, textShadow: `1px 0 ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, -1px 0 ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 0 1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 0 -1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 1px 1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, -1px -1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, 1px -1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}, -1px 1px ${colorTagShowField.borderColor || 'rgba(255, 255, 255, 0.2666666667)'}` }}></span>
                        <span style={{ marginTop: 10, fontSize: '1rem', color: `#000` }}>Etiqueta vinculada</span>
                    </div>
                </div>
                :
                null);
        case 'InputTextField':
            const inputTextField = field as IInputTextField<T>;
            return (typeof inputTextField.fieldName === 'string' ?
                <span>
                    <label htmlFor={inputTextField.fieldName} className={classNames({ 'p-error': isFormFieldValid(inputTextField.fieldName) })}>{inputTextField.description}</label>
                    <InputText id={inputTextField.fieldName} name={inputTextField.fieldName} value={inputTextField.value} onChange={inputTextField.onChange} autoFocus={inputTextField.autoFocus}
                        className={classNames({ 'p-invalid': isFormFieldValid(inputTextField.fieldName), 'field-disabeld': inputTextField.disabled })}
                        placeholder={inputTextField.placeholder}
                        onBlur={inputTextField.onBlur}
                        disabled={inputTextField.disabled || false}
                    // onBlur={inputTextField.onBlur}
                    />
                </span>
                :
                null);
        case 'InputTextAreaField':
            const inputTextAreaField = field as IInputTextAreaField<T>;
            return (typeof inputTextAreaField.fieldName === 'string' ?
                <span>
                    <label htmlFor={inputTextAreaField.fieldName} className={classNames({ 'p-error': isFormFieldValid(inputTextAreaField.fieldName) })}>{inputTextAreaField.description}</label>
                    <InputTextarea id={inputTextAreaField.fieldName} name={inputTextAreaField.fieldName} value={inputTextAreaField.value} onChange={inputTextAreaField.onChange} autoFocus={inputTextAreaField.autoFocus}
                        className={classNames({ 'p-invalid': isFormFieldValid(inputTextAreaField.fieldName) })} rows={inputTextAreaField.rows} cols={inputTextAreaField.cols} />
                </span>
                :
                null);
        case 'InputNumberField':
            const inputNumberField = field as IInputNumberField<T>;
            return (typeof inputNumberField.fieldName === 'string' ?
                <span>
                    <label htmlFor={inputNumberField.fieldName} className={classNames({ 'p-error': isFormFieldValid(inputNumberField.fieldName) })}>{inputNumberField.description}</label>
                    <InputNumber id={inputNumberField.fieldName} name={inputNumberField.fieldName} value={inputNumberField.value}
                        onValueChange={inputNumberField.onValueChange} autoFocus={inputNumberField.autoFocus}
                        mode={inputNumberField.mode} locale={inputNumberField.locale} minFractionDigits={inputNumberField.minFractionDigits}
                        min={inputNumberField.min} max={inputNumberField.max} showButtons={inputNumberField.showButtons}
                        className={classNames({ 'p-invalid': isFormFieldValid(inputNumberField.fieldName) })}
                        placeholder={inputNumberField.placeholder} />
                </span>
                :
                null);
        case 'InputTextHourField':
            const inputTextHourField = field as IInputTextHourField<T>;
            return (
                typeof inputTextHourField.fieldName === 'string' ?
                    <span>
                        <label htmlFor={inputTextHourField.fieldName} className={classNames({ 'p-error': isFormFieldValid(inputTextHourField.fieldName) })}>{inputTextHourField.description}</label>
                        <InputText id={inputTextHourField.fieldName} name={inputTextHourField.fieldName} value={inputTextHourField.value}
                            onChange={inputTextHourField.onChange} autoFocus={inputTextHourField.autoFocus}
                            type='time' min={inputTextHourField.min} max={inputTextHourField.max} step={inputTextHourField.step}
                            className={classNames({ 'p-invalid': isFormFieldValid(inputTextHourField.fieldName) })}
                            placeholder={inputTextHourField.placeholder} />
                    </span>
                    :
                    null);
        case 'InputMaskField':
            const inputMaskField = field as IInputMaskField<T>;
            return (typeof inputMaskField.fieldName === 'string' ?
                <span>
                    <label htmlFor={inputMaskField.fieldName} className={classNames({ 'p-error': isFormFieldValid(inputMaskField.fieldName) })}>{inputMaskField.description}</label>
                    <InputMask id={inputMaskField.fieldName} name={inputMaskField.fieldName} value={inputMaskField.value} onChange={inputMaskField.onChange}
                        className={classNames({ 'p-invalid': isFormFieldValid(inputMaskField.fieldName) })}
                        mask={inputMaskField.mask}
                        style={inputMaskField.style}
                        placeholder={inputMaskField.placeholder}
                        disabled={inputMaskField.disabled || false} />
                </span>
                :
                null);
        case 'GroupField':
            const groupField = field as IGroupField<T>;
            return (<div id={`${groupField}-${makeKeyByString(groupField.description)}`} style={{ fontWeight: 'bold', borderBottom: '1px solid #000' }}>{groupField.description}</div>);
        case 'IInstructionField':
            const instructionField = field as IInstructionField<T>;
            // console.log('instructionField.description: ', instructionField.value);
            return (<div id={`${instructionField.fieldType}-${makeKeyByString(instructionField.description)}`}>
                <div style={{ color: 'CaptionText', borderBottom: '1px solid #000', marginBottom: 20 }}>
                    {instructionField.description}
                </div>
                <div style={{ margin: 10 }}>
                    <pre style={{ marginLeft: 50, whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontFamily: 'Roboto, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif', fontSize: '14px' }}>{instructionField.value}</pre>
                </div>
            </div>
            );
        case 'CalendarField':
            const calendarField = field as ICalendarField<T>;
            return (typeof calendarField.fieldName === 'string' ?
                <span>
                    <label htmlFor={calendarField.fieldName} className={classNames({ 'p-error': isFormFieldValid(calendarField.fieldName) })}>{calendarField.description}</label>
                    <Calendar id={calendarField.fieldName} name={calendarField.fieldName} value={calendarField.value}
                        onChange={(e: CalendarChangeEvent) => { calendarField.onChange(e); calendarField.onHide(); }}
                        dateFormat={calendarField.dateFormat} showIcon={calendarField.showIcon}
                        locale={calendarField.locale} showTime={calendarField.showTime} stepMinute={calendarField.stepMinute}
                        className={classNames({ 'p-invalid': isFormFieldValid(calendarField.fieldName) })}
                        onShow={calendarField.onShow} onHide={calendarField.onHide} touchUI={calendarField.touchUI || false} />
                </span>
                :
                null);
        case 'DropdownField':
            const dropdownField = field as IDropdownField<T>;
            return (typeof dropdownField.fieldName === 'string' ?
                <span>
                    <label htmlFor={dropdownField.fieldName} className={classNames({ 'p-error': isFormFieldValid(dropdownField.fieldName) })}>{dropdownField.description}</label>
                    <Dropdown id={dropdownField.fieldName} name={dropdownField.fieldName} value={dropdownField.value} onChange={dropdownField.onChange} autoFocus={dropdownField.autoFocus}
                        className={classNames({ 'p-invalid': isFormFieldValid(dropdownField.fieldName) })}
                        placeholder={dropdownField.placeholder}
                        options={dropdownField.options} optionLabel={dropdownField.optionLabel}
                        filter={dropdownField.filter} showClear={dropdownField.showClear} filterBy={dropdownField.filterBy}
                        disabled={dropdownField.disabled} />
                </span>
                :
                null);
        case 'PasswordField':
            const passwordField = field as IPasswordField<T>;
            return (typeof passwordField.fieldName === 'string' ?
                <span>
                    <label htmlFor={passwordField.fieldName} className={classNames({ 'p-error': isFormFieldValid(passwordField.fieldName) })}>{passwordField.description}</label>
                    <Password id={passwordField.fieldName} name={passwordField.fieldName} value={passwordField.value} onChange={passwordField.onChange} autoFocus={passwordField.autoFocus}
                        className={classNames({ 'p-invalid': isFormFieldValid(passwordField.fieldName) })}
                        autoComplete={passwordField.autoComplete} toggleMask={passwordField.toggleMask}
                        placeholder={passwordField.placeholder} feedback={passwordField.feedback !== false} />
                </span>
                :
                null);
        case 'SelectButtonField':
            const selectButtonField = field as ISelectButtonField<T>;
            return (typeof selectButtonField.fieldName === 'string' ?
                <span>
                    <label htmlFor={selectButtonField.fieldName} className={classNames({ 'p-error': isFormFieldValid(selectButtonField.fieldName) })}>{selectButtonField.description}</label>
                    <SelectButton id={selectButtonField.fieldName} value={selectButtonField.value} onChange={selectButtonField.onChange}
                        className={classNames({ 'p-invalid': isFormFieldValid(selectButtonField.fieldName) })}
                        options={selectButtonField.options} optionLabel={selectButtonField.optionLabel} optionValue={selectButtonField.optionValue}
                        disabled={selectButtonField.disabled} />
                </span>
                :
                null);
        case 'SelectAreaList':
            const selectAreaList = field as ISelectAreaList<T>;
            const selectAreaListOptions = selectAreaList.objectDataList?.map((o: any) => {
                return <div className={selectAreaList.colspanItem || 'col-4'} key={`selectAreaListOptions_${o[selectAreaList.idField]}`}>
                    <ToggleButton id={o[selectAreaList.idField]} checked={o[selectAreaList.selectField]}
                        onLabel={o[selectAreaList.nameField]} offLabel={o[selectAreaList.nameField]} onIcon="pi pi-check-square" offIcon="pi pi-stop"
                        tooltip={o[selectAreaList.nameField]} onChange={(e: ToggleButtonChangeEvent) => {
                            // o[selectAreaList.selectField] = o.value;
                            selectAreaList.onChangeListOption(o[selectAreaList.idField], e.value);
                        }} />
                </div>
            });

            return (typeof selectAreaList.fieldName === 'string' ?
                <span>
                    <label htmlFor={selectAreaList.fieldName} className={classNames({ 'p-error': isFormFieldValid(selectAreaList.fieldName) })}>{selectAreaList.description}</label>
                    <div className='card grid' style={{ marginTop: 20, backgroundColor: 'rgba(17, 140, 172, 0.135)' }}>
                        {selectAreaListOptions}
                    </div>
                </span>
                :
                null);
        default:
            break;
    }
}
