import moment, { Moment } from 'moment';


export const getServerSocketDateTime = (): Moment => {
    const sServerSocketDateTime = localStorage.getItem('server.socket.date.time');
    return moment(sServerSocketDateTime || moment());
};

export const setServerSocketDateTime = (dateTime: string) => {
    localStorage.setItem('server.socket.date.time', moment(dateTime).toISOString());
};
