
// httpStatus List
export default class HttpStatus {
  private constructor(public readonly code: number, public readonly description: string) {
    this.code = code;
    this.description = description;
  }

  static readonly ACCEPTED = new HttpStatus(202, 'Accepted');
  static readonly BAD_GATEWAY = new HttpStatus(502, 'Bad Gateway');
  static readonly BAD_REQUEST = new HttpStatus(400, 'Bad Request');
  static readonly CONFLICT = new HttpStatus(409, 'Conflict');
  static readonly CONTINUE = new HttpStatus(100, 'Continue');
  static readonly CREATED = new HttpStatus(201, 'Created');
  static readonly EXPECTATION_FAILED = new HttpStatus(417, 'Expectation Failed');
  static readonly FAILED_DEPENDENCY = new HttpStatus(424, 'Failed Dependency');
  static readonly FORBIDDEN = new HttpStatus(403, 'Forbidden');
  static readonly GATEWAY_TIMEOUT = new HttpStatus(504, 'Gateway Timeout');
  static readonly GONE = new HttpStatus(410, 'Gone');
  static readonly HTTP_VERSION_NOT_SUPPORTED = new HttpStatus(505, 'HTTP Version Not Supported');
  static readonly IM_A_TEAPOT = new HttpStatus(418, 'I\'m a teapot');
  static readonly INSUFFICIENT_SPACE_ON_RESOURCE = new HttpStatus(419, 'Insufficient Space on Resource');
  static readonly INSUFFICIENT_STORAGE = new HttpStatus(507, 'Insufficient Storage');
  static readonly INTERNAL_SERVER_ERROR = new HttpStatus(500, 'Server Error');
  static readonly LENGTH_REQUIRED = new HttpStatus(411, 'Length Required');
  static readonly LOCKED = new HttpStatus(423, 'Locked');
  static readonly METHOD_FAILURE = new HttpStatus(420, 'Method Failure');
  static readonly METHOD_NOT_ALLOWED = new HttpStatus(405, 'Method Not Allowed');
  static readonly MOVED_PERMANENTLY = new HttpStatus(301, 'Moved Permanently');
  static readonly MOVED_TEMPORARILY = new HttpStatus(302, 'Moved Temporarily');
  static readonly MULTI_STATUS = new HttpStatus(207, 'Multi-Status');
  static readonly MULTIPLE_CHOICES = new HttpStatus(300, 'Multiple Choices');
  static readonly NETWORK_AUTHENTICATION_REQUIRED = new HttpStatus(511, 'Network Authentication Required');
  static readonly NO_CONTENT = new HttpStatus(204, 'No Content');
  static readonly NON_AUTHORITATIVE_INFORMATION = new HttpStatus(203, 'Non Authoritative Information');
  static readonly NOT_ACCEPTABLE = new HttpStatus(406, 'Not Acceptable');
  static readonly NOT_FOUND = new HttpStatus(404, 'Not Found');
  static readonly NOT_IMPLEMENTED = new HttpStatus(501, 'Not Implemented');
  static readonly NOT_MODIFIED = new HttpStatus(304, 'Not Modified');
  static readonly OK = new HttpStatus(200, 'OK');
  static readonly PARTIAL_CONTENT = new HttpStatus(206, 'Partial Content');
  static readonly PAYMENT_REQUIRED = new HttpStatus(402, 'Payment Required');
  static readonly PERMANENT_REDIRECT = new HttpStatus(308, 'Permanent Redirect');
  static readonly PRECONDITION_FAILED = new HttpStatus(412, 'Precondition Failed');
  static readonly PRECONDITION_REQUIRED = new HttpStatus(428, 'Precondition Required');
  static readonly PROCESSING = new HttpStatus(102, 'Processing');
  static readonly PROXY_AUTHENTICATION_REQUIRED = new HttpStatus(407, 'Proxy Authentication Required');
  static readonly REQUEST_HEADER_FIELDS_TOO_LARGE = new HttpStatus(431, 'Request Header Fields Too Large');
  static readonly REQUEST_TIMEOUT = new HttpStatus(408, 'Request Timeout');
  static readonly REQUEST_TOO_LONG = new HttpStatus(413, 'Request Entity Too Large');
  static readonly REQUEST_URI_TOO_LONG = new HttpStatus(414, 'Request-URI Too Long');
  static readonly REQUESTED_RANGE_NOT_SATISFIABLE = new HttpStatus(416, 'Requested Range Not Satisfiable');
  static readonly RESET_CONTENT = new HttpStatus(205, 'Reset Content');
  static readonly SEE_OTHER = new HttpStatus(303, 'See Other');
  static readonly SERVICE_UNAVAILABLE = new HttpStatus(503, 'Service Unavailable');
  static readonly SWITCHING_PROTOCOLS = new HttpStatus(101, 'Switching Protocols');
  static readonly TEMPORARY_REDIRECT = new HttpStatus(307, 'Temporary Redirect');
  static readonly TOO_MANY_REQUESTS = new HttpStatus(429, 'Too Many Requests');
  static readonly UNAUTHORIZED = new HttpStatus(401, 'Unauthorized');
  static readonly UNPROCESSABLE_ENTITY = new HttpStatus(422, 'Unprocessable Entity');
  static readonly UNSUPPORTED_MEDIA_TYPE = new HttpStatus(415, 'Unsupported Media Type');
  static readonly USE_PROXY = new HttpStatus(305, 'Use Proxy');
};

export function isSuccess(httpStatus: HttpStatus): boolean {
  return [HttpStatus.ACCEPTED, HttpStatus.CREATED, HttpStatus.OK].indexOf(httpStatus) >= 0;
}

export function httpStatusByStatusCode(statusCode: number): HttpStatus {
  for (const itemKey in HttpStatus) {
    if (Object.prototype.hasOwnProperty.call(HttpStatus, itemKey)) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      const anyHttpStatus = <any>HttpStatus;
      const element = anyHttpStatus[itemKey];
      if (element && element.code === statusCode) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return <HttpStatus>element;
      }
    }
  }
  return HttpStatus.INTERNAL_SERVER_ERROR;
}
