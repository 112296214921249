/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import moment from 'moment';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar, CalendarChangeEvent, CalendarProps } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { RoleRules, checVigilanceByToken, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import { limitCharacters } from '../../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import TagService from '../../service/admin/TagService';
import TrackerContactMessageService from '../../service/admin/TrackerContactMessageService';
import TrackerMessageService from '../../service/admin/TrackerMessageService';
import { IMessage } from '../Control';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataAttendanceUpdateData, IFormDataTag } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { FormikInterface } from '../interfaces/formikInterface';
import './MonitoringAttendance.css';
import { MonitoringAttendanceMessage } from './MonitoringAttendanceMessage';
// import { RecorderAudio } from './RecorderAudio';

enum StatusContactMessage {
    newer = 'newer',
    inProgress = 'inProgress',
    available = 'available',
    blocked = 'blocked',
    finished = 'finished',
    unknown = 'unknown',
}

const trackerContactMessageService = new TrackerContactMessageService();
const trackerMessageService = new TrackerMessageService();
const tagService = new TagService();

const timeRefreshContactList = 15000;

const marginDaysFilter = 90;

const limitContacts = 400;
const limitContactsMessage = 400;
const limitContactSearch = 5050;


export const MonitoringAttendance = (props: IPropsArgsComponents) => {
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [isVigilance] = useState<boolean>(!checVigilanceByToken());

    const [contactSelected, setContactSelected] = useState<any>();

    const [countContacts, setCountContacts] = useState<number>();

    const [trackerContactMessages, setTrackerContactMessages] = useState<any[]>();
    const [distinctTrackerContactMessages, setDistinctTrackerContactMessages] = useState<string[]>();

    const [dialogData, setDialogData] = useState(false);
    const [editFormData, setEditFormData] = useState<any | null>();
    // const [editFormCreateData, setEditFormCreateData] = useState<any | null>();
    // const [editScheduleData, setEditScheduleData] = useState<any | null>();

    const [customerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());

    const [token] = useState(props.args.token);

    const [branchGymsByStorageTokens, setBranchGymsByStorageTokens] = useState<any[] | null>();

    const [refreshKey, setRefreshKey] = useState(0);


    const [mobileMenuActive, setMobileMenuActive] = useState<boolean>(props.args.mobileMenuActive);
    const [rightSideOpen, setRightSideOpen] = useState<boolean>(true);

    const [filterPristine, setFilterPristine] = useState<boolean>(true);
    const [optionsFilters, setOptionsFilters] = useState<boolean>(false);
    const [selectActiveStatus, setSelectActiveStatus] = useState<boolean>(true);
    const [activeStatus, setActiveStatus] = useState<boolean | undefined>(true);
    const [selectEndStatus, setSelectEndStatus] = useState<boolean>(true);
    const [endStatus, setEndStatus] = useState<boolean | undefined>(false);
    const [selectMyContacts, setSelectMyContacts] = useState<boolean>(false);
    const [myContacts, setMyContacts] = useState<boolean>(false);

    const [messageChangeDate, setMessageChangeDate] = useState<string>('');
    const [messageChangeStatus, setMessageChangeStatus] = useState<string>('');

    const [startDateMessages, setStartDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
    const [selectStartDateMessages, setSelectStartDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
    const [endDateMessages, setEndDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().toDate());
    const [selectEndDateMessages, setSelectEndDateMessages] = useState<Date>(ls.socket.getServerSocketDateTime().toDate());

    const [tagData, setTagsData] = useState<IFormDataTag[]>();
    const [tagFiltersData, setTagFiltersData] = useState<IFormDataTag[]>();
    const [tagContactsData, setTagContactsData] = useState<IFormDataTag[]>();


    const [listMessagesOption, setListMessagesOption] = useState<'recebidas' | 'enviadas' | 'todas'>('todas');
    const listMessagesOptions = ['todas', 'recebidas', 'enviadas'];
    const [filterContactMessage, setFilterContactMessage] = useState<string>('');
    const [filterTextMessage, setFilterTextMessage] = useState<string>('');

    useEffect(() => { props.args.setLoading(true); }, []);
    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => setMobileMenuActive(props.args.mobileMenuActive), [props.args.mobileMenuActive]);

    useEffect(() => { if (!props?.args?.networkGymId) props.args.setLoading(false); setNetworkGymId(props.args.networkGymId); }, [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    useEffect(() => { refreshDistinctContactByMessages(); }, [networkGymId, refreshKey, selectActiveStatus, selectEndStatus, selectStartDateMessages, selectEndDateMessages]);

    useEffect(() => { refreshListTrackerContactMessage(true, false); }, []);
    useEffect(() => { refreshListTrackerContactMessage(true, false); }, [distinctTrackerContactMessages]);
    useEffect(() => { refreshListTrackerContactMessage(false); }, [refreshKey]);

    useEffect(() => { refreshListTags(); }, [networkGymId, branchGymId]);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: true },
    ]), []);

    useEffect(() => {
        ls.branchGym.getBranchGymsByStorageToken().then((branchGymsByStorageToken) => {
            if (branchGymsByStorageToken) setBranchGymsByStorageTokens(branchGymsByStorageToken);
        });
    }, []);


    // useEffect(() => { refreshContactsDetails(trackerContactMessages); }, [trackerContactMessages, networkGymData, branchGymData]);
    useEffect(() => {
        let contactMessageToSelect: any;
        if (contactSelected) {
            contactMessageToSelect = trackerContactMessages?.find((d: any) => d._id === contactSelected._id);
        }
        if (!contactMessageToSelect) {
            setContactSelected(undefined);
        } else {
            setContactSelected(contactMessageToSelect);
        }
    }, [trackerContactMessages]);

    const refreshDistinctContactByMessages = async () => {
        if (networkGymId) {
            let isMounted = true;

            // console.log('selectStartDateMessages: ', selectStartDateMessages, moment(selectStartDateMessages).set('hours', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate());
            // console.log('selectEndDateMessages: ', selectEndDateMessages, moment(selectEndDateMessages).add(1, 'days').set('hours', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate());

            const returned = await trackerMessageService.findDistinctByNetworkGym('jid', {
                queries: [{
                    value: {
                        networkGym: networkGymId,
                        dateText: {
                            $gte: moment(selectStartDateMessages).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate(),
                            $lt: moment(selectEndDateMessages).add(1, 'days').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0).toDate()
                        }
                    },
                }]
            });

            if (returned) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                    setDistinctTrackerContactMessages([]);
                    setTrackerContactMessages([]);
                    setContactSelected(undefined);
                } else if (isSuccess(returned.status)) {
                    if (isMounted) {
                        setDistinctTrackerContactMessages(returned.data);
                    }
                } else {
                    setDistinctTrackerContactMessages([]);
                }
            } else {
                setDistinctTrackerContactMessages([]);
                setTrackerContactMessages([]);
                setContactSelected(undefined);
            }
        }
    }


    const refreshListTags = () => {
        let isMounted = true;
        if (networkGymId) {
            tagService.allTags(networkGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { window.location.href = '/'; }, 5000);
                        setTagsData([]);
                        setTagFiltersData([]);
                        setTagContactsData([]);
                        // refreshTag();
                    } else if (isSuccess(returned.status)) {
                        let returnedTag = returned.data.sort((d1: IFormDataTag, d2: IFormDataTag) => d1.tag < d2.tag ? -1 : d1.tag > d2.tag ? 1 : 0);
                        setTagsData(returnedTag.map((r: IFormDataTag) => Object.assign({}, r)));
                        setTagFiltersData(returnedTag.map((r: IFormDataTag) => Object.assign({}, r)));
                        setTagContactsData(returnedTag.map((r: IFormDataTag) => Object.assign({}, r)));
                        // refreshTag();
                    } else {
                        setTagsData([]);
                        setTagFiltersData([]);
                        setTagContactsData([]);
                        // refreshTag();
                    }
                }
            }).catch((error: any) => {
                console.error('error', error);
                if (isMounted) {
                    setTagsData([]);
                    setTagFiltersData([]);
                    setTagContactsData([]);
                    // refreshTag();
                }
            });
        } else {
            setTagsData([]);
            setTagFiltersData([]);
            setTagContactsData([]);
            // refreshTag();
            // setTimeout(() => { refreshListTags(); }, 1000);
        }
        return () => { isMounted = false };
    };

    const setSelectTagContact = (tagId: string) => {
        // console.log('setSelectTagContact: ', tagId);
        if (tagContactsData) {
            const tag = tagContactsData.find(t => t._id === tagId);
            if (tag) {
                tag.selected = !tag?.selected;
                trackerContactMessageService.saveTrackerContactMessage({ _id: contactSelected._id, tags: tagContactsData.filter(t => t.selected).map(t => t._id).join() })
            }
            setTagContactsData([...tagContactsData]);
        }
    }

    const setSelectTagFiltered = (tagId: string) => {
        // console.log('setSelectTagContact: ', tagId);
        if (tagFiltersData) {
            const tag = tagFiltersData.find(t => t._id === tagId);
            if (tag) {
                tag.selected = !tag?.selected;
            }
            setTagFiltersData([...tagFiltersData]);
        }
    }

    const defaultFilters = () => {
        setFilterPristine(true);
        setContactSelected(undefined);
        setMessageChangeDate('');
        setMessageChangeStatus('');
        setOptionsFilters(false);
        setFilterContactMessage('');
        setListMessagesOption('todas');
        setFilterTextMessage('');


        setTagFiltersData((tags) => tags ? tags.map(t => {
            t.selected = false;
            return t;
        }) : undefined);

        setSelectActiveStatus(true);
        setActiveStatus(true);
        setSelectEndStatus(true);
        setEndStatus(true);
        setSelectMyContacts(false);
        setMyContacts(false);

        setSelectStartDateMessages(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
        setStartDateMessages(ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate());
        setSelectEndDateMessages(ls.socket.getServerSocketDateTime().toDate());
        setEndDateMessages(ls.socket.getServerSocketDateTime().toDate());
    }

    const updateListContact = async (newDataList: any[], includeNewer: boolean) => {
        let isMounted = true;
        if (isMounted) {
            if (includeNewer) {
                setCountContacts(newDataList?.length || 0);
                setTrackerContactMessages([...newDataList]);
            } else {
                setCountContacts(newDataList?.length || 0);
                setTrackerContactMessages(newDataList);
            }
        }
        return () => { isMounted = false };
    }

    const refreshListTrackerContactMessage = async (unloadingScreen?: boolean, refreshTimeout: boolean = true) => {
        // console.log('distinctTrackerContactMessages: ', distinctTrackerContactMessages);
        if (networkGymId) {
            let isMounted = true;

            const queries = [];
            let queryOrs: any = [];

            if (selectActiveStatus) {
                queryOrs.push({
                    finished: false,
                    jid: { $in: distinctTrackerContactMessages || [] }
                });
            }

            if (selectEndStatus) {
                queryOrs.push({
                    finished: true,
                    jid: { $in: distinctTrackerContactMessages || [] }
                });
            }

            queries.push({
                field: '$and',
                value: [
                    {
                        '$or': [
                            { branchGym: { $in: (branchGymsByStorageTokens || (await ls.branchGym.getBranchGymsByStorageToken()))?.map(b => b._id) } },
                            { branchGym: { $exists: false } },
                        ]
                    },
                    {
                        '$or': queryOrs
                    },
                ]
            });

            // let projections: any[] = [];
            let projections = [
                // { field: 'key' },
                { field: 'networkGym', detail: [{ field: '_id' }] },
                { field: 'branchGym', detail: [{ field: '_id' }, { field: 'name' }] },
                { field: 'trackerMobile', detail: [{ field: '_id' }] },
                { field: 'assumedBy', detail: [{ field: '_id' }, { field: 'nickname' }] },
                { field: 'lastAssumedBy', detail: [{ field: '_id' }] },
                // { field: 'closedBy' },
                // { field: 'lastClosedBy' },
                // { field: 'lastFromMessage' },
                // { field: 'typeContactMessage' },
                // { field: 'typeInitiatedContact' },
                // { field: 'enumerator' },
                { field: 'unreadMessages' },
                { field: 'dataExtra' },
                { field: 'jid' },
                { field: 'pushName' },
                { field: 'group' },
                // { field: 'guide' },
                // { field: 'guideStep' },
                // { field: 'npsRatings' },
                // { field: 'npsRatingComments' },
                // { field: 'stateContactMessage' },
                { field: 'tags' },
                { field: 'available' },
                { field: 'new' },
                { field: 'inProgress' },
                { field: 'finished' },
                { field: 'dateCreated' },
                { field: 'lastChange' },
                { field: 'isValidWhatsapp' },
                // { field: 'activelyInitiatedContact' },
                // { field: 'deleted' },
                { field: 'active' },
                { field: 'processFindbranchGymOptions' }
            ];

            // console.log('START: ', ls.socket.getServerSocketDateTime().toISOString(), networkGymId, branchGymId, { queries });
            trackerContactMessageService.findTrackerContactMessageByNetworkGymId(networkGymId, branchGymId, { queries, projections }, limitContactSearch).then((returned: any) => {
                if (isMounted) {
                    if (returned) {
                        if (returned.status === HttpStatus.UNAUTHORIZED) {
                            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                            if (refreshTimeout) setTimeout(() => {
                                window.location.href = '/';
                            }, 5000);
                            setTrackerContactMessages([]);
                            setContactSelected(undefined);
                        } else if (isSuccess(returned.status)) {
                            if (isMounted) {
                                updateListContact(returned.data, true);
                            }
                            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                        } else {
                            setTrackerContactMessages([]);
                            setContactSelected(undefined);
                            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                        }
                    } else {
                        setTrackerContactMessages([]);
                        setContactSelected(undefined);
                        setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                    }
                }
                if (unloadingScreen) prepareLoadingScreen(false);
            }).catch(error => {
                console.error('error', error);
                // if (isMounted) { }
                if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
                if (unloadingScreen) prepareLoadingScreen(false);
            });
            // }
            return () => { isMounted = false };
        } else {
            if (refreshTimeout) setTimeout(() => { setRefreshKey(oldKey => oldKey + 1); }, timeRefreshContactList);
        }
    };

    const prepareLoadingScreen = ((loadingState: boolean) => {
        props.args.setLoading(loadingState)
    });



    const setContact = (selectContact: any) => {
        // prepareLoadingScreen(true);
        if (!contactSelected || (contactSelected && selectContact && selectContact.jid !== contactSelected.jid)) {
            setContactSelected(selectContact);
            setTagContactsData((tags) => tags ? tags.map(t => {
                t.selected = selectContact.tags?.indexOf(t._id) >= 0;
                return t;
            }) : undefined);
        } else {
            setContactSelected(undefined);
            setTagContactsData((tags) => tags ? tags.map(t => {
                t.selected = false;
                return t;
            }) : undefined);
        }
        // prepareLoadingScreen(false);
    }


    const defineContactData = async () => {
        let mobilePhone = contactSelected.jid;
        if (mobilePhone.indexOf('@') > 0) mobilePhone = mobilePhone.substring(0, mobilePhone.indexOf('@'));
        else mobilePhone = '';

        setEditFormData({
            _id: contactSelected._id,
            pushName: contactSelected.pushName,
            mobilePhone: mobilePhone,
            dataExtra: contactSelected.dataExtra,
            active: true,
            deleted: false
        });
        setDialogData(true);
    }

    const checkTagContactMessage = (contactMessage: any, index: number, lastFromMessage?: boolean): StatusContactMessage => {
        if (contactMessage && contactMessage.inProgress && !contactMessage.finished
            && contactMessage.assumedBy?._id !== customerId
            && (lastFromMessage === undefined || (lastFromMessage === true
                || (lastFromMessage === false
                    && (!moment(contactMessage.lastChange).isBefore(ls.socket.getServerSocketDateTime().subtract(contactMessage.branchGym?.minutesTimeoutAttendant || 15, 'minutes')))
                )))) {
            return StatusContactMessage.blocked;
        } else if (contactMessage && contactMessage.inProgress && !contactMessage.finished && contactMessage.assumedBy?._id !== customerId) {
            return StatusContactMessage.available;
        }
        if (contactMessage && contactMessage.inProgress && !contactMessage.finished && contactMessage.assumedBy?._id === customerId) {
            return StatusContactMessage.inProgress;
        }
        if (contactMessage && contactMessage.finished) {
            return StatusContactMessage.finished;
        }
        if (contactMessage && contactMessage.available) {
            return StatusContactMessage.available;
        }
        if (contactMessage && contactMessage.new) {
            return StatusContactMessage.newer;
        }
        return StatusContactMessage.unknown;
    }

    const prepareTagContactMessage = (contactMessage: any, index: number, lastFromMessage?: boolean) => {
        const tagContactMessage: string = checkTagContactMessage(contactMessage, index, lastFromMessage);
        let backgroundColor = '#000';
        let fontColor = '#fff';
        let caracter = '';

        if (tagContactMessage === StatusContactMessage.blocked) {
            backgroundColor = '#000';
            fontColor = '#fff';
            caracter = 'A';
        } else if (tagContactMessage === StatusContactMessage.available) {
            backgroundColor = '#00F6';
            fontColor = '#fff';
            caracter = 'D';
        }
        if (tagContactMessage === StatusContactMessage.inProgress) {
            backgroundColor = '#ccf';
            fontColor = '#000';
            caracter = 'C';
        }
        if (tagContactMessage === StatusContactMessage.finished) {
            backgroundColor = '#F006';
            fontColor = '#fff';
            caracter = 'E';
        }
        if (tagContactMessage === StatusContactMessage.available) {
            backgroundColor = '#00F6';
            fontColor = '#fff';
            caracter = 'D';
        }
        if (tagContactMessage === StatusContactMessage.newer) {
            backgroundColor = '#FF06';
            fontColor = '#000';
            caracter = 'N';
        }

        if (caracter !== '') {
            return <div className='contact-status' style={{ backgroundColor, color: fontColor }}>
                <span>{caracter}</span>
            </div>;
        }

        return null;
    }

    const prepareTagContactMessageTooltip = (contactMessage: any, index: number, lastFromMessage: boolean) => {
        if (checkTagContactMessage(contactMessage, index, lastFromMessage) === StatusContactMessage.blocked) {
            return <Tooltip target={`.contact_message_${index}`} content='Em atendimento' mouseTrack mouseTrackLeft={10} />;
        } else if (checkTagContactMessage(contactMessage, index, lastFromMessage) === StatusContactMessage.available) {
            return <Tooltip target={`.contact_message_${index}`} content='Disponível' mouseTrack mouseTrackLeft={10} />;
        }
        if (checkTagContactMessage(contactMessage, index, lastFromMessage) === StatusContactMessage.inProgress) {
            return <Tooltip target={`.contact_message_${index}`} content='Conversa em andamento' mouseTrack mouseTrackLeft={10} />;
        }
        if (checkTagContactMessage(contactMessage, index, lastFromMessage) === StatusContactMessage.finished) {
            return <Tooltip target={`.contact_message_${index}`} content='Encerrado' mouseTrack mouseTrackLeft={10} />;
        }
        if (checkTagContactMessage(contactMessage, index, lastFromMessage) === StatusContactMessage.available) {
            return <Tooltip target={`.contact_message_${index}`} content='Disponível' mouseTrack mouseTrackLeft={10} />;
        }
        if (checkTagContactMessage(contactMessage, index, lastFromMessage) === StatusContactMessage.newer) {
            return <Tooltip target={`.contact_message_${index}`} content='Novo Contato' mouseTrack mouseTrackLeft={10} />;
        }
        return null;
    }


    const filterListContactMessages = (): any[] => {
        if (!trackerContactMessages) return [];

        let contactList = trackerContactMessages.sort((t1: any, t2: any) => moment(t2.lastChange).diff(moment(t1.lastChange)));

        contactList = filterContactMessage && filterContactMessage.length > 0 ? contactList.filter(c =>
            (c.pushName && c.pushName.toLowerCase().includes(filterContactMessage.toLowerCase())) ||
            (c.groupName && c.groupName.toLowerCase().includes(filterContactMessage.toLowerCase())) ||
            (c.jid.includes(filterContactMessage.toLowerCase()))) : contactList;


        contactList = contactList.filter(function (item, pos, self) {
            return self.findIndex(s => s.jid === item.jid) === pos;
        });

        let filteredContactMessages = contactList.filter((c: any, index: number) => {
            let valid = true;
            if (tagFiltersData) {
                let filteredList: any[] = tagFiltersData.filter(t => !t.blocked && t.selected)
                let filtered = filteredList.find((t: IFormDataTag) => c.tags?.includes(t._id));
                valid = filtered || filteredList.length === 0;
            }

            return valid;
        });

        return filteredContactMessages;
    }


    const renderDateOrTime = (c: any) => {
        return moment(c.lastChange || c.updatedAt).format('yyyyMMDD') !== moment().format('yyyyMMDD') ?
            <span className={classNames('time', `tooltip_${moment(c.lastChange || c.updatedAt).format('yyyyDDMMHHmmss')}`)}>{moment(c.lastChange || c.updatedAt).format('DD/MM')}</span>
            :
            <span className={classNames('time', `tooltip_${moment(c.lastChange || c.updatedAt).format('yyyyDDMMHHmmss')}`)}>{moment(c.lastChange || c.updatedAt).format('HH:mm')}</span>;
    }

    const renderContactMessages = () => {
        let filteredContactMessages = filterListContactMessages();


        return filteredContactMessages.map((c: any, index: number) => {

            // const lastMessages = listLastMessages.filter((l: any) => l.trackerContactMessageId === c._id);
            let lastFromMessage = c.lastFromMessage;

            const contactResponsibleNickName = c.assumedBy && c.assumedBy?.nickname ?
                `${c.assumedBy?.nickname || ''} ${c.branchGym && c.branchGym.name ? `(${c.branchGym.name.trim()})` : ''}` : '';

            const contactLastResponsibleNickName = c.lastAssumedBy && c.lastAssumedBy.nickname && checkTagContactMessage(c, index, lastFromMessage) === StatusContactMessage.finished ?
                `${c.lastAssumedBy?.nickname || ''} ${c.branchGym && c.branchGym.name ? `(${c.branchGym.name.trim()})` : ''}` :
                `${c.branchGym && c.branchGym.name ? `(${c.branchGym.name.trim()})` : ''}`;

            let listTags = tagData ? [...tagData.map(t => Object.assign({}, t))] : [];

            let tagListContact = listTags ? listTags.map((t) => {
                t.selected = c.tags?.indexOf(t._id) >= 0;
                return t;
            }) : [];

            tagListContact = tagListContact.filter(t => !t.blocked && t.selected).length > 0 ? tagListContact.filter(t => !t.blocked && t.selected) : [];


            const contactTag = <div key={c.jid}>
                <div className={classNames('chat', 'monitoring', `contact_message_${index}`,
                    { available: !isVigilance && ((c && c.available) || (checkTagContactMessage(c, 0, lastFromMessage) === StatusContactMessage.available)) },
                    { inProgress: c && c.inProgress },
                    { finished: c && c.finished },
                    { active: c && c.jid === contactSelected?.jid })}
                    onClick={() => setContact(c)} style={{ minHeight: 60 }}>
                    <div className='details'>
                        {/* <div className='imgBx'> <Image src='assets/imgs/img1.jpg' alt='' /> </div> */}
                        {c.group ? <i className='pi pi-users' /> : <i className='pi pi-user' />}
                        <div className={classNames('name', { 'group': c.group })}>
                            <span>{limitCharacters(c.pushName || '(não identificado)', 40)}</span>
                        </div>
                        {renderDateOrTime(c)}
                        {!isVigilance ? (c.unreadMessages && c.unreadMessages !== 0 ? <Badge className='unread-messages' value={`${c.unreadMessages > 9 ? '+9' : c.unreadMessages}`} severity='warning' /> : null) : null}
                        {!isVigilance ? prepareTagContactMessage(c, index, lastFromMessage) : null}

                        <i className='pi pi-whatsapp' />
                        {/* <Tooltip target={`.tooltip_${moment(c.lastChange || c.updatedAt).format('yyyyDDMMHHmmss')}`} content={`${moment(c.lastChange || c.updatedAt).format('DD-MM-yyyy HH:mm')}`} /> */}
                        {contactResponsibleNickName ?
                            <span className='responsible-name'>{contactResponsibleNickName}</span>
                            : contactLastResponsibleNickName ?
                                <span className='responsible-name'>{contactLastResponsibleNickName}</span>
                                : null}
                        {
                            tagListContact.length >= 1 ?
                                tagListContact.length >= 2 ?
                                    <>
                                        <span className="pi pi-bookmark-fill tag-1" style={{ color: `#${tagListContact[1].tagColor}` }}></span>
                                        <span className="pi pi-bookmark-fill tag-2" style={{ color: `#${tagListContact[0].tagColor}` }}></span>
                                    </>
                                    : <span className="pi pi-bookmark-fill tag-1" style={{ color: `#${tagListContact[0].tagColor}` }}></span>
                                : null
                        }
                        <div className='msgs'> <b className='num'></b> </div>
                    </div>
                </div>
                {prepareTagContactMessageTooltip(c, index, lastFromMessage)}
            </div>;

            const contactTagList = [contactTag];
            // for (let i = 0; i < 25; i++) {
            //     contactTagList.push(contactTag);

            // }

            return (contactTagList);
        });
    };


    const setLoadingDialog = (loading: boolean) => {
        props.args.setLoading(loading);
    }

    const changeStartDateMessages = (e: CalendarChangeEvent) => {
        // formatDate
        if (e.target.value instanceof Date) {
            setStartDateMessages(e.target.value);
            // console.log('changeStartDateMessages: ', moment(e.target.value));
        }
    }

    const changeEndDateMessages = (e: CalendarChangeEvent) => {
        // formatDate
        if (e.target.value instanceof Date) {
            setEndDateMessages(e.target.value);
            // console.log('changeEndDateMessages: ', moment(e.target.value));
        }
    }

    const processFilters = (process: boolean) => {
        if (process && validStartDate(startDateMessages, endDateMessages) && validEndDate(startDateMessages, endDateMessages)) {
            if (!activeStatus || endStatus || myContacts ||
                moment(selectStartDateMessages).diff(moment(startDateMessages), 'minutes') > 0 ||
                moment(selectEndDateMessages).diff(moment(endDateMessages), 'minutes') > 0) setFilterPristine(false);
            setSelectStartDateMessages(startDateMessages);
            setSelectEndDateMessages(endDateMessages);
        } else {
            setStartDateMessages(selectStartDateMessages);
            setEndDateMessages(selectEndDateMessages);
        }
    }

    const defineStartDate = (props: CalendarProps | Readonly<CalendarProps>): void => {
        setMessageChangeDate('');
        if (props.value instanceof Date) {
            setStartDateMessages(props.value);
            validStartDate(props.value, endDateMessages);
        }
    }

    const defineEndDate = (props: CalendarProps | Readonly<CalendarProps>): void => {
        setMessageChangeDate('');
        if (props.value instanceof Date) {
            setEndDateMessages(props.value);
            validEndDate(startDateMessages, props.value);
        }
    }

    const validStartDate = (startDateMessages: Date, endDateMessages: Date) => {
        if (moment(startDateMessages).isAfter(endDateMessages)) {
            setMessageChangeDate('Data de início da pesquisa não pode ser superior a data de início.');
            // setEndDateMessages(moment(startDateMessages).add(7, 'days').toDate());
            return false;
        };
        if (moment(startDateMessages).isBefore(moment(endDateMessages).subtract('days', marginDaysFilter))) {
            setMessageChangeDate(`Por favor, informe um período de no máximo ${marginDaysFilter} dias entre a data inicial e final da pesquisa.`);
            // setEndDateMessages(moment(startDateMessages).add(7, 'days').toDate());
            return false;
        }
        return true;
    }

    const validEndDate = (startDateMessages: Date, endDateMessages: Date) => {
        if (moment(endDateMessages).isBefore(startDateMessages)) {
            setMessageChangeDate('Data de fim da pesquisa não pode ser inferior a data de início.');
            // setStartDateMessages(moment(endDateMessages).subtract(7, 'days').toDate());
            return false;
        }
        if (moment(endDateMessages).isAfter(moment(startDateMessages).add(marginDaysFilter, 'days'))) {
            setMessageChangeDate(`Por favor, informe um período de no máximo ${marginDaysFilter} dias entre a data inicial e final da pesquisa.`);
            // setStartDateMessages(moment(endDateMessages).subtract(7, 'days').toDate());
            return false;
        }
        return true;
    }


    // console.log('trackerContactMessages?.map(c => c._id): ', trackerContactMessages?.map(c => c._id));

    if (!networkGymId)
        return (<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
            <h3>Você precisa selecionar uma Rede.</h3>
        </div>);
    else

        return (
            <>
                <div className='layout-content-whatsapp-monitoring-attendance flex' style={{ height: 'calc(100vh - 194px)' }}>


                    <div className='left-side'>
                        <div className='left-side-header'>
                            <div className='search-chat'>
                                <InputText type='text' value={filterContactMessage} placeholder='Buscar por contato' onChange={(e: any) => { setFilterContactMessage(e.currentTarget.value); }} />
                                <i className='pi pi-search'></i>
                                {filterContactMessage ? <i className='pi pi-times' onClick={(e: any) => setFilterContactMessage('')}></i> : null}
                            </div>
                            <div className='action-chat flex justify-content-around'>
                                <Button icon={`pi pi-comments`} className={classNames('button-action-chat', { 'active': selectActiveStatus })} text aria-label='Conversas Ativas'
                                    onClick={() => { setSelectActiveStatus(!selectActiveStatus); }} tooltip='Conversas Ativas' tooltipOptions={{ position: 'bottom' }} />

                                <Button icon={`pi pi-ban`} className={classNames('button-action-chat', { 'active': selectEndStatus })} text aria-label='Conversas encerradas'
                                    onClick={() => { setSelectEndStatus(!selectEndStatus); }} tooltip='Conversas encerradas' tooltipOptions={{ position: 'bottom' }} />

                                <Button icon={`pi pi-filter-slash`} className={classNames('button-action-chat')} text aria-label='Limpar filtros'
                                    onClick={() => { setContact(''); defaultFilters(); }} tooltip='Limpar filtros' tooltipOptions={{ position: 'bottom' }} />

                            </div>
                        </div>
                        <div className='left-side-content'>
                            {countContacts && countContacts >= limitContactsMessage ?
                                <div className='alert-max-contacts flex flex-column justify-content-center'>
                                    <span className='pi pi-exclamation-triangle warn'></span>
                                    <span>{`O sistema recuperou mais de ${limitContactsMessage} contatos ultrapassando o limite da lista abaixo.`}</span>
                                    <span>{`Para visualizar todos contatos filtrados, favor restringir o período de pesquisa.`}</span>
                                </div>
                                : null
                            }
                            <div className={classNames('chat-list', { 'max-contacts': (countContacts && countContacts >= limitContactsMessage) })}>
                                {renderContactMessages()}
                                <div style={{ height: '10px' }}></div>
                            </div>
                        </div>
                        <div className='left-side-footer' />
                        {/* {branchGymId ?
                            <div className='start-chat' style={{ padding: '10px', textAlign: 'right' }}>
                                <Button label='Iniciar conversa' type='button' style={{ fontSize: '0.9rem', minWidth: 150 }}
                                    className='p-button-rounded p-button p-button-success shadow-3' icon='pi pi-plus' />
                            </div> : null} */}
                    </div>
                    <div className={classNames('center-side', { 'center-side-sidebar-open': mobileMenuActive }, { 'center-side-close': !rightSideOpen })}>
                        <div className='center-side-header'>
                            <div style={{ width: '100%', flexWrap: 'nowrap' }}>
                                <div className='grid'>
                                    <div className={classNames({ 'col-10': !optionsFilters }, { 'col-12': optionsFilters })}>
                                        <div className='grid' style={{ marginBottom: -10 }}>
                                            <h4 className='col-5 align-items-center'>
                                                {contactSelected ?
                                                    <div className='flex flex-column justify-content-start'>
                                                        <div className='flex flex-row justify-content-start'>
                                                            <span style={{ fontSize: '1.1rem' }}>{limitCharacters(trackerContactMessages?.find(t => contactSelected && t.jid === contactSelected.jid)?.pushName || '', 30)}</span>
                                                            <Button icon="pi pi-book" label='' className="p-button-rounded p-button-secondary"
                                                                style={{ position: 'relative', height: 24, width: 24, fontSize: '1rem', marginLeft: 10, marginTop: 10 }} aria-label="Dados contato" onClick={() => defineContactData()} />
                                                            <Button icon='pi pi-times' label='' className='p-button-rounded p-button-warning unselect-contact'
                                                                style={{ position: 'relative', height: 24, width: 24, fontSize: '1rem', marginLeft: 10, marginTop: 10 }} onClick={() => setContact('')}></Button>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='flex flex-column'>
                                                        <span>Todos os contatos</span>
                                                        <span className='text-xs' style={{ color: 'GrayText' }}>(limitado a 2.000 mensagens)</span>
                                                    </div>
                                                }
                                            </h4>
                                            <div className='col-7'>
                                                {contactSelected ?
                                                    <>
                                                        <div className='flex align-items-center justify-content-start' style={{ overflow: 'auto' }}>
                                                            {
                                                                tagContactsData && tagContactsData.filter(t => !t.blocked && t.selected).length > 0 ?
                                                                    tagContactsData.filter(t => !t.blocked && t.selected).sort((l1: any, l2: any) => l1.tag < l2.tag ? -1 : l1.tag > l2.tag ? 1 : 0).map(t =>
                                                                        <div className='tag-header shadow-2' >
                                                                            <div className='flex align-items-center justify-content-start'>
                                                                                {contactSelected?.assumedBy?._id === customerId || checkRoleHierarchyByToken(RoleRules.manager) ?
                                                                                    <Button className='unselect-tag' style={{ height: 2, width: 2, margin: 2 }} icon='pi pi-times-circle' onClick={() => { if (t._id) setSelectTagContact(t._id); }}></Button>
                                                                                    :
                                                                                    null}
                                                                                <i className={`pi ${t.selected ? 'pi-bookmark-fill' : 'pi-bookmark'} `}
                                                                                    style={{ fontSize: '1rem', color: `#${t.tagColor}`, marginRight: '2px', textShadow: `1px 0 rgba(255, 255, 255, 0.2666666667), -1px 0 rgba(255, 255, 255, 0.2666666667), 0 1px rgba(255, 255, 255, 0.2666666667), 0 -1px rgba(255, 255, 255, 0.2666666667), 1px 1px rgba(255, 255, 255, 0.2666666667), -1px -1px rgba(255, 255, 255, 0.2666666667), 1px -1px rgba(255, 255, 255, 0.2666666667), -1px 1px rgba(255, 255, 255, 0.2666666667)` }} />
                                                                                <span style={{ color: '#000', fontWeight: 'bold', fontSize: '0.8rem', overflow: 'hidden' }}>{t.tag}</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='search-chat'>
                                    <InputText type='text' value={filterTextMessage} placeholder='Buscar por texto na mensagem' onChange={(e: any) => setFilterTextMessage(e.currentTarget.value)} />
                                    <i className="pi pi-search"></i>
                                    {filterTextMessage ? <i className="pi pi-times" onClick={(e: any) => setFilterTextMessage('')}></i> : null}
                                </div>
                            </div>
                        </div>

                        <div className='center-side-content'>
                            <div className='center-side-content-message'>
                                <div className='chatBx'>
                                    <MonitoringAttendanceMessage args={props.args}
                                        // data?: any[],
                                        // refreshData?: () => void,
                                        // params?: any,
                                        contactSelected={contactSelected} listMessagesOption={listMessagesOption}
                                        startDateMessages={selectStartDateMessages} endDateMessages={selectEndDateMessages}
                                        filterTextMessage={filterTextMessage} contactMessageList={filterListContactMessages()?.map(c => c.jid) || distinctTrackerContactMessages} />
                                </div>
                            </div>
                        </div>
                        <div className={classNames('center-side-footer', { 'center-side-sidebar-open': mobileMenuActive }, { 'center-side-footer-close': !rightSideOpen })} />
                    </div>
                    <div className={classNames('right-side flex', { 'right-side-open': !rightSideOpen })} style={{ backgroundColor: '#e6ebe4' }}>
                        <div className='right-side-control flex flex-column justify-content-center' onClick={() => setRightSideOpen(!rightSideOpen)}>
                            {rightSideOpen ?
                                <span className='pi pi-arrow-circle-right text-white' style={{ fontWeight: 'bold', fontSize: '1.2rem', marginLeft: '3px', color: '#fff' }}></span>
                                :
                                <span className='pi pi-arrow-circle-left text-white' style={{ fontWeight: 'bold', fontSize: '1.2rem', marginLeft: '3px', color: '#fff' }}></span>
                            }
                        </div>
                        <div className='right-side-content flex flex-column justify-content-start' style={{ overflowY: 'auto' }}>
                            <>
                                <span className='py-1 w-full text-white font-bold' style={{ color: '#fff', backgroundColor: '#43ac90', height: '2rem', fontSize: '1.0rem', textAlign: 'center' }}>:: FILTROS  APLICADOS ::</span>
                                <div className='flex flex-colum flex-wrap justify-content-start' style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                    <span className='filter-options filter-options-header'>Período:</span>
                                    <span className='filter-options filter-end-options'>
                                        <span>de <span>{moment(selectStartDateMessages).format('DD/MM/YYYY')}</span> até <span>{moment(selectEndDateMessages).format('DD/MM/YYYY')}</span></span>
                                    </span>
                                    <span className='filter-options filter-options-header'>Exibindo:</span>
                                    {filterContactMessage ? <span className='filter-options'>- Contatos filtrados por: "{filterContactMessage}"</span> : null}
                                    {listMessagesOption ? <span className='filter-options'>- Direção: "{listMessagesOption}"</span> : null}
                                    {filterTextMessage ? <span className='filter-options'>- Mensagens contendo: "{filterTextMessage}"</span> : null}
                                    {selectActiveStatus ? <span className='filter-options'>- Conversas ativas</span> : null}
                                    {selectEndStatus ? <span className='filter-options'>- Conversas encerradas</span> : null}
                                    {tagFiltersData && tagFiltersData.find(t => !t.blocked && t.selected) ?
                                        <span className='filter-options'>- Com alguma das etiquetas:&nbsp;
                                            {tagFiltersData.filter(t => !t.blocked && t.selected).map(t => t.tag).join(', ')}</span>
                                        :
                                        <span className='filter-options'>- Com ou sem etiquetas</span>}
                                </div>
                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#c3dede', fontSize: '0.8rem' }} />
                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }}>:: Direção</span>
                                <div style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                    <SelectButton className='select-button-action-chat' value={listMessagesOption} options={listMessagesOptions} onChange={(e: any) => !e.value ? setListMessagesOption('todas') : setListMessagesOption(e.value)} />
                                </div>
                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#c3dede', fontSize: '0.8rem' }} />
                                <div className='flex flex-colum flex-wrap justify-content-start' style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }}>:: Período</span>
                                    <div className='date-message-box' >
                                        <div className='col-12 grid' style={{ padding: 0 }}>
                                            <span className='col-2'>De:</span>
                                            <div className='col-10'>
                                                <Calendar id='startDateMessages' name={'startDateMessages'} value={startDateMessages}
                                                    onChange={(props: CalendarProps | Readonly<CalendarProps>) => props.value && props.value instanceof Date ? defineStartDate(props) : null}
                                                    dateFormat={'dd/mm/yy'} showIcon={true}
                                                    locale={'pt-BR'}
                                                    className='col-10 date-messages2 start-date-messages2' />
                                            </div>
                                            <span className='col-2'>Até:</span>
                                            <div className='col-10'>
                                                <Calendar id='endDateMessages' name={'endDateMessages'} value={endDateMessages}
                                                    onChange={(props: CalendarProps | Readonly<CalendarProps>) => props.value && props.value instanceof Date ? defineEndDate(props) : null}
                                                    dateFormat={'dd/mm/yy'} showIcon={true}
                                                    locale={'pt-BR'}
                                                    className='date-messages2 end-date-messages2' />
                                            </div>
                                        </div>
                                        {messageChangeDate ?
                                            <div className='col-12' style={{ padding: '10px 0px', textAlign: 'center' }}>
                                                <span style={{ fontSize: '0.7rem', color: '#000', margin: '10px' }}>{messageChangeDate}</span>
                                            </div>
                                            : null}
                                        <Button label='Aplicar data' type='button' onClick={() => { processFilters(true); }} style={{ fontSize: '0.8rem', minWidth: 120, marginRight: 10, padding: '0.4rem' }}
                                            className='p-button-rounded p-button p-button-success shadow-3' icon='pi pi-search' />
                                    </div>
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#c3dede', fontSize: '0.8rem' }} />
                                    <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }}>:: Status</span>
                                    <div style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                        <Button icon={`pi pi-comments`} label={selectActiveStatus ? 'Ocultar conversas ativas' : 'Exibir conversas ativas'}
                                            className={classNames('button-action-chat', { 'active': selectActiveStatus }, 'shadow-2')} text aria-label='Conversas Ativas'
                                            onClick={() => { setSelectActiveStatus(!selectActiveStatus); if (selectActiveStatus && !selectEndStatus) setSelectEndStatus(true); }} tooltip='Conversas Ativas' tooltipOptions={{ position: 'bottom' }} />
                                    </div>
                                    <div style={{ width: '100%', backgroundColor: '#c3dede' }}>
                                        <Button icon={`pi pi-ban`} label={selectEndStatus ? 'Ocultar conversas encerradas' : 'Exibir conversas encerradas'}
                                            className={classNames('button-action-chat', { 'active': selectEndStatus }, 'shadow-2')} text aria-label='Conversas encerradas'
                                            onClick={() => { setSelectEndStatus(!selectEndStatus); if (!selectActiveStatus && selectEndStatus) setSelectActiveStatus(true); }}
                                            tooltip='Conversas encerradas' tooltipOptions={{ position: 'bottom' }} />
                                    </div>
                                </div>
                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#c3dede', fontSize: '0.8rem' }} />
                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }}>:: Filtro de Etiquetas</span>
                                <div style={{ height: '30%', width: '100%', overflowY: 'auto', backgroundColor: '#c3dede' }}>
                                    {
                                        tagFiltersData && tagFiltersData.filter(t => !t.blocked).length > 0 ?
                                            tagFiltersData.filter(t => !t.blocked).sort((l1: any, l2: any) => l1.tag < l2.tag ? -1 : l1.tag > l2.tag ? 1 : 0).map(t =>
                                                <div key={t._id} className={classNames('tag', { 'active': t.selected }, 'bg-primary-reverse px-1 py-1 text-xs shadow-2')}
                                                    onClick={() => { if (t._id) setSelectTagFiltered(t._id); }}>
                                                    <div className='flex align-items-center justify-content-start'>
                                                        <i className={`pi ${t.selected ? 'pi-bookmark-fill' : 'pi-bookmark'} `} style={{ width: '15%', fontSize: '1.5rem', color: `#${t.tagColor}`, textShadow: `1px 0 rgba(255, 255, 255, 0.2666666667), -1px 0 rgba(255, 255, 255, 0.2666666667), 0 1px rgba(255, 255, 255, 0.2666666667), 0 -1px rgba(255, 255, 255, 0.2666666667), 1px 1px rgba(255, 255, 255, 0.2666666667), -1px -1px rgba(255, 255, 255, 0.2666666667), 1px -1px rgba(255, 255, 255, 0.2666666667), -1px 1px rgba(255, 255, 255, 0.2666666667)` }} />
                                                        <span style={{ width: '85%', fontSize: '0.8rem' }}>{t.tag}</span>
                                                    </div>
                                                    {/* <i className={`pi pi-align-left mr-1`} />{t.tag} */}
                                                    <Tooltip target='.Tag' content={''} />
                                                </div>
                                            )
                                            :
                                            <div className='m-1 bg-primary-reverse px-1 py-1 text-xs' style={{ color: '#b00020' }}><i className='pi pi-info mr-1' />Não há etiquetas disponíveis.</div>
                                    }
                                </div>
                                <span className='px-3 py-1 w-full text-white font-bold' style={{ backgroundColor: '#43ac90', fontSize: '0.8rem' }} />
                            </>
                        </div>
                        <div className='right-side-footer' />
                    </div>
                </div >

                {
                    contactSelected && token ?
                        <>
                            <GenericFormDialog<IFormDataAttendanceUpdateData> key='AttendanceUpdateDataDialog'
                                token={token}
                                objectData='contato'
                                refreshList={() => { }}
                                // refreshList={() => refreshListTrackerContactMessage()}
                                setLoading={(value: any) => setLoadingDialog(value)}
                                showDialog={dialogData}
                                setDialogData={(value: any) => setDialogData(value)}
                                setMessage={(message: IMessage) => props.args.setMessage(message)}
                                editFormData={editFormData}
                                emptyFormData={{
                                    _id: '',
                                    pushName: '',
                                    mobilePhone: '',
                                    dataExtra: '',
                                    active: true,
                                    deleted: false
                                }}
                                retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataAttendanceUpdateData>[]) => [
                                    {
                                        colSize: 'col-12', fieldName: 'pushName', fieldType: 'InputTextField', description: '* Nome:', placeholder: 'Nome do contato', value: formData.values.pushName, autoFocus: false,
                                        onChange: formData.handleChange
                                    },
                                    {
                                        colSize: 'col-12', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
                                        mask: '+55 (99) 99999-9999', disabled: true, autoFocus: false,
                                    },
                                    {
                                        colSize: 'col-12', fieldName: 'dataExtra', fieldType: 'InputTextAreaField', description: 'Dados Extras:', placeholder: 'Dados Extras', value: formData.values.dataExtra, autoFocus: false,
                                        rows: 5, cols: 5,
                                        onChange: formData.handleChange
                                    }
                                ])}
                                editForceData={{}}
                                editFunctions={[]}
                                formLists={[]}
                                validate={(data: any) => {
                                    let errors: any = {};

                                    if (!data.pushName) {
                                        errors.pushName = 'Nome do contato é obrigatório.';
                                    } else if (data.pushName.length < 3) {
                                        errors.pushName = 'Nome do contato precisa ter mais que 3 caracteres.';
                                    }

                                    return errors;
                                }}
                                submitForceData={{}}
                                onSubmit={(props: IPropsFormDialog<IFormDataAttendanceUpdateData>, data: FormikValues, formData: FormikInterface) => {
                                    const saveData = { _id: data._id, pushName: data.pushName, dataExtra: data.dataExtra };
                                    new TrackerContactMessageService().saveTrackerContactMessage(Object.assign(saveData))
                                        .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                                        .catch((error: any) => saveFailed(props, formData)(error));
                                }}
                            />

                        </>
                        :
                        null
                }
            </>
        );

};

