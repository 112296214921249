import { Route } from 'react-router-dom';
import { NotFound } from '../chat4fit/pages';
import { routes as chat4fit_routes } from "./chat4fit/routes";

// const routes: { [key: string]: RouteInterface[] } = { chat4fit_routes };
const routes: { [key: string]: RouteInterface[] } = { chat4fit_routes };

export interface RouteInterface {
    pathname: string,
    type: 'route' | 'class',
    route: any,
};

export const retrieveRoute = (projectId: string, location: any): any | undefined => {
    const projectRoutes = routes[`${projectId}_routes`];
    const route = projectRoutes.find(r => r.pathname === location.pathname);
    const routeDefault = projectRoutes.find(r => r.pathname === 'default');

    if (route)
        return { type: route.type, object: route.type === 'route' ? <Route key={`Route-${Math.random()*99999}`} path={location.pathname} component={route.route} /> : route.route };
    else if (routeDefault)
        return { type: routeDefault.type, object: routeDefault.type === 'route' ? <Route key={`Route-${Math.random()*99999}`} path={location.pathname} component={routeDefault.route} /> : routeDefault.route };

    return { type: 'route', object: <Route key={`Route-${Math.random()*99999}`} path={location.pathname} component={NotFound} /> };
};
