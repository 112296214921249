import { EnumTypeShowMessage } from "../../../utils/functions/message";
import SystemMessageDefaultService from "../admin/SystemMessageDefaultService";
import SystemMessageService from "../admin/SystemMessageService";

const systemMessageService = new SystemMessageService();
const systemMessageDefaultService = new SystemMessageDefaultService();

export default class ManagerSystemMessageService {
    async isBlockedSystemMessage(systemMessageKey: string, networkGym: string): Promise<boolean> {
        let systemMessage: any = await systemMessageService.findSystemMessageByKey(systemMessageKey, networkGym);
        if (systemMessage && systemMessage.data && systemMessage.data.length > 0 && systemMessage.data[0].text) {
            systemMessage = systemMessage.data[0];
            return systemMessage.blocked;
        } else {
            let systemMessageDefault = await systemMessageDefaultService.findSystemMessageDefaultByKey(systemMessageKey);
            if (systemMessageDefault && systemMessageDefault.data && systemMessageDefault.data.length > 0 && systemMessageDefault.data[0].text) {
                systemMessage = systemMessageDefault.data[0];
                return systemMessage.blockedDefault;
            }
        }
        return true;
    }

    async sendSystemMessage(systemMessageKey: string, networkGym: string, isGroup: boolean, args: {
        methodSend: (messageToSend: string, typeShowMessage: EnumTypeShowMessage, typeObjectMessage?: string | undefined) => Promise<void>;
        typeShowMessage: EnumTypeShowMessage;
        typeObjectMessage: string;
        customerNickname?: string | null;
        scheduleType?: string | null;
        scheduleDate?: string | null;
        scheduleHour?: string | null;
        branchGymName?: string | null;
        branchGymAddress?: string | null;
        branchGymNumber?: string | null;
        branchGymComplement?: string | null;
        branchGymDistrict?: string | null;
        branchGymCity?: string | null;
        branchGymState?: string | null;
        branchGymCep?: string | null;
        npsOptions?: string | null;
        callbackMethod: () => void;
        callbackMethodNotMessage?: () => void;
    }) {
        if (isGroup) return null;
        let systemMessage: any = await systemMessageService.findSystemMessageByKey(systemMessageKey, networkGym);
        if (systemMessage && systemMessage.data && systemMessage.data.length > 0 && systemMessage.data[0].text) {
            systemMessage = systemMessage.data[0];
            if (!systemMessage.blocked) {
                let textToSend = systemMessage.text;
                textToSend = this.replaceData(textToSend, args);
                await args.methodSend(textToSend, args.typeShowMessage, args.typeObjectMessage);
                args.callbackMethod();
            } else {
                if (args.callbackMethodNotMessage) args.callbackMethodNotMessage();
            }
        } else {
            let systemMessageDefault = await systemMessageDefaultService.findSystemMessageDefaultByKey(systemMessageKey);
            if (systemMessageDefault && systemMessageDefault.data && systemMessageDefault.data.length > 0 && systemMessageDefault.data[0].text) {
                systemMessage = systemMessageDefault.data[0];
                if (!systemMessage.blockedDefault) {
                    let textToSend = systemMessage.text;
                    textToSend = this.replaceData(textToSend, args);
                    await args.methodSend(textToSend, args.typeShowMessage, args.typeObjectMessage);
                    args.callbackMethod();
                } else {
                    if (args.callbackMethodNotMessage) args.callbackMethodNotMessage();
                }
            } else {
                if (args.callbackMethodNotMessage) args.callbackMethodNotMessage();
            }
        }
    }

    replaceData(textData: string, args: any): string {
        let textToSend = textData;
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{apelido_usuario\}\}', 'igm'), args.customerNickname);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{nome_unidade\}\}', 'igm'), args.branchGymName);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{tipo_agendamento\}\}', 'igm'), args.scheduleType);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{data_agendamento\}\}', 'igm'), args.scheduleDate);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{hora_agendamento\}\}', 'igm'), args.scheduleHour);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{endereco_unidade\}\}', 'igm'), args.branchGymAddress);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{numero_unidade\}\}', 'igm'), args.branchGymNumber);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{complemento_unidade\}\}', 'igm'), args.branchGymComplement);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{bairro_unidade\}\}', 'igm'), args.branchGymDistrict);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{cidade_unidade\}\}', 'igm'), args.branchGymCity);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{estado_unidade\}\}', 'igm'), args.branchGymState);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{cep_unidade\}\}', 'igm'), args.branchGymCep);
        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{notas_avaliacao\}\}', 'igm'), args.npsOptions);

        // eslint-disable-next-line no-useless-escape
        textToSend = textToSend.replace(new RegExp('\{\{[\\w]*\}\}', 'igm'), '');

        return textToSend;
    }
}

