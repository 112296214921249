/* eslint-disable no-eval */
import { isArray } from "../../../utils/functions";
import { makeKeyByString } from "../../../utils/functions/hash";
import { cloneRegister, editRegister } from "../commons/formDataFunctions";
import { createListData, createSkeletons } from "../commons/GenericCard";

export interface IConfigTitle {
    icon: string,
    value: string,
    rows?: number,
    inactive?: boolean
};

export interface IConfigLine {
    description?: string,
    value: string,
    fontSize?: string,
    overflow?: string,
    rows?: number,
    icon?: string,
    color?: string
};

export interface IConfig {
    title: IConfigTitle,
    lines?: Array<IConfigLine | null>,
    data: any,
    buttons: any[],
    selected: boolean
};

export interface IButtons { evalCondition?: string, key: string, icon: string; method?: (type: string, _id?: string | null | undefined, ...args: string[]) => void, onClick?: (_id: string) => void, tooltip: string; };

export function createConfig<T>(type: string, data: any,
    title: { icon: string, evalIcon?: string, evalValue?: string, evalInactive?: string },
    evalOptionLines: { condition?: string, description?: string, value: string, fontSize?: string, overflow?: string, icon?: string, color?: string, rows?: number }[],
    selectedId: string | null,
    args: {
        isEdited: boolean, isCloned: boolean, isSelected: boolean,
        evalIsEdited?: string,
        select: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        unselect: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        editForceData: any,
        setEditFormData?: React.Dispatch<any>,
        setEditStepData?: React.Dispatch<any>,
        setDialogData: React.Dispatch<React.SetStateAction<boolean>>,
        removeForceData?: string[], buttons?: IButtons[]
    }): IConfig {

    return {
        title: { icon: title.evalIcon ? eval(title.evalIcon) : title.icon, value: title.evalValue ? eval(title.evalValue) : data.key || makeKeyByString(data.description || '').toUpperCase(), inactive: title.evalInactive ? eval(title.evalInactive) : false },
        lines: createConfigLines<T>(data, evalOptionLines),
        data,
        buttons: data._id ? [
            ...args.buttons ? args.buttons.map(b => b.evalCondition && eval(b.evalCondition) ?
                { key: b.key, icon: b.icon, onClick: () => b.method ? b.method(type, data._id) : b.onClick ? b.onClick(data._id) : null, tooltip: b.tooltip }
                : null) : [],
            args.isEdited && (!args.evalIsEdited || eval(args.evalIsEdited)) ? { key: `editar`, icon: 'pi-pencil', onClick: () => editRegister(type, { data, editForceData: args.editForceData, setEditFormData: args.setEditFormData, setEditStepData: args.setEditStepData, setDialogData: args.setDialogData }), tooltip: 'Editar' } : null,
            args.isCloned ? { key: `clone`, icon: 'pi-copy', onClick: () => cloneRegister(type, { data, removeForceData: args.removeForceData ? args.removeForceData : [], setEditFormData: args.setEditFormData, setEditStepData: args.setEditStepData, setDialogData: args.setDialogData }), tooltip: 'Copiar' } : null,
            args.isSelected ? selectedId !== data._id ? { key: `selecionar`, icon: 'pi-tag', onClick: () => args.select(type, data._id), tooltip: 'Selecionar' } : null : null,
            args.isSelected ? selectedId === data._id ? { key: `desprezar`, icon: 'pi-times', onClick: () => args.unselect(type, data._id), tooltip: 'Desprezar' } : null : null,
        ] : [],
        selected: selectedId === data._id
    };
}

export function createConfigTracker<T>(type: string, data: any,
    title: { icon: string, evalValue?: string, evalInactive?: string },
    evalOptionLines: { condition?: string, description?: string, value: string, fontSize?: string, overflow?: string }[],
    args: {
        isQrCode: boolean, isContacts: boolean, isMessages: boolean, isPlay: boolean, isPause: boolean, isStop: boolean,
        connectWhatsApp: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        sendAskValidateMessage?: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        listContacts: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        listMessages: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        playCollectData: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        pauseCollectData: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        stopCollectData: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
    }): IConfig {

    const buttons = [];
    if ((data.status === 'criado' || data.status === 'aguardando vínculo') && args.isQrCode)
        buttons.push({ key: `connect`, icon: 'pi-qrcode', onClick: () => { args.connectWhatsApp(type, data._id, data.mobilePhone); }, tooltip: 'Conectar WhatsApp' });
    if (args.sendAskValidateMessage && (data.status === 'aguardando aceite' || data.status === 'rejeitado')) {
        const functionSendAskValidateMessage = args.sendAskValidateMessage;
        buttons.push({
            key: `sendAskValidate`, icon: 'pi-replay',
            onClick: () => functionSendAskValidateMessage(type, data._id, data.mobilePhone),
            tooltip: 'Enviar aceite novamente'
        });
    }
    if (data.status === 'aceito' && args.isMessages)
        buttons.push({ key: `select_tracker_mobile`, icon: 'pi-tag', onClick: () => args.listContacts(type, data._id), tooltip: 'Selecionar' });
    buttons.push({ key: `list_messages`, icon: 'pi-comments', onClick: () => args.listMessages(type, data._id), tooltip: 'Listar as mensagens' });
    return {
        title: { icon: title.icon, value: title.evalValue ? eval(title.evalValue) : data.key || makeKeyByString(data.description || '').toUpperCase(), inactive: title.evalInactive ? eval(title.evalInactive) : false },
        lines: createConfigLines<T>(data, evalOptionLines),
        data,
        buttons: data._id ? buttons : [],
        selected: false
    };
}

export function createConfigLines<T>(data: T, evalOptions: { condition?: string, description?: string, value: string, fontSize?: string, overflow?: string, icon?: string, color?: string, rows?: number }[]): IConfigLine[] {
    const lines: IConfigLine[] = [];
    evalOptions.forEach(option => {
        if (option.condition) {
            if (!!eval(option.condition)) lines.push({
                description: option.description ? eval(option.description) : '', 
                value: eval(option.value),
                fontSize: option.fontSize ? eval(option.fontSize) : '', 
                overflow: option.overflow ? eval(option.overflow) : '',
                icon: option.icon ? option.icon : '', 
                color: option.color ? eval(option.color) : '', 
                rows: option.rows
            });
        }
        else lines.push({
            description: option.description ? eval(option.description) : '', 
            value: eval(option.value),
            fontSize: option.fontSize ? eval(option.fontSize) : '', 
            overflow: option.overflow ? eval(option.overflow) : '',
            icon: option.icon ? option.icon : '', 
            color: option.color ? eval(option.color) : '', 
            rows: option.rows
        });
    });
    return lines;
};

export function createGenericListData(type: string,
    data: any[] | undefined | null,
    title: { icon: string, evalIcon?: string, evalValue?: string, evalInactive?: string },
    evalOptionLines: ({ condition?: string; description?: string; value: string; fontSize?: string, overflow?: string, icon?: string, color?: string, rows?: number })[],
    selectedId: string | null,
    args: {
        isEdited: boolean, isCloned: boolean, isSelected: boolean,
        evalIsEdited?: string
        loadingData: boolean,
        select: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        unselect: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        editForceData: any,
        setEditFormData?: React.Dispatch<any>,
        setEditStepData?: React.Dispatch<any>,
        setDialogData: React.Dispatch<React.SetStateAction<boolean>>,
        removeForceData?: string[], buttons?: IButtons[]
    }) {
    const listData = data;
    if (args.loadingData) return createSkeletons(8);
    if (!listData)
        return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
            <h3>Carregando dados...</h3>
        </div>;
    if (listData.length === 0)
        return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
            <h3>Não foi localizado nenhum registro.</h3>
        </div>;
    const listConfig = listData ?
        listData.map(d => {
            const config: IConfig = createConfig(type, d,
                title,
                evalOptionLines, selectedId, args);
            return config;
        }) : [];

    return createListData(listConfig);
};

export function createGenericListDataTracker(type: string,
    data: any[],
    title: { icon: string, evalValue?: string },
    evalOptionLines: ({ condition?: string; description?: string; value: string; })[],
    args: {
        isQrCode: boolean, isContacts: boolean, isMessages: boolean, isPlay: boolean, isPause: boolean, isStop: boolean,
        loadingData: boolean,
        connectWhatsApp: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        sendAskValidateMessage?: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        listContacts: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        listMessages: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        playCollectData: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        pauseCollectData: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
        stopCollectData: (type: string, _id?: string | null | undefined, ...args: string[]) => void,
    }) {
    const listData = data && isArray(data) ? data : [];
    if (args.loadingData) return createSkeletons(8);
    if (!listData || listData.length === 0)
        return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
            <h3>Não foi localizado nenhum registro.</h3>
        </div>;
    const listConfig = listData ?
        listData.map(d => {
            const config: IConfig = createConfigTracker(type, d,
                title,
                evalOptionLines, args);
            return config;
        }) : [];
    return createListData(listConfig);
};

