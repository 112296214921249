export enum Method {
    GET = 'get',
    DELETE = 'delete',
    HEAD = 'head',
    OPTIONS = 'options',
    POST = 'post',
    PUT = 'put',
    PATCH = 'patch',
    PURGE = 'purge',
    LINK = 'link',
    UNLINK = 'unlink',
};









