export enum EnumAnonymizeType {
    CNPJ,
    CPF,
    EMAIL,
    COMPLETE_NAME,
    NAME,
    PHONE
};

export enum EnumDateType {
    ISO,
    DATE,
};

export enum enumMongooseState {
    CONNECTED = 'connected',
    CONNECTING = 'connecting',
    DISCONNECTED = 'disconnected',
    DISCONNECTING = 'disconnecting',
};

export enum monthDescriptions { 
    JANEIRO = 'Janeiro',
    FEVEREIRO = 'Fevereiro',
    MARÇO = 'Março',
    ABRIL = 'Abril',
    MAIO = 'Maio',
    JUNHO = 'Junho',
    JULHO = 'Julho',
    AGOSTO = 'Agosto',
    SETEMBRO = 'Setembro',
    OUTUBRO = 'Outubro',
    NOVEMBRO = 'Novembro',
    DEZEMBRO = 'Dezembro',
};