/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import React, { useEffect, useState } from 'react';
import { RoleKeyPermiteds, RoleRules, checkRoleHierarchyByToken, convertRoleKeyPermiteds } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import BranchGymPermissionService from '../../service/admin/BranchGymPermissionService';
import BranchGymService from '../../service/admin/BranchGymService';
import PermissionsFunctionalitieService from '../../service/admin/PermissionsFunctionalitiesService';
import TagService from '../../service/admin/TagService';
import { IMessage } from '../Control';
import { createSkeletons } from '../commons/GenericCard';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { addNewRegister } from '../commons/formDataFunctions';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataTag } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { createGenericListData } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './Tag.css';

const tagService = new TagService();
const branchGymService = new BranchGymService();

const permissionsFunctionalitieService = new PermissionsFunctionalitieService();
const branchGymPermissionService = new BranchGymPermissionService();

export const Tag = (props: IPropsArgsComponents) => {
    const [title] = useState('Etiquetas');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);

    const [tagData, setTagData] = useState<IFormDataTag[]>();
    const [tagBranchGymData] = useState<any[]>();
    const [branchGymData, setBranchGymData] = useState<any[]>();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [editFormData, setEditFormData] = useState<any | null>();

    const [permissionCreateDefined, setPermissionCreateDefined] = useState<RoleKeyPermiteds>(RoleRules.owner);
    const [permissionEditDefined, setPermissionEditDefined] = useState<RoleKeyPermiteds>(RoleRules.owner);
    const [permissionBlockDefined, setPermissionBlockDefined] = useState<RoleKeyPermiteds>(RoleRules.owner);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingDataRefreshList, setLoadingDataRefreshList] = useState<boolean>(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loadingTagBranchGymList, setLoadingTagBranchGymList] = useState<boolean>(true);



    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        // { key: 'branchGym', show: true, considered: true },
    ]), []);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    useEffect(() => { refreshList(); }, [networkGymId]);

    useEffect(() => { refreshListBranchGyms(); }, []);
    // useEffect(() => { refreshTagBranchGymList(); }, [tagData]);

    useEffect(() => { refreshListBranchGymPermissionPermissionCreateDefined(); refreshListBranchGymPermissionPermissionEditDefined(); refreshListBranchGymPermissionPermissionBlockDefined(); }, [networkGymId, branchGymId]);

    useEffect(() => { refreshTagWithTagBranchGymList(); }, [branchGymData, tagBranchGymData]);

    // useEffect(() => { setLoadingData(loadingDataRefreshList || loadingTagBranchGymList); },
    //     [loadingDataRefreshList, loadingTagBranchGymList]);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    const refreshListBranchGymPermissionPermissionCreateDefined = async () => {
        await definePermission('register_tags_create', setPermissionCreateDefined);
    }

    const refreshListBranchGymPermissionPermissionEditDefined = async () => {
        await definePermission('register_tags_edit', setPermissionEditDefined);
    }

    const refreshListBranchGymPermissionPermissionBlockDefined = async () => {
        await definePermission('register_tags_block', setPermissionBlockDefined);
    }

    const definePermission = async (key: string, methodSet: React.Dispatch<React.SetStateAction<RoleKeyPermiteds>>) => {
        let role: RoleRules | undefined = RoleRules.owner;
        const functionCreate = await permissionsFunctionalitieService.findPermissionsFunctionalityByFunctionalityKey(key);
        if (functionCreate.data && functionCreate.data.length > 0 && functionCreate.data[0]._id) {
            const permissionCreate = await branchGymPermissionService.findBranchGymPermissionByPermissionsFunctionalitiesId(networkGymId, branchGymId, functionCreate.data[0]._id);
            if (permissionCreate.data && permissionCreate.data.length > 0 && permissionCreate.data[0]._id) {
                role = convertRoleKeyPermiteds(permissionCreate.data[0].role.hierarchyRoleKey)?.key;
            } else {
                role = convertRoleKeyPermiteds(functionCreate.data[0].defaultRole.hierarchyRoleKey)?.key;
            }
            methodSet(role && role !== RoleRules.no_role ? role : RoleRules.owner);
        } else {
            methodSet(RoleRules.owner);
        }
    }

    // const refreshTagBranchGymList = () => {
    //     let isMounted = true;
    //     if (!tagData) return;
    //     const validIds: string[] = tagData
    //         .filter(b => b._id !== undefined && typeof b._id === 'string') // Filtra apenas por elementos com _id definido e do tipo string
    //         .map(q => q._id || ''); // Agora o TypeScript sabe que o resultado é string[]

    //     tagBranchGymService.allTagBranchGyms(validIds).then(result => {
    //         if (isMounted) { setTagBranchGymData(result.data); }
    //     });
    //     return () => { isMounted = false };
    // }

    const refreshTagWithTagBranchGymList = () => {
        if (branchGymData && tagData && tagData.length > 0 && tagBranchGymData) {
            loadBranchGyms();
        }
    }
    const refreshList = () => {
        let isMounted = true;
        setLoadingDataRefreshList(true);
        setLoadingData(true);
        if (networkGymId) {
            tagService.allNetworkGymTags(networkGymId).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingDataRefreshList(false); setLoadingData(false); window.location.href = '/'; }, 5000);
                        setLoadingData(false);
                        setTagData([]);
                    } else if (isSuccess(returned.status)) {
                        let returnedTags = returned.data;
                        // console.log('returned.data: ', returned.data);
                        returnedTags = returnedTags.sort((a: any, b: any) => a.tag < b.tag ? -1 : a.tag > b.tag ? 1 : 0);
                        setTagData(returnedTags);
                    } else {
                        setTagData([]);
                    }
                    setLoadingDataRefreshList(false);
                    setLoadingData(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingDataRefreshList(false);
                    setLoadingData(false);
                    setTagData([]);
                }
            });
        } else {
            if (isMounted) {
                setLoadingDataRefreshList(false);
                setLoadingData(false);
                setTagData([]);
            }
        }
        return () => { isMounted = false };
    };

    const refreshListBranchGyms = async () => {
        let isMounted = true;
        const branchGyms: any[] = []

        const branchGymsByStorageToken = await ls.branchGym.getBranchGymsByStorageToken();

        if (branchGymsByStorageToken && branchGymsByStorageToken.length > 0) {
            for (let i = 0; i < branchGymsByStorageToken.length; i++) {
                const branchGym = branchGymsByStorageToken[i];
                const branchGymReturned: any = await branchGymService.findBranchGymById(branchGym._id);
                if (branchGymReturned.data && branchGymReturned.data && !branchGymReturned.data.nonpayer && !branchGymReturned.data.unsubscribe) {
                    branchGyms.push(branchGymReturned.data)
                }
            }
            setBranchGymData(branchGyms);
        } else {
            setBranchGymData([]);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return () => { isMounted = false };
    };

    const setEditStepData = async (formData: IFormDataTag) => {
        props.args.setLoading(true);
        if (formData && formData._id) {
            const resultTag = await tagService.findTagById(formData._id);
            setEditFormData(Object.assign({}, resultTag.data));
            props.args.setLoading(false);
            setDialogData(true);
        } else {
            setEditFormData(null);
            props.args.setLoading(false);
            setDialogData(true);
        }
    }

    const listData = () => {
        let listData = tagData ? tagData : [];

        listData = listData.sort((l1: any, l2: any) => {
            return l1.title < l2.title ? -1 : l1.title > l2.title ? 1 : 0;
        });

        // listData.forEach((d: any) => {
        //     d.tag = loadBranchGyms(d.customer?._id, branchGymData, tagData);
        //     d.tags = d.tag.filter((f: any) => f.selected).map((b: any) => b.branchGymName).join(', ');
        // });

        // console.log('tagData: ', tagData);

        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;

        if (loadingData) return createSkeletons(8);


        const buttons = [];
        if (checkRoleHierarchyByToken(permissionBlockDefined)) {
            buttons.push({
                evalCondition: '(!data.blocked || data.blocked === false) ', key: `bloquear`, icon: 'pi-lock',
                method: (type: string, _id?: string | null | undefined, ...args: string[]) => blocked(type, _id, ...args), tooltip: 'Bloquear'
            });

            buttons.push({
                evalCondition: '(data.blocked && data.blocked === true)', key: `desbloquear`, icon: 'pi-lock-open',
                method: (type: string, _id?: string | null | undefined, ...args: string[]) => unblocked(type, _id, ...args), tooltip: 'Desbloquear'
            });
        }

        return createGenericListData('tag', listData,
            { icon: 'pi-bookmark', evalValue: 'data.tag', evalInactive: 'data.blocked' },
            [{ description: '\'Descrição\'', value: 'data.description' },
            { description: '\'Cor\'', value: '', icon: 'pi-bookmark-fill', color: 'data.tagColor', rows: 2 },
            ],
            localStorage.getItem('tag'),
            {
                isEdited: !!checkRoleHierarchyByToken(permissionEditDefined),
                // evalIsEdited: `data.holder._id === '${getCustomerIdStorageToken()}' || ${checkRoleHierarchyByToken(permissionEditDefined)} `,
                isCloned: !!checkRoleHierarchyByToken(RoleRules.admin), isSelected: false,
                loadingData,
                editForceData: { networkGym: networkGymId },
                // removeForceData: ['_id', 'key'],
                select: props.args.select,
                unselect: props.args.unselect,
                setEditFormData: (formData: any) => { setEditStepData(formData) },
                setDialogData: setDialogData,
                buttons: [...buttons]
            });
    }

    const blockOrUnblock = async (tagById: string, block: boolean) => {
        await tagService.saveTag({
            _id: tagById,
            blocked: block
        });
        refreshList();
    }
    const blocked = async (type: string, _id?: string | null | undefined, ...args: string[]) => {
        if (_id) await blockOrUnblock(_id, true);
    }

    const unblocked = async (type: string, _id?: string | null | undefined, ...args: string[]) => {
        if (_id) await blockOrUnblock(_id, false);
    }

    const loadBranchGyms = () => {
        let isMounted = true;
        if (branchGymData && tagData && tagData.length > 0 && tagBranchGymData) {
            setLoadingTagBranchGymList(true);
            let tags = tagData;
            const localTagBranchGym = tagBranchGymData;
            const branchGymsMap = branchGymData.reduce((acc, gym) => ({ ...acc, [gym._id]: gym }), {});
            if (localTagBranchGym && localTagBranchGym.length > 0) {
                tags.forEach(tag => {
                    tag.branchGyms = localTagBranchGym
                        .filter(qmbg => qmbg.tag?._id === tag?._id || qmbg.tag === tag?._id)
                        .map(qmbg => branchGymsMap[qmbg.branchGym])
                        .filter(b => !!b); // Filtra possíveis undefined

                    tag.branchGymString = tag.branchGyms.map((b: any) => b.name).join(', ');
                    tag.branchGymString = tag.branchGymString ? tag.branchGymString : 'nenhum(a)';
                });
            }
            if (isMounted) setTagData([...tags]);
            if (isMounted) setLoadingTagBranchGymList(false);
        }

        return () => { isMounted = false };
    }

    const setNewFormData = () => {
        setEditFormData({
            _id: undefined,
            networkGym: networkGymId,
            branchGym: undefined,
            tag: '',
            description: '',
            tagColor: '',
            blocked: false,
            active: true,
            deleted: false,
            branchGyms: branchGymData
        });

    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: !!networkGymId && branchGymData && checkRoleHierarchyByToken(permissionCreateDefined),
                // addNewRegister('branchGymExternalUser', undefined, { setEditFormData: setEditNewCustomerData, setDialogData }),
                addNewRegister: () => addNewRegister('tag', undefined, { setEditFormData: setNewFormData, setDialogData }),
                hasSearchTerm: false, searchTerm, setSearchTerm, setEditFormData: setNewFormData, setDialogData,
                listData: () => listData()
            })}
            {token && networkGymId && branchGymData ?
                <>
                    <GenericFormDialog<IFormDataTag> key='TagDialog'
                        token={token}
                        objectData='etiqueta'
                        refreshList={() => refreshList()}
                        setLoading={(value) => props.args.setLoading(value)}
                        showDialog={dialogData}
                        setDialogData={(value) => setDialogData(value)}
                        setMessage={(message: IMessage) => props.args.setMessage(message)}
                        editFormData={editFormData}
                        emptyFormData={{
                            _id: undefined,
                            networkGym: networkGymId,
                            branchGym: undefined,
                            tag: '',
                            description: '',
                            tagColor: '',
                            blocked: false,
                            active: true,
                            deleted: false
                        }}
                        retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataTag>[]) => [
                            {
                                colSize: 'col-6', fieldName: 'tag', fieldType: 'InputTextField', description: '* Etiqueta', placeholder: 'Etiqueta', value: formData.values.tag,
                                onChange: formData.handleChange, autoFocus: true,
                            },
                            {
                                colSize: 'col-2', fieldName: 'tagColor', fieldType: 'ColorField', description: '* Cor', value: formData.values.tagColor,
                                defaultColor: 'ffffff',
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            // {
                            //     colSize: 'col-offset-2 col-6', fieldName: 'tagColorShow', fieldType: 'ColorTagShowField', description: '', value: formData.values.tagColor,
                            //     defaultColor: 'ffffff', backgroundColor: '#e6ebe4', borderColor: '#fff',
                            //     onChange: formData.handleChange, autoFocus: false,
                            // },
                            {
                                colSize: 'col-12', fieldName: 'description', fieldType: 'InputTextField', description: '* Descrição', placeholder: 'Descrição', value: formData.values.description,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            // {
                            //     colSize: 'col-12', fieldName: 'branchGyms', fieldType: 'SelectAreaList', description: '* Unidades', placeholder: 'unidades da etiqueta', value: formData.values.branchGyms,
                            //     objectDataList: formData.values.branchGyms,
                            //     colspanItem: 'col-4', idField: '_id',
                            //     nameField: 'name',
                            //     selectField: 'selected',
                            //     onChangeListOption: (branchGymId: string, select: boolean) => {
                            //         const list = formData.values.branchGyms;
                            //         list.forEach((l: any) => {
                            //             if (l._id === branchGymId) l.selected = select;
                            //         });
                            //         formData.setFieldValue('branchGyms', list);
                            //     }, autoFocus: false,
                            // },
                        ])}
                        editForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        // editFunctions={[]}
                        formLists={[]}
                        validate={(data: any) => {
                            let errors: any = {};

                            if (!data.tag) {
                                errors.tag = 'Etiqueta é obrigatório.';
                            } else if (data.tag.length < 5) {
                                errors.tag = 'Etiqueta precisa ter mais que 2 caracteres.';
                            } else if (data.tag.length > 25) {
                                errors.tag = 'Etiqueta precisa ter menos que 25 caracteres.';
                            }

                            if (!data.tagColor) {
                                errors.tagColor = 'Cor é obrigatório.';
                            }

                            if (!data.description) {
                                errors.description = 'Descrição é obrigatório.';
                            }

                            return errors;
                        }}
                        submitForceData={{
                            networkGym: networkGymId
                        }}
                        onSubmit={(props: IPropsFormDialog<IFormDataTag>, data: FormikValues, formData: FormikInterface) => {
                            let dataTag = Object.assign({}, data);

                            dataTag.tagColor = dataTag.tagColor.replace('#', '');

                            new TagService().saveTag(dataTag)
                                .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                                .catch((error: any) => saveFailed(props, formData)(error));

                        }} />
                    {/* 
                    <GenericFormDialog<IFormDataTag> key='ExternalUserEditDialog'
                        token={token}
                        objectData='etiqueta'
                        refreshList={() => refreshList()}
                        setLoading={(value) => props.args.setLoading(value)}
                        showDialog={editDialogData}
                        setDialogData={(value) => setEditDialogData(value)}
                        setMessage={(message: IMessage) => props.args.setMessage(message)}
                        editFormData={editFormData}
                        emptyFormData={{
                            _id: undefined,
                            networkGym: networkGymId,
                            branchGym: undefined,
                            tag: '',
                            description: '',
                            tagColor: '',
                            blocked: false,
                            active: true,
                            deleted: false
                        }}
                        retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataTag>[]) => [
                            {
                                colSize: 'col-10', fieldName: 'tag', fieldType: 'InputTextField', description: '* Etiqueta', placeholder: 'Etiqueta', value: formData.values.tag,
                                onChange: formData.handleChange, autoFocus: true,
                            },
                            {
                                colSize: 'col-2', fieldName: 'tagColor', fieldType: 'ColorField', description: '* Cor', value: formData.values.tagColor,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-12', fieldName: 'description', fieldType: 'InputTextField', description: '* Descrição', placeholder: 'Descrição', value: formData.values.description,
                                onChange: formData.handleChange, autoFocus: false,
                            },
                            {
                                colSize: 'col-12', fieldName: 'branchGyms', fieldType: 'SelectAreaList', description: '* Unidades', placeholder: 'unidades da etiqueta', value: formData.values.branchGyms,
                                objectDataList: formData.values.branchGyms,
                                colspanItem: 'col-4', idField: 'branchGymId',
                                nameField: 'branchGymName',
                                selectField: 'selected',
                                onChangeListOption: (branchGymId: string, select: boolean) => {
                                    const list = formData.values.branchGyms;
                                    list.forEach((l: any) => {
                                        if (l.branchGymId === branchGymId) l.selected = select;
                                    });
                                    formData.setFieldValue('branchGyms', list);
                                }, autoFocus: false,
                            },
                        ])}
                        editForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        // editFunctions={[]}
                        formLists={[]}
                        validate={(data: any) => {
                            let errors: any = {};

                            if (!data.tag) {
                                errors.tag = 'Etiqueta é obrigatório.';
                            } else if (data.tag.length < 5) {
                                errors.tag = 'Etiqueta precisa ter mais que 2 caracteres.';
                            } else if (data.tag.length > 15) {
                                errors.tag = 'Etiqueta precisa ter menos que 15 caracteres.';
                            }

                            if (!data.tagColor) {
                                errors.tagColor = 'Nome é obrigatório.';
                            }

                            if (!data.description) {
                                errors.description = 'Descrição é obrigatório.';
                            }

                            return errors;
                        }}
                        submitForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        onSubmit={(props: IPropsFormDialog<IFormDataTag>, data: FormikValues, formData: FormikInterface) => {
                            const dataTag = Object.assign({}, data);

                            console.log('dataTag: ', dataTag);

                            // updateBranchGymsCustomer(externalUser, externalUser._id).finally(() => {
                            //     // console.log('updateBranchGymsCustomer', 'finally');
                            //     ; new CustomerService().saveCustomer(externalUser)
                            //         .then((returned: IPerformRequestResult<any>) => {
                            //             // console.log('saveCustomer', 'then');
                            //             if (returned.status === HttpStatus.NOT_FOUND) {
                            //                 saveSuccessed(props, formData, 'Email informado já cadastrado no sistema Chat4Fit, verifique o e-mail informado ou entre em contato com o suporte!')(returned);
                            //             } else {
                            //                 saveSuccessed(props, formData)(returned);
                            //             }
                            //         })
                            //         .catch((error: any) => {
                            //             // console.log('saveCustomer', 'catch');
                            //             saveFailed(props, formData)(error);
                            //         });
                            // });
                        }} /> */}

                    {/* <GenericStepDialog<IStepListDataTag> key='TagDialog'
                        token={token}
                        objectData='etiqueta'
                        refreshList={() => refreshList()}
                        setLoading={(value) => props.args.setLoading(value)}
                        showStepDialog={dialogData}
                        setStepDialogData={(value) => setDialogData(value)}
                        setMessage={(message: IMessage) => props.args.setMessage(message)}
                        editStepData={editFormData}
                        emptyStepData={{
                            _id: undefined,
                            networkGym: networkGymId,
                            branchGym: undefined,
                            tag: '',
                            description:'',
                            tagColor: '',
                            blocked: false,
                            active: true,
                            deleted: false
                        }}
                        editForceData={{
                            networkGym: networkGymId,
                            // branchGym: branchGymId
                        }}
                        showDialog={false}
                        setDialogData={function (value: React.SetStateAction<boolean>): void {
                            throw new Error('Function not implemented.');
                        }} /> */}
                </>
                :
                null
            }
        </>
    )
}


