import moment from "moment";
import { makeKeyByString } from "./hash";

export enum EnumTypeShowMessage {
    hidden = 'hidden',
    systemMessage = 'system.message',
    botMessage = 'bot.message',
    botMessageHidden = 'bot.message.hidden',
    interactionMessage = 'interaction.message',
    imageMessage = 'image.message',
    videoMessage = 'video.message',
    pdfMessage = 'pdf.message',
    npsQuestionHidden = 'nps.question.hidden',
}

export const createMessage = (
    jid: string, keyData: string,
    typeShowMessage: EnumTypeShowMessage,
    message: string,
    args: {
        trackerMobileId: string,
        networkGymId: string,
        branchGymId?: string,
    },
    messageParams?: {
        buttons?: any[],
        footer?: string,
        replaceMessages?: { key: string, value: string }[]
    },
) => {
    let sendMessage = message;
    messageParams?.replaceMessages?.forEach(r => sendMessage = sendMessage.replace(new RegExp('{{' + r.key + '}}'), r.value));
    const messageCreated = {
        key: `${jid}.web.whatsapp.${makeKeyByString(keyData)}`,
        typeMessage: 'web.whatsapp',
        typeObjectMessage: 'messagesSender.message.conversation',
        typeShowMessage,
        jid: jid,
        fromMe: true,
        text: sendMessage,
        footer: messageParams?.footer || '',
        buttons: messageParams?.buttons || undefined,
        dateText: moment().toDate(),
        networkGym: args.networkGymId,
        branchGym: args.branchGymId,
        trackerMobile: args.trackerMobileId,
        group: false,
        readReceipt: false,
        sendedMessage: false,
        deleted: false,
        active: true,
    };
    // console.log('messageCreated: ', JSON.stringify(messageCreated));
    return messageCreated;
}