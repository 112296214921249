import { Button } from "primereact/button";
import { Skeleton } from "primereact/skeleton";
import { IButtons, IConfig } from "../generics/genericCardConfig";

export const createSkeletons = (size: number) => {
    const skeletons = [];
    for (let i = 1; i < size; i++) {
        skeletons.push(<div key={'skeleton' + i} className='col-6 md:col-3 lg:col-3' style={{ padding: '0.5rem 0.5rem 1rem 0.5rem', height: 84 + 2 * 14 }}>
            <div className='card shadow-4 border-round p-fluid' style={{ position: 'relative', color: '#238c70', backgroundColor: '#118CAC2A', padding: '0.8rem' }}>
                <div className='grid' style={{ minWidth: '100%' }}>
                    <div className='col-12 flex' style={{ fontSize: '0.8rem', padding: 0, marginBottom: 10 }}>
                        <Skeleton shape='circle' size='1rem' className='mr-2' />
                        <Skeleton width='5rem' className='mb-2' />
                    </div>
                    <div className='col-12' style={{ marginLeft: 15, padding: 0, height: 16 }}>
                        <Skeleton width='80%' height='14px' />
                    </div>
                    <div className='col-12' style={{ marginLeft: 15, padding: 0, height: 16 }}>
                        <Skeleton width='80%' height='14px' />
                    </div>
                    <div className='col-12 flex flex-nowrap justify-content-end' style={{ margin: '10px 0px -5px 0px', padding: 0 }}>
                        <Skeleton shape='circle' size='1rem' className='mr-2' />
                        <Skeleton shape='circle' size='1rem' className='mr-2' />
                    </div>
                </div>
            </div>
        </div>);
    }
    return skeletons;
};

export const createListData = (config: IConfig[], buttons: IButtons[] = []) => {
    const maxLines = config.reduce((p, c) => {
        const countLines = c.lines?.reduce((lp, lc) => lp += lc ? lc.rows ? lc.rows : 1 : 0, 0) || 0;
        return p = p < countLines ? countLines : p;
    }, 0);
    const sortData = config.sort((c1, c2) => c1.selected === true ? -1 : c2.selected === true ? 1 : 0);
    return sortData.map((c: any) => {

        return (<div key={c.data.key || c.data._id} className='col-6 md:col-3 lg:col-3' style={{ padding: '0.5rem 0.5rem 1rem 0.5rem', height: 86 + maxLines * 14 }}>
            <div className='card shadow-4 border-round p-fluid' style={{ position: 'relative', color: c.title.inactive ? '#222' : '#238c70', backgroundColor: c.title.inactive ? '#ccc9' : `${c.selected === true ? '#FF03' : '#118CAC2A'}`, padding: '1.2rem' }}>
                <div className='grid' style={{ minWidth: '100%' }}>
                    <div className='col-12' style={{ fontSize: '0.8rem', padding: 0, marginBottom: 10, height: (c.title.rows ? c.title.rows : 1) * 16, overflow: 'hidden' }}><i className={`pi pi-fw ${c.title.icon}`} style={{ marginRight: 5 }}></i><b>{c.title.value}</b></div>
                    <div className='grid' style={{ marginLeft: 5, padding: 0, height: maxLines * 14 }}>
                        {c.lines ?
                            c.lines.map((line: any, index: number) => {
                                return (line ?
                                    <div key={index} className='col-12 flex' style={{ fontSize: line.fontSize || '0.8rem', padding: 0, height: (line.rows ? line.rows : 1) * 14, width: '100%', overflow: line.overflow || 'hidden' }}>
                                        <b style={{ fontSize: '0.7rem' }}>{`${line.description ? `${line.description}: ` : ''}`}</b>
                                        {line.icon ?
                                            <span className={`pi ${line.icon}`} style={{ fontSize: '2rem', color: `#${line.color}`, textShadow: `1px 0 rgba(255, 255, 255, 0.2666666667), -1px 0 rgba(255, 255, 255, 0.2666666667), 0 1px rgba(255, 255, 255, 0.2666666667), 0 -1px rgba(255, 255, 255, 0.2666666667), 1px 1px rgba(255, 255, 255, 0.2666666667), -1px -1px rgba(255, 255, 255, 0.2666666667), 1px -1px rgba(255, 255, 255, 0.2666666667), -1px 1px rgba(255, 255, 255, 0.2666666667)` }}></span>
                                            :
                                            <>&nbsp;{line.value}</>
                                        }
                                    </div>
                                    : null);
                            })
                            :
                            null}
                    </div>
                    {/* <div className='col-12' style={{ marginTop: -10, padding: 0 }}>
                        <Divider align='right'> <div className='inline-flex align-items-center' style={{ fontSize: '0.8rem' }}> <i className='pi pi-cog mr-2' />Ações</div> </Divider>
                    </div> */}
                    <div className='col-12 flex flex-nowrap justify-content-end' style={{ margin: '10px 0px -5px 0px', padding: 0 }}>
                        {c.buttons.map((b: any) => b ? <div key={b.key} ><Button icon={`pi ${b.icon}`} className='p-button-rounded shadow-3' style={{ marginLeft: 2, fontSize: '0.2rem', height: 16, width: 16, padding: '0.9rem' }} onClick={() => b.method ? b.method('', c.data._id) : b.onClick ? b.onClick(c.data._id) : null} tooltip={b.tooltip} /></div> : null)}
                        {buttons.map(b => <div key={b.key} ><Button icon={`pi ${b.icon}`} className='col-6 p-button-rounded shadow-3' style={{ marginLeft: 2 }} onClick={() => b.method ? b.method('', c.data._id) : b.onClick ? b.onClick(c.data._id) : null} tooltip={b.tooltip} /></div>)}
                    </div>
                </div>
            </div>
        </div>);
    });
};

export const createListScaleData = (config: IConfig[], buttons: IButtons[] = []) => {
    const maxLines = config.reduce((p, c) => {
        const countLines = c.lines?.reduce((lp, lc) => lp += lc ? lc.rows ? lc.rows : 1 : 0, 0) || 0;
        return p = p < countLines ? countLines : p;
    }, 0);
    const sortData = config.sort((c1, c2) => c1.selected === true ? -1 : c2.selected === true ? 1 : 0);
    // console.log('sortData: ', sortData.map(s => s.lines?.map(l => l.value)));
    return sortData.map((c: any) => {
        return (<div key={c.data.key || c.data._id} className='col-6 md:col-3 lg:col-3' style={{ padding: '0.5rem 0.5rem 1rem 0.5rem', height: 86 + maxLines * 14 }}>
            <div className='card shadow-4 border-round p-fluid' style={{ position: 'relative', color: '#238c70', backgroundColor: `${c.selected === true ? '#FF03' : '#118CAC2A'}`, padding: '1.2rem' }}>
                <div className='grid' style={{ minWidth: '100%' }}>
                    <div className='col-12' style={{ fontSize: '0.8rem', padding: 0, marginBottom: 10, height: (c.title.rows ? c.title.rows : 1) * 16, overflow: 'hidden' }}><i className={`pi pi-fw ${c.title.icon}`} style={{ marginRight: 5 }}></i><b>{c.title.value}</b></div>
                    <div className='grid' style={{ marginLeft: 5, padding: 0, height: maxLines * 14 }}>
                        {c.lines ?
                            // eslint-disable-next-line array-callback-return
                            c.lines.map((line: any, index: number) => {
                                if (line)
                                    return (
                                        <div key={index} className='col-12 flex' style={{ fontSize: '0.8rem', padding: 0, height: (line.rows ? line.rows : 1) * 14, width: '100%', overflow: 'hidden' }}>
                                            <b style={{ fontSize: '0.7rem' }}>{`${line.description ? `${line.description}: ` : ''}`}</b>&nbsp;{line.value}
                                        </div>
                                    )
                            })
                            :
                            null}
                    </div>
                    {/* <div className='col-12' style={{ marginTop: -10, padding: 0 }}>
                        <Divider align='right'> <div className='inline-flex align-items-center' style={{ fontSize: '0.8rem' }}> <i className='pi pi-cog mr-2' />Ações</div> </Divider>
                    </div> */}
                    <div className='col-12 flex flex-nowrap justify-content-end' style={{ margin: '10px 0px -5px 0px', padding: 0 }}>
                        {c.buttons.map((b: any) => b ? <div key={b.key} ><Button icon={`pi ${b.icon}`} className='p-button-rounded shadow-3' style={{ marginLeft: 2, fontSize: '0.2rem', height: 16, width: 16, padding: '0.9rem' }} onClick={() => b.onClick(c.data._id)} tooltip={b.tooltip} /></div> : null)}
                        {buttons.map(b => <div key={b.key} ><Button icon={`pi ${b.icon}`} className='col-6 p-button-rounded shadow-3' style={{ marginLeft: 2 }} onClick={() => b.method ? b.method('', c.data._id) : b.onClick ? b.onClick(c.data._id) : null} tooltip={b.tooltip} /></div>)}
                    </div>
                </div>
            </div>
        </div>);
    });
};

export const createListDataSelect = (config: IConfig[]) => {
    return config.map((c: any) => <div key={c.data.key || c.data._id} className='col-12 sm:col-6 md:col-6 lg:col-4'>
        <div className='shadow-4 border-round p-fluid' style={{ position: 'relative', color: '#238c70', backgroundColor: '#118CAC2A', padding: '0 1rem' }}>
            <div className='grid' style={{ minWidth: '100%' }}>
                <div className='col-8'><i className={`pi pi-fw ${c.title.icon}`} style={{ fontSize: '1.2rem', marginRight: 20 }}></i><b>{c.title.value}</b></div>
                <div className='col-4 grid flex flex-nowrap justify-content-center' style={{ margin: 'auto' }}>
                    {c.buttons.map((b: any) => b ? <div key={b.key} className='col-2'><Button icon={`pi ${b.icon}`} className='p-button-rounded shadow-3' style={{ marginLeft: 10, fontSize: '0.2rem', height: 28, width: 28 }} onClick={() => b.method ? b.method('', c.data._id) : b.onClick ? b.onClick(c.data._id) : null} tooltip={b.tooltip} /></div> : null)}
                </div>
            </div>
        </div>
    </div>);
};