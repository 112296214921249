const getSelectorType = (type: string): string => {
    switch (type) {
        case 'id': return '#';
        case 'classname': return '.';
        default: return '';
    }
}

function changeAttrib<T>(type: 'id' | 'classname', attrib: keyof T, key: string, value?: any): void {
    // console.log('changeAttrib: ', `${getSelectorType(type)}${key}`, document.querySelectorAll(`${getSelectorType(type)}${key}`));
    [].forEach.call(document.querySelectorAll(`${getSelectorType(type)}${key}`), (el: T) => {
        el[attrib] = value || '';
    });
}

const methods = { changeAttrib };

export default methods;