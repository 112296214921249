import { useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { retrieveRoute } from './projects/routes';

const AppWrapper = (props: any) => {
    let location = useLocation();
    const project = props.project;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [location]);

    const route = retrieveRoute(project, location);
    let returned;
    if (route.type === 'route') returned = route.object;
    else if (route.type === 'class') {
        const App = route.object;
        returned = <App project={project} />
    }
    return returned;
}

export default withRouter(AppWrapper);
