import enviroment from "../../../.env";
import projectEnviroment from "../../../project.enviroments";
import ls from "../../../projects/localStorage";
import { IPerformRequestResult } from "../../../utils/interfaces";
import { Method } from "../../../utils/method";
import PerformRequest from "../../../utils/performRequest";
import AuthenticateService from "../AuthenticateService";
import EntitiesService, { Entities } from "./EntitiesService";

export default class GenericService extends PerformRequest<any> {
    protected async findAll<T>(entity: Entities, admin: boolean = false, search?: string | undefined, token?: string | null, headers?: any): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<T>({
            method: Method.GET,
            url: this.createUrl(admin, entity, search ? `/byRegex/${search}` : ''),
            // headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)
            headers: { ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)), ...(headers ? headers : {}) },
        });
    }

    protected async findById<T>(entity: Entities, admin: boolean = false, _id: string | null, token?: string | null, headers?: any): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<T>({
            method: Method.GET,
            url: this.createUrl(admin, entity, `/id/${_id}`),
            // headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)
            headers: { ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)), ...(headers ? headers : {}) },
        })
    }

    protected async findByKey<T>(entity: Entities, admin: boolean = false, key: string | null, token?: string | null): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<T>({
            method: Method.GET,
            url: this.createUrl(admin, entity, `/key/${key}`),
            headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)
        })
    }

    protected async findByIdNoAnon<T>(entity: Entities, admin: boolean = false, _id: string | null, token?: string | null, headers?: any): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        // console.log('this.prepareBearerHeader(userToken): ', this.prepareBearerHeader(userToken));
        return await this.call<T>({
            method: Method.GET,
            url: this.createUrl(admin, entity, `/id/${_id}`),
            headers: {
                ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)), ...(headers ? headers : {}),
                summaries: false
            }
        })
    }

    protected async findByEmailNoAnon<T>(entity: Entities, admin: boolean = false, email: string | null, token?: string | null): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        // console.log('this.prepareBearerHeader(userToken): ', this.prepareBearerHeader(userToken));
        return await this.call<T>({
            method: Method.GET,
            url: this.createUrl(admin, entity, `/byField/email/${email}`),
            headers: {
                ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)),
                summaries: false
            }
        })
    }

    protected async findByReferenceIdWithProjection<T>(entity: Entities, admin: boolean = false, referenceField: string,
        referenceId: string | null, projection?: string, search?: string | undefined, token?: string | null): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        const headers = admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)
        if (projection) headers.projection = projection;
        return await this.call<T>({
            method: Method.GET,
            url: search ? this.createUrl(false, entity, `/byRegexField/${search}/${referenceField}/${referenceId}`) :
                this.createUrl(false, entity, `/byField/${referenceField}/${referenceId}`),
            headers
        });
    }

    protected async findByReferenceId<T>(entity: Entities, admin: boolean = false, referenceField: string,
        referenceId: string | null, search?: string | undefined, token?: string | null): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');

        return await this.call<T>({
            method: Method.GET,
            url: search ? this.createUrl(false, entity, `/byRegexField/${search}/${referenceField}/${referenceId}`) :
                this.createUrl(false, entity, `/byField/${referenceField}/${referenceId}`),
            headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)
        });
    }


    protected async findByQuery<T>(entity: Entities, admin: boolean = false,
        params: {
            references?: { field: Entities, value: any }[],
            queries?: { field: string, value: any }[],
            sorts?: { field: string, value: number }[],
            projections?: { field: string, detail?: string | { field: string }[] }[]
            ignoreSummaryData?: boolean
        },
        token?: string | null, headers?: any): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');

        const query: any = {};
        params.queries?.forEach(r => query[r.field] = r.value);
        params.references?.forEach(r => query[EntitiesService(r.field).singular] = r.value);

        const sort: any = {};
        params.sorts?.forEach(r => sort[r.field] = r.value);

        const projection: any = {};
        params.projections?.forEach(r => {
            projection[r.field] = 1;
        });

        const projectionSub: any = {};
        params.projections?.forEach(r => {
            if (r.detail && !Array.isArray(r.detail)) {
                projectionSub[`${r.field}`] = r.detail;
            } else if (r.detail && Array.isArray(r.detail)) {
                projectionSub[`${r.field}`] = {};
                r.detail?.forEach(d => {
                    projectionSub[`${r.field}`][d.field] = 1;
                });
            }
        });

        // console.log('query: ', query);

        let resultSearch = await this.call<T>({
            method: Method.POST,
            url: this.createUrl(false, entity, '/search'),
            headers: { ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)), ...(headers ? headers : {}) },
            data: JSON.stringify({ search: { query, sort, projection, projectionSub, ignoreSummaryData: params.ignoreSummaryData } })
        });

        // if (entity === Entities.TRACKER_MESSAGE) console.log('resultSearch: ', resultSearch?.data?.length);

        return resultSearch;
    }



    // findDistinctByQuery(TRACKER_MESSAGE: Entities, arg1: boolean, field: string, queries: { field: string; value: ({ networkGym: string; } | { dateText: { $gte: Date; $lt: Date; }; })[]; }[]) {
    //     throw new Error('Method not implemented.');
    // }

    protected async findDistinctByQuery<T>(entity: Entities, admin: boolean = false,
        field: string,
        params: {
            references?: { field: Entities, value: any }[],
            queries?: { field?: string, value: any }[],
            sorts?: { field: string, value: number }[],
            projections?: { field: string, detail?: string | { field: string }[] }[]
            ignoreSummaryData?: boolean
        },
        token?: string | null, headers?: any): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');

        let query: any = {};
        params.queries?.forEach(r => r.field && r.value ? query[r.field] = r.value : query = r.value);
        params.references?.forEach(r => query[EntitiesService(r.field).singular] = r.value);

        const sort: any = {};
        params.sorts?.forEach(r => sort[r.field] = r.value);

        // console.log('query: ', query);

        return await this.call<T>({
            method: Method.POST,
            url: this.createUrl(false, entity, `/distinct/${field}`),
            headers: { ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)), ...(headers ? headers : {}) },
            data: JSON.stringify({ search: { query, sort, ignoreSummaryData: params.ignoreSummaryData } })
        });
    }

    protected async save<T>(entity: Entities, admin: boolean = false, data: any, token?: string | null): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        const dataEntity: any = {};
        dataEntity[`${EntitiesService(entity).singular}`] = data;
        const saved = await this.call<T>({
            method: data._id ? Method.PUT : Method.POST,
            url: this.createUrl(admin, entity, data._id ? `/id/${data._id}` : ''),
            headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken),
            data: JSON.stringify(dataEntity)
        });
        // console.log('saved: ', saved);
        return saved;
    }

    protected async updateFiltered<T>(entity: Entities, admin: boolean = false, id: string, data: { filter: any, object: any }, token?: string | null): Promise<IPerformRequestResult<T>> {
        if (!id) throw new Error('Ocorreu um erro ao tentar realizar esta ação, código do registro não informado!');
        if (!data.filter) throw new Error('Ocorreu um erro ao tentar realizar esta ação, filtro não informado!');
        if (!data.object) throw new Error('Ocorreu um erro ao tentar realizar esta ação, dados não informados!');
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        const dataEntity: any = {};
        dataEntity[`${EntitiesService(entity).singular}`] = data;
        const updateFiltered = await this.call<T>({
            method: Method.PUT,
            url: this.createUrl(admin, entity, `/filtered/${id}`),
            headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken),
            data: JSON.stringify(dataEntity)
        });
        // console.log('updateFiltered: ', updateFiltered);
        return updateFiltered;
    }

    protected async deleteById<T>(entity: Entities, admin: boolean = false, _id: string | null, token?: string | null, headers?: any): Promise<IPerformRequestResult<T>> {
        let userToken = ls.getLocalStorageToken();
        if (token) userToken = token;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        userToken = admin ? await new AuthenticateService().adminToken(userToken) : userToken;
        if (!userToken) throw new Error('Você não tem permissão para este acesso!');
        return await this.call<T>({
            method: Method.DELETE,
            url: this.createUrl(admin, entity, `/id/${_id}`),
            // headers: admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)
            headers: { ...(admin ? this.prepareBearerHeaderAdmin(userToken) : this.prepareBearerHeader(userToken)), ...(headers ? headers : {}) },
        })
    }

    private createUrl(admin: boolean = false, entity: Entities, endpoint: string, plural: boolean = true): string {
        // console.log('xxx: ', projectEnviroment.buildPath(`${admin ? 'admin/' : ''}${plural ? EntitiesService(entity).plural : EntitiesService(entity).singular}${endpoint}`));
        return projectEnviroment.buildPath(`${admin ? 'admin/' : ''}${plural ? EntitiesService(entity).plural : EntitiesService(entity).singular}${endpoint}`, enviroment());
    }
}
