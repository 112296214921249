/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../../projects/checkRoleHierarchy';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import { IMessage } from '../Control';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { GenericFields } from '../commons/GenericFields';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataSystemMessageDefault } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
// import { TagSelecteds } from '../../AppTagSelecteds';
import SystemMessageDefaultService from '../../service/admin/SystemMessageDefaultService';
import './SystemMessageDefault.css';

const systemMessageDefaultService = new SystemMessageDefaultService();

export const SystemMessageDefault = (props: IPropsArgsComponents) => {
    const [title] = useState('Mensagem Sistema Padrão');
    const [objectData] = useState('mensagem');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataSystemMessageDefault[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [editFormData, setEditFormData] = useState<IFormDataSystemMessageDefault | undefined>();

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'systemMessageDefault', show: true, considered: false },
    ]), []);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        systemMessageDefaultService.allSystemMessageDefaults(searchTerm).then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { props.args.setLoading(false); setLoadingData(false); window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    let systemMessageDefaultDatas = returned.data;
                    let listSystemMessage = systemMessageDefaultDatas.filter((sm: any) => sm.systemMessageKey !== 'OUTRA_UNIDADE');
                    setData(listSystemMessage.sort((s1: any, s2: any) => s1.title < s2.title ? -1 : s1.title > s2.title ? 1 : 0));
                } else {
                    setData([]);
                }
                setLoadingData(false); props.args.setLoading(false);
            }
        }).catch(error => {
            console.log('error', error);
            if (isMounted) {
                setLoadingData(false); props.args.setLoading(false);
                setData([]);
            }
        });
        return () => { isMounted = false };
    };

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setEditFormData(undefined);
    //     setDialogData(true);
    // }

    const edit = (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;
        if (type === 'systemMessageDefault') {
            // console.log('item: ', item);
            setEditFormData({
                _id: item._id,
                key: item.key,
                systemMessageKey: item.systemMessageKey,
                title: item.title,
                origin: item.origin,
                text: item.text,
                description: item.description,
                blockedDefault: item.blockedDefault,
                deleted: item.deleted,
                active: item.active
            });
            setDialogData(true);
        }
    }

    // const clone = async (type: string, _id?: string, ...args: string[]) => {
    //     const item = data.find(d => d._id === _id);
    //     if (!item) return;
    //     if (type === 'systemMessageDefault') {
    //         setEditFormData({
    //             key: item.key,
    //             systemMessageKey: item.systemMessageKey,
    //             title: item.title,
    //             origin: item.origin,
    //             text: item.text,
    //             description: item.description,
    //             blockedDefault: item.blockedDefault,
    //             deleted: item.deleted,
    //             active: item.active
    //         });
    //         setDialogData(true);
    //     }
    // }

    const listData = () => {
        const listData = data ? data : [];
        if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map(d => {
                    const selectedSystemMessageDefaultId = localStorage.getItem('systemMessageDefault');
                    const config: IConfig = {
                        title: { icon: 'pi-wrench', value: d.title, inactive: d.blockedDefault },
                        lines: [
                            // { description: 'Fantasia', value: d.fantasyName },
                            { description: 'Tipo', value: d.origin },
                            { description: 'Bloqueado Padrão', value: d.blockedDefault ? 'Sim' : 'Não' },
                        ],
                        data: d,
                        buttons: d._id ? [
                            checkRoleHierarchyByToken(RoleRules.owner) ? { key: 'editar', icon: 'pi-pencil', onClick: () => edit('systemMessageDefault', d._id), tooltip: 'Editar' } : null,
                            // checkRoleHierarchyByToken(RoleRules.admin) ? { key: `clone`, icon: 'pi-copy', onClick: () => clone('systemMessageDefault', d._id), tooltip: 'Copiar' } : null,
                            // selectedSystemMessageDefaultId === d._id ? { key: 'unidades', icon: 'pi-th-large', onClick: () => { props.args.select('systemMessageDefault', d._id); window.location.href = '/#/branchGyms'; }, tooltip: 'Unidades' } : null,
                            // !ls.checkUniqueLocalStorageToken('systemMessageDefault') && selectedSystemMessageDefaultId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('systemMessageDefault', d._id), tooltip: 'Selecionar' } : null,
                            // !ls.checkUniqueLocalStorageToken('systemMessageDefault') && selectedSystemMessageDefaultId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('systemMessageDefault', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedSystemMessageDefaultId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: false, //checkRoleHierarchyByToken(RoleRules.admin), addNewRegister: () => addNewRegister('systemMessageDefault', undefined, { setEditFormData, setDialogData }),
                hasSearchTerm: false,
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token ?
                <GenericFormDialog<IFormDataSystemMessageDefault> key='SystemMessageDefaultDialog'
                    token={token}
                    objectData={objectData}
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                    editFormData={editFormData}
                    emptyFormData={{
                        key: '',
                        systemMessageKey: '',
                        title: '',
                        origin: '',
                        text: '',
                        description: '',
                        blockedDefault: false,
                        deleted: false,
                        active: true
                    }}
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataSystemMessageDefault>[]) => [
                        // GenericFields.indentificationAdminField(formData, { colSize: 4 }),
                        {
                            colSize: 'col-8', fieldName: 'systemMessageKey', fieldType: 'InputTextField', description: 'Chave Mensagem:', placeholder: 'Chave da mensagem', value: formData.values.systemMessageKey,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        {
                            colSize: 'col-4', fieldName: 'origin', fieldType: 'InputTextField', description: 'Tipo:', placeholder: 'Tipo', value: formData.values.origin,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        {
                            colSize: 'col-8', fieldName: 'title', fieldType: 'InputTextField', description: 'Título:', placeholder: 'Título', value: formData.values.title,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        GenericFields.isBlockedDefaultField(formData, { colSize: 4 }),
                        {
                            colSize: 'col-12', fieldType: 'IInstructionField', description: 'Instruções:', value: formData.values.description,
                            onChange: formData.handleChange, autoFocus: false, disabled: true
                        },
                        {
                            colSize: 'col-12', fieldName: 'text', fieldType: 'InputTextAreaField', description: '* Descrição:', placeholder: 'Descrição', value: formData.values.text, autoFocus: false,
                            rows: 5, cols: 5,
                            onChange: formData.handleChange
                        },
                    ])}
                    editForceData={{}}
                    editFunctions={[]}
                    // editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};


                        if (!data.text) {
                            errors.text = 'Texto da mensagem é obrigatório.';
                        } else if (data.text.length < 5) {
                            errors.text = 'Texto da mensagem precisa ter mais que 2 caracteres.';
                        }

                        return errors;
                    }}
                    submitForceData={{}}
                    onSubmit={(props: IPropsFormDialog<IFormDataSystemMessageDefault>, data: FormikValues, formData: FormikInterface) => {
                        // console.log('data: ', data);
                        let dataSystemMessageDefault = {
                            _id: data._id,
                            text: data.text,
                            blockedDefault: data.blockedDefault
                        }
                        new SystemMessageDefaultService().saveSystemMessageDefault(dataSystemMessageDefault)
                            .then(async (returned: IPerformRequestResult<any>) => {
                                let dependencys:any = returned.data.dependencySystemMessageKeys;
                                dependencys = dependencys.split(';');
                                if (dependencys.length > 0) {
                                    for (let i = 0; i < dependencys.length; i++) {
                                        const dependency = dependencys[i];
                                        let systemMessageDefaultByKey:any = await (new SystemMessageDefaultService()).findSystemMessageDefaultByKey(dependency);
                                        if (systemMessageDefaultByKey && systemMessageDefaultByKey.data && systemMessageDefaultByKey.data.length > 0 && systemMessageDefaultByKey.data[0]) {
                                            let systemMessageDefault = systemMessageDefaultByKey.data[0];
                                            await (new SystemMessageDefaultService()).saveSystemMessageDefault({
                                                _id: systemMessageDefault._id,
                                                blockedDefault: data.blockedDefault
                                            });
                                        }
                                    }
                                }
                                saveSuccessed(props, formData)(returned);
                            })
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }} />
                :
                null
            }
        </>
    )
}
