import { FormikValues } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import enviroment from '../.env';
import { getRoleDescriptionByToken } from '../projects/checkRoleHierarchy';
import ls from '../projects/localStorage';
import { isCPF, isMobilePhone } from '../utils/functions/data';
import { onlyNumbers } from '../utils/functions/string';
import { IPerformRequestResult } from '../utils/interfaces';
import { IMessage } from './components/Control';
import { GenericFormDialog } from './components/commons/GenericFormDialog';
import { returnNoSuccessed, returnSuccessed, saveFailed, saveSuccessed } from './components/commons/functions';
import { IFormDataEditExternalUser } from './components/generics/entities';
import { IPropsFormDialog, IPropsFormListDialog } from './components/generics/formInterfaces';
import { FormikInterface } from './components/interfaces/formikInterface';
import AuthenticateService from './service/AuthenticateService';
import CustomerService from './service/CustomerService';
import DashboardReportService from './service/admin/DashboardReportService';

const authenticateService = new AuthenticateService();
const customerService = new CustomerService();
const dashboardReportService = new DashboardReportService();

const AppTopbar = (props: any) => {
	const [token] = useState(ls.getLocalStorageToken());
	const [mobileMenuActive, setMobileMenuActive] = useState(props.mobileMenuActive);

	const [customerInformation, setCustomerInformation] = useState<any>({});

	const [showEditDialogData, setShowEditDialogData] = useState<boolean>(false);
	const [showDialogConfirmLogout, setShowDialogConfirmLogout] = useState<boolean>(false);
	const [showDialogChangePassword, setShowDialogChangePassword] = useState<boolean>(false);

	const [customerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());
	const [username] = useState<string | null>(ls.customer.getCustomerUsernameStorage());


	const [editPasswordFormData, setEditPasswordFormData] = useState<any>();
	const [editExternalUserFormData, setEditExternalUserFormData] = useState<IFormDataEditExternalUser>();


	const history = useHistory();

	const logout = async () => {
		if (customerId) await dashboardReportService.updateLogonDashboardReport('access', 'logon', customerId);
		localStorage.clear();
		props.setToken(null);
	}

	useEffect(() => setMobileMenuActive(props.mobileMenuActive), [props.mobileMenuActive]);

	useEffect(() => {
		const customerInformation = localStorage.getItem('customer.information');
		if (!customerInformation) {
			const tokenLocalStorage = ls.getLocalStorageToken();
			// console.log('tokenLocalStorage:  ',  tokenLocalStorage);
			if (tokenLocalStorage) {
				customerService.getInformation(tokenLocalStorage).then((customerInformation) => {
					localStorage.setItem('customer.information', JSON.stringify(customerInformation));
					setCustomerInformation(customerInformation);
				}).catch((error) => {
					console.log('error: ', error);
					setCustomerInformation({});
				});
			}
		} else {
			setCustomerInformation(JSON.parse(customerInformation));
		}
	}, []);

	const goToHome = () => {
		history.push('/')
	}

	// const onTopbarItemClick = (event: any, item: any) => {
	// 	if (props.onTopbarItemClick) {
	// 		props.onTopbarItemClick({
	// 			originalEvent: event,
	// 			item: item
	// 		});
	// 	}
	// }

	let topbarClass = classNames('topbar-menu fadeInDown', { 'topbar-menu-active': props.topbarMenuActive })
	let horizontalIcon = (props.layoutMode === 'horizontal') &&
		<button type="button" className="p-link topbar-logo" onClick={goToHome}>
			<img alt="logo" src="chat4fit/assets/layout/images/logo-horizontal-white.png" />
		</button>;

	const editCustomerData = async () => {
		const customer = await customerService.findCustomerById(ls.customer.getCustomerIdStorageToken());
		setEditExternalUserFormData({
			_id: customer.data._id,
			name: customer.data.name,
			nickname: customer.data.nickname,
			email: customer.data.email,
			cpf: customer.data.cpf,
			mobilePhone: customer.data.mobilePhone,
			customer: customer.data,
			active: true,
			deleted: false
		});
		setShowEditDialogData(true);
	}

	const changePassword = () => {
		setEditPasswordFormData({
			username: ls.customer.getCustomerUsernameStorage(),
			oldPassword: '',
			newPassword: '',
			retryPassword: ''
		});
		setShowDialogChangePassword(true);
	}

	return (
		<>
			<div className={classNames("layout-topbar", { 'layout-topbar-sidebar-close': !mobileMenuActive, "layout-homologation": enviroment() === 'HOMOLOGATION' })}>
				{horizontalIcon}
				<img alt="logo" src="chat4fit/assets/layout/images/logo-horizontal-white.png" className="mobile-logo" />

				<button type="button" className="p-link menu-btn" onClick={props.onMenuButtonClick}>
					<i className="pi pi-bars"></i>
				</button>

				<Button type="button" className="p-link topbar-menu-btn" onClick={props.onTopbarMobileMenuButtonClick} tooltip='Meus dados' tooltipOptions={{ position: 'left' }} >
					<i className="pi pi-user"></i>
				</Button>

				<span className="topbar-item-name profile-name">{customerInformation.nickname}<span className="profile-type">{`(${getRoleDescriptionByToken()})`} </span></span>

				{props.children}

				<div className="layout-topbar-menu-wrapper">
					<ul className={topbarClass}>
						<li className={classNames('profile-item', { 'active-topmenuitem': props.activeTopbarItem === 'profile' })}>
							<ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
								<li className='flex justify-content-center'>
									<span className="topbar-item-name profile-name">{customerInformation.nickname}</span>
								</li>
								<li className='flex justify-content-center'>
									<span className="profile-type">{`${getRoleDescriptionByToken()}`} </span>
								</li>
								<li className='flex justify-content-center'>
									<span className="profile-email">{ls.customer.getCustomerUsernameStorage()}</span>
								</li>
								<li>
									<Divider />
								</li>
								<li className='profile-option' role="menuitem" onClick={() => editCustomerData()}>
									<i className="pi pi-user"></i>
									<span>Profile</span>
								</li>
								<li className='profile-option' role="menuitem" onClick={() => changePassword()}>
									<i className="pi pi-lock"></i>
									<span>Alterar Senha</span>
								</li>
								<li>
									<Divider />
								</li>
								<li className='profile-option' role="menuitem" onClick={() => setShowDialogConfirmLogout(true)}>
									<i className="pi pi-sign-out"></i>
									<span>Logout</span>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<Dialog visible={showDialogConfirmLogout} style={{ width: '40%' }} modal={true} closable={false}
				onHide={() => setShowDialogConfirmLogout(false)}>
				<div className='grid'>
					<div className='col-12'>
						<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
							<h3>Deseja realmente sair?</h3>
						</div>
					</div>
					<div className='flex p-fluid col-6 align-items-center justify-content-center'>
						<Button label='Sair' type='button' onClick={() => { setShowDialogConfirmLogout(false); logout(); }} style={{ minWidth: 150 }}
							className='p-button-rounded p-button p-button shadow-3' icon='pi pi-sign-out' />
					</div>
					<div className='flex p-fluid col-6 align-items-center justify-content-center'>
						<Button label='Cancelar' type='button' onClick={() => { setShowDialogConfirmLogout(false); }} style={{ minWidth: 150 }}
							className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
					</div>
				</div>
			</Dialog>
			<Dialog visible={showDialogChangePassword} style={{ width: '40%' }} modal={true} closable={false}
				onHide={() => setShowDialogChangePassword(false)}>
				<div className='grid'>
					<div className='col-12'>
						<div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
							<h3>Deseja realmente sair?</h3>
						</div>
					</div>
					<div className='flex p-fluid col-6 align-items-center justify-content-center'>
						<Button label='Sair' type='button' onClick={() => { setShowDialogChangePassword(false); }} style={{ minWidth: 150 }}
							className='p-button-rounded p-button p-button shadow-3' icon='pi pi-sign-out' />
					</div>
					<div className='flex p-fluid col-6 align-items-center justify-content-center'>
						<Button label='Cancelar' type='button' onClick={() => { setShowDialogChangePassword(false); }} style={{ minWidth: 150 }}
							className='p-button-rounded p-button p-button-warning shadow-3' icon='pi pi-times' />
					</div>
				</div>
			</Dialog>
			{
				token ?
					<GenericFormDialog<any> key='PasswordChangeDialog'
						token={token}
						title='Alterar senha do sistema'
						icon='pi-lock'
						objectData='password'
						refreshList={() => null}
						setLoading={(value: any) => props.setLoading(value)}
						showDialog={showDialogChangePassword}
						setDialogData={(value: any) => setShowDialogChangePassword(value)}
						setMessage={(message: IMessage) => props.setMessage(message)}
						editFormData={editPasswordFormData}
						emptyFormData={{
							username: username,
							oldPassword: '',
							newPassword: '',
							retryPassword: ''
						}}
						retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<any>[]) => [

							{
								colSize: 'col-12', fieldName: 'username', fieldType: 'TextField', description: 'Username', placeholder: 'username', value: formData.values.username
							},

							{
								colSize: 'col-12', fieldName: 'oldPassword', fieldType: 'PasswordField', description: '* Senha Atual', placeholder: '********', value: formData.values.oldPassword,
								autoComplete: 'new-password', toggleMask: true, feedback: false,
								onChange: formData.handleChange, autoFocus: false,
							},
							{
								colSize: 'col-12', fieldName: 'newPassword', fieldType: 'PasswordField', description: '* Nova senha', placeholder: '********', value: formData.values.newPassword,
								autoComplete: 'new-password', toggleMask: true,
								onChange: formData.handleChange, autoFocus: false,
							},
							{
								colSize: 'col-12', fieldName: 'retryPassword', fieldType: 'PasswordField', description: '* Confirme nova senha', placeholder: '********', value: formData.values.retryPassword,
								autoComplete: 'new-password', toggleMask: true,
								onChange: formData.handleChange, autoFocus: false,
							}
						])}
						editForceData={{}}
						editFunctions={[]}
						formLists={[]}
						validate={(data: any) => {
							let errors: any = {};
							if (!data.oldPassword) {
								errors.oldPassword = 'Senha atual é obrigatória.';
							} else if (data.oldPassword.length < 6) {
								errors.oldPassword = 'Senha atual precisa ter mais que 6 caracteres.';
							}

							if (!data.newPassword) {
								errors.newPassword = 'Nova senha é obrigatória.';
							} else if (data.newPassword.length < 6) {
								errors.newPassword = 'Nova senha precisa ter mais que 6 caracteres.';
							} else if (data.newPassword !== data.retryPassword) {
								errors.newPassword = 'Nova senha e confirmação de senha precisam ser exatamente iguais!';
							}

							if (!data.retryPassword) {
								errors.retryPassword = 'Confirmação de senha é obrigatória.';
							} else if (data.retryPassword.length < 6) {
								errors.retryPassword = 'Confirmação de senha precisa ter mais que 6 caracteres.';
							} else if (data.retryPassword !== data.newPassword) {
								errors.retryPassword = 'Nova senha e confirmação de senha precisam ser exatamente iguais!';
							}

							return errors;
						}}
						submitForceData={{}}
						onSubmit={(props: IPropsFormDialog<any>, data: FormikValues, formData: FormikInterface) => {
							try {
								authenticateService.retrieveToken(data.username, data.oldPassword)
									.then((returned: string) => {
										let returnedToken: string = returned;
										authenticateService.retrievePasswordChangeToken(returnedToken)
											.then((returned: string) => {
												authenticateService.changePassword(returned, data.newPassword)
													.then((returned: any) => {
														returnSuccessed(props, formData, undefined, () => logout())();
													})
													.catch((error: any) => {
														returnNoSuccessed(props, formData, 'Não foi possível aterar a senha, verifiques os dados informados ou tente novamente mais tarde!')();
														formData.resetForm();
													})
											})
											.catch((error: any) => {
												returnNoSuccessed(props, formData, 'Não foi possível aterar a senha, verifiques os dados informados ou tente novamente mais tarde!')();
												formData.resetForm();
											})
									})
									.catch((error: any) => {
										returnNoSuccessed(props, formData, 'Não foi possível aterar a senha, verifiques os dados informados ou tente novamente mais tarde!')()
										formData.resetForm();
									});
							} catch (error) {
								returnNoSuccessed(props, formData, 'Não foi possível aterar a senha, verifiques os dados informados ou tente novamente mais tarde!')();
								formData.resetForm();
							}
						}}
					/>
					: null

				// new BranchGymService().saveBranchGym(data)
				// 	.then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
				// 	.catch((error: any) => saveFailed(props, formData)(error));
			}

			{
				token ?
					<GenericFormDialog<IFormDataEditExternalUser> key='ExternalUserEditDialog'
						token={token}
						objectData='usuário'
						refreshList={() => null}
						setLoading={(value) => props.setLoading(value)}
						showDialog={showEditDialogData}
						setDialogData={(value) => setShowEditDialogData(value)}
						setMessage={(message: IMessage) => props.setMessage(message)}
						editFormData={editExternalUserFormData}
						emptyFormData={{
							name: '',
							nickname: '',
							cpf: '',
							mobilePhone: '',
							email: '',
							customer: '',
							active: true,
							deleted: false
						}}
						retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataEditExternalUser>[]) => [
							{
								colSize: 'col-12', fieldName: 'email', fieldType: 'InputTextField', description: '* Email', placeholder: 'email do usuário', value: formData.values.email,
								autoComplete: 'off', disabled: true,
								onChange: formData.handleChange, autoFocus: false,
							},
							{
								colSize: 'col-12', fieldName: 'name', fieldType: 'InputTextField', description: '* Nome', placeholder: 'nome do usuário', value: formData.values.name,
								onChange: formData.handleChange, autoFocus: true,
							},
							{
								colSize: 'col-6', fieldName: 'nickname', fieldType: 'InputTextField', description: '* Apelido', placeholder: 'apelido do usuário', value: formData.values.nickname,
								onChange: formData.handleChange, autoFocus: false,
							},
							{
								colSize: 'col-12', fieldName: 'cpf', fieldType: 'InputMaskField', description: '* CPF', placeholder: '999.999.999-99', value: formData.values.cpf,
								mask: '999.999.999-99',
								onChange: formData.handleChange, autoFocus: false,
							},
							{
								colSize: 'col-12', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
								mask: '+55 (99) 99999-9999',
								onChange: formData.handleChange, autoFocus: false,
							}
						])}
						editForceData={{}}
						// editFunctions={[]}
						formLists={[]}
						validate={(data: any) => {
							let errors: any = {};

							if (!data.nickname) {
								errors.nickname = 'Apelido é obrigatório.';
							} else if (data.nickname.length < 3) {
								errors.nickname = 'Apelido precisa ter mais que 2 caracteres.';
							}

							if (!data.name) {
								errors.name = 'Nome é obrigatório.';
							} else if (data.name.length < 5) {
								errors.name = 'Nome precisa ter mais que 4 caracteres.';
							}

							if (data.cpf && !isCPF(data.cpf)) {
								errors.cpf = 'CPF inválido.';
							}

							if (!data.mobilePhone) {
								errors.mobilePhone = 'Número de celular é obrigatório.';
							} else if (!isMobilePhone(onlyNumbers(data.mobilePhone))) {
								errors.mobilePhone = 'Número de celular inválido.';
							}

							return errors;
						}}
						submitForceData={{}}
						onSubmit={(props: IPropsFormDialog<IFormDataEditExternalUser>, data: FormikValues, formData: FormikInterface) => {
							const customerToSave = {
								_id: customerId,
								name: data.name,
								nickname: data.nickname,
								cpf: data.cpf,
								mobilePhone: data.mobilePhone,
							}
							new CustomerService().saveCustomer(customerToSave)
								.then((returned: IPerformRequestResult<any>) => {
									saveSuccessed(props, formData)(returned);
								})
								.catch((error: any) => {
									saveFailed(props, formData)(error);
								});
						}} />
					: null
			}
		</>
	);

}

export default AppTopbar;