/* eslint-disable react-hooks/exhaustive-deps */
import moment, { Moment } from 'moment';
import 'moment/locale/pt-br';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { ScheduleInterface, StatusSchedule } from '../data/Schedule';
import { IPropsArgsComponents } from '../generics/formInterfaces';
import Schedule from '../Schedule';
import './ScheduleCalendarStyle.css';
import ls from '../../../projects/localStorage';

export const ScheduleCalendar = (props: IPropsArgsComponents) => {

    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [loggedAttendant] = useState('');

    const [dataSchedules, setDataSchedules] = useState<any[]>([]);

    const [initialHour] = useState<number>(6);
    const [initialMinute] = useState<number>(0);
    const [finalHour] = useState<number>(22);
    const [finalMinute] = useState<number>(0);


    // const [currentDate, setCurrentDate] = useState(ls.socket.getServerSocketDateTime());
    // const [actualDate] = useState(aroundDate(ls.socket.getServerSocketDateTime()));
    // const [firstDate, setFirstDate] = useState(moment(actualDate));
    // const [lastDate, setLastDate] = useState(moment(actualDate));
    // const [oneDate, setOneDate] = useState(true);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);
    useEffect(() => { if (props.data) setDataSchedules(props.data) }, [props.data]);

    useEffect(() => {
        let newFirstDate = moment(props.params.currentDate);
        while (moment(newFirstDate).weekday() !== 0) newFirstDate = newFirstDate.subtract(1, 'day');

        let newLastDate = moment(props.params.currentDate);
        while (moment(newLastDate).weekday() !== 6) newLastDate = newLastDate.add(1, 'day');

        props.params?.setFirstDate(newFirstDate);
        props.params?.setLastDate(newLastDate);

    }, [props.params.currentDate]);

    const moveToday = () => { props.params.setCurrentDate(ls.socket.getServerSocketDateTime()); props.params?.setOneDate(true) };
    const moveDay = (qtty: number) => props.params.setCurrentDate(moment(props.params.currentDate).add(qtty, 'day'));

    const createTitle = (oneDate: boolean, currentDate: Moment, firstDate: Moment, lastDate: Moment) => {
        if (!currentDate?.format) return (<></>);
        const title = oneDate === true ? `${currentDate.format('D')} de ${currentDate.format('MMMM')} de ${currentDate.format('YYYY')}` : `${firstDate.format('D')} - ${lastDate.format('D')} de ${lastDate.format('MMMM')} de ${lastDate.format('YYYY')}`;
        return (
            <div className='grid'>
                <div className='col juddon-inline-table title-week'>
                    {
                        networkGymId && branchGymId ?
                            <Button icon='pi pi-plus' className='p-button-rounded shadow-3'
                                style={{ marginLeft: 20, height: 24, width: 24 }}
                                onClick={() => props.params.addNewSchedule()} tooltip='Adicionar' />
                            : null
                    }
                    <span className='title-week-value'>Agendamentos do dia {title}</span>
                </div>
                <div className='col-fixed juddon-inline-table week-hour-key' style={{ width: '350px', marginTop: -30 }}>
                    <div className='juddon-center-middle-table-cell'>
                        <Button className='button-rounded button-help shadow-24 week-hour-header-button' icon='pi pi-caret-left'
                            style={{ marginRight: '0.5rem', marginTop: '+22px' }}
                            onClick={() => moveDay(oneDate === true ? -1 : -7)}
                        />
                        {oneDate === true ?
                            <Button label='Semana' className='button-rounded button-help shadow-24 week-hour-header-button'
                                style={{ marginRight: '0.5rem', marginTop: '+22px' }}
                                onClick={() => props.params?.setOneDate(false)} />
                            :
                            <Button label='Diário' className='button-rounded button-help shadow-24 week-hour-header-button'
                                style={{ marginRight: '0.5rem', marginTop: '+22px' }}
                                onClick={() => props.params?.setOneDate(true)} />
                        }
                        <Button label='Hoje' className='button-rounded button-help shadow-24 week-hour-header-button'
                            style={{ marginRight: '0.5rem', marginTop: '+22px' }}
                            onClick={moveToday} disabled={moment(currentDate).diff(moment(props.params?.actualDate), 'day') === 0} />
                        <Button className='button-rounded button-help shadow-24 week-hour-header-button' icon='pi pi-caret-right'
                            style={{ marginTop: '+22px' }}
                            onClick={() => moveDay(oneDate === true ? 1 : 7)}
                        />

                    </div>
                </div>
            </div>
        )
    }

    const createHeader = (actualDate: Moment, oneDate: boolean, currentDate: Moment, firstDate: Moment, lastDate: Moment) => {
        const headers = [];
        if (!currentDate?.format) return (<></>);

        const workFirstDate = oneDate ? currentDate : firstDate;
        const workLastDate = oneDate ? currentDate : lastDate;

        for (let i = 0; i <= workLastDate.diff(workFirstDate, 'day'); i++) {
            const currentDay = moment(workFirstDate).add(i, 'day');

            headers.push(<div key={`header-${Math.random() * 99999}`} className={classNames('col', 'juddon-inline-table', 'week-hour-header-item', { 'week-hour-header-item-actual': actualDate.diff(currentDay, 'day') === 0 })}>
                <div className='grid juddon-center-middle-table-cell'>
                    <div className='col-12 juddon-inline-table'>
                        <span className='juddon-center-middle-table-cell'>{currentDay.format('ddd').toUpperCase()} - {currentDay.format('DD')}</span>
                    </div>
                </div>
            </div>);
        }

        return (
            <div className='grid p-shadow-4 header-week'>
                <div className='col-fixed juddon-inline-table week-hour-key week-hour-header' style={{ width: '100px' }}>&nbsp;</div>
                {headers}
            </div>
        )
    }

    const createHours = (actualDate: Moment, oneDate: boolean, currentDate: Moment, firstDate: Moment, lastDate: Moment, dataSchedules: ScheduleInterface[]) => {
        const dateTime = moment(actualDate).set('hour', initialHour).set('minute', initialMinute).set('second', 0).set('millisecond', 0);
        const hours: any[] = [];

        if (!currentDate?.format) return (<></>);

        const workFirstDate = oneDate ? currentDate : firstDate;
        const workLastDate = oneDate ? currentDate : lastDate;

        // console.log('Min Hour: ', dataSchedules.reduce((p, c) => p = moment(c.date).hour() + (moment(c.date).minute() / 60) < p ? moment(c.date).hour() + (moment(c.date).minute() / 60) : p, 24));
        // console.log('Max Hour: ', dataSchedules.reduce((p, c) => p = moment(c.date).hour() + (moment(c.date).minute() / 60) > p ? moment(c.date).hour() + (moment(c.date).minute() / 60) : p, 0));


        while (dateTime.hour() < finalHour || (dateTime.hour() === finalHour && dateTime.minute() <= finalMinute)) {
            // console.log('dataSchedules: ', dateTime.hour(), dataSchedules);
            const evenOrOdd = 'week-hour-item-' + (dateTime.hour() % 2 === 0 ? 'even' : 'odd');
            const dayHours = [];
            for (let i = 0; i <= workLastDate.diff(workFirstDate, 'day'); i++) {
                const currentDay = moment(workFirstDate).add(i, 'day');

                const contentDateHourMinute = {
                    date: moment(currentDay).clone(),
                    hour: dateTime.hour(),
                    minute: dateTime.minute(),
                };
                // console.log('contentDateHourMinute.date: ', contentDateHourMinute.date.toDate());

                const listDatesStatus: any[] = [];

                listDatesStatus.push(...Object.values(StatusSchedule).map(s => {
                    return {
                        date: currentDay,
                        initialDate: currentDay,
                        finishDate: moment(currentDay).add(1, 'day').subtract(1, 'second'),
                        statusSchedule: s,
                        hour: dateTime.hour(),
                        minute: dateTime.minute(),
                        schedules: dataSchedules?.filter(ds => ds.typeSchedule === s.toString()
                            && moment(ds.date).date() === currentDay.date()
                            && ((moment(ds.date).hour() === dateTime.hour()
                                && (moment(ds.date).minute() - 16 <= dateTime.minute())
                                && moment(ds.date).minute() + 16 > dateTime.minute())
                                || (moment(ds.date).hour() < dateTime.hour()
                                    && dateTime.hour() === initialHour && dateTime.minute() === initialMinute)
                                || (moment(ds.date).hour() > dateTime.hour()
                                    && dateTime.hour() === finalHour && dateTime.minute() === finalMinute))
                        )
                    }
                }));

                const scheduleDate = moment(currentDay).set('hour', dateTime.hour()).set('minute', dateTime.minute());

                dayHours.push(<div key={`dayHour-${Math.random() * 99999}`}
                    className={classNames('col', 'week-hour-item', { 'week-hour-special-day': currentDay.weekday() === 0 },
                        { 'week-hour-row-actual': currentDay.weekday() !== 0 && currentDay.date() === actualDate.date() },
                        'week-hour-item-selected')}
                    onClick={(e: any) => {
                        e.stopPropagation();
                        listDatesStatus.reduce((p: any, c: any) => p += c.schedules.length, 0) > 0 && !oneDate ?
                            props.params.eventClick(scheduleDate, listDatesStatus) :
                            branchGymId ?
                                props.params.addNewSchedule(contentDateHourMinute)
                                :
                                props.args.setMessage({ severity: 'warn', summary: 'Selecione uma unidade para adicionar um agendamento!', detail: '', life: 5000 });
                    }
                    }>
                    <div className={classNames('grid', 'juddon-flex')}>
                        {
                            listDatesStatus.map(ds => ds.schedules?.length >= 0 ?
                                oneDate ? ds.schedules.map((element: any) => <Schedule key={`Schedule-${Math.random() * 99999}`}
                                    style={{ marginLeft: '1rem', marginTop: '1rem' }}
                                    info={element} logged={loggedAttendant}
                                    onClick={(e: any) => { e.stopPropagation(); props.params.eventScheduleClick(element); }} />)
                                    :
                                    createScheduleTagStatus(ds.statusSchedule, ds.schedules.length)
                                : null)
                        }
                    </div>
                </div >);
            }

            hours.push(<div key={`hour-${Math.random() * 99999}`} className={classNames('grid', 'header-week', evenOrOdd, { 'week-hour-row-actual': dateTime.hour() === ls.socket.getServerSocketDateTime().hour() })}>
                <div className={classNames('col-fixed', 'juddon-inline-table', 'week-hour-key', 'week-hour', { 'week-hour-actual': dateTime.hour() === ls.socket.getServerSocketDateTime().hour() })} style={{ width: '100px' }}>
                    <span className='juddon-center-middle-table-cell'>{dateTime.format('HH:mm')}</span>
                </div>
                {dayHours}
            </div >);
            dateTime.add(0.5, 'hour');
        }
        return (<>{hours}</>);
    }

    const createDialog = () => {
        const schedules = [];
        if (!props?.params?.momentDialog?.format) return (<></>);

        const title = `${props.params.momentDialog.format('D')} de ${props.params.momentDialog.format('MMMM')} de ${props.params.momentDialog.format('YYYY')} - ${props.params.momentDialog.format('HH')}:${props.params.momentDialog.format('mm')}`;
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.DAILY));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.VISIT));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.CLASS));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.CONTACT));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.FOLLOWUP));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.SCHEDULED));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.VISITED));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.RESCHEDULED));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.HIRED));
        schedules.push(...retrieveScheduleTags(props.params.schedulesDialog, StatusSchedule.MISSED));
        return (
            <Dialog visible={props.params.eventDialog} style={{ minWidth: '80%' }} modal={true} closable={true} onHide={() => props.params.setEventDialog(false)}>
                <div className='col juddon-inline-table title-week'>
                    <span className='title-week-value'>Agendamentos</span><Button label='ir para hoje' className='p-button-rounded p-button-help p-shadow-24 week-hour-header-button'
                        style={{ marginLeft: '0.5rem', marginTop: '-20px' }}
                        onClick={() => { moveToday(); props.params.setEventDialog(false); }} />
                    <p className='title-week-date'>{title}</p>
                </div>
                <div className='p-fluid'>
                    <div className='grid'>
                        {schedules}
                    </div>
                </div>
            </Dialog>
        );
    }

    // const createScheduleDialog = () => {
    //     // const schedules = [];
    //     const campaign: any = scheduleDialog?.typeSchedule;
    //     const title = `${momentDialog.format('D')} de ${momentDialog.format('MMMM')} de ${momentDialog.format('YYYY')} - ${momentDialog.format('HH')}:${momentDialog.format('mm')}`;
    //     return (
    //         <Dialog visible={eventScheduleDialog} style={{ minWidth: '400px', maxWidth: '600px', overflow: 'auto' }} modal={true} closable={true} onHide={() => setEventScheduleDialog(false)}>
    //             {scheduleDialog ?
    //                 <>
    //                     <div className='col juddon-inline-table title-week'>
    //                         <h5 className='title-week-date'>{campaign ? campaign.description : ''} - {title}</h5>
    //                     </div>

    //                     <div className="p-fluid p-formgrid grid">
    //                         <div className="p-field col-12 p-md-6">
    //                             <label htmlFor="firstname">Nome do contato</label>
    //                             <InputText id="firstname" type="text" value={scheduleDialog.name} disabled={true} />
    //                         </div>
    //                         <div className="p-field col-6 p-md-3">
    //                             <label htmlFor="firstname">Fone do contato</label>
    //                             <InputText id="firstname" type="text" value='11 99170-2020' disabled={true} />
    //                         </div>
    //                         <div className="p-field col-6 p-md-3">
    //                             <label htmlFor="attendant">Atendente</label>
    //                             <InputText id="attendant" type="text" value={scheduleDialog.attendant} disabled={true} />
    //                         </div>
    //                         <Divider />
    //                         <div className="p-field col-6 col-md-3">
    //                             <Button label="Confirmou?" className="p-button-lg"
    //                                 icon={`pi ${!infConfirmou ? 'pi-stop' : confirmou ? 'pi-check' : 'pi-times'}`}
    //                                 style={{ backgroundColor: !infConfirmou ? '#333' : confirmou ? '#0808' : '#8008' }}
    //                                 onClick={() => { setInfConfirmou(true); setConfirmou(!confirmou) }} />
    //                         </div>
    //                         {confirmou ?
    //                             <div className="p-field col-6 col-md-6">
    //                                 <Button label="Compareceu?" className="p-button-lg"
    //                                     icon={`pi ${!infCompareceu ? 'pi-stop' : compareceu ? 'pi-check' : 'pi-times'}`}
    //                                     style={{ backgroundColor: !infCompareceu ? '#333' : compareceu ? '#0808' : '#8008' }}
    //                                     onClick={() => { setInfCompareceu(true); setCompareceu(!compareceu) }} />
    //                             </div>
    //                             :
    //                             null
    //                         }
    //                         <Divider />
    //                         {infConfirmou && (!confirmou || (infCompareceu && !compareceu)) ?
    //                             <div className="p-field col-12 col-md-12">
    //                                 <Button label="Reagendou?" className="p-button-lg"
    //                                     icon={`pi ${!infReagendou ? 'pi-stop' : reagendou ? 'pi-check' : 'pi-times'}`}
    //                                     style={{ backgroundColor: !infReagendou ? '#333' : reagendou ? '#0808' : '#8008' }}
    //                                     onClick={() => { setInfReagendou(true); setReagendou(!reagendou) }} />
    //                             </div>
    //                             :
    //                             null
    //                         }
    //                         {infReagendou && reagendou && infCompareceu && !compareceu ?
    //                             <div className="p-field col-12 p-md-4">
    //                                 <label htmlFor="icon">Reagendado para:</label>
    //                                 <PrimCalendar id="icon" value={getServerSocketDateTime().toDate()} showIcon />
    //                             </div>
    //                             :
    //                             null
    //                         }
    //                         {infReagendou && !reagendou ?
    //                             <div className="p-field col-12 p-md-12">
    //                                 <label htmlFor="motivo">Motivo do não reagendamento</label>
    //                                 <InputText id="motivo" type="text" />
    //                             </div>
    //                             :
    //                             null
    //                         }
    //                         {infConfirmou && (!confirmou || !compareceu) ? <Divider /> : null}
    //                         {
    //                             compareceu ?
    //                                 <div className="p-field col-12 col-md-12">
    //                                     <Button label="Fechou?" className="p-button-lg"
    //                                         icon={`pi ${!infFechou ? 'pi-stop' : fechou ? 'pi-check' : 'pi-times'}`}
    //                                         style={{ backgroundColor: !infFechou ? '#333' : fechou ? '#0808' : '#8008' }}
    //                                         onClick={() => { setInfFechou(true); setFechou(!fechou) }} />
    //                                 </div>
    //                                 :
    //                                 null
    //                         }
    //                         {
    //                             infFechou && !fechou ?
    //                                 <div className="p-field col-12 col-md-12">
    //                                     <Button label="Agendou?" className="p-button-lg"
    //                                         icon={`pi ${!infAgendou ? 'pi-stop' : agendou ? 'pi-check' : 'pi-times'}`}
    //                                         style={{ backgroundColor: !infAgendou ? '#333' : agendou ? '#0808' : '#8008' }}
    //                                         onClick={() => { setInfAgendou(true); setAgendou(!agendou) }} />
    //                                 </div>
    //                                 :
    //                                 null
    //                         }
    //                         {
    //                             agendou ?
    //                                 <div className="p-field col-12 p-md-4">
    //                                     <label htmlFor="icon">Reagendado para:</label>
    //                                     <PrimCalendar id="icon" value={getServerSocketDateTime().toDate()} showIcon />
    //                                 </div>
    //                                 :
    //                                 null
    //                         }
    //                         {
    //                             infAgendou && !agendou ?
    //                                 <div className="p-field col-12 p-md-12">
    //                                     <label htmlFor="motivo">Motivo do não reagendamento</label>
    //                                     <InputText id="motivo" type="text" />
    //                                 </div>
    //                                 :
    //                                 null
    //                         }
    //                         {
    //                             fechou ?
    //                                 <>
    //                                     <div className="p-field col-9 p-md-9">
    //                                         <label htmlFor="contrato">Contrato</label>
    //                                         <Dropdown inputId="state" placeholder="escolha um contrato" optionLabel="escolha um contrato" />
    //                                     </div>
    //                                     <div className="p-field col-3 p-md-3">
    //                                         <label htmlFor="valor">Valor</label>
    //                                         <InputText id="valor" type="text" placeholder="Valor do contrato" />
    //                                     </div>
    //                                 </>
    //                                 :
    //                                 null
    //                         }
    //                         {infCompareceu && compareceu ? <Divider /> : null}
    //                         <div className="p-field col-6 p-md-6">
    //                             <Button label="Observações" icon="pi pi-history" className="p-button" />
    //                         </div>
    //                         <div className="p-field col-6 p-md-6">
    //                             <Button label="Histórico de Conversas" icon="pi pi-history" className="p-button" />
    //                         </div>
    //                     </div>
    //                 </>
    //                 : null
    //             }
    //         </Dialog>
    //     );
    // }

    const retrieveScheduleTags = (schedules: any[], status: StatusSchedule) => {
        const scheduleds: any[] = [];
        const scheduledFiltered = schedules.find(s => s.statusSchedule === status && s.schedules.length > 0);
        if (scheduledFiltered) {
            scheduleds.push(scheduledFiltered.schedules.map((s: any, i: number) => <div key={`ScheduleTag-${i}`} className='col'><Schedule onClick={() => props.params.eventScheduleClick(s)} info={s}></Schedule></div>));
        }
        return scheduleds;
    }

    const createScheduleTagStatus = (status: string, qttyTags: number) => {
        const styleClass = `juddon-schedule-type-${status}`;
        // const inactiveStyleClass = `juddon-schedule-type-inactive-${status}`;
        return (
            <div key={`TagStatus-${Math.random() * 99999}`} className='col-4 juddon-center-middle-table-cell'>
                {qttyTags !== 0 ?
                    <div className={classNames('juddon-center-middle-table-cell', 'schedule-tag', styleClass)}>
                        <div className='grid'>
                            <div className='col-12 p-sm-12 p-md-12 p-lg-6 p-xl-12'><i className='pi pi-clock week-schedule-tag-icon' /></div>
                            <div className='col-12 p-sm-12 p-md-12 p-lg-6 p-xl-12'><span>{qttyTags}</span></div>
                        </div>
                    </div>
                    :
                    <></>
                    // <div className={classNames('juddon-center-middle-table-cell', 'schedule-tag', inactiveStyleClass)}>
                    //     <div className='grid'>
                    //         <div className='col-12 p-sm-12 p-md-12 p-lg-6 p-xl-12'><i className='pi pi-clock week-schedule-tag-icon' /></div>
                    //         <div className='col-12 p-sm-12 p-md-12 p-lg-6 p-xl-12'><span>-</span></div>
                    //     </div>
                    // </div>
                }
            </div>
        )
    }

    // console.log('currentDate: ', currentDate);

    return (
        <>
            <div className='col widget-schedule-calendar-box'>
                {createTitle(props.params.oneDate, props.params.currentDate, props.params.firstDate, props.params.lastDate)}
                {createHeader(props.params.actualDate, props.params.oneDate, props.params.currentDate, props.params.firstDate, props.params.lastDate)}
                {createHours(props.params.actualDate, props.params.oneDate, props.params.currentDate, props.params.firstDate, props.params.lastDate, dataSchedules)}
            </div>
            {createDialog()}
            {/* {createScheduleDialog()} */}
        </>
    )
}