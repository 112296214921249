export enum Enviroments { LOCAL = 'LOCAL', DEVELOPMENT = 'DEVELOPMENT', HOMOLOGATION = 'HOMOLOGATION', PRODUCTION = 'PRODUCTION' };
export enum Protocols { HTTP = 'http', HTTPS = 'https', FTP = 'ftp', SSH = 'ssh' };
export enum Applications { SERVER_APPLICATION = 'API', SERVER_SOCKET = 'SOCKET.API' };

const fncBuildPath = (endpoint: string, env?: Enviroments | 'DEVELOPMENT' | 'HOMOLOGATION' | 'PRODUCTION', application?: Applications) => {
    const config = !application || application === Applications.SERVER_APPLICATION ?
        projectEnviroment.configServerApplication.find(c => env ? c.type === env || c.type.toString() === env : c.type === projectEnviroment.default)
        : projectEnviroment.configSocketServerApplication.find(c => env ? c.type === env || c.type.toString() === env : c.type === projectEnviroment.default);
    if (config) return `${config.path.protocol}://${config.path.host}${config.path.port ? `:${config.path.port}` : ''}/${!application || application === Applications.SERVER_APPLICATION ? `${config.path.path}/${endpoint ? endpoint : ''}` : ''}`;
    else throw new Error(`Fail retrieve enviroments to ${env}`);
}

const projectEnviroment: IProject = {
    default: Enviroments.LOCAL,
    configServerApplication: [
        {
            type: Enviroments.LOCAL,
            path: {
                protocol: Protocols.HTTP,
                host: 'localhost',
                port: '8086',
                path: 'api'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        }, {
            type: Enviroments.DEVELOPMENT,
            path: {
                protocol: Protocols.HTTP,
                // host: '143.198.145.187',
                host: 'localhost',
                port: '8086',
                path: 'api'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        }, {
            type: Enviroments.HOMOLOGATION,
            path: {
                protocol: Protocols.HTTPS,
                // host: '143.198.145.187',
                host: 'api.hml.chat4fit.com',
                port: '443', // '8286'
                path: 'api'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        }, {
            type: Enviroments.PRODUCTION,
            path: {
                protocol: Protocols.HTTPS,
                // host: '143.198.145.187',
                host: 'api.chat4fit.com',
                port: '443', // '8186'
                path: 'api'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        },
    ],
    configSocketServerApplication: [
        {
            type: Enviroments.LOCAL,
            path: {
                protocol: Protocols.HTTP,
                host: 'localhost',
                port: '8088'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        },
        {
            type: Enviroments.DEVELOPMENT,
            path: {
                protocol: Protocols.HTTP,
                host: 'localhost',
                port: '8088'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        },
        {
            type: Enviroments.HOMOLOGATION,
            path: {
                protocol: Protocols.HTTPS,
                host: 'socket.api.hml.chat4fit.com',
                port: '443' // '8288',
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        },
        {
            type: Enviroments.PRODUCTION,
            path: {
                protocol: Protocols.HTTPS,
                host: 'socket.api.chat4fit.com',
                port: '443' // '8188'
            },
            encryptServiceSecret: 'xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv'
        }],
    buildPath: fncBuildPath
}


export default projectEnviroment;

interface IProject {
    default: Enviroments;
    configServerApplication: {
        type: Enviroments,
        path: {
            protocol: Protocols;
            host: string;
            port: string;
            path?: string;
        },
        encryptServiceSecret: string
    }[];
    configSocketServerApplication: {
        type: Enviroments,
        path: {
            protocol: Protocols;
            host: string;
            port: string;
            path?: string;
        },
        encryptServiceSecret: string
    }[];
    buildPath: (endpoint: string, env?: Enviroments | 'DEVELOPMENT' | 'HOMOLOGATION' | 'PRODUCTION', application?: Applications) => string;
}


// # BASE CONFIG
// PROJECT_NAME=SERVER API
// NODE_ENV=DEV
// PORT=8186
// VERSION=0.0.1
// #SERVER_APPLICATION_MONGODB_URI=mongodb://serverApplicationUser:password-mongo-2021@144.126.221.154:27017/judoon_db
// SERVER_APPLICATION_MONGODB_URI=mongodb://127.0.0.1:27017/judoon_db
// SERVER_APPLICATION_MONGODB_DB=judoon_db
// SERVER_APPLICATION_PUSH=https://dev-server-application-push.firebaseio.com

// # DEFAULT REQUEST
// #    HEADER
// DEFAULT_REQUEST_HEADER_PAGE=0
// DEFAULT_REQUEST_HEADER_SKIP=0
// DEFAULT_REQUEST_HEADER_LIMIT=10


// # SECRETS
// ENCRYPT_KEY_SECRET=xQ0A62F9cjQ6fQUbMVrcavWbdrwICHSmlr6jnrQsDeZSjfiYBd36b01CNVMycTxFgJP7H6xMZzfMcBxmo8ktv3a9zTLbm9NRTy9nc3FSUFcTeSscMxR96LjR6QPraS8csFI1ICYtrsegxgKpBLcoXz2Y9X5kgKPOcTnWxNqiNXXlRm3iEdKO3q6K9z9LX24o5PLkZnFuZ4WHNusVtkGyGGHy8Ykjxr8OArZmwMtX5quC0qMXaYJEWohzyhQ6Qolv
// ENCRYPT_PASSWORD_SECRET=RjiXGaAuCKRKhOzg9LrjUjfWb6h81yE6WNj7VSrmzjGjoR2l1CxfS6hKoD1HvFQPwp3rgLB0AVh9CJXMb3ZHXUEPjgJOISutlIKgP1vjRyAs2FwG3lbrmoqFi5EK4NJQqCcuwpuwdl8ktwX1PhyoG0qJ1o8uTC5igt0zYVnw35pO1O5PR7adHUp4dizzRnu1b9YhBGnpd4GkbJGrSKM7AJcjRGxnIOo6hpj1MB9LrLL3JH9wSnynM7EQ1iNRpqxU
// ENCRYPT_SERVICE_SECRET=BA4VLoQEgtVaR2iaeOMQIxp3CuugVMa4Ry4xJRTjeQR0fQfAgSBcWOeUEidOenvOfmxNklGv1Aj20DqZTUdUh5INYJTbUe8J7tyJHLOEPrTmMAdVGayRvJd7lTKNuGRNBuaj678Lb8ZurGoTuHtndyACJSbqgI4D7Fti9gHE3Sf3b4kt90WFXSONBLKV0hTjWwSZiF86eq0u9CSC6fMkMeLbPFGc0t4c0oT2mSAw8MMo2PZpVczFwlXcEVEDEiN1

// ## DEBUG
// # DEBUG COMMONS
// DEBUG_AUTH=true
// DEBUG_CUSTOM_ERROR=true
// DEBUG_CUSTOM_RESPONSE=false
// DEBUG_GENERIC=true
// DEBUG_GENERIC_ENDPOINT=true
// DEBUG_GENERIC_FILE_ROUTE=false
// DEBUG_GENERIC_NOT_VERIFICATED=false
// # DEBUG GENERIC CLASS
// DEBUG_GENERIC_CONTROLLER_ERROR=false
// DEBUG_GENERIC_BUSINESS_ERROR=false
// DEBUG_GENERIC_MODEL_ERROR=true
// DEBUG_LOG_REQUEST_TIME_SCHEMAS=exemplo1,exemplo2,processeddataTrade
//     // exchange/optionTrades,oldTradeLookup
// DEBUG_LOG_REQUEST_TIME_SCHEMAS_SIZE=50

// ## SERVICES
// # MAILGUN
// MAILGUN_PROTOCOL= https
// MAILGUN_HOST= api.mailgun.net
// MAILGUN_PORT= 443
// MAILGUN_BASE_URL= v3/mailgun.loopedsquare.net
// MAILGUN_DOMAIN= mailgun.loopedsquare.net
// MAILGUN_BASIC_USER_AUTH= api
// MAILGUN_BASIC_PASSWORD_AUTH= key-88cf57d0c9fb2bd3a87d36091e205ad1
// # ZENVIA
// ZENVIA_PROTOCOL= https
// ZENVIA_HOST= api-rest.zenvia.com
// ZENVIA_PORT= 443
// ZENVIA_BASE_URL= services
// ZENVIA_BASIC_USER_AUTH= wildworld.smsonline
// ZENVIA_BASIC_PASSWORD_AUTH= h0wvJ76B7F
// # DIGITAL OCEAN STORAGE
// DIGITAL_OCEAN_SPACES_ENDPOINT=sfo2.digitaloceanspaces.com
// DIGITAL_OCEAN_SPACES_KEY=6XXHAESLICC5AYVKM4CO
// DIGITAL_OCEAN_SPACES_SECRET=R/Cv8cj0rXAy2Pb+eOcuR2staWMzjH08s8UodP9jaV0
// DIGITAL_OCEAN_PROFILE_IMAGE_BUCKET=vortics-spaces
// DIGITAL_OCEAN_PATH_PROFILE_IMAGE_BUCKET=vortics/profile-pictures/