export const addNewRegister = (type?: string, _id?: string, args?: {
    setEditFormData: React.Dispatch<any>,
    setDialogData: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    if (!args) return;
    args.setEditFormData(null);
    args.setDialogData(true);
}

export const addNewStepRegister = (type?: string, _id?: string, args?: {
    setEditStepData: React.Dispatch<any>,
    setDialogData: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    if (!args) return;
    args.setEditStepData(null);
    args.setDialogData(true);
}

export const editRegister = async (type: string, args?: {
    data: any,
    editForceData?: any,
    setEditFormData?: React.Dispatch<any>,
    setEditStepData?: React.Dispatch<any>,
    setDialogData: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    if (!args) return;
    let edititem = Object.assign({}, args.data);
    if (args.editForceData) edititem = Object.assign(edititem, args.editForceData);
    if (args.setEditFormData) await args.setEditFormData(edititem);
    if (args.setEditStepData) await args.setEditStepData(edititem);
    args.setDialogData(true);
}

export const uploadRegister = async (type: string, args?: {
    networkGymId: string,
    branchGymId: string,
    guideId: string,
}) => {
    if (!args) return;
    // TODO: create uploadRegister
    console.log('uploadRegister: ', type, args);
}

export const uploadCloudRegister = async (type: string, args?: { guideId: string }) => {
    if (!args) return;
    // TODO: create uploadCloudRegister
    console.log('uploadCloudRegister: ', type, args);
}

export const cloneRegister = async (type: string, args?: {
    data: any,
    editForceData?: any,
    removeForceData?: string[],
    setEditFormData?: React.Dispatch<any>,
    setEditStepData?: React.Dispatch<any>,
    setDialogData: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    if (!args) return;
    if (!args.data) return;
    // console.log('args.data: ', args.data);
    let editItem = Object.assign({}, args.data);
    editItem = Object.assign(editItem, args.editForceData);

    delete editItem._id;
    // console.log('args.editForceData: ', editItem);
    if (args.removeForceData) args.removeForceData.forEach(r => delete editItem[r]);
    // console.log('args.removeForceData: ', editItem);
    if (args.setEditFormData) await args.setEditFormData(editItem);
    if (args.setEditStepData) await args.setEditStepData(editItem);
    // console.log('editItem: ', editItem);
    args.setDialogData(true);
}