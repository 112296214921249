import { defined } from ".";
import { EnumDateType } from "../enums";
import { IChangeDate } from "../interfaces";
import { padLeft } from "./string";

export const listDayOfWeeks = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
export const listShortDayOfWeeks = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
export const listMinDayOfWeeks = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
export const listMonthOfYear = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
export const listShortMonthOfYear = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

export const addMinutesToDate = (date: Date, minutes: number) => new Date(date.getTime() + (minutes * 60000));

export const convertDateToString = (date: Date) => `${padLeft(date.getUTCFullYear(), 4, '0')}-${padLeft(date.getUTCMonth() + 1, 2, '0')}-${padLeft(date.getUTCDate(), 2, '0')}`;

export const convertDateTimeToString = (dateTime: Date) => `${padLeft(dateTime.getUTCFullYear(), 4, '0')}-${padLeft(dateTime.getUTCMonth() + 1, 2, '0')}-${padLeft(dateTime.getUTCDate(), 2, '0')}T${padLeft(dateTime.getUTCHours(), 2, '0')}:${padLeft(dateTime.getUTCMinutes(), 2, '0')}:${padLeft(dateTime.getUTCSeconds(), 2, '0')}`;

export const formatDate = (date: string) => {
    if (!date) return undefined;
    let newDate = date.replace(/-/g, '');
    newDate = newDate.replace(/[^0-9]/g, '').substr(0, 8);
    if (newDate.length === 8) new Date(`${newDate.substr(4, 4)}-${newDate.substr(2, 2)}-${newDate.substr(0, 2)}`);
    return newDate;
};

export const formatDateToNumberString = (value: Date) => {
    return new Date(value).toISOString().split('T')[0]?.replace(/[^0-9]/g, '');
}

export const formatDateToShowDate = (value: Date) => {
    const newDate = formatDateToNumberString(value);
    return `${padLeft(newDate?.substr(6, 2), 2, '0')}/${padLeft(newDate?.substr(4, 2), 2, '0')}/${padLeft(newDate?.substr(0, 4), 4, '0')}`;
};

export const formatDateToStringDate = (value: Date) => {
    const newDate = formatDateToNumberString(value);
    return `${padLeft(newDate?.substr(0, 4), 4, '0')}-${padLeft(newDate?.substr(4, 2), 2, '0')}-${padLeft(newDate?.substr(6, 2), 2, '0')}`;
};

export const isDate = (dateToCheck: any) => dateToCheck instanceof Date || !isNaN(dateToCheck);

export const retrieveDate = (changeDate: IChangeDate, dateBase?: Date, typeDate?: EnumDateType) => {
    const dateWork = dateBase && isDate(dateBase) ? dateBase : new Date();
    if (changeDate) {
        if (changeDate.year) dateWork.setUTCFullYear(changeDate.year);
        else if (changeDate.years) dateWork.setUTCFullYear(dateWork.getUTCFullYear() + changeDate.years);

        if (changeDate.month) dateWork.setUTCMonth(changeDate.month);
        else if (changeDate.months) dateWork.setUTCMonth(dateWork.getUTCMonth() + changeDate.months);

        if (changeDate.day) dateWork.setUTCDate(changeDate.day);
        else if (changeDate.days) dateWork.setUTCDate(dateWork.getUTCDate() + changeDate.days);

        if (changeDate.hour) dateWork.setUTCHours(changeDate.hour);
        else if (changeDate.hours) dateWork.setUTCHours(dateWork.getUTCHours() + changeDate.hours);

        if (changeDate.minute) dateWork.setUTCMinutes(changeDate.minute);
        else if (changeDate.minutes) dateWork.setUTCMinutes(dateWork.getUTCMinutes() + changeDate.minutes);

        if (changeDate.second) dateWork.setUTCSeconds(changeDate.second);
        else if (changeDate.seconds) dateWork.setUTCSeconds(dateWork.getUTCSeconds() + changeDate.seconds);

        if (changeDate.millisecond) dateWork.setUTCMilliseconds(changeDate.millisecond);
        else if (changeDate.milliseconds) dateWork.setUTCMilliseconds(dateWork.getUTCMilliseconds() + changeDate.milliseconds);
    }
    switch (typeDate) {
        // case EnumDateType.ISO: return dateWork.toISOString();
        case EnumDateType.DATE: return dateWork;
        default: return dateWork;
    }
};

export const millisecondDiff = (d1: Date, d2: Date) => {
    return d2.getTime() - d1.getTime();
}

export const secondsDiff = (d1: Date, d2: Date) => {
    let secDiff = Math.floor(millisecondDiff(d1, d2) / 1000);
    return secDiff;
}

export const minutesDiff = (d1: Date, d2: Date) => {
    let seconds = secondsDiff(d1, d2);
    let minutesDiff = Math.floor(seconds / 60);
    return minutesDiff;
}

export const hoursDiff = (d1: Date, d2: Date) => {
    let minutes = minutesDiff(d1, d2);
    let hoursDiff = Math.floor(minutes / 60);
    return hoursDiff;
}

export const daysDiff = (d1: Date, d2: Date) => {
    let hours = hoursDiff(d1, d2);
    let daysDiff = Math.floor(hours / 24);
    return daysDiff;
}

export const weeksDiff = (d1: Date, d2: Date) => {
    let days = daysDiff(d1, d2);
    let weeksDiff = Math.floor(days / 7);
    return weeksDiff;
}

export const yearsDiff = (d1: Date, d2: Date) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let yearsDiff = date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
}

export const monthsDiff = (d1: Date, d2: Date) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    let years = yearsDiff(d1, d2);
    let months = (years * 12) + (date2.getMonth() - date1.getMonth());
    return months;
}


export const dateStringDiff = (d1: Date, d2: Date) => {
    let stringDiff = '';
    const years = yearsDiff(d1, d2);
    d2 = retrieveDate({ years: years * -1 });
    const months = monthsDiff(d1, d2);
    d2 = retrieveDate({ months: months * -1 });
    const days = daysDiff(d1, d2);
    d2 = retrieveDate({ days: days * -1 });
    const hours = hoursDiff(d1, d2);
    d2 = retrieveDate({ hours: hours * -1 });
    const minutes = minutesDiff(d1, d2);
    d2 = retrieveDate({ minutes: minutes * -1 });
    const seconds = secondsDiff(d1, d2);
    d2 = retrieveDate({ seconds: seconds * -1 });
    const milliseconds = millisecondDiff(d1, d2);

    if (years > 0) stringDiff += `${years} years `;
    if (months > 0) stringDiff += `${months} months `;
    if (days > 0) stringDiff += `${days} days `;
    if (hours > 0) stringDiff += `${hours} hrs `;
    if (minutes > 0) stringDiff += `${minutes} min. `;
    if (seconds > 0) stringDiff += `${seconds} sec. `;

    if (!defined(stringDiff)) stringDiff = `${milliseconds} ms`;

    return stringDiff;
}

