import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import React from 'react';
import { makeKeyByString } from '../../../utils/functions/hash';
import { IPropsParamFormDialog } from '../generics/formInterfaces';
import { createObjectField } from './functions';

export function FormDialog<T>(props: IPropsParamFormDialog<T>) {
    const isFormFieldValid = (name: keyof T) => !!((props.formData.touched || props.formData.touched[name]) && props.formData.errors[name]);
    const getFormErrorMessage = (name: keyof T) => isFormFieldValid(name) && <small className='p-error'>{props.formData.errors[name]}</small>;

    const createFields = (fields: any[]): React.ReactNode => {
        return fields.map(f => f ? <div key={f.fieldName || makeKeyByString(f.description)} className={classNames(f.colSize, 'col', 'field')}>
            {createObjectField(f, isFormFieldValid)}
            {getFormErrorMessage(f.fieldName)}
        </div> : null);
    }

    return (
        <Dialog visible={props.showDialog} maximizable style={{ width: '80%' }} modal={true} closable={false}
            onHide={() => props.setDialogData(false)}>
            {props.fields ?
                <form onSubmit={props.formData.handleSubmit}>
                    <div className='card' style={{ backgroundColor: '#118CAC3C' }}>
                        <div className='grid p-fluid title-dialog'>
                            <div className='col-6'>
                                <i className={classNames('pi', 'pi-fw', { 'pi-table': !props.icon }, props.icon, 'layout-menuitem-icon')} style={{ fontSize: 24, color: '#56ae93' }}></i>
                                <span className='title-dialog-value' style={{ fontSize: 24 }}>{
                                    props.title ? props.title :
                                        props.formData.values._id ? `Altere os dados d${new RegExp(/o$/, 'ig').test(props?.objectData) ? 'o' : 'a'} ${props.objectData}` : `Criar nov${new RegExp(/o$/, 'ig').test(props?.objectData) ? 'o' : 'a'} ${props.objectData}`
                                }</span>
                            </div>
                            <div className='col-6 grid flex flex-nowrap justify-content-evenly'>
                                <Button label='Gravar' disabled={props.saveButtonDisabled} type={!props.saveButtonDisabled ? 'submit' : 'button'}
                                    style={{ minWidth: 150 }} className={classNames('p-button-rounded',
                                        { 'p-button-success': !props.saveButtonDisabled }, { 'p-button-plain': props.saveButtonDisabled },
                                        'shadow-3')} icon='pi pi-save' />
                                <Button label='Cancelar' disabled={props.cancelButtonDisabled} type='button' onClick={() => props.setDialogData(false)} style={{ minWidth: 150 }}
                                    className={classNames('p-button-rounded', { 'p-button-warning': !props.cancelButtonDisabled }, { 'p-button-plain': props.cancelButtonDisabled },
                                        'shadow-3')} icon='pi pi-times' />
                                {/* <Button label='Delete' style={{ minWidth: 150 }} className='p-button-rounded p-button-danger shadow-3' icon='pi pi-times' /> */}
                            </div>
                        </div>
                    </div>
                    <div className='grid p-fluid'>
                        {createFields(props.fields)}
                    </div>
                    <Divider align='right'>
                        <div className='inline-flex align-items-center'> <i className='pi pi-cog mr-2'></i> <b>Ações</b> </div>
                    </Divider>
                    <div className='col-6 col-offset-6 grid  p-fluid flex flex-nowrap justify-content-evenly'>

                        <Button label='Gravar' disabled={props.saveButtonDisabled} type={!props.saveButtonDisabled ? 'submit' : 'button'}
                            style={{ minWidth: 150 }} className={classNames('p-button-rounded',
                                { 'p-button-success': !props.saveButtonDisabled }, { 'p-button-plain': props.saveButtonDisabled },
                                'shadow-3')} icon='pi pi-save' />
                        <Button label='Cancelar' disabled={props.cancelButtonDisabled} type='button' onClick={() => props.setDialogData(false)} style={{ minWidth: 150 }}
                            className={classNames('p-button-rounded', { 'p-button-warning': !props.cancelButtonDisabled }, { 'p-button-plain': props.cancelButtonDisabled },
                                'shadow-3')} icon='pi pi-times' />
                    </div>
                </form>
                :
                null
            }
        </Dialog>
    );
}

