import { Toast } from 'primereact/toast';
import { useEffect, useRef, useState } from 'react';
import { BlockLoading } from './BlockLoading';

export interface IMessage {
	severity: string, summary: string, detail: string, life: number, sticky?: boolean, action?: () => void
};

export const Control = (props: { loading: { blocked: boolean, fullScreen?: boolean }, message?: IMessage }) => {
	const toast = useRef<any>();
	const hasWindow = typeof window !== 'undefined';

	const [blocked, setBlocked] = useState<boolean>(props.loading.blocked);
	const [fullScreen] = useState<boolean>(props.loading.fullScreen || true);
	const getWindowDimensions = () => { return { width: hasWindow ? window.innerWidth : null, height: hasWindow ? window.innerHeight : null }; }
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	const handleResize = () => setWindowDimensions(getWindowDimensions());


	// useEffect(() => {
	// 	console.trace('loading: ', blocked);
	// }, [blocked]);

	useEffect(() => {
		if (hasWindow) {
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasWindow]);

	useEffect(() => {
		if (props.message) {
			toast.current.show(props.message);
			// messages.current.show([props.message]);
		}
	}, [props.message]);

	useEffect(() => {
		setBlocked(props.loading.blocked);
	}, [props.loading.blocked]);

	return (
		<div className="fluid">
			<Toast ref={toast} onHide={() => { if (props.message && props.message.action) props.message.action(); }} />
			{/* <Messages ref={messages} onRemove={() => { if (props.message && props.message.action) props.message.action(); }} /> */}
			<BlockLoading blocked={blocked} fullScreen={fullScreen} />
		</div>
	)
}