import jwt from 'jsonwebtoken';
import { isArray } from '../../utils/functions';
import { defineUniqueBranchGym, getAllBranchGyms, getBranchGymsByStorageToken, hasBranchGymSelected, setAllBranchGyms } from './branchGym';
import { getCustomerUsernameStorage, getCustomerIdStorageToken, getCustomerNicknameStorage } from './customer';
import { defineUniqueNetworkGym, getCountNetworkGymsByStorageToken, getNetworkGymsByStorageToken, hasNetworkGymSelected } from './networkGym';
import { getServerSocketDateTime, setServerSocketDateTime } from './socket';
import moment, { Moment } from 'moment';


const getLocalStorageToken = (): string | null => {
    const checkToken = localStorage.getItem('project.chat4fit.token');
    if (!checkToken) return null;
    return checkToken || null;
}

const checkUniqueLocalStorageToken = (key: string): boolean => {
    const checkToken = localStorage.getItem('project.chat4fit.token');
    if (!checkToken) return false;
    const decode: any = jwt.decode(checkToken);
    for (const keyDecode in decode) {
        if (keyDecode.indexOf(key) === 0 && isArray(decode[keyDecode])) {
            return decode[keyDecode].length === 1;
        }
    }
    return false;
}

const getLocalStorageDataKey = <T>(key: string): T[] | null => {
    try {
        let slist = localStorage.getItem(key);
        if (!slist) return null;
        let list = JSON.parse(slist);
        return list.list;
    } catch (error) {
        return null;
    }
};

const getLocalStorageDateTimeKey = (key: string): Moment | null => {
    try {
        let slist = localStorage.getItem(key);
        if (!slist) return null;
        let list = JSON.parse(slist);
        // console.log('list.dateTime: ', list.datetime);
        return moment(list.datetime);
    } catch (error) {
        return null;
    }
};

const setLocalStorageKey = <T>(key: string, list: T[]): boolean => {
    try {
        localStorage.setItem(key, JSON.stringify({ key, list, datetime: moment() }));
        return true;
    } catch (error) { return false; }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getLocalStorageToken,
    checkUniqueLocalStorageToken,
    setLocalStorageKey,
    getLocalStorageDataKey,
    getLocalStorageDateTimeKey,
    customer: {
        getCustomerIdStorageToken,
        getCustomerNicknameStorage,
        getCustomerUsernameStorage,
    },
    networkGym: {
        getNetworkGymsByStorageToken,
        hasNetworkGymSelected,
        getCountNetworkGymsByStorageToken,
        defineUniqueNetworkGym,
    },
    branchGym: {
        hasBranchGymSelected,
        getBranchGymsByStorageToken,
        defineUniqueBranchGym,
        setAllBranchGyms,
        getAllBranchGyms
    },
    socket: {
        getServerSocketDateTime,
        setServerSocketDateTime
    }
}
