import { FormikValues } from "formik";
import { checkRoleHierarchyByToken, RoleRules } from "../../../projects/checkRoleHierarchy";
import { IGroupField, IInputMaskField, IInputTextField, IPropsFormListDialog, ISelectButtonField, ITextField } from "../generics/formInterfaces";

function genericField<T>(formData: FormikValues, formLists?: IPropsFormListDialog<T>[], args?: {
    fieldType: 'TextField' | 'InputTextField' | 'InputNumberField' | 'InputTextHourField' | 'InputMaskField' | 'CalendarField' | 'DropdownField' | 'GroupField' | 'PasswordField' | 'SelectButtonField',
    colSize?: number, fieldName?: keyof T, description: string, placeholder?: string, autoFocus: boolean, required: boolean,
    options?: { name: string, value: any }[], optionLabel?: string,
    mask?: string, style?: any
}): unknown {
    const field = args?.fieldName ? args.fieldName : 'key' as keyof T;
    return {
        colSize: args?.colSize ? `col-${args.colSize}` : 'col-12', fieldName: field, fieldType: args?.fieldType ? args?.fieldType : 'GroupField',
        description: `${args?.required ? '* ' : ''}${args?.description}` || `description`,
        placeholder: args?.placeholder || null, value: formData.values[field],
        onChange: formData.handleChange, autoFocus: args?.autoFocus || false,
        onBlur: formData.handleBlur,
        options: args?.options ? args.options : null, optionLabel: args?.optionLabel ? args.optionLabel : null,
        mask: args?.mask ? args.mask : null,
        style: args?.style ? args.style : null
    };
}

function genericTextField<T>(formData: FormikValues, args: {
    colSize?: number, fieldName: keyof T, description: string, placeholder: string, autoFocus: boolean, required: boolean,
}): unknown {
    return genericField<T>(formData, [], {
        colSize: args?.colSize || 12, fieldName: args.fieldName, fieldType: 'TextField', description: args.description || 'description',
        placeholder: args.placeholder || 'placeholder', autoFocus: args.autoFocus || false, required: args.required || false,
    }) as ITextField<T>;
}

function genericInputTextField<T>(formData: FormikValues, args: {
    colSize?: number, fieldName: keyof T, description: string, placeholder: string, autoFocus: boolean, required: boolean,
}): unknown {
    return genericField<T>(formData, [], {
        colSize: args?.colSize || 12, fieldName: args.fieldName, fieldType: 'InputTextField', description: args.description || 'description',
        placeholder: args.placeholder || 'placeholder', autoFocus: args.autoFocus || false, required: args.required || false,
    }) as IInputTextField<T>;
}

function genericInputMaskField<T>(formData: FormikValues, args: {
    style?:any, colSize?: number, fieldName: keyof T, description: string, placeholder: string, mask?: string, autoFocus: boolean, required: boolean,
}): unknown {
    return genericField<T>(formData, [], {
        colSize: args?.colSize || 12, fieldName: args.fieldName, fieldType: 'InputMaskField', description: args.description || 'description',
        placeholder: args.placeholder || 'placeholder',
        mask: args.mask,
        style: args.style,
        autoFocus: args.autoFocus || false, required: args.required || false,
    }) as IInputMaskField<T>;
}

function genericSelectButtonField<T>(formData: FormikValues, args: {
    colSize?: number, fieldName: keyof T, description: string, autoFocus: boolean, required: boolean,
    options: { name: string, value: any }[], optionLabel: string
}): unknown {
    return genericField<T>(formData, [], {
        colSize: args?.colSize || 12,
        fieldName: args.fieldName, fieldType: 'SelectButtonField', description: args?.description || 'description',
        autoFocus: args?.autoFocus || false, required: args?.required || false,
        options: args.options, optionLabel: args.optionLabel
    }) as ISelectButtonField<T>;
}

function genericGroupField<T>(description: string, args?: { colSize?: number }): IGroupField<T> {
    return { colSize: args?.colSize ? `col-${args.colSize}` : 'col-12', fieldType: 'GroupField', description } as IGroupField<T>;
}

function chargeField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'charge' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Cobrança por unidade?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function blockedField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'blocked' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Usuário bloqueado?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function confirmedPresenceField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'confirmedPresence' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Confirmou?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function attendedAcademyField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'attendedAcademy' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Compareceu?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function closedContractField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'closedContract' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Fechou contrato?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function finishedField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'finished' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Encerrado?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function nonpayerField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'nonpayer' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Falta de Pagamento?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function unsubscribeField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'unsubscribe' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Assinatura cancelada?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function isVigilanceField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'isVigilance' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'É Vigilância?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function isisBlockedField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'blocked' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'É bloqueado?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function isBlockedDefaultField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'blockedDefault' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'É bloqueado por padrão?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};





function descriptioField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean
}): IInputTextField<T> {
    const field = args?.fieldName ? args.fieldName : 'description' as keyof T;
    return genericInputTextField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Descrição',
        placeholder: args?.placeholder || 'descrição', autoFocus: args?.autoFocus || false, required: args?.required || true,
    }) as IInputTextField<T>;
}

function indentificationField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean
}): IInputTextField<T> {
    const field = args?.fieldName ? args.fieldName : 'key' as keyof T;

    return genericInputTextField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Identificação no sistema',
        placeholder: args?.placeholder || 'identificacao', autoFocus: args?.autoFocus || false, required: args?.required || true,
    }) as IInputTextField<T>;
}

function indentificationAdminField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean
}): IInputTextField<T> {
    const field = args?.fieldName ? args.fieldName : 'key' as keyof T;

    return checkRoleHierarchyByToken(RoleRules.admin) ? genericInputTextField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Identificação no sistema',
        placeholder: args?.placeholder || 'identificacao', autoFocus: args?.autoFocus || false, required: args?.required || true,
    }) as IInputTextField<T>
        : genericTextField<T>(formData, {
            colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Identificação no sistema',
            placeholder: args?.placeholder || 'identificacao', autoFocus: args?.autoFocus || false, required: args?.required || true,
        }) as IInputTextField<T>;
}

function isMobileField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean,
    options?: { name: string, value: any }[], optionLabel?: string
}): ISelectButtonField<T> {
    const field = args?.fieldName ? args.fieldName : 'isMobile' as keyof T;
    return genericSelectButtonField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'É Celular?',
        autoFocus: args?.autoFocus || false, required: args?.required || true,
        options: args?.options || [{ name: 'Sim', value: true }, { name: 'Não', value: false }],
        optionLabel: args?.optionLabel || 'name'
    }) as ISelectButtonField<T>;
};

function mobilePhoneField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, mask?: string, placeholder?: string, autoFocus?: boolean, required?: boolean, style?:any
}): IInputMaskField<T> {
    const field = args?.fieldName ? args.fieldName : 'mobilePhone' as keyof T;
    return genericInputMaskField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Celular',
        mask: args?.mask || '+55 (99) 99999-9999',
        style: args?.style || null,
        placeholder: args?.placeholder || '+55 (99) 99999-9999', autoFocus: args?.autoFocus || false, required: args?.required || true,
    }) as IInputMaskField<T>;
}

function phoneField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, mask?: string, placeholder?: string, autoFocus?: boolean, required?: boolean
}): IInputMaskField<T> {
    const field = args?.fieldName ? args.fieldName : 'mobilePhone' as keyof T;
    return genericInputMaskField<T>(formData, {
        colSize: args?.colSize || 6, fieldName: field, description: args?.description || 'Telefone Fixo',
        mask: args?.mask || '+55 (99) 9999-9999',
        placeholder: args?.placeholder || '+55 (99) 9999-9999', autoFocus: args?.autoFocus || false, required: args?.required || true,
    }) as IInputMaskField<T>;
}


function nameField<T>(formData: FormikValues, args?: {
    colSize?: number, fieldName?: keyof T, description?: string, placeholder?: string, autoFocus?: boolean, required?: boolean
}): IInputTextField<T> {
    const field = args?.fieldName ? args.fieldName : 'name' as keyof T;
    return genericInputTextField<T>(formData, {
        fieldName: field, description: args?.description || 'Nome fantasia', placeholder: args?.placeholder || 'Nome fantasia', autoFocus: args?.autoFocus || false, required: args?.required || false,
    }) as IInputTextField<T>;
}

export const GenericFields = {
    attendedAcademyField,
    blockedField,
    chargeField,
    closedContractField,
    confirmedPresenceField,
    descriptioField,
    finishedField,
    genericGroupField,
    genericTextField,
    indentificationAdminField,
    indentificationField,
    isisBlockedField,
    isBlockedDefaultField,
    isMobileField,
    isVigilanceField,
    mobilePhoneField,
    phoneField,
    nameField,
    nonpayerField,
    unsubscribeField,
};