import { defined } from ".";
import { clone } from "./object";
import { padLeft } from "./string";

export const formatMoney = (value: number, digits: number = 2, size: number = 15) => padLeft(value.toLocaleString('pt-br', { minimumFractionDigits: digits, style: 'currency', currency: 'BRL' }), size, ' ');

export const formatValue = (value: number, digits: number = 2, size: number = 15) => padLeft(value.toLocaleString('pt-br', { minimumFractionDigits: digits }), size, ' ');

export const formatValueDecimal = (value: number) => value.toLocaleString('pt-br');

export const isNumber = (numberToCheck: any) => !isNaN(numberToCheck) || typeof numberToCheck === 'number';

export const randomInt = (min: number, max: number) => Math.floor(Math.random() * ((max - min) + 1)) + min;

export const minimumArrayValue = (array: Array<any>, field: string) => defined(array) ? array.reduce((r: number, c: any) => r = r === -1 || r > c[field] ? c[field] : r, -1) : 0;

export const maximumArrayValue = (array: Array<any>, field: string) => defined(array) ? array.reduce((r: number, c: any) => r = r < c[field] ? c[field] : r, 0) : 0;

export const completeArrayValue = (array: Array<any>, min: number, max: number, dec: number, pos: number) => {
    if (array.length >= pos) return array;
    const newArray = clone(array);
    for (let index = array.length - 1; index < pos - 1; index++) {
        newArray.push(randomInt(min, max) / dec);
    }
    return newArray
};

export const completeArrayDefaultValue = (array: Array<any>, pos: number, val: number) => {
    if (array.length >= pos) return array;
    const newArray = clone(array);
    for (let index = array.length - 1; index < pos - 1; index++) {
        newArray.push(val);
    }
    return newArray
};

export const accumulateArrayValue = (array: Array<any>, field: string) => defined(array) ? array.reduce(
    (r: number, c: any) => {
        const parts = field.split('.');
        let objectFields: any = c, continueFor: boolean = true;
        parts.forEach((part: string) => continueFor === true && defined(objectFields) && defined(objectFields[part]) ? objectFields = objectFields[part] : continueFor = false);
        return r += continueFor === true ? objectFields : 0;
    }, 0) : 0;

export const averageArrayValue = (array: Array<any>, field: string) => defined(array) ? accumulateArrayValue(array, field) / array.length : 0;

export const countArrayValue = (array: Array<any>, field: string, value: any) => defined(array) ? array.reduce(
    (r: number, c: any) => {
        const parts = field.split('.');
        let objectFields: any = c, continueFor: boolean = true;
        parts.forEach((part: string) => continueFor === true && defined(objectFields) && defined(objectFields[part]) ? objectFields = objectFields[part] : continueFor = false);
        return r += continueFor === true && value === objectFields ? 1 : 0;
    }, 0) : 0;

export const specificArrayValue = (array: Array<any>, field: string, value: any, result: string) => array.find((arr: any) => arr[field] === value)[result];

export const arrayValues = (array: Array<any>, field: string) => array.map((arr: any) => arr[field]);

export const ruleOfThree = (greatnessBase: number, greatnessResult: number, unknownBase: number, proportional: boolean = true): number =>
    greatnessBase === 0 || greatnessResult === 0 || unknownBase === 0 ? 0 : proportional === true ? unknownBase * greatnessResult / greatnessBase : greatnessBase * greatnessResult / unknownBase;

export const proportionalRuleOfThree = (firstBase: number, secondBase: number, firstResult?: number, secondResult?: number, percentage: boolean = false): { firstResult: number, secondResult: number } =>
    !defined(firstBase) || firstBase === 0 || !defined(secondBase) || secondBase === 0 ? { firstResult: defined(firstResult) ? firstResult : 0, secondResult: defined(secondResult) ? secondResult : 0 } :
        defined(firstResult) && defined(secondResult) ? { firstResult, secondResult } :
            defined(firstResult) ? { firstResult, secondResult: ruleOfThree(firstBase, firstResult, secondBase) } :
                defined(secondResult) ? { firstResult: ruleOfThree(secondBase, secondResult, firstBase), secondResult } :
                    { firstResult: ruleOfThree(firstBase + secondBase, (percentage === false ? 100 : 1), firstBase), secondResult: ruleOfThree(firstBase + secondBase, (percentage === false ? 100 : 1), secondBase) }


export const countDecimals = (value: number) => {
    if (Math.floor(value) === value) return 0;
    const valueParts = value.toString().split(".");
    return defined(valueParts) && valueParts.length === 2 && defined(valueParts[1]) ? valueParts[1].length : 0;
}

export const floorDecimals = (value: number, qttyDecimals: number = 0) => {
    // console.log(`floorDecimals: ${value} / ${qttyDecimals} / ${Math.floor(value * Math.pow(10, qttyDecimals)) / Math.pow(10, qttyDecimals)}`);
    return Math.floor(value * Math.pow(10, qttyDecimals)) / Math.pow(10, qttyDecimals);
}

export const roundDecimals = (value: number, qttyDecimals: number = 0) => {
    // console.log(`roundDecimals: ${value} / ${qttyDecimals} / ${Math.round(value * Math.pow(10, qttyDecimals)) / Math.pow(10, qttyDecimals)}`);
    return Math.round(value * Math.pow(10, qttyDecimals)) / Math.pow(10, qttyDecimals);
}
