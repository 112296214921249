import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class BranchGymPermissionService extends GenericService {
    async findBranchGymPermissionByPermissionsFunctionalitiesId(networkGymId: string | null, branchGymId: string | null, permissionsFunctionalityId: any) {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId },
                { field: 'permissionsFunctionality', value: permissionsFunctionalityId },
            ]
        };
        if (branchGymId) search.queries.push({ field: EntitiesService(Entities.BRANCH_GYM).singular, value: branchGymId });
        return await super.findByQuery(Entities.BRANCH_GYM_PERMISSION, false, search, undefined, { limit: 0 })
    }
    allNetworkGymPermissions = async (networkGymId: string) => {
        const search = {
            queries: [
                { field: EntitiesService(Entities.NETWORK_GYM).singular, value: networkGymId }
            ]
        };
        return await super.findByQuery(Entities.BRANCH_GYM_PERMISSION, false, search, undefined, { limit: 0 })
    };
    findBranchGymPermissionById = async (_id: string | null) => await super.findById(Entities.BRANCH_GYM_PERMISSION, false, _id);
    saveBranchGymPermission = async (data: any) => await super.save(Entities.BRANCH_GYM_PERMISSION, false, data);
}
