import Chat4Fit from './chat4fit';
import enviroment from '../.env';

import { defineLink, defineMeta, defineTitle, defineProject } from './methods';

export interface ProjectInterface {
    id: string;
    name: string;
    template: string;
    description: string;
    themeColor: string;
    layoutCssWrapper: { id: string, href: string };
    themeCssWrapper: { id: string, href: string };
};

export interface InterfaceListProject {
    id: string,
    config: ProjectInterface,
}

interface InterfaceProjectDefault {
    projects: InterfaceListProject[];
    publicUrl: string;
    hideSplashScreen: () => void;
    defineTitle: (projectStorageId: string) => void;
    defineMeta: (projectStorageId: string) => void;
    defineLink: (projectStorageId: string) => void;
    defineProject: (projectStorageId: string) => void;
}

/* eslint-disable import/no-anonymous-default-export */
const ProjectDefault: InterfaceProjectDefault = {
    projects: [{ id: 'chat4fit', config: Chat4Fit }],
    publicUrl: enviroment() === 'DEVELOPMENT' ? 'http://localhost:3000' : enviroment() === 'HOMOLOGATION' ? 'http://app.hml.chat4fit.com' : 'https://app.chat4fit.com',
    hideSplashScreen: () => [].forEach.call(document.querySelectorAll('.splash-screen'), (el: any) => el.style.display = 'none'),
    defineTitle, defineLink, defineMeta, defineProject,
};

// console.log('enviroment(): ', enviroment());

export default ProjectDefault;