/* eslint-disable no-eval */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import React from 'react';
import { isArray } from '../../../utils/functions';
import { IConfig } from '../generics/genericCardConfig';
import { createListDataSelect, createSkeletons } from './GenericCard';

export function SelecDialog(props: {
    keyDialog: string,
    data: any[],
    typeData: { value: string, tag: string },
    add?: boolean,
    icon?: string,
    attrbSelectId?: string,
    dialogIcon: string,
    titleIcon: string,
    evalTitleValue: string,
    objectData: string,
    showDialog: boolean,
    loadingData: boolean,
    select: (type: string, _id?: string | null, ...args: string[]) => void,
    unselect: (type: string, _id?: string | null, ...args: string[]) => void,
    setDialogData: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void),
}) {
    const select = (type: string, _id?: string | null, ...args: string[]) => {
        props.select(type, _id);
        if (!props.add || !props.add) props.setDialogData(false);
    }

    const unselect = (type: string, _id?: string | null, ...args: string[]) => {
        props.unselect(type, _id);
        if (!props.add || !props.add) props.setDialogData(false);
    }


    const listData = () => {
        const listData = props.data && isArray(props.data) ? props.data : [];
        if (props.loadingData) return createSkeletons(Math.ceil(Math.random() * 10));
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData && listData.length > 0 ?
                listData.map((data: any) => {
                    const selectedId = localStorage.getItem(props.typeData.value);
                    const config: IConfig = {
                        title: { icon: props.titleIcon || 'pi-question', value: eval(props.evalTitleValue) },
                        data,
                        buttons: (props.attrbSelectId ? eval(props.attrbSelectId) : data._id) ? [
                            selectedId !== (props.attrbSelectId ? eval(props.attrbSelectId) : data._id) ? { key: `selecionar`, icon: `${props.icon ? props.icon : 'pi-tag'}`, onClick: () => { select(props.typeData.value, props.attrbSelectId ? eval(props.attrbSelectId) : data._id); }, tooltip: 'Selecionar' } : null,
                            selectedId === (props.attrbSelectId ? eval(props.attrbSelectId) : data._id) ? { key: `desprezar`, icon: 'pi-times', onClick: () => { unselect(props.typeData.value, props.attrbSelectId ? eval(props.attrbSelectId) : data._id); }, tooltip: 'Desprezar' } : null
                        ] : [],
                        selected: selectedId === (props.attrbSelectId ? eval(props.attrbSelectId) : data._id)
                    };
                    return config;
                }) : [];
            return createListDataSelect(listConfig);
        }
    }

    return (
        <Dialog key={props.keyDialog} visible={props.showDialog} maximizable style={{ minWidth: '80%' }} modal={true} closable={false}
            onHide={() => props.setDialogData(false)}>
            <div className='card' style={{ backgroundColor: '#118CAC3C' }}>
                <div className='grid flex flex-nowrap title-dialog'>
                    <div>
                        <i className={classNames('pi', 'pi-fw', props.dialogIcon, 'layout-menuitem-icon')} style={{ fontSize: 32, color: '#56ae93' }}></i>
                        <span className='title-dialog-value'>{`Selecione ${props.objectData}`}</span>
                        <Button type='button' onClick={() => props.setDialogData(false)}
                            style={{ position: 'absolute', right: '50px', margin: 0 }}
                            className='p-button-rounded p-button-warning shadow-3' icon='pi pi-times' />
                    </div>
                </div>
            </div>
            <div className='justify-content-center sm:col-12 md:col-12 lg:col-12 xl:col-12'>
                <div className='grid' style={{ margin: 40 }}>
                    {listData()}
                </div>
            </div>
        </Dialog>
    );
}

