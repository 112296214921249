import { Entities } from '../generics/EntitiesService';
import GenericService from '../generics/GenericService';

export default class ScheduleService extends GenericService {
    findScheduleById = async (_id: string | null) => await super.findById(Entities.SCHEDULE, false, _id);
    findScheduleByQuery = async (params: {
        references?: any[], queries?: any[], sorts?: any[], projections?: any[],
    }) => await super.findByQuery(Entities.SCHEDULE, false, params, undefined, {limit: 0});
    saveSchedule = async (data: any) => await super.save(Entities.SCHEDULE, false, data);
}
