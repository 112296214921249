
import ls from '../../../projects/localStorage';
import { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class NetworkGymService extends GenericService {
    allNetworkGyms = async (search?: string | undefined, headers?: any) => await super.findAll(Entities.NETWORK_GYM, false, search, undefined, headers);
    allNetworkGymNoBlockeds = async (search?: any | undefined) => {
        if (!search) search = {};
        if (!search.queries) search.queries = [];
        const networkGyms = await ls.networkGym.getNetworkGymsByStorageToken();
        search.queries.push({ field: '_id', value: { $in: networkGyms?.map((b: any) => b._id) } });
        return await super.findByQuery(Entities.NETWORK_GYM, false, search);
    }
    findNetworkGymById = async (_id: string | null) => await super.findById(Entities.NETWORK_GYM, false, _id);
    saveNetworkGym = async (data: any) => await super.save(Entities.NETWORK_GYM, false, data);
}
