import ExcelJS, { Column } from 'exceljs';

export class SheetFactory {
  private workbook;

  private currentWorksheet!: ExcelJS.Worksheet;

  constructor(title = 'leads') {
    this.workbook = new ExcelJS.Workbook();
    this.workbook.creator = 'Chat4Fit';
    // this.currentWorksheet = this.workbook.addWorksheet(title);
  }

  addEmptyRow() {
    this.currentWorksheet.addRow([]);
  }

  addWorksheet(title: string) {
    this.currentWorksheet = this.workbook.addWorksheet(title);
  }

  setCreator(creator: string) {
    this.workbook.creator = creator;
  }

  setColumnSizes(sizes: number[]) {
    this.currentWorksheet.columns = sizes.map(
      (size) => ({ width: size } as Column),
    );
  }

  addTitleRow(values: any[]) {
    const upperCasedValues = values.map((value) => String(value).toUpperCase());
    const row = this.currentWorksheet.addRow(upperCasedValues);
    row.font = { size: 18, bold: true, color: { argb: 'FFFFFF' } };
    row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '077676' } };
    // row.height = 22;
  }

  addSubtitleRow(values: any[]) {
    const row = this.currentWorksheet.addRow(values);
    row.font = { size: 16, bold: true, color: { argb: 'FFFFFF' } };
    row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '077676' } };
    // row.height = 20;
  }

  addRows(values: any[]) {
    this.currentWorksheet.addRows(values);
    // const rows = this.currentWorksheet.addRows(values);
    // rows.forEach((row: ExcelJS.Row) => {
    //   row.border = {
    //     top: { style: 'medium', color: { argb: 'DDDDDD' } },
    //     left: { style: 'medium', color: { argb: 'DDDDDD' } },
    //     bottom: { style: 'medium', color: { argb: 'DDDDDD' } },
    //     right: { style: 'medium', color: { argb: 'DDDDDD' } }
    //   };
    //   // row.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '000000' } };
    // });
  }

  async finishSheet() {
    const buffer = await this.workbook.xlsx.writeBuffer();
    return buffer;
  }

  async downloadSheet(filename: string) {
    const buffer = await this.workbook.xlsx.writeBuffer();

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    let url = window.URL.createObjectURL(new Blob([buffer], { type: fileType }));
    let a = document.createElement('a');
    a.href = url;
    a.download = `${filename || ''}.xlsx`;
    a.click();
  }
}