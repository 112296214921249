import { withRouter } from 'react-router-dom';

export const AppBreadcrumb = (props: any) => {

    // const location = useLocation();
    // const history = useHistory();

    // const logout = () => {
    //     localStorage.clear();
    //     props.setToken(null);
    // }

    return (
        <div className="layout-breadcrumb">
            {/* <ul>
                <li><button className="p-link" onClick={() => history.push('/')}><i className="pi pi-home"></i></button></li>
                <li>{location.pathname}</li>
            </ul> */}

            {/* <div className="layout-breadcrumb-options">
                <button className="p-link" title="Logout" onClick={() => logout()}>
                    <i className="pi pi-power-off"></i>
                </button>
            </div> */}
        </div>
    );
}

export default withRouter(AppBreadcrumb);
