/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { checkRoleHierarchyByToken, RoleRules } from '../../../projects/checkRoleHierarchy';
import ls from '../../../projects/localStorage';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import NetworkGymService from '../../service/admin/NetworkGymService';
import StateCityService from '../../service/StateCityService';
import { addNewRegister } from '../commons/formDataFunctions';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { createListData, createSkeletons } from '../commons/GenericCard';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { GenericFields } from '../commons/GenericFields';
import { retrieveListData, retrieveListDataWithParentId } from '../commons/retrieveDataFunctions';
import { IMessage } from '../Control';
import { IFormDataNetworkGym } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { IConfig } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
// import { TagSelecteds } from '../../AppTagSelecteds';
import './NetworkGym.css';

const networkGymService = new NetworkGymService();
const stateCityService = new StateCityService();

export const NetworkGym = (props: IPropsArgsComponents) => {
    const [title] = useState('Redes de academias');
    // const [objectData] = useState('rede de academia');
    const [token] = useState(props.args.token);
    const [loadingData, setLoadingData] = useState(true);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataNetworkGym[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    const [editFormData, setEditFormData] = useState<any | null>();

    const [states, setStates] = useState<any>([]);
    const [stateSelected, setStateSelected] = useState<string>();
    const [cities, setCities] = useState<any>([]);

    useEffect(retrieveListData(() => stateCityService.allStates(), props, (data: any[]) => setStates(data)), []);
    useEffect(retrieveListDataWithParentId(stateSelected, (stateSelectedId: string) => stateCityService.cityByState(stateSelectedId), props, (data: any[]) => setCities(data)), [stateSelected]);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: false },
    ]), []);

    useEffect(() => { if (!token) window.location.href = '/'; }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm])

    const selectStateHandle = (event: DropdownChangeEvent) => event && event.value && event.value._id ? setStateSelected(event.value._id) : setStateSelected(undefined);

    const refreshList = () => {
        let isMounted = true;
        setLoadingData(true);
        networkGymService.allNetworkGyms(searchTerm).then((returned: any) => {
            if (isMounted) {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { props.args.setLoading(false); setLoadingData(false); window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    setData(returned.data);
                } else {
                    setData([]);
                }
                setLoadingData(false); props.args.setLoading(false);
            }
        }).catch(error => {
            console.log('error', error);
            if (isMounted) {
                setLoadingData(false); props.args.setLoading(false);
                setData([]);
            }
        });
        return () => { isMounted = false };
    };

    // const add = (type?: string, _id?: string, ...args: string[]) => {
    //     setEditFormData(null);
    //     setDialogData(true);
    // }

    const edit = (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;
        if (type === 'networkGym') {
            // console.log('item: ', item);
            setEditFormData({
                _id: item._id,
                key: item.key,
                fantasyName: item.fantasyName,
                companyName: item.companyName,
                companyNumber: item.companyNumber,
                address: item.address,
                number: item.number,
                complement: item.complement,
                district: item.district,
                zipCode: item.zipCode,
                city: item.city,
                state: item.state,
                active: item.active,
                unsubscribe: item.unsubscribe,
                isVigilance: item.isVigilance
            });
            setDialogData(true);
        }
    }

    const clone = async (type: string, _id?: string, ...args: string[]) => {
        const item = data.find(d => d._id === _id);
        if (!item) return;
        if (type === 'networkGym') {
            setEditFormData({
                // key: item.key,
                fantasyName: item.fantasyName,
                companyName: item.companyName,
                companyNumber: item.companyNumber,
                active: item.active
            });
            setDialogData(true);
        }
    }

    const listData = () => {
        const listData = data ? data : [];
        if (loadingData) return createSkeletons(8);
        else if (!listData || listData.length === 0)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#56ae93' }}>
                <h3>Não foi localizado nenhum registro.</h3>
            </div>;
        else {
            const listConfig = listData ?
                listData.map(d => {
                    const selectedNetworkGymId = localStorage.getItem('networkGym');
                    const config: IConfig = {
                        title: { icon: 'pi-table', value: d.fantasyName },
                        lines: [
                            // { description: 'Fantasia', value: d.fantasyName },
                            { description: 'Razão social', value: d.companyName },
                        ],
                        data: d,
                        buttons: d._id ? [
                            checkRoleHierarchyByToken(RoleRules.owner) ? { key: 'editar', icon: 'pi-pencil', onClick: () => edit('networkGym', d._id), tooltip: 'Editar' } : null,
                            checkRoleHierarchyByToken(RoleRules.admin) ? { key: `clone`, icon: 'pi-copy', onClick: () => clone('networkGym', d._id), tooltip: 'Copiar' } : null,
                            // selectedNetworkGymId === d._id ? { key: 'unidades', icon: 'pi-th-large', onClick: () => { props.args.select('networkGym', d._id); window.location.href = '/#/branchGyms'; }, tooltip: 'Unidades' } : null,
                            !ls.checkUniqueLocalStorageToken('networkGym') && selectedNetworkGymId !== d._id ? { key: 'selecionar', icon: 'pi-tag', onClick: () => props.args.select('networkGym', d._id), tooltip: 'Selecionar' } : null,
                            !ls.checkUniqueLocalStorageToken('networkGym') && selectedNetworkGymId === d._id ? { key: 'desprezar', icon: 'pi-times', onClick: () => props.args.unselect('networkGym', d._id), tooltip: 'Desprezar' } : null,
                        ] : [],
                        selected: selectedNetworkGymId === d._id,
                    };
                    return config;
                }) : [];
            return createListData(listConfig);
        }
    }

    return (
        <>
            {createView(title, {
                editData: true,
                addNew: checkRoleHierarchyByToken(RoleRules.admin), addNewRegister: () => addNewRegister('networkGym', undefined, { setEditFormData, setDialogData }),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData()
            })}
            {token ?
                <GenericFormDialog<IFormDataNetworkGym> key='NetworkGymDialog'
                    token={token}
                    objectData='rede'
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                    editFormData={editFormData}
                    emptyFormData={{
                        key: '',
                        fantasyName: '',
                        companyName: '',
                        responsibleName: '',
                        responsibleMobilePhone: '',
                        responsibleEmail: '',
                        charge: false,
                        companyNumber: '',
                        address: '',
                        number: '',
                        complement: '',
                        district: '',
                        zipCode: '',
                        city: '',
                        state: '',
                        active: true,
                        deleted: false,
                        showButtons: false,
                        unsubscribe: false,
                        isVigilance: false,
                    }}
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataNetworkGym>[]) => [
                        // GenericFields.indentificationAdminField(formData, { colSize: 4 }),
                        {
                            colSize: 'col-12', fieldName: 'fantasyName', fieldType: 'InputTextField', description: '* Nome Fantasia:', placeholder: 'Nome Fantasia', value: formData.values.fantasyName,
                            onChange: formData.handleChange, autoFocus: false
                        },
                        {
                            colSize: 'col-12', fieldName: 'companyName', fieldType: 'InputTextField', description: '* Razão Social:', placeholder: 'Razão Social', value: formData.values.companyName,
                            onChange: formData.handleChange, autoFocus: false
                        },

                        formData.values.charge ?
                            {
                                colSize: 'col-12', fieldName: 'companyNumber', fieldType: 'InputMaskField', description: '* CNPJ:', placeholder: 'CNPJ', value: formData.values.companyNumber,
                                mask: '99.999.999/9999-99',
                                onChange: formData.handleChange, autoFocus: false
                            } : null,
                        {
                            colSize: 'col-12', fieldType: 'GroupField', description: 'Dados de Endereço:',
                        },
                        {
                            colSize: 'col-8', fieldName: 'address', fieldType: 'InputTextField', description: '* Logradouro:', placeholder: 'Logradouro', value: formData.values.address, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-4', fieldName: 'number', fieldType: 'InputTextField', description: '* Número:', placeholder: 'Número', value: formData.values.number, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-12', fieldName: 'complement', fieldType: 'InputTextField', description: '* Complemento:', placeholder: 'Complemento', value: formData.values.complement, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-6', fieldName: 'district', fieldType: 'InputTextField', description: '* Bairro:', placeholder: 'Bairro', value: formData.values.district, autoFocus: false,
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-6', fieldName: 'zipCode', fieldType: 'InputMaskField', description: '* CEP:', placeholder: 'CEP', value: formData.values.zipCode, autoFocus: false,
                            mask: '99999-999',
                            onChange: formData.handleChange
                        },
                        {
                            colSize: 'col-4', fieldName: 'state', fieldType: 'DropdownField', description: '* Estado:', placeholder: 'Selecione um estado', value: formData.values.state, autoFocus: false,
                            onChange: (event: DropdownChangeEvent) => {
                                if (event.value?._id !== formData.values.state?._id) formData.values.city = '';
                                selectStateHandle(event); formData.handleChange(event);
                            },
                            options: states, optionLabel: 'name', filter: true, showClear: true, filterBy: 'name'
                        },
                        {
                            colSize: 'col-8', fieldName: 'city', fieldType: 'DropdownField', description: '* Cidade:', placeholder: 'Selecione uma cidade', value: formData.values.city, autoFocus: false,
                            onChange: formData.handleChange,
                            options: cities, optionLabel: 'name', filter: true, showClear: true, filterBy: 'name',
                            disabled: !formData.values.state || !cities,
                        },
                        // {
                        //     colSize: 'col-12', fieldName: 'companyNumber', fieldType: 'InputMaskField', description: '* CNPJ:', placeholder: 'CNPJ', value: formData.values.companyNumber,
                        //     mask: '99.999.999/9999-99',
                        //     onChange: formData.handleChange, autoFocus: false
                        // },
                        checkRoleHierarchyByToken(RoleRules.admin) ? GenericFields.unsubscribeField(formData, { colSize: 6 }) : null,
                        checkRoleHierarchyByToken(RoleRules.admin) ? GenericFields.isVigilanceField(formData, { colSize: 6 }) : null,
                    ])}
                    editForceData={{}}
                    editFunctions={[(formData: FormikValues) => {
                        setStateSelected(formData.state?._id);
                    }]}
                    // editFunctions={[]}
                    formLists={[{
                        key: 'state',
                        data: states,
                        changeMethod: {
                            field: 'state',
                            method: (value: any) => selectStateHandle(value),
                        }
                    }, {
                        key: 'city',
                        data: cities,
                    }]}
                    validate={(data: any) => {
                        let errors: any = {};

                        // if (!data.key) {
                        //     errors.key = 'Identificação é obrigatório.';
                        // } else if (data.key.length < 5) {
                        //     errors.key = 'Identificação precisa ter mais que 4 caracteres.';
                        // }

                        if (!data._id || !data.unsubscribe) {
                            data.unsubscribe = false;
                        }

                        if (!data.fantasyName) {
                            errors.fantasyName = 'Nome fantasia é obrigatório.';
                        } else if (data.fantasyName.length < 3) {
                            errors.fantasyName = 'Nome fantasia precisa ter mais que 2 caracteres.';
                        } else if (data.fantasyName.length > 20) {
                            errors.fantasyName = 'Nome fantasia precisa ter menos que 21 caracteres.';
                        }

                        data.key = data.fantasyName;

                        if (!data.companyName) {
                            errors.companyName = 'Razão social é obrigatório.';
                        } else if (data.companyName.length < 3) {
                            errors.companyName = 'Razão social precisa ter mais que 2 caracteres.';
                        }


                        // if (!data.responsibleName) {
                        //     errors.responsibleName = 'Nome do Responsável é obrigatório.';
                        // } else if (data.responsibleName.length < 3) {
                        //     errors.responsibleName = 'Nome do Responsável precisa ter mais que 2 caracteres.';
                        // }

                        // if (!data.responsibleMobilePhone) {
                        //     errors.responsibleMobilePhone = 'Celular do Responsável é obrigatório.';
                        // } else if (!isMobilePhone(onlyNumbers(data.responsibleMobilePhone))) {
                        //     errors.responsibleMobilePhone = 'Número de celular do responsável inválido.';
                        // }

                        // if (!data.responsibleEmail) {
                        //     errors.responsibleEmail = 'Email do Responsável é obrigatório.';
                        // } else if (!isMail(data.responsibleEmail)) {
                        //     errors.responsibleEmail = 'Email do Responsável inválido.';
                        // }

                        // if (data.charge) {
                        //     if (!data.companyNumber) {
                        //         errors.companyNumber = 'CNPJ é obrigatório.';
                        //     } else if (!isCNPJ(data.companyNumber)) {
                        //         errors.companyNumber = 'CNPJ é inválido. Favor verificar';
                        //     }

                        //     if (!data.companyName) {
                        //         errors.companyName = 'Razão Social é obrigatório.';
                        //     }
                        // }

                        if (!data.address) {
                            errors.address = 'Endereço é obrigatório.';
                        }

                        if (!data.number) {
                            errors.number = 'Número é obrigatório.';
                        }

                        if (!data.district) {
                            errors.district = 'Bairro é obrigatório.';
                        }

                        if (!data.zipCode) {
                            errors.zipCode = 'CEP é obrigatório.';
                        }

                        if (!data.city) {
                            errors.city = 'Cidade é obrigatório.';
                        }

                        if (!data.state) {
                            errors.state = 'Estado é obrigatório.';
                        }

                        return errors;
                    }}
                    submitForceData={{}}
                    onSubmit={(props: IPropsFormDialog<IFormDataNetworkGym>, data: FormikValues, formData: FormikInterface) => {
                        // console.log('data: ', data);
                        new NetworkGymService().saveNetworkGym(data)
                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }} />
                :
                null
            }
        </>
    )
}
