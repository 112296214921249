import { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class PermissionsFunctionalitieService extends GenericService {
    async findPermissionsFunctionalityByFunctionalityKey(functionalityKey: string) {
        const search = {
            queries: [
                { field: 'functionalityKey', value: functionalityKey },
            ]
        };
        return await super.findByQuery(Entities.PERMISSIONS_FUNCTIONALITY, false, search, undefined, { limit: 0 })
    }
    allPermissionsFunctionalities = async (search?: string | undefined) => await super.findAll(Entities.PERMISSIONS_FUNCTIONALITY, false, search);
    findPermissionsFunctionalityById = async (_id: string | null) => await super.findById(Entities.PERMISSIONS_FUNCTIONALITY, false, _id);
}
