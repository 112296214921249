import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class GuideScriptService extends GenericService {
    allGuideScripts = async (search?: string | undefined) => await super.findAll(Entities.GUIDE_SCRIPT, true, search);
    findGuideScriptById = async (_id: string | null) => await super.findById(Entities.GUIDE_SCRIPT, false, _id);
    findAllGuideScriptByGuideId = async (guideId: string | null, search?: string | undefined) => await super.findByReferenceId(Entities.GUIDE_SCRIPT, false, 
        EntitiesService(Entities.GUIDE).singular, guideId, search);
    saveGuideScript = async (data: any) => await super.save(Entities.GUIDE_SCRIPT, true, data);
}
