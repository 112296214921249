/* eslint-disable react-hooks/exhaustive-deps */
import { FormikValues } from 'formik';
import moment from 'moment';
import { Button } from 'primereact/button';
import { CalendarChangeEvent } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { FileUpload, FileUploadErrorEvent, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { createRef, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { SheetFactory } from '../../../utils/SheetFactory';
import { isMobilePhone } from '../../../utils/functions/data';
import { makeHash, makeKeyByString } from '../../../utils/functions/hash';
import { onlyNumbers } from '../../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../../utils/httpStatus';
import { IPerformRequestResult } from '../../../utils/interfaces';
import ContactListService from '../../service/admin/ContactListService';
import ContactService from '../../service/admin/ContactService';
import GuideService from '../../service/admin/GuideService';
import { IMessage } from '../Control';
import { GenericFormDialog } from '../commons/GenericFormDialog';
import { saveFailed, saveSuccessed } from '../commons/functions';
import { IFormDataContact, IFormDataContactList, IFormDataGuide } from '../generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../generics/formInterfaces';
import { createGenericListData } from '../generics/genericCardConfig';
import { createView } from '../generics/view';
import { FormikInterface } from '../interfaces/formikInterface';
import './Contact.css';

const contactService = new ContactService();
const contactListService = new ContactListService();

interface IMessageFileUpload {
    type: 'success' | 'error',
    fileName: string,
    message: string,
    dataRows?: any[]
}

interface IDataFileUpload {
    name: string;
    nickname: string;
    mobilePhone: string;
    dateRef?: Date;
    __rowNum__: number;
}

export const Contact = (props: IPropsArgsComponents) => {
    const fileUploadRef = useRef(null);

    const [title] = useState('Leads');
    const [token] = useState(props.args.token);
    const [dialogData, setDialogData] = useState(false);
    const [data, setData] = useState<IFormDataContact[]>([]);
    const [loadingData, setLoadingData] = useState(true);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);
    const [guideId, setGuideId] = useState(props.args.guideId);
    const [guideSelected, setGuideSelected] = useState<IFormDataGuide | null>();
    const [displayPosition, setDisplayPosition] = useState(false);
    const [totalSize, setTotalSize] = useState(0);
    const [position] = useState<any>('top');


    const [editFormData, setEditFormData] = useState<any | null>();

    const [msgFileErrors, setMsgFileErrors] = useState<any[]>([]);

    const [drag, setDrag] = useState(false);
    let dropRef = createRef<HTMLDivElement>();

    const guideService = new GuideService();

    useEffect(() => {
        setGuideId(props.args.guideId);
        if (props.args.guideId && (!guideSelected || props.args.guideId !== guideSelected._id)) {
            guideService.findGuideById(props.args.guideId).then((returned: any) => {
                if (returned.status === HttpStatus.UNAUTHORIZED) {
                    props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                    setTimeout(() => { window.location.href = '/'; }, 5000);
                    setData([]);
                } else if (isSuccess(returned.status)) {
                    setGuideSelected(returned.data);
                } else {
                    setGuideSelected(null);
                }

            }).catch(error => {
                console.log('error', error);
                setGuideSelected(null);

            });
        } else {
            setGuideSelected(null);
        }
    }, [props.args.guideId]);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: true },
        { key: 'guide', show: true, considered: true },
    ]), []);

    useEffect(() => {
        if (!token) window.location.href = '/';
    }, [token]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => { refreshList(); }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        let div: any = dropRef.current;
        if (!div) return;
        div.addEventListener('dragenter', handleDragIn);
        div.addEventListener('dragleave', handleDragOut);
        // div.addEventListener('dragover', handleDrag);
        // div.addEventListener('drop', handleDrop);
        return () => {
            div.removeEventListener('dragenter', handleDragIn);
            div.removeEventListener('dragleave', handleDragOut);
            // div.removeEventListener('dragover', handleDrag);
            // div.removeEventListener('drop', handleDrop);
        };
    });

    const handleDragIn = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(true);
    };

    const handleDragOut = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(false);
    };

    const refreshList = () => {
        if (networkGymId && branchGymId && guideId) {
            let isMounted = true;
            setLoadingData(true);
            contactService.findContactsByIds(networkGymId, branchGymId, guideId, searchTerm).then((returned: any) => {
                if (isMounted) {
                    if (returned.status === HttpStatus.UNAUTHORIZED) {
                        props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                        setTimeout(() => { setLoadingData(false); window.location.href = '/'; }, 5000);
                        setData([]);
                    } else if (isSuccess(returned.status)) {
                        setData(returned.data);
                    } else {
                        setData([]);
                    }
                    setLoadingData(false);
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setLoadingData(false);
                    setData([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const listData = (data: any[]) => {
        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;
        if (!branchGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Unidade.</h3>
            </div>;
        if (!guideId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar um Roteiro.</h3>
            </div>;

        // console.log('data: ', data);
        return createGenericListData('contact', data,
            { icon: 'pi-envelope', evalValue: '\'Arquivo: \'+data.filename' },
            [{ description: '\'Qtd.\'', value: 'data.quantityContact' },
            { description: '\'Script\'', value: 'data.script?._id || \'pendente\'' },
            { description: '\'Versão\'', value: 'data.version || \'pendente\'' }],
            localStorage.getItem('contact'),
            {
                isEdited: false, isCloned: false, isSelected: false,
                loadingData,
                editForceData: { networkGym: networkGymId, branchGym: branchGymId, guide: guideId },
                removeForceData: ['_id', 'key'],
                select: props.args.select,
                unselect: props.args.unselect,
                setEditFormData,
                setDialogData,
            });
    }


    const renderFooter = () => {
        return (
            <div>
                <Button label='Fechar' icon='pi pi-times' className='p-button-warning' onClick={() => onHide()} />
                {/* <Button label='Yes' icon='pi pi-check' onClick={() => onHide(name)} autoFocus /> */}
            </div>
        );
    }

    const onShow = () => {
        setDisplayPosition(true);
    }


    const onHide = () => {
        setDisplayPosition(false);
    }


    const onSelect = (fileUploadSelectParams: FileUploadSelectEvent) => {
        setMsgFileErrors([]);
        let _totalSize = fileUploadSelectParams.files[0].size;
        setTotalSize(_totalSize);
    }

    const onUpload = (fileUploadUploadFileUploadUploadEvent: FileUploadUploadEvent) => {
        let _totalSize = fileUploadUploadFileUploadUploadEvent.files[0].size;
        setTotalSize(_totalSize);
        props.args.setMessage({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 5000 });
    }

    const onTemplateRemove = (file: any, callback: any) => {
        setMsgFileErrors([]);
        setTotalSize(totalSize - file.size);
        callback();
    }

    const onError = (fileUploadErrorFileUploadErrorEvent: FileUploadErrorEvent) => {
        setMsgFileErrors([]);
        setTotalSize(0);
    }

    const onClear = () => {
        setMsgFileErrors([]);
        setTotalSize(0);
    }

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        // const value = totalSize / (1024 * 1024 * 1024);
        // const formatedValue = value === 0 ? '0 B' : value + 'B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                {/* <ProgressBar value={value} displayValueTemplate={() => `${formatedValue} / 1 MB`} style={{ width: '300px', height: '20px', marginLeft: 'auto' }}></ProgressBar> */}
            </div>
        );
    }

    const msgError = (message: string) => {
        return <><span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>Mensagem:&nbsp;</span>{message}</>;
    }

    const itemTemplate = (file: any, props: any) => {
        const msgListFileErrors = msgFileErrors?.filter(e => file.name === e.fileName);
        // console.log('msgListFileErrors: ', msgListFileErrors);
        return (
            <div className='flex flex-column'>
                <div className='flex align-items-center flex-wrap'>
                    <div className='flex align-items-center' style={{ width: '80%' }}>
                        <Tag value={props.formatSize} severity='warning' className='px-3 py-2' style={{ width: '20%' }} />
                        <span className='flex flex-column text-left ml-9' style={{ marginLeft: '20px' }}>
                            <small>{new Date().toLocaleDateString()}</small>
                            {file.name}
                        </span>
                    </div>
                    <Button icon='pi pi-times' className='p-button-rounded p-button-danger shadow-3 ml-auto'
                        style={{ marginLeft: 20, height: 24, width: 24 }}
                        onClick={() => onTemplateRemove(file, props.onRemove)} tooltip='Remover' />
                </div>
                <div className='flex align-items-center flex-wrap'>
                    {msgListFileErrors.map(msgFileError =>
                        <Tag key={makeHash(10)} value={msgError(msgFileError.message)} severity={msgFileError.type === 'error' ? 'danger' : 'success'} className='px-3 py-2' style={{ width: '100%' }} />
                    )}
                </div>
            </div>
        )
    }

    const emptyTemplate = () => {
        return (
            <div ref={dropRef} className={classNames('flex align-items-center flex-column drag-drop-area', { drag: drag })}>
                <i className='pi pi-file-excel mt-3 p-5' style={{ 'fontSize': '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ 'fontSize': '1.2em', color: 'var(--text-color-secondary)' }} className='my-5'>Arraste e solte o arquivo aqui.</span>
            </div>
        )
    };

    const customBase64Uploader = async (event: FileUploadHandlerEvent) => {
        setMsgFileErrors(await processListFiles(event.files));
        refreshList();
    }

    const processListFiles = async (files: File[]): Promise<IMessageFileUpload[]> => {
        const returnPromises = [];

        if (!networkGymId || !branchGymId || !guideId) return [{ type: 'error', fileName: 'file failed!', message: 'Falha ao carregar a planilha.' }];

        // convert file to base64 encoded 
        for (let i = 0; i < files.length; i++) {
            returnPromises.push(new Promise(async (resolve: (value: IMessageFileUpload[]) => void, reject: (reason?: any) => void) => {
                const file = files[i];
                const reader = new FileReader();
                if (!guideSelected?._id || !guideSelected) return;

                const guideReturned = await guideService.findGuideById(guideSelected._id);
                const guide: IFormDataGuide = guideReturned.data;

                reader.onload = async (evt: ProgressEvent<FileReader>) => {
                    const bstr = evt.target?.result;
                    const wb = XLSX.read(bstr, { type: 'binary' });
                    const wsname = wb.SheetNames.find(s => s === 'contatos');
                    if (!wsname) return resolve([{ type: 'error', fileName: file.name, message: 'Planilha de carga inválida, aba "contatos" não localizada.' }]);
                    const ws = wb.Sheets[wsname];
                    const headerPlan = guide.hasGenericDate ? ['name', 'nickname', 'mobilePhone', 'genericDate'] : ['name', 'nickname', 'mobilePhone'];
                    let data = XLSX.utils.sheet_to_json<IDataFileUpload>(ws, { header: headerPlan });

                    const dataReaders: any[] = data.filter((d: any) => d.name === 'ROTEIRO:' || d.name === 'Nome do Cliente');
                    if (dataReaders.length !== 2) resolve([{ type: 'error', fileName: file.name, message: 'Planilha de carga inválida, cabeçalho não confere.' }]);

                    if (dataReaders[0].name !== 'ROTEIRO:' && dataReaders[0].nickname === makeKeyByString(guideSelected.key).toUpperCase())
                        return resolve([{ type: 'error', fileName: file.name, message: 'Planilha de carga inválida, cabeçalho não confere.' }]);

                    if (dataReaders[1].name !== 'Nome do Cliente' || dataReaders[1].nickname !== 'Apelido do Cliente'
                        || dataReaders[1].mobilePhone !== 'Núm. Celular do Cliente'
                        || (guideSelected.hasGenericDate === true && dataReaders[1].genericDate !== guide.descriptionGenericDate))
                        return resolve([{ type: 'error', fileName: file.name, message: 'Planilha de carga inválida, cabeçalho não confere.' }]);

                    const dataRows: IDataFileUpload[] = data.filter((d: IDataFileUpload) => d.name !== 'ROTEIRO:' && d.name !== 'Nome do Cliente');
                    if (dataRows.filter((d: IDataFileUpload) => RegExp('(exemplo)').test(d.name)).length > 0)
                        return resolve([{ type: 'error', fileName: file.name, message: 'Planilha de carga inválida, dados de exemplo não substituídos.' }]);


                    const dataRowErrors: IMessageFileUpload[] = [];
                    let qtty: number = 0

                    for (let j = 0; j < dataRows.length; j++) {
                        const row = dataRows[j];
                        if (row.name.trim().indexOf(' ') <= 0) dataRowErrors.push({ type: 'error', fileName: file.name, message: `Nome completo inválido. linha: ${row.__rowNum__}` });
                        // console.log('error - 1: ', row.name.trim().indexOf(' '), dataRowErrors);
                        if (row.name.trim().length <= 5) dataRowErrors.push({ type: 'error', fileName: file.name, message: `Nome completo inválido. linha: ${row.__rowNum__}` });
                        // console.log('error - 2: ', row.name.trim().length, dataRowErrors);
                        if (row.nickname.trim().length === 0) dataRowErrors.push({ type: 'error', fileName: file.name, message: `Apelido inválido. linha: ${row.__rowNum__}` });
                        // console.log('error - 3: ', row.nickname.trim().length, dataRowErrors);
                        if (onlyNumbers(row.mobilePhone.trim()).length !== 11) dataRowErrors.push({ type: 'error', fileName: file.name, message: `Núm. celular inválido. linha: ${row.__rowNum__}` });
                        // console.log('error - 4: ', row.mobilePhone.trim().length, dataRowErrors);
                        qtty++;
                    }
                    if (dataRowErrors.length > 0) return resolve(dataRowErrors);
                    if (dataRows.length === 0) return resolve([{ type: 'error', fileName: file.name, message: `Planilha de carga inválida. Não há dados para carga.` }]);

                    const contact: IFormDataContact = {
                        key: makeKeyByString(`${networkGymId}${branchGymId}${guideId}_${file.name}_${dataRows.length}`),
                        filename: file.name,
                        networkGym: networkGymId,
                        branchGym: branchGymId,
                        guide: guideId,
                        // script: script,
                        // version: '',
                        quantityContact: dataRows.length,
                        deleted: false,
                        active: true
                    }

                    const contactSaved: IPerformRequestResult<IFormDataContact> = await contactService.saveContact(contact);

                    if (!contactSaved || !contactSaved.data || !contactSaved.data._id) return resolve([{ type: 'error', fileName: file.name, message: `Planilha de carga inválida. Já foi carregada ou ocorreu um erro inesperado.` }]);
                    // console.log('contactSaved: ', contactSaved);
                    const contactSavedData = contactSaved.data;

                    for (let j = 0; j < dataRows.length; j++) {
                        const rowData = dataRows[j];
                        const contactList: IFormDataContactList = {
                            contact: contactSavedData._id,
                            name: rowData.name,
                            nickname: rowData.nickname,
                            mobilePhone: rowData.mobilePhone,
                            dateRef: rowData.dateRef,
                            active: true,
                            deleted: false
                        }
                        await contactListService.saveContactList(contactList);
                        // console.log('contactListSaved: ', contactListSaved);
                    }
                    return resolve([{ type: 'success', fileName: file.name, message: `Dados carregados com sucesso, foram validados ${qtty} contatos.` }]);
                };
                reader.readAsBinaryString(file);
            }));
        }

        const processReturnPromises = await Promise.all(returnPromises);
        return processReturnPromises.reduce((acc, curVal) => acc.concat(curVal), []);
    }

    const downloadXLSX = async () => {
        if (!guideSelected?._id || !guideSelected) return;

        const guideReturned = await guideService.findGuideById(guideSelected._id);
        const guide: IFormDataGuide = guideReturned.data;

        const guideRef = makeKeyByString(guideSelected.key).toUpperCase();

        const sheet = new SheetFactory();

        sheet.addWorksheet('contatos');
        sheet.setColumnSizes([50, 30, 40, 20]);
        sheet.addTitleRow(['Roteiro:', guideRef]);
        sheet.addSubtitleRow(['Nome do Cliente', 'Apelido do Cliente', 'Núm. Celular do Cliente', guide.hasGenericDate ? guide.descriptionGenericDate : null]);
        sheet.addRows([
            ['José dos Santos Silva (exemplo)', 'José Silva', '11 980801010', guide.hasGenericDate ? '01/01/1980' : null],
            ['Maria Joaquina Santos (exemplo)', 'Jô', '11 970702020', guide.hasGenericDate ? '23/10/1932' : null],
        ]);

        await sheet.downloadSheet(`${guideRef.toLowerCase()}-${moment().format('YYYY-MM-DD-HH-mm-ss')}`)
    }

    const chooseOptions = { label: 'Browser', icon: 'pi pi-fw pi-file-excel', className: 'p-button-rounded' };
    const uploadOptions = { label: 'Carregar', icon: 'pi pi-fw pi-upload', className: 'p-button-success p-button-rounded' };
    const cancelOptions = { label: 'Cancelar', icon: 'pi pi-fw pi-times', className: 'p-button-warning p-button-rounded' };

    return (
        <>
            {createView(title, {
                editData: !!props.args.networkGymId,
                downloadPlan: true, downloadPlanRegister: () => downloadXLSX(),
                uploadPlan: true, uploadPlanRegister: () => onShow(),
                searchTerm, setSearchTerm,
                setEditFormData, setDialogData,
                listData: () => listData(data)
            })}
            {networkGymId && token ?
                <GenericFormDialog<IFormDataContact>
                    key='ContactDialog'
                    token={token}
                    objectData='lead'
                    retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataContact>[]) => [
                        {
                            colSize: 'col-12', fieldName: 'name', fieldType: 'InputTextField', description: '* Nome do Cliente', placeholder: 'nome do cliente', value: formData.values.name,
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        {
                            colSize: 'col-12', fieldName: 'nickname', fieldType: 'InputTextField', description: '* Apelido do Cliente', placeholder: 'apelido do cliente', value: formData.values.nickname,
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        {
                            colSize: 'col-6 sm:col-12', fieldName: 'mobilePhone', fieldType: 'InputMaskField', description: '* Celular do Cliente', placeholder: '+55 (99) 99999-9999', value: formData.values.mobilePhone,
                            mask: '+55 (99) 99999-9999',
                            onChange: formData.handleChange, autoFocus: false,
                        },
                        {
                            colSize: 'col-12', fieldName: 'dateRef', fieldType: 'CalendarField', description: '* Data Referência', value: formData.values.dateRef,
                            onChange: (e: CalendarChangeEvent) => formData.handleChange(e), autoFocus: false,
                            dateFormat: 'dd/mm/yy', locale: 'pt-BR', showIcon: true
                        },
                    ])}
                    emptyFormData={{
                        key: '',
                        filename: `MANUAL-LEAD-${makeHash(10)}`,
                        networkGym: networkGymId ? networkGymId : '',
                        branchGym: branchGymId ? branchGymId : '',
                        guide: guideId ? guideId : '',
                        script: undefined,
                        version: undefined,
                        quantityContact: 0,
                        // mobilePhone: '',
                        // nickname: '',
                        // name: '',
                        // dateRef: undefined,
                        active: true,
                        deleted: false
                    }}
                    editFormData={editFormData}
                    editForceData={{ networkGym: networkGymId, branchGym: branchGymId, guide: guideId }}
                    editFunctions={[]}
                    formLists={[]}
                    validate={(data: any) => {
                        let errors: any = {};

                        if (!data.mobilePhone) {
                            errors.mobilePhone = 'Número de celular é obrigatório.';
                        } else if (!isMobilePhone(onlyNumbers(data.mobilePhone))) {
                            errors.mobilePhone = 'Número de celular inválido.';
                        }

                        if (!data.nickname) {
                            errors.nickname = 'Apelido é obrigatório.';
                        } else if (data.nickname.length < 3) {
                            errors.nickname = 'Apelido precisa ter mais que 2 caracteres.';
                        }

                        if (!data.name) {
                            errors.name = 'Nome é obrigatório.';
                        } else if (data.name.length < 5) {
                            errors.name = 'Nome precisa ter mais que 4 caracteres.';
                        }

                        return errors;
                    }}
                    submitForceData={{ networkGym: networkGymId, branchGym: branchGymId, guide: guideId }}
                    onSubmit={(props: IPropsFormDialog<IFormDataContact>, data: FormikValues, formData: FormikInterface) => {
                        new ContactService().saveContact(data)
                            .then((returned: IPerformRequestResult<any>) => saveSuccessed(props, formData)(returned))
                            .catch((error: any) => saveFailed(props, formData)(error));
                    }}
                    refreshList={() => refreshList()}
                    setLoading={(value: any) => props.args.setLoading(value)}
                    showDialog={dialogData}
                    setDialogData={(value: any) => setDialogData(value)}
                    setMessage={(message: IMessage) => props.args.setMessage(message)}
                />
                :
                null
            }
            <Dialog visible={displayPosition} position={position} modal style={{ width: '90%' }} footer={renderFooter()} onHide={() => onHide()}
                draggable={false} resizable={false}>
                <div className='card'>
                    <Tooltip target='.custom-choose-btn' content='Choose' position='bottom' />
                    <Tooltip target='.custom-upload-btn' content='Upload' position='bottom' />
                    <Tooltip target='.custom-cancel-btn' content='Clear' position='bottom' />

                    <FileUpload ref={fileUploadRef}
                        // accept='.xls|.xlsx|.xlsm'
                        // url='https://primefaces.org/primereact/showcase/upload.php'
                        onUpload={onUpload}
                        onSelect={onSelect}
                        onError={onError}
                        onClear={onClear}
                        headerTemplate={headerTemplate}
                        itemTemplate={itemTemplate}
                        emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions}
                        uploadOptions={uploadOptions}
                        cancelOptions={cancelOptions}
                        chooseLabel='Browse'
                        maxFileSize={(1024 * 1024 * 1024)}
                        customUpload
                        uploadHandler={customBase64Uploader}
                        multiple />
                </div>
            </Dialog>
        </>
    )
};
