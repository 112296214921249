import { IPerformRequestResult } from "../../utils/interfaces";
import EntitiesService, { Entities } from "./generics/EntitiesService";
import GenericService from "./generics/GenericService";

export default class WhatsAppMessageService extends GenericService {
    allWhatsAppMessages = async (search?: string | undefined): Promise<IPerformRequestResult<any[]>> => await this.findAll(Entities.WHATSAPP_MESSAGE, false, search);
    findWhatsAppMessageById = async (_id: string | null): Promise<IPerformRequestResult<any>> => await this.findById(Entities.WHATSAPP_MESSAGE, false, _id);
    findAllWhatsAppMessageByTraceMobileId = async (traceMobileIs: string | null, search?: string | undefined): Promise<IPerformRequestResult<any[]>> => await this.findByReferenceId(Entities.WHATSAPP_MESSAGE, false,
        EntitiesService(Entities.TRACKER_MOBILE).singular, traceMobileIs, search);
    saveWhatsAppMessage = async (data: any): Promise<IPerformRequestResult<any>> => await this.save(Entities.WHATSAPP_MESSAGE, false, data);
}
