import { useEffect, useState } from 'react';
import './Notification.css';

const NotificationComponent = (props: any) => {
    const [visibilityChange, setVisibilityChange] = useState<string>('visible');
    const [unreadMessages, setUnreadMessages] = useState<boolean>(props.unreadMessages);
    // const [newMessage, setNewMessage] = useState<boolean>(props.sendNotificationNewMessage);
     
    useEffect(() => {
        // console.log('unreadMessages: ', unreadMessages);
        // console.log('props.unreadMessages: ', props.unreadMessages);
        if (unreadMessages !== undefined && unreadMessages < props.unreadMessages) {
            showNotification('Nova mensagem', 'Você tem uma nova mensagem no sistema');
        }
        setUnreadMessages(props.unreadMessages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.unreadMessages]);

    // useEffect(() => {
    //     if (newMessage) {
    //         // props.setSendNotificationNewMessage(false);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [newMessage]);

    const showNotification = (title: string, body: string) => {
        if (visibilityChange === 'hidden') {
            if (Notification.permission === 'granted') {
                new Notification(title, { body });
            }
            // Tocar som de notificação
            const audio = new Audio('/chat4fit/assets/sounds/notification.mp3');
            audio.play();
        }
    };

    useEffect(() => {
        if ('Notification' in window) {
            if (Notification.permission !== 'granted') {
                Notification.requestPermission();
            }
        }

        const handleVisibilityChange = () => {
            setVisibilityChange(document.visibilityState);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    return (<></>);
};

export default NotificationComponent;