import ls from '../../../projects/localStorage';
import EntitiesService, { Entities } from '../generics/EntitiesService';
import GenericService from '../generics/GenericService';

export default class DashboardService extends GenericService {
    findAllDashboard = async (networkGymId: string, branchGymId?: string | null, customerId?: string | null,
        startDate?: Date, endDate?: Date) => {
        const search: any = {
            queries: [
                { field: `${EntitiesService(Entities.NETWORK_GYM).singular}Id`, value: networkGymId },
                { field: `${EntitiesService(Entities.BRANCH_GYM).singular}Id`, value: { '$in': (await ls.branchGym.getBranchGymsByStorageToken())?.map((b: any) => b._id) } },
                { field: '$and', value: [{ date: { $gte: startDate } }, { date: { $lt: endDate } }] },
                { field: 'report', value: { '$in': ['conversation', 'schedule'] } },
            ]
        };
        if (branchGymId) search.queries.push({ field: `${EntitiesService(Entities.BRANCH_GYM).singular}Id`, value: branchGymId })
        if (customerId) search.queries.push({ field: 'userReferenceId', value: customerId })
        // console.log('search: ', search);
        const dashboards = await super.findByQuery(Entities.DASHBOARD, false, search);
        return dashboards;
    };

}
