import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class TrackerMobileService extends GenericService {
    findTrackerMobileById = async (_id: string | null, headers?: any) => await super.findById(Entities.TRACKER_MOBILE, false, _id, undefined, headers);
    saveTrackerMobile = async (data: any) => await super.save(Entities.TRACKER_MOBILE, false, data);
    findTrackerMobileByNetworkGymId = async (networkId: string | null, search?: string) => await super.findByReferenceId(Entities.TRACKER_MOBILE, false,
        EntitiesService(Entities.NETWORK_GYM).singular, networkId, search);
    findTrackerMobileByBranchGymId = async (branchId: string | null, search?: string) => await super.findByReferenceId(Entities.TRACKER_MOBILE, false,
        EntitiesService(Entities.BRANCH_GYM).singular, branchId, search);
}
