import { IPerformRequestResult } from "../../../utils/interfaces";
import { IFormDataSport } from "../../components/generics/entities";
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class SportService extends GenericService {
    allSports = async (search?: string | undefined): Promise<IPerformRequestResult<IFormDataSport[]>> => await super.findAll(Entities.SPORT, true, search);
    findSportById = async (_id: string | null): Promise<IPerformRequestResult<IFormDataSport>> => await super.findById(Entities.SPORT, false, _id);
    findAllSportByBranchGymId = async (branchGymId: string | null, search?: string | undefined): Promise<IPerformRequestResult<IFormDataSport[]>> => await super.findByReferenceId(Entities.BRANCH_GYM_SPORT, false,
        EntitiesService(Entities.BRANCH_GYM).singular, branchGymId, search);
    saveSport = async (data: any): Promise<IPerformRequestResult<IFormDataSport>> => await super.save(Entities.SPORT, true, data);
}
