import { FormikValues } from 'formik';
import moment, { Moment } from 'moment';
import { Button } from 'primereact/button';
import { CalendarChangeEvent } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { DropdownChangeEvent } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import { RoleRules, checkRoleHierarchyByToken } from '../../projects/checkRoleHierarchy';
import ls from '../../projects/localStorage';
import { isDate } from '../../utils/functions/date';
import { makeKeyByString } from '../../utils/functions/hash';
import { padLeft } from '../../utils/functions/string';
import HttpStatus, { isSuccess } from '../../utils/httpStatus';
import { IPerformRequestResult } from '../../utils/interfaces';
import { IMessage } from '../components/Control';
import ScheduleCalendar from '../components/ScheduleCalendar';
import { ScheduleList } from '../components/ScheduleList/ScheduleList';
import { GenericFields } from '../components/commons/GenericFields';
import { GenericFormDialog } from '../components/commons/GenericFormDialog';
import { saveFailed, saveSuccessed } from '../components/commons/functions';
import { ScheduleInterface, aroundDate } from '../components/data/Schedule';
import { IFormDataScheduleData } from '../components/generics/entities';
import { IPropsArgsComponents, IPropsFormDialog, IPropsFormListDialog } from '../components/generics/formInterfaces';
import { FormikInterface } from '../components/interfaces/formikInterface';
import DashboardReportService from '../service/admin/DashboardReportService';
import ScheduleService from '../service/admin/ScheduleService';
import TrackerContactMessageService from '../service/admin/TrackerContactMessageService';
import TrackerMessageService from '../service/admin/TrackerMessageService';

const scheduleService = new ScheduleService();
const trackerContactMessageService = new TrackerContactMessageService();
const trackerMessageService = new TrackerMessageService();

export const Schedule = (props: IPropsArgsComponents) => {
    const [token] = useState(props.args.token);
    const [dataSchedules, setDataSchedules] = useState(new Array<any>());
    const [dataSchedulePendents, setDataSchedulePendents] = useState(new Array<any>());
    const [dataScheduleFinisheds, setDataScheduleFinisheds] = useState(new Array<any>());

    const [showDialog, setShowDialog] = useState(false);
    const [contactMessage, setContactMessage] = useState<any>();
    const [messages, setMessages] = useState<any[]>();

    const [scheduleDialogData, setScheduleDialogData] = useState(false);

    const [networkGymId, setNetworkGymId] = useState(props.args.networkGymId);
    const [branchGymId, setBranchGymId] = useState(props.args.branchGymId);

    const [editFormData, setEditFormData] = useState<IFormDataScheduleData>();
    const [saveButtonForceDisabled, setSaveButtonForceDisabled] = useState<boolean>(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
    const [cancelButtonDisabled, setCancelButtonDisabled] = useState<boolean>(false);

    const [eventDialog, setEventDialog] = useState<boolean>(false);
    const [momentDialog, setMomentDialog] = useState<Moment>(ls.socket.getServerSocketDateTime());
    const [schedulesDialog, setSchedulesDialog] = useState<any[]>([]);


    const [customerId] = useState<string | null>(ls.customer.getCustomerIdStorageToken());

    const [currentDate, setCurrentDate] = useState(ls.socket.getServerSocketDateTime());
    const [actualDate] = useState(aroundDate(ls.socket.getServerSocketDateTime()));
    const [firstDate, setFirstDate] = useState(moment(actualDate));
    const [lastDate, setLastDate] = useState(moment(actualDate));
    const [oneDate, setOneDate] = useState(true);

    const [initialHour] = useState<number>(6);
    const [initialMinute] = useState<number>(0);
    const [finalHour] = useState<number>(22);
    const [finalMinute] = useState<number>(0);

    useEffect(() => props.args.setUpdateConsidered([
        { key: 'networkGym', show: true, considered: true },
        { key: 'branchGym', show: true, considered: true },
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ]), []);

    useEffect(() => setNetworkGymId(props.args.networkGymId), [props.args.networkGymId]);
    useEffect(() => setBranchGymId(props.args.branchGymId), [props.args.branchGymId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { refreshList(); refreshPedentList(); refreshFinishedList() }, [networkGymId, branchGymId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { refreshList(); }, [currentDate, oneDate]);

    const eventClick = (scheduleDate: Moment, dataSchedules: ScheduleInterface[]) => {
        setMomentDialog(scheduleDate);
        setSchedulesDialog(dataSchedules);
        // const { title, start, end } = e.event;
        setEventDialog(true);
        // setChangedEvent({ title, start, end, allDay: null });
    }

    const eventScheduleClick = (schedule: any) => {
        if (!schedule.guestName && schedule.guest) schedule.guestName = schedule.guest.pushName;
        setEditFormData({
            _id: schedule?._id,
            key: schedule?.key,
            networkGym: schedule?.networkGym?._id || schedule?.networkGym,
            branchGym: schedule?.branchGym?._id || schedule?.branchGym,
            invitedBy: schedule?.invitedBy?._id || schedule?.invitedBy,
            date: moment(schedule?.date).toDate(),
            datePristine: moment(schedule?.date).toDate(),
            statusSchedule: !schedule?.statusSchedule || schedule?.statusSchedule === 'opened' ||
                schedule?.statusSchedule?.id === 'opened' ? { id: 'opened', name: 'aberto', description: 'Aberto' } :
                { id: 'closed', name: 'encerrado', description: 'Encerrado' },
            typeSchedule: schedule?.typeSchedule === 'daily' ? { id: 'daily', name: 'DIÁRIA', description: 'Diária' } :
                schedule?.typeSchedule === 'visit' ? { id: 'visit', name: 'VISITA PROGRAMADA', description: 'Visita Programada' } :
                    schedule?.typeSchedule === 'class' ? { id: 'class', name: 'AULA EXPERIMENTAL', description: 'Aula Experimental' } :
                        // schedule?.typeSchedule === 'contact' ? { id: 'contact', name: 'CONTATO PESSOAL', description: 'Contato Pessoal' } :
                        //     schedule?.typeSchedule === 'followup' ? { id: 'followup', name: 'FOLLOW-UP', description: 'Follow-up' } : 
                        null,
            guest: schedule?.guest?._id || schedule?.guest,
            guestName: schedule?.guestName || schedule?.guest?.pushName,
            confirmedPresence: schedule?.confirmedPresence,
            attendedAcademy: schedule?.attendedAcademy,
            closedContract: schedule?.closedContract,
            dataScheduleExtra: schedule?.dataScheduleExtra,
            active: schedule?.active,
            deleted: schedule?.deleted
        });
        setSaveButtonForceDisabled(checkRoleHierarchyByToken(RoleRules.attendant, false) &&
            ((moment(schedule.date).isAfter(ls.socket.getServerSocketDateTime()) && customerId !== schedule.invitedBy?._id)
                || (moment(schedule.date).isBefore(ls.socket.getServerSocketDateTime()) && (schedule.statusSchedule === 'closed' || schedule.statusSchedule?.id === 'closed'))))
        setSaveButtonDisabled(checkRoleHierarchyByToken(RoleRules.attendant, false) &&
            ((moment(schedule.date).isAfter(ls.socket.getServerSocketDateTime()) && customerId !== schedule.invitedBy?._id)
                || (moment(schedule.date).isBefore(ls.socket.getServerSocketDateTime()) && (schedule.statusSchedule === 'closed' || schedule.statusSchedule?.id === 'closed'))));
        setScheduleDialogData(true);
    }

    const addNewSchedule = (contentDateHourMinute?: { date: Moment, hour: number, minute: number }) => {
        // console.log('contentDateHourMinute.date: ', contentDateHourMinute?.date.toDate());
        const schedule = {
            key: makeKeyByString(ls.socket.getServerSocketDateTime().toDate().toDateString()),
            networkGym: networkGymId!,
            branchGym: branchGymId!,
            invitedBy: customerId!,
            date: moment(contentDateHourMinute?.date || currentDate)
                .set('hours', contentDateHourMinute && contentDateHourMinute.hour ? contentDateHourMinute.hour : 9)
                .set('minutes', contentDateHourMinute && contentDateHourMinute.minute ? contentDateHourMinute.minute : 0).toDate(),
            datePristine: ls.socket.getServerSocketDateTime().toDate(),
            statusSchedule: { id: 'opened', name: 'aberto', description: 'Aberto' },
            typeSchedule: { id: 'daily', name: 'DIÁRIA', description: 'Diária' },
            // typeSchedule: { id: 'followup', name: 'FOLLOW-UP', description: 'Follow-up' },
            guest: '',
            guestName: '',
            confirmedPresence: false,
            attendedAcademy: false,
            closedContract: false,
            dataScheduleExtra: '',
            active: true,
            deleted: false
        };
        setEditFormData(schedule);
        setSaveButtonForceDisabled(false);
        setSaveButtonDisabled(false);
        setScheduleDialogData(true);
    }


    const refreshList = () => {
        if (networkGymId) {
            let isMounted = true;
            const search = {
                queries: [
                    { field: 'networkGym', value: networkGymId },
                    {
                        field: '$and', value: [
                            { date: { $gt: oneDate ? moment(currentDate).subtract(1, 'days').toDate() : moment(currentDate).subtract(8, 'days').toDate() } },
                            { date: { $lt: oneDate ? moment(currentDate).add(1, 'days').toDate() : moment(currentDate).add(8, 'days').toDate() } }
                        ]
                    }
                ], sort: {
                    date: 1
                }
            };
            if (branchGymId) search.queries.push({ field: 'branchGym', value: branchGymId });
            scheduleService.findScheduleByQuery(search).then((returned: any) => {
                if (isMounted) {
                    if (returned) {
                        if (returned.status === HttpStatus.UNAUTHORIZED) {
                            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                            setTimeout(() => { window.location.href = '/'; }, 5000);
                            setDataSchedules([]);
                        } else if (isSuccess(returned.status)) {
                            setDataSchedules(returned.data);
                        } else {
                            setDataSchedules([]);
                        }
                    } else {
                        setDataSchedules([]);
                    }
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setDataSchedules([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const refreshPedentList = () => {
        if (networkGymId) {
            let isMounted = true;
            const search = {
                queries: [
                    { field: 'networkGym', value: networkGymId },
                    { field: '$and', value: [{ date: { $gte: ls.socket.getServerSocketDateTime().subtract(30, 'days').toDate() } }, { date: { $lte: ls.socket.getServerSocketDateTime().add(1, 'days').set('hours', 0).set('minutes', 0).toDate() } }] },
                    { field: 'statusSchedule', value: 'opened' }
                ]
            };
            if (branchGymId) search.queries.push({ field: 'branchGym', value: branchGymId });
            scheduleService.findScheduleByQuery(search).then((returned: any) => {
                if (isMounted) {
                    if (returned) {
                        if (returned.status === HttpStatus.UNAUTHORIZED) {
                            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                            setTimeout(() => { window.location.href = '/'; }, 5000);
                            setDataSchedulePendents([]);
                        } else if (isSuccess(returned.status)) {
                            // console.log('returned.data: ', returned.data);
                            setDataSchedulePendents(returned.data);
                        } else {
                            setDataSchedulePendents([]);
                        }
                    } else {
                        setDataSchedulePendents([]);
                    }
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setDataSchedulePendents([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const refreshFinishedList = () => {
        if (networkGymId) {
            let isMounted = true;
            const search = {
                queries: [
                    { field: 'statusSchedule', value: 'closed' },
                    { field: 'networkGym', value: networkGymId },
                    { field: '$and', value: [{ date: { $gte: ls.socket.getServerSocketDateTime().subtract(7, 'days').toDate() } }, { date: { $lte: ls.socket.getServerSocketDateTime().add(7, 'days').toDate() } }] }
                ]
            };
            if (branchGymId) search.queries.push({ field: 'branchGym', value: branchGymId });
            scheduleService.findScheduleByQuery(search).then((returned: any) => {
                if (isMounted) {
                    if (returned) {
                        if (returned.status === HttpStatus.UNAUTHORIZED) {
                            props.args.setMessage({ severity: 'danger', summary: 'Você não tem permissão para este acesso!', detail: '', life: 5000 });
                            setTimeout(() => { window.location.href = '/'; }, 5000);
                            setDataScheduleFinisheds([]);
                        } else if (isSuccess(returned.status)) {
                            setDataScheduleFinisheds(returned.data);
                        } else {
                            setDataScheduleFinisheds([]);
                        }
                    } else {
                        setDataScheduleFinisheds([]);
                    }
                }
            }).catch(error => {
                console.error('error', error);
                if (isMounted) {
                    setDataScheduleFinisheds([]);
                }
            });
            return () => { isMounted = false };
        }
    };

    const openConversation = async (trackerContactMessageId: any) => {
        // console.log('trackerContactMessageId: ', trackerContactMessageId);
        const trackerContactMessageResult = await trackerContactMessageService.findTrackerContactMessageById(trackerContactMessageId);
        if (trackerContactMessageResult && trackerContactMessageResult.data) {
            // console.log('trackerContactMessageResult.data: ', trackerContactMessageResult.data);
            const contactMessage = trackerContactMessageResult.data;
            // console.log('contactMessage: ', contactMessage.jid, contactMessage?.networkGym?._id, contactMessage?.branchGym?._id, contactMessage?.trackerMobile?._id);

            if (contactMessage.jid && contactMessage?.networkGym?._id && contactMessage?.trackerMobile?._id) {
                const trackerMessageResult = await trackerMessageService.findTrackerMessageByTrackerMobileId(contactMessage?.jid,
                    contactMessage?.networkGym?._id, undefined, contactMessage?.trackerMobile?._id,
                    { start: ls.socket.getServerSocketDateTime().subtract(3, 'months').toDate(), end: ls.socket.getServerSocketDateTime().add(1, 'days').toDate() });
                if (trackerMessageResult && trackerMessageResult.data) {
                    let dataMessages = trackerMessageResult.data;
                    setContactMessage(contactMessage);
                    setMessages(dataMessages);
                    setShowDialog(true);
                }
            }
        }
    };

    const listData = (data: any[]) => {
        if (!networkGymId)
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Rede.</h3>
            </div>;
        if (!branchGymId && !checkRoleHierarchyByToken(RoleRules.manager))
            return <div className='flex p-fluid col-12 align-items-center justify-content-center' style={{ color: '#DBA00D' }}>
                <h3>Você precisa selecionar uma Unidade.</h3>
            </div>;


        // const [actualDate] = useState(aroundDate(ls.socket.getServerSocketDateTime()));
        // const [firstDate, setFirstDate] = useState(moment(actualDate));
        // const [lastDate, setLastDate] = useState(moment(actualDate));
        // const [oneDate, setOneDate] = useState(true);

        return <>
            <div className="sm:col-12 md:col-12 lg:col-12 xl:col-2">
                <div className="card schedule-list">
                    <ScheduleList data={[{ pendentList: dataSchedulePendents, finishedList: dataScheduleFinisheds }]} refreshData={() => { refreshPedentList(); refreshFinishedList(); }} args={props.args} params={{
                        addNewSchedule, setCurrentDate, currentDate, setEditFormData: { eventScheduleClick }, customerId, setScheduleDialogData,
                        eventClick, eventScheduleClick, momentDialog, schedulesDialog, eventDialog, setEventDialog,
                        actualDate, firstDate, setFirstDate, lastDate, setLastDate, oneDate, setOneDate
                    }} />
                </div>
            </div>
            <div className="sm:col-12 md:col-12 lg:col-12 xl:col-10">
                <div className="card schedule-calendar">
                    <ScheduleCalendar data={dataSchedules} refreshData={() => { refreshList(); refreshPedentList(); refreshFinishedList(); }} args={props.args} params={{
                        addNewSchedule, setCurrentDate, currentDate, setEditFormData: { eventScheduleClick }, customerId, setScheduleDialogData,
                        eventClick, eventScheduleClick, momentDialog, schedulesDialog, eventDialog, setEventDialog,
                        actualDate, firstDate, setFirstDate, lastDate, setLastDate, oneDate, setOneDate
                    }} />
                    {/* {ScheduleCalendar({ ...props, refreshList: () => refreshList() })} */}
                </div>
            </div >
        </>;
    }

    const prettyMessage = (message: string) => {
        return message.replace(/```([a-z][\S\s]+?)```/gmi, '<span style="font-size: 0.7rem; font-style: italic;">$1</span>')
            .replace(/\*([a-z][\S\s]+?)\*/gmi, '<span style="font-weight: bold;">$1</span>')
            .replace(/_([a-z][\S\s]+?)_/gmi, '<span style="font-style: italic;">$1</span>')
            .replace(/\n/g, "<br />");
    }

    const availableContactMessage = async (contactMessageId: string) => {
        let contactMessage: any = await trackerContactMessageService.saveTrackerContactMessage({
            _id: contactMessageId,
            branchGym: branchGymId,
            assumedBy: customerId,
            activelyInitiatedContact: true,
            typeInitiatedContact: 'pró-ativa',
            available: false,
            new: false,
            inProgress: true,
            finished: false
        });
        await new DashboardReportService().saveConversationDashboardReport(
            'dashboard-report-new-conversation-attendance-reopen-schedule',
            networkGymId, branchGymId,
            customerId!,
            {
                contactMessage: contactMessage,
                contactMessageEnumerator: contactMessage.enumerator,
                flags: {
                    contactMessageReopened: true,
                    contactMessageProactive: true
                }
            });
        refreshList();
    }

    const renderMessages = () => {
        if (!messages) return <></>
        let messageList = messages.filter((m: any) => !m.deleted);
        messageList = messageList.sort((t1: any, t2: any) => moment(t1.dateText).diff(moment(t2.dateText)));

        return messageList.map((m, i) => {
            // let contact = trackerContactMessages.find(c => c.jid === m.jid);
            // contact = contact ? contact.pushName : 'desconhecido';
            let contact = m.pushName; // contact ? contact.pushName : 'desconhecido';
            const content = <p>
                {!contactMessage.jid || m.group ? <span className='msg_contact'>{contact}</span> : null}
                {!m.isObject ?
                    <span dangerouslySetInnerHTML={{ __html: prettyMessage(m.text) }}></span>
                    :
                    <span>{`Envio de mídia: ${m.mimetype}`}</span>
                }
                <br /><span className={classNames('msg_time', `tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`)}>{moment(m.dateText).format('DD-MM-yyyy HH:mm')}
                    <i className={classNames('pi', { 'pi-circle': !m.readReceipt }, { 'pi-check-circle': m.readReceipt })} style={{ fontSize: 10, marginTop: 3, marginLeft: 10 }}></i>
                </span>
                <Tooltip target={`.tooltip_msg_${m._id}_${moment(m.dateText).format('yyyyDDMMHHmmss')}`} content={`${moment(m.dateText).format('DD-MM-yyyy HH:mm')}`} />
            </p>;
            if (i === messageList.length - 1) {
                return (<div key={`${m._id}_${m.dateText}`} className={classNames('msg', { 'msg_geral': !contactMessage.jid || m.group }, { 'msg-object': m.isObject }, { 'msg-me': m.fromMe }, { 'msg-frnd': !m.fromMe })}>
                    {/* ref={(el) => setMessagesEnd(el)} > */}
                    {content}
                </div>);
            } else {
                return (<div key={`${m._id}_${m.dateText}`} className={classNames('msg', { 'msg_geral': !contactMessage.jid || m.group }, { 'msg-object': m.isObject }, { 'msg-me': m.fromMe }, { 'msg-frnd': !m.fromMe })}>
                    {content}
                </div>);
            }
        });
    }


    const isSameBranchGym = (branchGym_1: any, branchGym_2: any) => {
        return (branchGym_1?._id && branchGym_2?._id && branchGym_1?._id === branchGym_2?._id) ||
            (branchGym_1 && branchGym_2?._id && branchGym_1 === branchGym_2?._id) ||
            (branchGym_1?._id && branchGym_2 && branchGym_1?._id === branchGym_2) ||
            (branchGym_1 && branchGym_2 && branchGym_1 === branchGym_2);
    }


    const removeNode = () => {
        // const objs = document?.getElementsByClassName('p-connected-overlay-enter-done');
        // for (let i = 0; i < objs.length; i++) {
        //     const obj = objs[i];
        //     obj.classList.remove('p-connected-overlay-enter-done');
        //     // obj.classList.add('p-connected-overlay-exit');
        //     obj.classList.add('p-connected-overlay-exit-active');
        //     obj.
        // }
    };

    return (<>
        <div className="grid justify-content-center">{listData(dataSchedules)}</div>

        <GenericFormDialog<IFormDataScheduleData> key='ScheduleDataDialog'
            token={token}
            objectData='agendamento'
            refreshList={() => { refreshList(); refreshPedentList(); refreshFinishedList() }}
            setLoading={(value: any) => props.args.setLoading(value)}
            showDialog={scheduleDialogData}
            setDialogData={(value: any) => { setScheduleDialogData(value); setEventDialog(value); }}
            setMessage={(message: IMessage) => props.args.setMessage(message)}
            saveButtonDisabled={saveButtonDisabled}
            cancelButtonDisabled={cancelButtonDisabled}
            editFormData={editFormData}
            emptyFormData={{
                key: makeKeyByString(ls.socket.getServerSocketDateTime().toDate().toDateString()),
                networkGym: networkGymId!,
                branchGym: branchGymId!,
                invitedBy: customerId!,
                date: moment(currentDate).set('hours', 9).set('milliseconds', 0).toDate(),
                datePristine: ls.socket.getServerSocketDateTime().toDate(),
                statusSchedule: { id: 'opened', name: 'aberto', description: 'Aberto' },
                typeSchedule: { id: 'daily', name: 'DIÁRIA', description: 'Diária' },
                // typeSchedule: { id: 'followup', name: 'FOLLOW-UP', description: 'Follow-up' },
                guest: '',
                guestName: '',
                confirmedPresence: false,
                attendedAcademy: false,
                closedContract: false,
                dataScheduleExtra: '',
                active: true,
                deleted: false
            }}
            retrieveFields={((formData: FormikValues, formLists?: IPropsFormListDialog<IFormDataScheduleData>[]) => [
                formData.values.guest &&
                    (isSameBranchGym(formData.values.branchGym, formData.values.guest?.branchGym)) ?
                    {
                        colSize: 'col-offset-8 col-4', fieldName: 'guest', fieldType: 'Action', description: 'Visualizar conversa', value: '',
                        onClick: () => openConversation(formData.values.guest._id)
                    } : null,
                {
                    colSize: 'col-8', fieldName: 'date', fieldType: 'CalendarField', description: '* Data do agendamento', value: moment(formData.values.date).toDate(),
                    onChange: (e: CalendarChangeEvent) => { formData.handleChange(e); removeNode(); }, autoFocus: false,
                    dateFormat: 'dd/mm/yy', locale: 'pt-BR', showTime: true, stepMinute: 30, showIcon: true,
                    onShow: () => { if (!saveButtonForceDisabled) { setSaveButtonDisabled(true); } setCancelButtonDisabled(true); },
                    onHide: () => { if (!saveButtonForceDisabled) { setSaveButtonDisabled(false); } setCancelButtonDisabled(false); }
                },
                // GenericFields.finishedField(formData, { colSize: 6 }),
                {
                    colSize: 'col-6', fieldName: 'typeSchedule', fieldType: 'DropdownField', description: '* Motivo:', placeholder: 'Selecione um motivo', value: formData.values.typeSchedule, autoFocus: false,
                    onChange: (event: DropdownChangeEvent) => formData.handleChange(event),
                    // options: [{ id: 'visit', name: 'VISITA PROGRAMADA', description: 'Visita Programada' }, { id: 'class', name: 'AULA EXPERIMENTAL', description: 'Aula Experimental' },
                    // { id: 'contact', name: 'CONTATO PESSOAL', description: 'Contato Pessoal' }, { id: 'followup', name: 'FOLLOW-UP', description: 'Follow-up' }],
                    options: [{ id: 'daily', name: 'DIÁRIA', description: 'Diária' }, { id: 'visit', name: 'VISITA PROGRAMADA', description: 'Visita Programada' }, { id: 'class', name: 'AULA EXPERIMENTAL', description: 'Aula Experimental' }],
                    optionLabel: 'description', filter: true, showClear: true, filterBy: 'description',
                },
                {
                    colSize: 'col-6', fieldName: 'statusSchedule', fieldType: 'DropdownField', description: '* Situação:', placeholder: 'Selecione uma situação', value: formData.values.statusSchedule, autoFocus: false,
                    onChange: (event: DropdownChangeEvent) => formData.handleChange(event),
                    options: [{ id: 'opened', name: 'aberto', description: 'Aberto' },
                    { id: 'closed', name: 'encerrado', description: 'Encerrado' }],
                    optionLabel: 'description', filter: true, showClear: true, filterBy: 'description',
                },
                {
                    colSize: 'col-6', fieldName: 'guestName', fieldType: 'InputTextField', description: '* Nome do Convidado:', placeholder: 'nome do convidado', value: formData.values.guestName, autoFocus: false,
                    onChange: formData.handleChange
                },
                formData.values.invitedBy && formData.values.invitedBy.nickname ?
                    {
                        colSize: 'col-6', fieldName: 'name', fieldType: 'InputTextField', description: '* Nome do Atendente:', placeholder: 'nome do atendente', value: formData.values.invitedBy.nickname, autoFocus: false,
                        onChange: formData.handleChange
                    }
                    :
                    null,
                GenericFields.genericGroupField('Andamento'),
                GenericFields.confirmedPresenceField(formData, { colSize: 4 }),
                GenericFields.attendedAcademyField(formData, { colSize: 4 }),
                GenericFields.closedContractField(formData, { colSize: 4 }),
                {
                    colSize: 'col-12', fieldName: 'dataScheduleExtra', fieldType: 'InputTextAreaField', description: 'Detalhes:', placeholder: 'Detalhes', value: formData.values.dataScheduleExtra, autoFocus: false,
                    rows: 5, cols: 5,
                    onChange: formData.handleChange
                },
            ])}
            editForceData={{
                invitedBy: ls.customer.getCustomerIdStorageToken()
            }}
            editFunctions={[]}
            formLists={[]}
            validate={(data: any) => {
                let errors: any = {};

                if (!data.date) {
                    errors.date = 'Data de agendamento é obrigatório.';
                } else if (!isDate(data.date)) {
                    errors.date = 'Data de agendamento não é uma data válida.';
                } else if (moment(data.date).isBefore(ls.socket.getServerSocketDateTime()) &&
                    (moment(data.date).diff(data.datePristine, 'minutes') !== 0)) {
                    errors.date = 'Data de agendamento não pode ser inferior a data atual.';
                } else if (moment(data.date).hours() < initialHour || (moment(data.date).hours() > finalHour || (moment(data.date).hours() === finalHour && moment(data.date).minutes() > finalMinute))) {
                    errors.date = `Data de agendamento precisar estar entre ${initialHour}${initialMinute !== 0 ? `:${padLeft(initialMinute, 2, '0')}` : ''}h e ${finalHour}${finalMinute !== 0 ? `:${padLeft(finalMinute, 2, '0')}` : ''}h.`;
                }

                if (!data.typeSchedule) {
                    errors.typeSchedule = 'Motivo do agendamento é obrigatório';
                }

                if (!data.statusSchedule) data.statusSchedule = { id: 'opened', name: 'aberto', description: 'Aberto' };

                if (!data.guestName) {
                    errors.guestName = 'Nome do convidado é obrigatório.';
                } else if (data.guestName.length < 4) {
                    errors.guestName = 'Nome do convidado precisa ter mais de 4 caracteres.';
                }
                if (!data.confirmedPresence) data.confirmedPresence = false;
                if (!data.attendedAcademy) data.attendedAcademy = false;
                if (!data.closedContract) data.closedContract = false;
                if (!data.finished) data.finished = false;


                return errors;
            }}
            submitForceData={{}}
            onSubmit={(props: IPropsFormDialog<IFormDataScheduleData>, data: FormikValues, formData: FormikInterface) => {
                const scheduleToSave = Object.assign({}, data);

                if (!scheduleToSave.guest?._id) delete scheduleToSave.guest;
                else if (scheduleToSave.guest && scheduleToSave.guest._id) scheduleToSave.guest = scheduleToSave.guest._id;

                if (scheduleToSave.statusSchedule && scheduleToSave.statusSchedule.id) scheduleToSave.statusSchedule = scheduleToSave.statusSchedule.id;

                if (scheduleToSave.typeSchedule && scheduleToSave.typeSchedule.id) scheduleToSave.typeSchedule = scheduleToSave.typeSchedule.id;

                new ScheduleService().saveSchedule(scheduleToSave)
                    .then(async (returned: IPerformRequestResult<any>) => {
                        try {
                            const schedule = returned.data;
                            processDashboardSchedule(schedule, networkGymId, branchGymId, customerId);
                        } catch (error) {
                            console.log('error: ', error);
                        } finally {
                            saveSuccessed(props, formData)(returned);
                        }

                    })
                    .catch((error: any) => saveFailed(props, formData)(error));
            }}
        />
        <Dialog visible={showDialog} maximizable style={{ width: '80%' }} modal={true} closable={false}
            onHide={() => setShowDialog(false)}>
            <div className='grid'>
                <div className='col-offset-8 col-2 '>
                    {
                        contactMessage?.finished === true && branchGymId ?
                            <Button label='Reabrir conversa' type='button' onClick={() => { availableContactMessage(contactMessage?._id); setShowDialog(false); }} style={{ minWidth: 150 }} className='p-button-rounded p-button shadow-3' icon='pi pi-megaphone' />
                            :
                            null
                    }
                </div>
                <div className='col-2'>
                    <Button label='Fechar' type='button' onClick={() => setShowDialog(false)} style={{ minWidth: 150 }} className='p-button-rounded p-button-warning shadow-3' icon='pi pi-times' />
                </div>
                <div className='col-12'>
                    <div className='layout-content-whatsapp-attendance'>
                        <div className='container-whatsapp-attendance'>
                            <div className='container-whatsapp-attendance-message'>
                                <div className='center-side'>
                                    <div className='header-whatsapp-attendance'>
                                        <div style={{ width: '100%', flexWrap: 'nowrap' }}>
                                            <h4>
                                                <span>Contato: {contactMessage?.pushName}</span>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className='chatBx'>
                                        {contactMessage ? renderMessages() : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div>
            </div>

        </Dialog>

    </>
    )
}

async function processDashboardSchedule(schedule: any, networkGymId: string | null, branchGymId: string | null, customerId: string | null) {
    if (customerId && schedule && schedule._id) {
        if (schedule.guest) {
            await new DashboardReportService().saveConversationDashboardReport(
                'dashboard-report-schedule-direct-frontend',
                schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                customerId,
                {
                    contactMessage: schedule.guest,
                    contactMessageEnumerator: schedule.guest?.enumerator, flags: {
                        contactMessageScheduled: true
                    }
                });
        }

        await new DashboardReportService().saveScheduleDashboardReport(
            'dashboard-report-schedule-direct-frontend',
            schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
            customerId,
            {
                schedule: schedule,
                flags: {
                    scheduleByChat: false,
                    scheduleReason: schedule.typeSchedule,
                    scheduleFinished: schedule.statusSchedule === 'closed'
                }
            });

        if (schedule.guest) {
            await new DashboardReportService().saveGenericConversationDashboardReport('dashboard-report-scheduled-conversation-frontend-attendant',
                'conversation', 'scheduled',
                schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                customerId, schedule.guest._id,
                schedule.guest.enumerator);
        }

        const dashboardReportCreateds = await new DashboardReportService().findDashboardReportsBySearch({
            queries: [
                { field: 'schedule', value: schedule._id },
                { field: 'report', value: 'schedule' },
                { field: 'referenceReport', value: 'created' }
            ]
        });
        const dashboardReportFinisheds = await new DashboardReportService().findDashboardReportsBySearch({
            queries: [
                { field: 'schedule', value: schedule._id },
                { field: 'report', value: 'schedule' },
                { field: 'referenceReport', value: 'finished' },
                { field: 'deleted', value: false }
            ]
        });

        if (dashboardReportCreateds.status.code === 404 || dashboardReportCreateds?.data?.length === 0) {
            await new DashboardReportService().saveGenericScheduleDashboardReport('dashboard-report-created-schedule-direct-frontend-attendant',
                'schedule', 'created',
                schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                customerId, schedule._id);
        }

        if (dashboardReportCreateds?.data?.length >= 1) {
            for (let i = 1; i < dashboardReportCreateds?.data?.length; i++) {
                const dashboardReportCreated = dashboardReportCreateds?.data[i];
                await new DashboardReportService().updateDashboardReport({ _id: dashboardReportCreated._id, deleted: true });
            }
        }

        if (schedule.statusSchedule === 'closed') {
            if (schedule.statusSchedule === 'closed' && (dashboardReportFinisheds.status.code === 404 || dashboardReportFinisheds?.data?.length === 0)) {
                await new DashboardReportService().saveGenericScheduleDashboardReport('dashboard-report-created-schedule-direct-frontend-attendant',
                    'schedule', 'finished',
                    schedule.networkGym?._id || networkGymId, schedule.branchGym?._id || branchGymId,
                    customerId, schedule._id);
            }
        }

        for (let i = schedule.statusSchedule === 'opened' ? 0 : 1; i < dashboardReportFinisheds?.data?.length; i++) {
            const dashboardReportFinished = dashboardReportFinisheds?.data[i];
            await new DashboardReportService().updateDashboardReport({ _id: dashboardReportFinished._id, deleted: true });
        }
    }
}

