/* eslint-disable react-hooks/exhaustive-deps */
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { SetStateAction, useEffect, useState } from 'react';
import { IPropsStepDialog } from '../generics/formInterfaces';
import { StepDialog } from './StepDialogx';
import { editStepData } from './functions';

export function GenericStepDialog<T extends FormikValues>(props: IPropsStepDialog<T>) {
    const [objectData] = useState(props.objectData);
    const formData = useFormik<T>({
        initialValues: props.emptyStepData, validate: props.validate,
        onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => {
            props.setLoading(true);
            const data = Object.assign<T, any>(values, props.submitForceData);
            if (props.onSubmit) props.onSubmit(props, data, formData);
        }
    });


    
    useEffect(editStepData<T, any>(props, formData, props.editForceData, props.editFunctions), [props.editStepData]);

    return (
        <StepDialog<T>
            title={props.editStepData?.title || 'Carregando...'}
            icon={props.icon}
            objectData={objectData}
            showStepDialog={props.showStepDialog}
            setStepDialogData={props.setStepDialogData}
            saveButtonDisabled={props.saveButtonDisabled || false}
            cancelButtonDisabled={props.cancelButtonDisabled || false}
            showDialog={false}
            editStepData={props.editStepData}
            setEditStepData={props.setEditStepData}
            refreshStepData={props.refreshList}
            setDialogData={function (value: SetStateAction<boolean>): void {
                throw new Error('Function not implemented.');
            }}
            args={{
                setMessage: props.setMessage,
                setLoading: props.setLoading,
                networGymkId: props.emptyStepData.networkGym
            }}
        />
    );
}

