import jwt from 'jsonwebtoken';
import ls from './localStorage';

export enum RoleRules {
    admin = 'admin',
    owner = 'owner',
    manager = 'manager',
    attendant = 'attendant',
    user = 'user',
    visitor = 'visitor',
    no_role = 'no_role',
}

export const convertRoleRules = (role: RoleRules) => {
    const roleFounded = hierarchyRoles.find(h => h.keyValue && h.keyValue === role);
    return roleFounded;
}


export const convertRoleKeyPermiteds = (role: RoleKeyPermiteds) => {
    const roleFounded = hierarchyRoles.find(h => h.keyValue && h.keyValue === role);
    return roleFounded;
}

export type RoleKeys = RoleRules.no_role | RoleRules.visitor | RoleRules.user | RoleRules.attendant | RoleRules.manager | RoleRules.owner | RoleRules.admin;
export type RoleKeyPermiteds = RoleRules.visitor | RoleRules.user | RoleRules.attendant | RoleRules.manager | RoleRules.owner | RoleRules.admin;

export interface interfaceHierarchyRoles {
    key: RoleKeys,
    keyValue: string,
    value: number,
    description: string
}

export const hierarchyRoles: interfaceHierarchyRoles[] = [
    { key: RoleRules.admin, keyValue: 'admin', value: 999, description: 'Administrador' },
    { key: RoleRules.owner, keyValue: 'owner', value: 500, description: 'Proprietário' },
    { key: RoleRules.manager, keyValue: 'manager', value: 400, description: 'Supervisor' },
    { key: RoleRules.attendant, keyValue: 'attendant', value: 300, description: 'Atendente' },
    { key: RoleRules.user, keyValue: 'user', value: 200, description: 'Usuário' },
    { key: RoleRules.visitor, keyValue: 'visitor', value: 100, description: 'Visitante' },
    { key: RoleRules.no_role, keyValue: 'no_role', value: -1, description: 'Sem Permissão' }
]


export const checkRoleHierarchyByTokenAnds = (rolePermiteds: RoleKeyPermiteds[],
    byHierarchy: boolean = true, token?: string) => {
    const checkToken = token || ls.getLocalStorageToken();
    if (!checkToken) return false;
    const decode: any = jwt.decode(checkToken);
    return rolePermiteds.reduce((previousValue: boolean | undefined, currentValue: RoleKeyPermiteds) => {
        return previousValue && checkRoleHierarchy(decode.role, currentValue, byHierarchy);
    }, undefined);
}

export const checkRoleHierarchyByTokenOrs = (rolePermiteds: RoleKeyPermiteds[],
    byHierarchy: boolean = true, token?: string) => {
    const checkToken = token || ls.getLocalStorageToken();
    if (!checkToken) return false;
    const decode: any = jwt.decode(checkToken);
    return rolePermiteds.reduce((previousValue: boolean | undefined, currentValue: RoleKeyPermiteds) => {
        return previousValue || checkRoleHierarchy(decode.role, currentValue, byHierarchy);
    }, undefined);
}

export const checkRoleHierarchyByToken = (rolePermited: RoleKeyPermiteds,
    byHierarchy: boolean = true, token?: string): boolean => {
    const checkToken = token || ls.getLocalStorageToken();
    if (!checkToken) return false;
    const decode: any = jwt.decode(checkToken);
    return checkRoleHierarchy(decode.role, rolePermited, byHierarchy);
}

export const checVigilanceByToken = (token?: string): boolean => {
    const checkToken = token || ls.getLocalStorageToken();
    if (!checkToken) return false;
    const decode: any = jwt.decode(checkToken);
    return decode.vigilance !== true;
}

export const checkRoleHierarchy = (role: RoleKeys,
    rolePermited: RoleKeyPermiteds,
    byHierarchy: boolean = true): boolean => {

    const hierarchyRole = hierarchyRoles.find(h => h.key && h.key?.toUpperCase() === role?.toUpperCase());
    const hierarchyRolePermited = hierarchyRoles.find(h => h.key && h.key?.toUpperCase() === rolePermited?.toUpperCase());

    if (!byHierarchy) return !!hierarchyRole && !!hierarchyRolePermited && hierarchyRole.value === hierarchyRolePermited.value;

    return !!hierarchyRole && !!hierarchyRolePermited && hierarchyRole.value >= hierarchyRolePermited.value;
}

export const getRoleDescriptionByToken = (token?: string) => {
    const checkToken = token || ls.getLocalStorageToken();
    if (!checkToken) return convertRoleRules(RoleRules.no_role)?.description;
    const decode: any = jwt.decode(checkToken);
    if (!decode.role) return convertRoleRules(RoleRules.no_role)?.description;
    return decode.role ? convertRoleRules(decode.role)?.description : convertRoleRules(RoleRules.no_role)?.description;
}

export const getRoleValueByToken = (token?: string) => {
    const checkToken = token || ls.getLocalStorageToken();
    if (!checkToken) return convertRoleRules(RoleRules.no_role)?.keyValue;
    const decode: any = jwt.decode(checkToken);
    if (!decode.role) return convertRoleRules(RoleRules.no_role)?.keyValue;
    return decode.role ? convertRoleRules(decode.role)?.keyValue : convertRoleRules(RoleRules.no_role)?.keyValue;
}