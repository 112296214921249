import { IPerformRequestResult } from "../../../utils/interfaces";
import { IFormDataContactList } from "../../components/generics/entities";
import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class ContactListService extends GenericService {
    allContactLists = async (search?: string | undefined): Promise<IPerformRequestResult<IFormDataContactList[]>> => await super.findAll(Entities.CONTACT_LIST, true, search);
    findContactListById = async (_id: string | null): Promise<IPerformRequestResult<IFormDataContactList>> => await super.findById(Entities.CONTACT_LIST, false, _id);
    findAllContactListByGuideId = async (networkGymId: string | null, search?: string | undefined): Promise<IPerformRequestResult<IFormDataContactList[]>> => await super.findByReferenceId(Entities.CONTACT_LIST, false,
        EntitiesService(Entities.GUIDE).singular, networkGymId, search);
    saveContactList = async (data: any): Promise<IPerformRequestResult<IFormDataContactList>> => await super.save(Entities.CONTACT_LIST, true, data);
}
