import EntitiesService, { Entities } from "../generics/EntitiesService";
import GenericService from "../generics/GenericService";

export default class ScriptService extends GenericService {
    allScripts = async (search?: string | undefined) => await super.findAll(Entities.SCRIPT, true, search);
    findScriptById = async (_id: string | null) => await super.findById(Entities.SCRIPT, false, _id);
    findAllScriptByGuideId = async (guideId: string | null, search?: string | undefined) => await super.findByReferenceId(Entities.SCRIPT, false, 
        EntitiesService(Entities.GUIDE).singular, guideId, search);
    saveScript = async (data: any) => await super.save(Entities.SCRIPT, true, data);
}
